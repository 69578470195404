import { Heading, Text } from "@grow-therapy-team/sprout-ui";
import NotFoundBlocks from "../assets/not-found-blocks.svg";
import { FallbackProps } from "react-error-boundary";
import { useEffect } from "react";
import { logger } from "../datadog/logger";

function SubtitleDefault() {
  return (
    <>
      Our page is out of order at the moment. Please try again later, or contact
      support through your portal.
    </>
  );
}

export function ErrorPage({
  title = "An unexpected error occurred.",
  subtitle = <SubtitleDefault />,
  errorCode,
  error,
}: {
  errorCode?: number;
  title?: string;
  subtitle?: string | JSX.Element;
} & Partial<FallbackProps>) {
  useEffect(
    function logError() {
      logger.error(
        "The page crashed after encountering an unexpected error; displaying fallback",
        error,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6 bg-neutral_rebrand-300 px-16">
      <img src={NotFoundBlocks} alt="" />
      {errorCode && (
        <Text variant="lg" className="text-neutral-600 font-medium text-center">
          {errorCode}
        </Text>
      )}
      <Heading variant="3xl" className="font-normal text-center" as="h1">
        {title}
      </Heading>
      <Text as="div" className="text-neutral_rebrand-700 text-center">
        {subtitle}
      </Text>
    </div>
  );
}
