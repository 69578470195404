import { useEffect } from "react";
import { logger, setLoggerGlobalContext } from "./logger";
import { useAtomValue } from "jotai";
import { twilioRoomAtom } from "../twilio/state";
import { useStoredAudioVideoSettings } from "../twilio/useStoredAudioVideoSettings";
import { participantSidsAtom, roomToDiagnosticInfo } from "../twilio";

/**
 * Sets application-specific DataDog global context for logging i.e. all logs
 * will have this information. This should help debug with
 * application/user-specific context.
 *
 * You can add info to he global context as desired, but take care to not add
 * PHI/PII.
 */
export function useInitGlobalDDLoggerContext(
  additionalContext: Record<string, unknown> = {},
) {
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const remoteParticipantSids = useAtomValue(participantSidsAtom);
  const storedAudioVideoSettings =
    useStoredAudioVideoSettings()?.storedAudioVideoSettings;

  useEffect(
    function setGlobalLoggerDDContext() {
      (async () => {
        try {
          setLoggerGlobalContext({
            storedAudioVideoSettings: storedAudioVideoSettings,
            ...(twilioRoom
              ? {
                  twilio: roomToDiagnosticInfo(twilioRoom),
                }
              : {}),
            remoteParticipantSids,
            ...additionalContext,
          });
        } catch (error) {
          logger.error(
            "Unable to set global logger context",
            {},
            error as Error,
          );
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteParticipantSids, storedAudioVideoSettings, twilioRoom],
  );
}
