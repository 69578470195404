import { AnalyticsBrowser } from "@segment/analytics-next";
import {
  SegmentTrackMetricEnum,
  EventTypeEnum,
  TrackParams,
  UserViewedParams,
  UserClickedParams,
  FormSubmittedParams,
} from "./types";
import { UserType } from "../types";
import { getSegmentConfig } from "./config";
import { getGrowUniversalId, isAutomatedUser } from "./utils";
import { useMemo } from "react";

const { segmentWriteKey } = getSegmentConfig();

let segmentAnalytics: AnalyticsBrowser;
let segmentUserType: UserType | undefined = undefined;
const anonymousId: string | undefined = undefined;
const growUniversalId = getGrowUniversalId();

export const loadSegment = (userType: UserType, anonymousId?: string): void => {
  if (isAutomatedUser(anonymousId)) return;
  const analytics = AnalyticsBrowser.load(
    { writeKey: segmentWriteKey },
    { storage: { stores: ["cookie", "localStorage", "memory"] } },
  );

  analytics.setAnonymousId(anonymousId);
  segmentAnalytics = analytics;
  segmentUserType = userType;
};

export const analytics = {
  identify: (
    userId?: string,
    traits: Record<string, unknown> = {},
    options: Record<string, unknown> = {},
  ): void => {
    if (!segmentAnalytics) return;
    segmentAnalytics.identify(userId, traits, {
      anonymousId,
      ...options,
    });
  },
  page: (
    name: string,
    properties: Record<string, unknown> = {},
    options: Record<string, unknown> = {},
  ): void => {
    if (!segmentAnalytics) return;
    segmentAnalytics.page(undefined, name, properties, {
      anonymousId,
      ...options,
    });
  },
  track: (
    eventName: SegmentTrackMetricEnum,
    properties: Record<string, unknown>,
    options: Record<string, unknown> = {},
  ): void => {
    if (!segmentAnalytics) return;
    segmentAnalytics.track(eventName, properties, {
      anonymousId,
      userType: segmentUserType,
      growUniversalId: growUniversalId,
      ...options,
    });
  },
};

function trackEvent<P extends EventTypeEnum>(
  eventName: SegmentTrackMetricEnum,
  type: P,
  properties: Partial<TrackParams>,
): void {
  if (!segmentAnalytics || !type) return;

  analytics.track(eventName, {
    type,
    userType: segmentUserType,
    ...properties,
  });
}

export const useTrackEvent = (): {
  userViewed: <P extends EventTypeEnum>(
    type: NonNullable<P>,
    properties: UserViewedParams<P>,
  ) => void;
  userClicked: <P extends EventTypeEnum>(
    type: NonNullable<P>,
    properties: UserClickedParams<P>,
  ) => void;
  formSubmitted: <P extends EventTypeEnum>(
    type: NonNullable<P>,
    properties: FormSubmittedParams<P>,
  ) => void;
} => {
  return useMemo(
    () => ({
      userViewed: <P extends EventTypeEnum>(
        type: NonNullable<P>,
        properties: UserViewedParams<P>,
      ): void => {
        trackEvent(SegmentTrackMetricEnum.USER_VIEWED, type, properties);
      },
      userClicked: <P extends EventTypeEnum>(
        type: NonNullable<P>,
        properties: UserClickedParams<P>,
      ): void => {
        trackEvent(SegmentTrackMetricEnum.USER_CLICKED, type, properties);
      },
      formSubmitted: <P extends EventTypeEnum>(
        type: NonNullable<P>,
        properties: FormSubmittedParams<P>,
      ): void => {
        trackEvent(SegmentTrackMetricEnum.FORM_SUBMITTED, type, properties);
      },
    }),
    [],
  );
};
