import { useCallback, useEffect, useMemo, useRef } from "react";
import { RemoteParticipant, RemoteTrackPublication } from "twilio-video";
import { TrackSubscriptionHandler, TrackEvent, TrackSource } from "./types";
import {
  remoteScreenShareParticipantAtom,
  remoteScreenShareTrackAtom,
} from "./state";
import { useSetAtom } from "jotai";
import {
  remoteParticipantToDiagnosticInfo,
  trackToDiagnosticInfo,
  trackStatusByParticipantIdAtom,
  parseTrackName,
  remoteTracksByParticipantIdAtom,
  addTrackToParticipant,
  removeTrackFromParticipant,
  useVideoQualityCallbacks,
  gracefullyHandleTrackEvents,
} from ".";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { Mutex } from "async-mutex";
import { useRemoteTrackEventListeners } from "./useRemoteTrackEventListeners";

export type UseRemoteVideoProps = {
  remoteParticipant: RemoteParticipant;
  onTrackSubscribed?: TrackSubscriptionHandler;
  onTrackUnsubscribed?: TrackSubscriptionHandler;
};

export function useRemoteVideo({
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
}: UseRemoteVideoProps) {
  const remoteParticipantVideoRef = useRef<HTMLVideoElement>(null);
  const setRemoteScreenShareParticipant = useSetAtom(
    remoteScreenShareParticipantAtom,
  );
  const setRemoteScreenShareVideoTrack = useSetAtom(remoteScreenShareTrackAtom);

  const setVideoTrackState = useSetAtom(trackStatusByParticipantIdAtom);
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);
  const { degradeVideoQuality, revertVideoQuality } =
    useVideoQualityCallbacks();

  useEffect(
    function handleParticipantTrackSubscriptions() {
      if (!remoteParticipant) return;

      const eventToCallbackMap: Omit<
        Record<TrackEvent, TrackSubscriptionHandler>,
        "trackSwitchedOff" | "trackSwitchedOn"
      > = {
        trackSubscribed: async (track, ...otherArgs) => {
          const element = remoteParticipantVideoRef.current;
          if (track.kind !== "video" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);

          if (source === TrackSource.Screen && remoteParticipant) {
            // TODO: We may be able to get rid of setting the screen share track
            // now that we're setting all remote video tracks
            setRemoteScreenShareVideoTrack(track);
            setRemoteScreenShareParticipant(remoteParticipant);
          }

          if (source === TrackSource.Camera) {
            track.attach(element);
            onTrackSubscribed?.(track, ...otherArgs);
            setVideoTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isVideoOff: !track.isEnabled,
              },
            }));
          }

          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          await degradeVideoQuality();

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant video track subscribed",
            },
          );
        },
        trackEnabled: (track) => {
          if ((track as unknown as RemoteTrackPublication).kind !== "video")
            return;
          setVideoTrackState((prev) => ({
            ...prev,
            [remoteParticipant.identity]: {
              ...prev[remoteParticipant.identity],
              isVideoOff: false,
            },
          }));
        },
        trackUnsubscribed: async (track, ...otherArgs) => {
          const element = remoteParticipantVideoRef.current;
          if (track.kind !== "video" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);

          if (source === TrackSource.Screen && remoteParticipant) {
            setRemoteScreenShareParticipant(undefined);
            setRemoteScreenShareVideoTrack(undefined);
          }
          if (source === TrackSource.Camera) {
            track.detach(element);
            element.srcObject = null;
            onTrackUnsubscribed?.(track, ...otherArgs);
            setVideoTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isVideoOff: true,
              },
            }));
          }

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          await revertVideoQuality();

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant video track unsubscribed",
            },
          );
        },
      };

      // Attach already published tracks
      for (const trackPublication of remoteParticipant.videoTracks.values()) {
        if (!trackPublication.isSubscribed) continue;
        const track = trackPublication.track;
        if (!track) continue;
        eventToCallbackMap.trackSubscribed(
          track,
          trackPublication,
          remoteParticipant,
        );
      }

      Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
        remoteParticipant.on(event, callback);
      });

      return function cleanup() {
        Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
          remoteParticipant.off(event, callback);
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      remoteParticipant,
      remoteParticipantVideoRef,
      onTrackSubscribed,
      onTrackUnsubscribed,
    ],
  );

  return remoteParticipantVideoRef;
}

// V2

const videoTrackEventMutex = new Mutex();

function useGetRemoteVideoTrackEventListeners({
  getVideoElement,
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
}: UseRemoteVideoProps & {
  getVideoElement: () => HTMLAudioElement | null;
}) {
  const setRemoteScreenShareParticipant = useSetAtom(
    remoteScreenShareParticipantAtom,
  );
  const setRemoteScreenShareVideoTrack = useSetAtom(remoteScreenShareTrackAtom);

  const setVideoTrackState = useSetAtom(trackStatusByParticipantIdAtom);
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);
  const { degradeVideoQuality, revertVideoQuality } =
    useVideoQualityCallbacks();

  return useMemo(
    () => {
      if (!remoteParticipant) return;
      return gracefullyHandleTrackEvents(videoTrackEventMutex, {
        trackSubscribed: async (track, ...otherArgs) => {
          const element = getVideoElement();
          if (track.kind !== "video" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);

          if (source === TrackSource.Screen && remoteParticipant) {
            // TODO: We may be able to get rid of setting the screen share track
            // now that we're setting all remote video tracks
            setRemoteScreenShareVideoTrack(track);
            setRemoteScreenShareParticipant(remoteParticipant);
          }

          if (source === TrackSource.Camera) {
            track.attach(element);
            onTrackSubscribed?.(track, ...otherArgs);
            setVideoTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isVideoOff: !track.isEnabled,
              },
            }));
          }

          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          await degradeVideoQuality();

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant video track subscribed",
            },
          );
        },
        trackEnabled: (track) => {
          if ((track as unknown as RemoteTrackPublication).kind !== "video")
            return;
          setVideoTrackState((prev) => ({
            ...prev,
            [remoteParticipant.identity]: {
              ...prev[remoteParticipant.identity],
              isVideoOff: false,
            },
          }));
        },
        trackUnsubscribed: async (track, ...otherArgs) => {
          const element = getVideoElement();
          if (track.kind !== "video" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);

          if (source === TrackSource.Screen && remoteParticipant) {
            setRemoteScreenShareParticipant(undefined);
            setRemoteScreenShareVideoTrack(undefined);
          }
          if (source === TrackSource.Camera) {
            track.detach(element);
            element.srcObject = null;
            onTrackUnsubscribed?.(track, ...otherArgs);
            setVideoTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isVideoOff: true,
              },
            }));
          }

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          await revertVideoQuality();

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant video track unsubscribed",
            },
          );
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getVideoElement,
      onTrackSubscribed,
      onTrackUnsubscribed,
      remoteParticipant,
    ],
  );
}

export function useRemoteVideoV2({
  remoteParticipant,
  ...otherProps
}: UseRemoteVideoProps) {
  const remoteParticipantVideoRef = useRef<HTMLVideoElement>(null);
  const getVideoElement = useCallback(
    () => remoteParticipantVideoRef.current,
    [],
  );

  const remoteTrackEventListeners = useGetRemoteVideoTrackEventListeners({
    ...otherProps,
    getVideoElement,
    remoteParticipant,
  });

  useRemoteTrackEventListeners({
    trackKind: "video",
    remoteParticipant,
    remoteTrackEventListeners,
  });

  return remoteParticipantVideoRef;
}
