import { useMemo } from "react";
import { useInitGlobalDDLoggerContext } from "../datadog";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";

export function useInitProviderGlobalDDLoggerContext() {
  const providerShortId =
    useGetCurrentProviderQuery().data?.currentProvider?.shortId;
  const additionalContext = useMemo(
    () =>
      providerShortId
        ? {
            providerShortId,
          }
        : {},
    [providerShortId],
  );
  useInitGlobalDDLoggerContext(additionalContext);
}
