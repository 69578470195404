import { atom } from "jotai";

export type NavAtom = {
  previousRoute?: string;
  currentRoute?: string;
};

export const navAtom = atom<NavAtom>({});

export const currentRouteAtom = atom(
  (get) => get(navAtom).currentRoute,
  /**
   * Sets the previous route to the current route and the current route to the given route
   */
  (get, set, update: string) => {
    const previousRoute = get(navAtom).currentRoute;
    set(navAtom, { ...get(navAtom), previousRoute, currentRoute: update });
  },
);

export const previousRouteAtom = atom((get) => get(navAtom).previousRoute);
