import { Button, Icon, Tag, TagUse, Text } from "@grow-therapy-team/sprout-ui";
import { Toast } from "../../components";
import { twMerge } from "tailwind-merge";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import { hasRecordingAtom } from "../session-recording/state";
import { useAtomValue } from "jotai";

type Props = {
  className: string;
  onClose: () => void;
  onCleanup: () => void;
  onClickScheduleFollowUp: () => void;
};

function ToastContent({
  onClose,
  onCleanup,
  className,
  title,
  subtitle,
}: {
  onClose: () => void;
  onCleanup: () => void;
  className: string;
  title: string;
  subtitle: React.ReactNode;
}) {
  return (
    <Toast
      className={twMerge("p-4 w-fit", className)}
      onClose={onClose}
      onCleanup={onCleanup}
      closeButtonClassName="self-start mt-1"
    >
      <div className="flex gap-4 align-start flex-col">
        <div className="flex justify-between">
          <Tag
            use={TagUse.Lilac}
            className="flex flex-row justify-start items-center gap-2 px-2 py-1"
          >
            <Icon
              aria-hidden
              icon={faLightbulb}
              className="text-neutral-800 font-medium"
            />
            <Text variant="xs" className="text-neutral-800 font-medium">
              {title}
            </Text>
          </Tag>
        </div>
        <Text variant="sm" className="text-left sm:w-80">
          {subtitle}
        </Text>
      </div>
    </Toast>
  );
}

export function PromptFollowUpToastWrapper({
  hasSeenSessionSummaryNudge,
  onClickScheduleFollowUp,
  ...props
}: Props & { hasSeenSessionSummaryNudge: boolean }) {
  const hasRecording = useAtomValue(hasRecordingAtom);
  if (hasRecording && !hasSeenSessionSummaryNudge) {
    return (
      <ToastContent
        {...props}
        title="Documentation Tip"
        subtitle='Click "Complete Session" to receive the session summary & AI suggestions 5 minutes after the session ends.'
      />
    );
  } else {
    // TODO(CARED-1599): toast should handle if a follow up is already scheduled
    return (
      <ToastContent
        {...props}
        title="5 Minute Reminder"
        subtitle={
          <Text variant="sm" className="text-left sm:w-80">
            Consider wrapping up your discussion, and setting up your next
            appointment from{" "}
            <Button
              onClick={onClickScheduleFollowUp}
              use="unstyled"
              className="underline decoration-neutral-500 inline p-0"
            >
              the appointments tab
            </Button>
            .
          </Text>
        }
      />
    );
  }
}
