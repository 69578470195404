import { useEffect } from "react";
import { logger } from "../datadog/logger";

export function reloadWhenPersisted(event: PageTransitionEvent) {
  if (event.persisted) {
    logger.info("Reloading from back-forward cache");
    location.reload();
  }
}

/**
 * Adds a listener that reloads when loading from the browser's back-forward
 * cache. Runs on mount.
 *
 * https://web.dev/articles/bfcache
 */
export function useBackForwardCacheReload() {
  useEffect(
    function handlePageShowListener() {
      window.addEventListener("pageshow", reloadWhenPersisted);

      return function cleanup() {
        window.removeEventListener("pageshow", reloadWhenPersisted);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
