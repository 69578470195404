import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { participantsByIdAtom, twilioRoomAtom } from "../../twilio/state";
import { logger } from "../../datadog/logger";
import { toast } from "@grow-therapy-team/sprout-ui";
import { removeImmutablyFromSet } from "../../utils";
import { useSetAtom } from "jotai";
import { deadlinesByVisitorIdFamily } from "../delayed-entry/state";
import { joiningVisitorIdsAtom } from "../state";
import { differenceInMilliseconds, secondsToMilliseconds } from "date-fns";
import { Statsig } from "statsig-react";
import { delayedEntryGate } from "../../statsig/gates";

export function useWaitForClientToJoinCallback() {
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const getParticipantsByVisitorId = useAtomCallback(
    useCallback((get) => get(participantsByIdAtom), []),
  );
  const setJoiningVisitorIds = useSetAtom(joiningVisitorIdsAtom);
  const getVisitorDeadline = useAtomCallback(
    useCallback(
      (get, _, visitorId: string) => get(deadlinesByVisitorIdFamily(visitorId)),
      [],
    ),
  );
  const hasOtherJoiningVisitors = useAtomCallback(
    useCallback((get) => get(joiningVisitorIdsAtom)?.size, []),
  );

  return useCallback(
    ({
      visitorId,
      onTimeout,
      onFailure,
    }: {
      visitorId: string;
      onTimeout?: () => void;
      onFailure?: () => void;
    }) => {
      const isDelayedEntry = Statsig.checkGate(delayedEntryGate);
      const base = secondsToMilliseconds(10);
      let timeout = base;
      if (isDelayedEntry && getVisitorDeadline(visitorId) > new Date()) {
        timeout += differenceInMilliseconds(
          getVisitorDeadline(visitorId),
          new Date(),
        );
      }

      return setTimeout(() => {
        onTimeout?.();
        setJoiningVisitorIds((prev) => removeImmutablyFromSet(prev, visitorId));
        if (
          hasOtherJoiningVisitors() ||
          getParticipantsByVisitorId()[visitorId] ||
          !getTwilioRoom()
        )
          return;
        toast.error(
          "The patient may have left the waiting room while being admitted",
        );
        logger.warn("Timed out waiting for visitor to join", { visitorId });
        onFailure?.();
      }, timeout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
