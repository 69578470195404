import { IdToken } from "@auth0/auth0-react";
import { atom } from "jotai";
import { createDerivedWritableAtom } from "../utils";

export type AuthState = {
  idClaims?: IdToken;
  isShowingTimeoutToast?: boolean;
};

export const authAtom = atom<AuthState>({});

export const idClaimsAtom = createDerivedWritableAtom(authAtom, "idClaims");

export const isShowingTimeoutToastAtom = createDerivedWritableAtom(
  authAtom,
  "isShowingTimeoutToast",
);
