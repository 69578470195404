import { useCallback, useState } from "react";
import { useAdmitClientCallback } from "./useAdmitClientCallback";
import { useAtomCallback } from "jotai/utils";
import { twilioRoomAtom } from "../../twilio/state";
import { useCompleteTwilioSessionCallback } from "../useCompleteTwilioSessionCallback";

export type StartNewClientSessionCallback = (
  visitorUuid: string,
  visitorName: string,
  patientShortId: string,
) => Promise<boolean | undefined>;

export function useStartNewClientSessionCallback(): [
  StartNewClientSessionCallback,
  { loading?: boolean },
] {
  const [awaitingCallbacks, setAwaitingCallbacks] = useState(false);
  const completeSession = useCompleteTwilioSessionCallback();
  const [admitClient, { loading: waitingForParticipantToJoin }] =
    useAdmitClientCallback();
  const getRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const loading = awaitingCallbacks || waitingForParticipantToJoin;
  const callback = useCallback(
    async (
      visitorUuid: string,
      visitorName: string,
      patientShortId: string,
    ) => {
      const room = getRoom();

      if (!room) return;
      setAwaitingCallbacks(true);
      completeSession();
      const status = !!(await admitClient(
        visitorUuid,
        visitorName,
        patientShortId,
      ));
      setAwaitingCallbacks(false);
      return status;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [callback, { loading }];
}
