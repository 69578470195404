import { PROVIDER_PATH } from "../config";
import { Env } from "../types";
import { getEnvFromHostName } from "../utils";

export type Auth0Config = {
  domain: string;
  clientId: string;
  audience: string;
  scope: string;
  loginUri: string;
  redirectUri: string;
};

const SCOPE = "openid email profile offline_access";

export const PROVIDER_LOGIN_PATH = "/api/provider/login";

export function getAuth0Config(): Auth0Config {
  const env = getEnvFromHostName(window.location.hostname);
  switch (env) {
    case Env.PROD:
      return {
        domain: "growtherapy.us.auth0.com",
        clientId: "guGGikPlebDR5Qa1yJ578syyaCYlIOmj",
        audience: "https://telehealth.growtherapy.com/api",
        scope: SCOPE,
        loginUri: `${location.origin}${PROVIDER_LOGIN_PATH}`,
        redirectUri: `${location.origin}${PROVIDER_PATH}`,
      };
    case Env.DEV:
      return {
        domain: import.meta.env.VITE_DEVELOPMENT_AUTH0_DOMAIN as string,
        clientId: import.meta.env.VITE_DEVELOPMENT_AUTH0_CLIENT_ID as string,
        audience: import.meta.env.VITE_DEVELOPMENT_AUTH0_AUDIENCE as string,
        scope: SCOPE,
        loginUri: `${location.origin}${PROVIDER_LOGIN_PATH}`,
        redirectUri: `${location.origin}${PROVIDER_PATH}`,
      };
    default:
      return {
        domain: "growtherapystaging.us.auth0.com",
        clientId: "HUCGLL3eECkRLo0xhu5Dp7sOygBHmNvL",
        audience: "https://telehealth.growtherapystaging.com/api",
        scope: SCOPE,
        loginUri: `${location.origin}${PROVIDER_LOGIN_PATH}`,
        redirectUri: `${location.origin}${PROVIDER_PATH}`,
      };
  }
}
