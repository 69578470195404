import { BlockedPermissionMessageWrapper as BlockedPermissionMessage } from "../../../components/settings-drawer/BlockedPermissionMessage";
import { NoAccessVariant } from "../../../twilio/audio-video-controls/NoAccessToast";

export type BlockedPermissionPageContentProps = {
  isAudioPermissionDenied: boolean;
  isVideoPermissionDenied: boolean;
};

/**
 * When using this component, make sure any of the following hooks are executed
 * to be sure the application stays in-sync with the user's device permissions:
 * - useUpdateAvailableDevicesInterval
 * - useListenForAvailableDeviceChanges
 */
export function BlockedPermissionPageContent({
  isAudioPermissionDenied,
  isVideoPermissionDenied,
}: BlockedPermissionPageContentProps) {
  const noAccessVariant = isVideoPermissionDenied
    ? NoAccessVariant.Camera
    : isAudioPermissionDenied
    ? NoAccessVariant.Microphone
    : undefined;

  return (
    <BlockedPermissionMessage
      className="border-neutral-600 rebrand:border-neutral_rebrand-600"
      noAccessVariant={noAccessVariant}
    />
  );
}
