import { ControlPanelButton, Toast, ToastVariant } from "../../components";
import { ComponentProps, useState } from "react";
import { faPlay, faPause, faPenLine } from "@fortawesome/pro-solid-svg-icons";
import toast from "react-hot-toast";
import { Text } from "@grow-therapy-team/sprout-ui";

type ControlPanelButtonProps = Omit<
  ComponentProps<typeof ControlPanelButton>,
  "aria-label" | "icon" | "iconName" | "iconVariant" | "iconDefinition"
>;
type RecordingControlProps = ControlPanelButtonProps & {
  isRecording?: boolean;
  consentPending: boolean;
  onRecordingToggle: (shouldRecord: boolean) => void | Promise<void>;
  onPendingConsentClick?: () => void | Promise<void>;
};

function RecordingControlErrorToast({ id: toastId }: { id: string }) {
  return (
    <Toast
      className="bottom-center-toast"
      variant={ToastVariant.Error}
      data-testid="recording-control-error-toast"
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <Text>
        Something went wrong while toggling recording. Please try again.
      </Text>
    </Toast>
  );
}

export function RecordingControl({
  isRecording,
  consentPending,
  onRecordingToggle,
  onPendingConsentClick,
  ...props
}: RecordingControlProps) {
  const [isLoading, setIsLoading] = useState(false);

  let buttonLabel = `Toggle recording ${isRecording ? "off" : "on"}`;
  let buttonIcon = isRecording ? faPause : faPlay;

  if (consentPending) {
    buttonLabel = "Request transcription consent";
    buttonIcon = faPenLine;
  }

  return (
    <ControlPanelButton
      {...props}
      loading={isLoading}
      aria-label={buttonLabel}
      iconDefinition={buttonIcon}
      data-testid="recording-control"
      onClick={async () => {
        if (!consentPending) {
          try {
            setIsLoading(true);
            await onRecordingToggle(!isRecording);
          } catch (error) {
            toast.custom(RecordingControlErrorToast, {
              position: "bottom-center",
            });
          } finally {
            setIsLoading(false);
          }
        } else if (onPendingConsentClick) {
          await onPendingConsentClick();
        }
      }}
    />
  );
}
