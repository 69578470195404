import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { allVisitorChatParticipantsAtom } from "../state";
import { parseIdentity } from "../../twilio/utils";
import { logger } from "../../datadog/logger";

export function useGetVisitorChatUser() {
  const getAllVisitorChatParticipants = useAtomCallback(
    useCallback((get) => get(allVisitorChatParticipantsAtom), []),
  );

  return useCallback(
    async (visitorId: string) => {
      const visitorChatParticipant = getAllVisitorChatParticipants().find(
        (participant) =>
          parseIdentity(participant.identity ?? "").id === visitorId,
      );
      try {
        return await visitorChatParticipant?.getUser();
      } catch (error) {
        logger.error(
          "Error getting client's chat user",
          { visitorId },
          error as Error,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
