import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Text, Tooltip } from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

interface HideSelfViewButtonProps {
  onClick: () => void;
  isVideoEnabled: boolean;
  isSelfViewHidden: boolean;
  position?: string;
  className?: string;
  isMobile: boolean;
  isInSession: boolean;
}

export const HideSelfViewButton = forwardRef<
  HTMLButtonElement,
  HideSelfViewButtonProps
>(function HideSelfViewButton(
  {
    onClick,
    isVideoEnabled,
    isSelfViewHidden,
    position,
    className,
    isMobile,
    isInSession,
  },
  ref,
) {
  return (
    <div
      className={twMerge(
        classNames(
          "absolute z-10 flex items-center max-h-8",
          position,
          className,
          {
            "top-2 right-2": !position,
          },
        ),
      )}
    >
      <HideSelfViewTooltip
        isSelfViewHidden={isSelfViewHidden}
        isVideoEnabled={isVideoEnabled}
        isInSession={isInSession}
      >
        <Button
          ref={ref}
          onClick={onClick}
          className={classNames(
            "group/self-view-button opacity-0 focus:opacity-100 px-1.5 h-8 flex items-center justify-center shadow-border-none focus-visible:shadow-border-none bg-neutral_rebrand-800 hover:bg-neutral_rebrand-800 active:bg-neutral_rebrand-800 text-neutral_rebrand-300 active:text-neutral_rebrand-300 outline-transparent focus-visible:outline-none focus-visible:outline-neutral_rebrand-800 focus-visible:outline-offset-0 border-2 border-transparent focus-visible:border-lilac-400",
            {
              "group-hover/video-thumbnail:opacity-100 w-8 hover:w-auto":
                !isSelfViewHidden,
              "opacity-100": isSelfViewHidden,
            },
          )}
        >
          {!isSelfViewHidden && !isMobile && (
            <Text
              variant="sm"
              aria-hidden
              className="mr-1 font-normal hidden group-hover/self-view-button:inline-block"
            >
              Hide self view
            </Text>
          )}
          {isSelfViewHidden && (
            <Text
              variant="sm"
              className="mr-1 inline"
              data-testid="hide-self-view-button.hidden"
            >
              (You)
            </Text>
          )}
          <Icon icon={faEyeSlash} aria-hidden className="w-4" />
          <span className="sr-only">
            {isSelfViewHidden ? "Show" : "Hide"} self view
          </span>
        </Button>
      </HideSelfViewTooltip>
    </div>
  );
});

const IN_SESSION_TOOLTIP_TEXT = "Others can still see your video";
const NOT_IN_SESSION_TOOLTIP_TEXT = "Others will still see your video";

function HideSelfViewTooltip({
  isSelfViewHidden,
  isVideoEnabled,
  isInSession,
  children,
}: PropsWithChildren<
  Pick<
    HideSelfViewButtonProps,
    "isSelfViewHidden" | "isVideoEnabled" | "isInSession"
  >
>) {
  // This state starts undefined so that the tooltip operates in an uncontrolled
  // manner (show on hover) until interacted with.
  const [initialShowTooltip, setInitialShowTooltip] = useState<boolean>();
  const tooltipTimeoutRef = useRef<number | null>(null);
  const [srAlert, setSrAlert] = useState<string>("");
  const srAlertTimeoutRef = useRef<number | null>(null);

  // We temporarily take control of the tooltip visibility to show it when the
  // user hides their self view, if they had video enabled. This is to help the
  // user understand that others can still see their video. After 5 seconds, we
  // hide the tooltip again. The tooltip's `afterHide` callback will be
  // immediately invoked when the tooltip is hidden, setting the state back to
  // `undefined` and thus returning the tooltip to its uncontrolled, default
  // (show on hover) behavior.
  useEffect(() => {
    if (isSelfViewHidden && isVideoEnabled) {
      setInitialShowTooltip(true);
      // The SR alert needs to be added to the DOM after the live region, or
      // else it won't be read.
      srAlertTimeoutRef.current = window.setTimeout(() => {
        setSrAlert(
          isInSession ? IN_SESSION_TOOLTIP_TEXT : NOT_IN_SESSION_TOOLTIP_TEXT,
        );
      }, 100);
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setInitialShowTooltip(false);
      }, 5000);
    } else {
      setSrAlert("");
    }
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      if (srAlertTimeoutRef.current) {
        clearTimeout(srAlertTimeoutRef.current);
      }
    };
  }, [isSelfViewHidden, isVideoEnabled, isInSession]);

  return (
    <>
      {isSelfViewHidden && isVideoEnabled && (
        <span className="sr-only" aria-live="polite">
          {srAlert}
        </span>
      )}
      <Tooltip
        content={
          isInSession ? IN_SESSION_TOOLTIP_TEXT : NOT_IN_SESSION_TOOLTIP_TEXT
        }
        forceInteractive={false}
        disabled={!isSelfViewHidden || !isVideoEnabled}
        isOpen={initialShowTooltip}
        // Reset the state to `undefined` after the tooltip is hidden to return it
        // to uncontrolled mode.
        afterHide={() => setInitialShowTooltip(undefined)}
        delayHide={0}
      >
        {children}
      </Tooltip>
    </>
  );
}
