import { Text } from "@grow-therapy-team/sprout-ui";
import { useClientMood } from "../../hooks/measures/useClientMood";
import { ComponentProps } from "react";

type ClientMoodProps = ComponentProps<typeof Text> & {
  mood?: string;
};

export function ClientMood({ mood, ...textProps }: ClientMoodProps) {
  if (!mood) return null;

  return (
    <Text
      as="span"
      variant="sm"
      className="fs-exclude italic text-neutral-700 self-start"
      data-dd-privacy="mask"
      data-dd-action-name="Client Mood"
      {...textProps}
    >
      {`Reports feeling ${mood}`}
    </Text>
  );
}

type ClientMoodWrapperProps = Omit<ClientMoodProps, "mood"> & {
  visitorUuid?: string;
};

export function ClientMoodWrapper({
  visitorUuid,
  ...textProps
}: ClientMoodWrapperProps) {
  const mood = useClientMood(visitorUuid);

  return <ClientMood {...textProps} mood={mood} />;
}
