import { useAtomValue } from "jotai";
import { visitorPresenceMapTwilioTokenAtom } from "../state";
import { useInitTwilioSyncClient as useBaseInitTwilioSyncClient } from "../../twilio/sync/useInitTwilioSyncClient";
import { useCallback, useRef } from "react";
import { useAtomCallback } from "jotai/utils";
import { twilioRoomAtom } from "../../twilio";
import toast from "react-hot-toast";
import { ConnectionErrorToast } from "./ConnectionErrorToast";

export function useInitTwilioSyncClient() {
  const showedConnectionErrorToastIdRef = useRef<string>();

  const checkInSession = useAtomCallback(
    useCallback((get) => !!get(twilioRoomAtom), []),
  );

  useBaseInitTwilioSyncClient({
    jwt: useAtomValue(visitorPresenceMapTwilioTokenAtom),
    onConnectionError: useCallback(
      () => {
        if (showedConnectionErrorToastIdRef.current || checkInSession()) {
          return;
        }

        showedConnectionErrorToastIdRef.current = toast.custom(
          ({ id: toastId }) => {
            return (
              <ConnectionErrorToast
                onClose={() => {
                  toast.remove(toastId);
                }}
              />
            );
          },
          {
            duration: Infinity,
            position: "top-center",
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  });
}
