import { toast } from "react-hot-toast";
import { EndSessionButton } from "../components";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useCompleteTwilioSessionCallback } from "./useCompleteTwilioSessionCallback";
import { SessionCompletedToastWrapper as SessionCompletedToast } from "./SessionCompletedToast";
import { hasRecordingAtom } from "./session-recording/state";
import { useSetAtom } from "jotai";

export function CompleteSessionButton() {
  const completeSession = useCompleteTwilioSessionCallback();
  const setHasRecording = useSetAtom(hasRecordingAtom);
  return (
    <EndSessionButton
      onClick={() => {
        sendLoggingEvents(TrackingEvents.PROVIDER_SESSION_COMPLETE);
        completeSession();
        toast.custom(
          ({ id }) => (
            <SessionCompletedToast
              className="bottom-center-toast"
              onClose={() => toast.remove(id)}
              onCleanup={() => setHasRecording(false)}
            />
          ),
          {
            position: "bottom-center",
          },
        );
      }}
      aria-label="Complete session"
    >
      Complete Session
    </EndSessionButton>
  );
}
