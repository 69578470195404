import { useEffect } from "react";
import { useGetTelehealthSessionInfoCallback } from ".";
import { useTrackEvent } from "../segment/segment";
import { EventTypeEnum } from "../segment/types";
import { logger } from "../datadog";

export function useWaitingRoomTrackingEvent(
  event:
    | EventTypeEnum.CLIENT_WAITING_ROOM
    | EventTypeEnum.PROVIDER_WAITING_ROOM,
  providerShortId?: string,
  patientShortId?: string,
) {
  const [getTelehealthSessionInfo] = useGetTelehealthSessionInfoCallback(
    providerShortId,
    patientShortId,
  );
  const { userViewed } = useTrackEvent();

  useEffect(() => {
    (async () => {
      if (!providerShortId) {
        return;
      }
      try {
        const { data } = await getTelehealthSessionInfo();
        if (data?.telehealthSessionInfo?.appointment) {
          const { shortId, timeStart } = data.telehealthSessionInfo.appointment;
          const params = {
            appointmentShortId: shortId,
            appointmentTimeStart: timeStart,
            componentName: `${event
              .toLocaleLowerCase()
              .replace(/\W/g, "_")}_page`,
            componentType: "page",
          };
          userViewed(event, params);
          logger.debug("Submitted tracking event", { event, params });
        }
      } catch (error) {
        logger.warn(
          "Unable to submit tracking event due to missing session info",
          {},
          error as Error,
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerShortId]);
}
