import { AdditionalOptionsMenu } from "../components/AdditionalOptionsMenu";
import { useChatDrawerButtonUtils } from "./chat/useChatDrawerButtonUtils";
import { useAtomValue } from "jotai";
import { drawerStateAtom } from "../provider/state";
import {
  participantCountAtom,
  localScreenShareTrackAtom,
  remoteScreenShareParticipantAtom,
} from "../twilio";
export function AdditionalOptionsMenuWrapper({
  drawerId,
  onClickSettingsButton,
  onOpenDrawer,
  onCloseDrawer,
}: {
  drawerId: string;
  onClickSettingsButton: () => void;
  onOpenDrawer?: () => void;
  onCloseDrawer?: () => void;
}) {
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils({ onOpen: onOpenDrawer, onClose: onCloseDrawer });
  const drawerState = useAtomValue(drawerStateAtom);
  const isInSession = !!useAtomValue(participantCountAtom);
  const isScreenSharing = !!useAtomValue(localScreenShareTrackAtom);
  const isSomeoneElseSharing = !!useAtomValue(remoteScreenShareParticipantAtom);

  return (
    <AdditionalOptionsMenu
      drawerId={drawerId}
      drawerState={drawerState}
      isInSession={isInSession}
      isScreenSharing={isScreenSharing}
      isSomeoneElseSharing={isSomeoneElseSharing}
      onClickSettingsButton={onClickSettingsButton}
      unreadMessageCount={unreadMessagesCount}
      isChatButtonDisabled={isChatDrawerDisabled}
      onClickChatButton={toggleChatDrawer}
    />
  );
}
