import { atom } from "jotai";
import { participantCountAtom } from "../../twilio";
import { createDerivedWritableAtom } from "../../utils";
import { providerAtom } from "../state";
import { isRecordingAtom } from "../../twilio/messages/state";

export const shouldRecordAtom = atom((get) => {
  const isRecordingEnabled = get(isRecordingAtom).value;
  const hasParticipants = !!get(participantCountAtom);
  return isRecordingEnabled && hasParticipants;
});

export const audioRecordingEntitiesAtom = createDerivedWritableAtom(
  providerAtom,
  "audioRecordingEntities",
);

export const recordingAudioContextAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioContext,
);

export const recordingAudioDestinationNodeAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioDestinationNode,
);

export const audioRecorderAtom = atom(
  (get) => get(audioRecordingEntitiesAtom)?.audioRecorder,
);

export const hasRecordingAtom = atom(false);
