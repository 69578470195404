import { useCallback, useState } from "react";
import { useInterval } from "usehooks-ts";
import { useGetTelehealthSessionInfo } from "./useGetTelehealthSessionInfo";

function shouldShowToast(appointmentEndTime: string, nudgeTime: number) {
  const appointmentEndDate = new Date(appointmentEndTime);
  const targetNudgeTime = new Date(appointmentEndDate.getTime() - nudgeTime);
  const msToNudgeTime = targetNudgeTime.getTime() - new Date().getTime();
  // Show the toast if the appointment hasn't already passed and we've reached the nudge time
  return msToNudgeTime + nudgeTime >= 0 && msToNudgeTime <= 0;
}

export function useEndOfSessionToast(
  providerShortId: string | undefined,
  patientShortId: string | undefined,
  showToast: () => void,
  nudgeTime: number,
  isInSession: boolean,
) {
  const [hasShownToast, setHasShownToast] = useState(false);
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);

  const appointmentEndTime = data?.telehealthSessionInfo?.appointment?.timeEnd;
  useInterval(
    useCallback(() => {
      if (
        !appointmentEndTime ||
        !shouldShowToast(appointmentEndTime, nudgeTime) ||
        !isInSession
      ) {
        return;
      }
      setHasShownToast(true);
      showToast();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentEndTime, isInSession]),
    !appointmentEndTime || hasShownToast ? null : 1000,
  );
}
