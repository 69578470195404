import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useMemo } from "react";
import { visitorPresenceAtom } from "../../provider/state";

export function getMostSpecificEmotion(emotions?: string[]) {
  return emotions?.at(-1);
}

/**
 * A hook to get the client's mood checkin data from the VisitorPresenceMap,
 * which is the source of truth for the client's mood checkin data
 */
export function useClientMood(visitorId: string | null | undefined) {
  const visitorPresence = useAtomValue(visitorPresenceAtom);

  const id = visitorId ?? "";

  return useMemo(
    () => getMostSpecificEmotion(visitorPresence[id]?.emotions ?? []),
    [id, visitorPresence],
  );
}

/**
 * A hook to get a function that returns the client's mood checkin data from the
 * VisitorPresenceMap. use this hook to iterate over the visitorPresenceAtom
 */
export function useClientEmotionsCallback() {
  return useAtomCallback(
    useCallback((get, _set, id: string) => {
      const visitorPresence = get(visitorPresenceAtom);
      return visitorPresence[id]?.emotions ?? [];
    }, []),
  );
}
