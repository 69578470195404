import { useAtomValue, useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import { visitorNameAtom, activeConversationSidAtom } from "../../state";
import { useCreateVisitorChatExperienceCallback } from "./useCreateVisitorChatAccess";
import { useEffect } from "react";
import { useInitTwilioChatClient } from "../../../twilio/chat/useInitTwilioChatClient";
import { useUpdateVisitorPresenceCallback } from "../useUpdateVisitorPresenceCallback";
import { useVisitorId } from "../../useVisitorId";

export function useInitVisitorChatAccess() {
  const { providerShortId } = useParams() as { providerShortId: string };
  const visitorId = useVisitorId();
  const name = useAtomValue(visitorNameAtom);
  const setActiveConversationSid = useSetAtom(activeConversationSidAtom);
  const [createVisitorChatExperience, { data }] =
    useCreateVisitorChatExperienceCallback();
  const updateVisitorPresence = useUpdateVisitorPresenceCallback();

  useEffect(() => {
    if (createVisitorChatExperience) {
      createVisitorChatExperience(providerShortId, name, visitorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function handleChatInitSuccess() {
      const conversationSid =
        data?.createTwilioWaitingRoomConversation?.conversationSid;
      if (!conversationSid) return;
      setActiveConversationSid(conversationSid);
      updateVisitorPresence();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.createTwilioWaitingRoomConversation?.conversationSid],
  );

  useInitTwilioChatClient({
    twilioChatToken: data?.createTwilioVisitorChatAccess?.jwt,
  });
}
