export const PROVIDER_TELEHEALTH_HELP_ARTICLE =
  "https://help.growtherapy.com/en/articles/8478586-grow-therapy-telehealth-faq";

export const PROVIDER_CLIENT_NO_SHOW_ARTICLE =
  "https://help.growtherapy.com/en/articles/5131686-no-show-and-late-cancellation-fees";

export const CLIENT_TELEHEALTH_HELP_ARTICLE =
  "https://help.growtherapy.com/clients/en/articles/8482385-grow-therapy-telehealth-faq";

export const CLIENT_SESSION_NOT_STARTED_HEADING = "My session hasn’t started";
