import {
  faCalendarCircleUser as farCalendarCircleUser,
  faChevronRight as farChevronRight,
  faExternalLink as farExternalLink,
  faFaceMeh as farFaceMeh,
  faFaceSadSweat as farFaceSadSweat,
  faFaceSmile as farFaceSmile,
  faHourglassStart as farHourglassStart,
  faHourglassEnd as farHourglassEnd,
  faSquareXmark as farSquareXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAirplay as fasAirplay,
  faArrowDownLeft as fasArrowDownLeft,
  faArrowDownRight as fasArrowDownRight,
  faArrowUpLeft as fasArrowUpLeft,
  faArrowUpRight as fasArrowUpRight,
  faArrowsUpDownLeftRight as fasArrowsUpDownLeftRight,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faDiamondExclamation as fasDiamondExclamation,
  faEllipsis as fasEllipsis,
  faGear as fasGear,
  faHandsHoldingHeart as fasHandsHoldingHeart,
  faHandHoldingHeart as fasHandHoldingHeart,
  faImageUser as fasImageUser,
  faInfoCircle as fasInfoCircle,
  faLightbulb as fasLightbulb,
  faLock as fasLock,
  faMessage as fasMessage,
  faMicrophone as fasMicrophone,
  faMicrophoneSlash as fasMicrophoneSlash,
  faVideo as fasVideo,
  faVideoSlash as fasVideoSlash,
  faVolume as fasVolume,
  faVolumeOff as fasVolumeOff,
  faPause as fasPause,
  faPlay as fasPlay,
  faUser as fasUser,
  faFaceSmile as fasFaceSmile,
  faFaceMeh as fasFaceMeh,
  faFaceSadSweat as fasFaceSadSweat,
  faLightbulbOn as fasLightbulbOn,
  faPaperPlaneTop as fasPaperPlaneTop,
  faSparkles as fasSparkles,
  faWarning as fasWarning,
  faArrowLeftLong as fasArrowLeftLong,
  faArrowRight as fasArrowRight,
  faAngleUp as fasAngleUp,
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faWifiExclamation as fasWifiExclamation,
  faEyeSlash as fasEyeSlash,
  faMemo as fasMemo,
  faMugHot as fasMugHot,
  faClock as fasClock,
  faLocationDot as fasLocationDot,
  faRepeat as fasRepeat,
  faHexagonExclamation,
  faCircleXmark,
  faFileArrowUp,
  faCircleCheck,
  faPartyHorn,
  faXmark,
  faSpinnerThird,
  faCalendar,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export function addAppIconsToLibrary() {
  library.add(
    fadSpinnerThird,
    farCalendarCircleUser,
    farChevronRight,
    farExternalLink,
    farFaceMeh,
    farFaceSadSweat,
    farFaceSmile,
    farHourglassEnd,
    farHourglassStart,
    farSquareXmark,
    fasAirplay,
    fasArrowDownLeft,
    fasArrowDownRight,
    fasArrowUpLeft,
    fasArrowUpRight,
    fasCheckCircle,
    fasCheck,
    fasDiamondExclamation,
    fasEllipsis,
    fasFaceMeh,
    fasFaceSadSweat,
    fasFaceSmile,
    fasGear,
    fasHandsHoldingHeart,
    fasHandHoldingHeart,
    fasImageUser,
    fasInfoCircle,
    fasLightbulb,
    fasLightbulbOn,
    farSquareXmark,
    fasLock,
    fasMessage,
    fasMicrophone,
    fasMicrophoneSlash,
    fasPaperPlaneTop,
    fasPause,
    fasPlay,
    fasSparkles,
    fasUser,
    fasVideo,
    fasVideoSlash,
    fasVolume,
    fasVolumeOff,
    fasWarning,
    fasArrowLeftLong,
    fasArrowRight,
    fasAngleUp,
    fasAngleDown,
    fasAngleLeft,
    fasAngleRight,
    fasWifiExclamation,
    fasArrowsUpDownLeftRight,
    fasEyeSlash,
    fasMemo,
    fasMugHot,
    fasClock,
    fasLocationDot,
    fasRepeat,
    faCalendar,

    // placeholders, until Sprout has been updated to include these
    faCircleCheck,
    faCircleXmark,
    faFileArrowUp,
    faHexagonExclamation,
    faPartyHorn,
    faSpinnerThird,
    faXmark,
  );
}
