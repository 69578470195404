import { Button, ButtonSize, Icon } from "@grow-therapy-team/sprout-ui";
import { BackgroundAnimationOverlay } from "../../components";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { LottieRef } from "lottie-react";
import {
  ACTION_ICONS,
  ActionType,
  getNextAction,
} from "./useAnimationStateReducer";

type AnimationStateProps = {
  currentAnimationState: ActionType;
  toggle: () => void;
};

export function PausePlayAnimationButton({
  className,
  toggle,
  currentAnimationState,
}: AnimationStateProps & { className?: string }): JSX.Element {
  const nextActionName = getNextAction(currentAnimationState);
  const nextActionIcon = ACTION_ICONS[nextActionName];
  return (
    <div
      className={twMerge(
        "flex justify-center m-3 sm:absolute sm:bottom-0 sm:right-0",
        className,
      )}
    >
      <Button
        use="link"
        size={ButtonSize.Small}
        className="flex gap-x-2 items-center text-marigold-700 no-underline hover:brightness-75 hover:text-marigold-700 "
        onClick={toggle}
        aria-label={`${nextActionName} background animation`}
      >
        <Icon icon={nextActionIcon} />
        {nextActionName} animation
      </Button>
    </div>
  );
}

type BackgroundAnimationProps = PropsWithChildren<
  AnimationStateProps & {
    lottieRef: LottieRef;
  }
>;

export function BackgroundAnimation({
  children,
  lottieRef,
  toggle,
  currentAnimationState,
}: BackgroundAnimationProps): JSX.Element {
  return (
    <>
      <BackgroundAnimationOverlay
        data-animation-state={currentAnimationState}
        lottieRef={lottieRef}
      />
      {/* "relative" to ensure the content is rendered above the animation */}
      <div className="relative flex flex-col overflow-y-auto h-full w-[calc(100%+0.2rem)]">
        {children}
        <PausePlayAnimationButton
          toggle={toggle}
          currentAnimationState={currentAnimationState}
          className="sm:hidden"
        />
      </div>
      <PausePlayAnimationButton
        toggle={toggle}
        currentAnimationState={currentAnimationState}
        className="max-xs:hidden"
      />
    </>
  );
}
