import { Text } from "@grow-therapy-team/sprout-ui";
import { useAtom } from "jotai";
import { deadlinesByVisitorIdFamily } from "./state";
import { useAtomCallback } from "jotai/utils";
import { useCountdown } from "../../hooks/useCountdown";
import { ComponentProps, useCallback } from "react";
import { waitingVisitorsAtom } from "../state";

export interface AutomaticallyTransferringTextProps
  extends ComponentProps<typeof Text> {
  countdown: string;
}

export function AutomaticallyTransferringText({
  countdown,
  ...textProps
}: AutomaticallyTransferringTextProps) {
  return (
    <Text
      variant="sm"
      className="text-left text-neutral-700"
      data-testid="deadline"
      {...textProps}
    >
      Automatically transferring in {countdown}
    </Text>
  );
}

export interface AutomaticallyTransferringTextWrapperProps
  extends ComponentProps<typeof Text> {
  visitorId: string;
  onFinish?: () => void;
}

export function AutomaticallyTransferringTextWrapper({
  visitorId,
  onFinish,
  ...textProps
}: AutomaticallyTransferringTextWrapperProps) {
  const [deadline, setDeadline] = useAtom(
    deadlinesByVisitorIdFamily(visitorId),
  );
  const getWaitingVisitors = useAtomCallback(
    useCallback((get) => get(waitingVisitorsAtom), []),
  );

  const countdown = useCountdown({
    deadline,
    earlyTerminateCallback: () => !(visitorId in getWaitingVisitors()),
    onFinish: () => {
      onFinish?.();
      setDeadline(null);
    },
  });

  if (!countdown) {
    return null;
  }

  return <AutomaticallyTransferringText countdown={countdown} {...textProps} />;
}
