import { useAtomValue } from "jotai";
import { isScreenShareModeAtom } from "../state";
import { ScreenShareLayoutWrapper as ScreenShareLayout } from "./ScreenShareLayout";
import {
  RemoteParticipantGridWrapper as RemoteAudioVideoGrid,
  RemoteParticipantGridProps,
} from "./RemoteParticipantGrid";
import { ReactNode } from "react";
import { usePictureInPicture } from "../usePictureInPicture";

export function SessionGrid({
  thumbnail,
  remoteParticipantComponent: RemoteParticipantComponent,
}: {
  thumbnail: ReactNode;
} & Pick<RemoteParticipantGridProps, "remoteParticipantComponent">) {
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  usePictureInPicture();

  return isScreenShareMode ? (
    <ScreenShareLayout
      remoteParticipantComponent={RemoteParticipantComponent}
      thumbnail={thumbnail}
    />
  ) : (
    <RemoteAudioVideoGrid
      remoteParticipantComponent={RemoteParticipantComponent}
    />
  );
}
