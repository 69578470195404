import { logger } from "../datadog";
import { Env } from "../types";
import { getEnvFromHostName } from "../utils";
import * as FS from "@fullstory/browser";

export function stopFullStory() {
  if (getEnvFromHostName(location.hostname) === Env.LOCAL) return;
  FS.shutdown();
}

export function startFullStory() {
  if (getEnvFromHostName(location.hostname) === Env.LOCAL) return;
  FS.restart();
}

export function setUserId(userId: string) {
  if (getEnvFromHostName(location.hostname) === Env.LOCAL) {
    logger.debug("Setting FullStory user ID", { userId });
    return;
  }
  FS.identify(userId);
}

export function setUserVars(userVars: Record<string, unknown>) {
  if (getEnvFromHostName(location.hostname) === Env.LOCAL) {
    logger.debug("Setting FullStory user vars", userVars);
    return;
  }
  FS.setUserVars(userVars);
}
