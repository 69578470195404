import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { publishLocalTrack } from "../tracks";
import { LocalTrackState, localTrackFamily, twilioRoomAtom } from "../state";
import { AudioVideoTrackKind, LocalAVTrack } from "../types";
import { CreateLocalTrackOptions } from "twilio-video";
import { useAtomCallback } from "jotai/utils";
import { useGetOrCreateLocalTrackCallback } from "./useGetOrCreateLocalTrackCallback";
import { logger } from "../../datadog/logger";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { BackgroundSettings } from "../config";
import { Mutex } from "async-mutex";

const trackKindToMutexMap = {
  audio: new Mutex(),
  video: new Mutex(),
};

export type PublishTrackOptions = {
  trackOptions?: CreateLocalTrackOptions;
  backgroundOptions?: BackgroundSettings;
  trackState?: LocalTrackState;
  track?: LocalAVTrack;
};

export function usePublishLocalTrackCallback(trackKind: AudioVideoTrackKind) {
  const getLocalParticipant = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom)?.localParticipant, []),
  );

  const setLocalTrack = useSetAtom(localTrackFamily(trackKind));
  const getOrCreateLocalTrack = useGetOrCreateLocalTrackCallback(trackKind);

  return useCallback(
    async (options: PublishTrackOptions = {}) => {
      const release = await trackKindToMutexMap[trackKind]?.acquire();
      try {
        const {
          trackOptions,
          track: givenTrack,
          trackState: givenTrackState,
        } = options;
        const trackState = givenTrackState ?? LocalTrackState.READY;
        const shouldEnable = trackState === LocalTrackState.READY;
        setLocalTrack((prev) => ({
          ...prev,
          state: LocalTrackState.LOADING,
        }));
        const track = givenTrack ?? (await getOrCreateLocalTrack(trackOptions));

        if (!track) return;

        await publishLocalTrack(track, getLocalParticipant(), shouldEnable);

        setLocalTrack((prev) => {
          return {
            ...prev,
            track: track,
            state: trackState,
          };
        });

        let deviceName: string | null = null;

        deviceName =
          // @ts-expect-error noiseCancellation will exist on most audio tracks but not on video tracks
          track.noiseCancellation?.sourceTrack?.label ??
          track?.mediaStreamTrack?.label ??
          null;

        sendLoggingEvents(TrackingEvents.LOCAL_TRACK_PUBLISHED, {
          trackKind,
          trackState,
          deviceName,
        });
        return track;
      } catch (error) {
        logger.error("Unable to publish local track", options, error as Error);
      } finally {
        release?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackKind],
  );
}
