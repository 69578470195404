import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../../hooks";
import { Theme } from "../../state";
import { VideoControlWrapper as VideoControl } from "./VideoControl";
import { AudioControlWrapper as AudioControl } from "./AudioControl";
import { FC, HTMLAttributes } from "react";
import { ScreenShareControlWrapper as ScreenShareControl } from "./ScreenShareControl";

export type AudioVideoControlsProps = {
  className?: string;
  additionalLeftControls?: React.ReactNode;
  additionalRightControls?: React.ReactNode;
  openHelpModal: () => void;
  audioControlComponent: typeof AudioControl;
  videoControlComponent: typeof VideoControl;
  recordingControlComponent?: FC;
  screenShareControlComponent: typeof ScreenShareControl;
  chatButtonComponent?: React.ReactNode;
  clientInformationButtonComponent?: React.ReactNode;
  additionalOptionsComponent: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export function AudioVideoControls({
  className,
  additionalLeftControls,
  additionalRightControls,
  openHelpModal,
  audioControlComponent: AudioControlComponent,
  videoControlComponent: VideoControlComponent,
  recordingControlComponent: RecordingControlComponent,
  screenShareControlComponent: ScreenShareControlComponent,
  chatButtonComponent,
  clientInformationButtonComponent,
  additionalOptionsComponent,
  ...props
}: AudioVideoControlsProps) {
  const { theme } = useTheme();

  return (
    <div
      aria-label="Session controls"
      className={twMerge(
        classNames(
          "relative md:px-12 w-screen flex flex-row md:grid md:grid-cols-3 min-h-[4.5rem] gap-5 justify-center",
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          className,
        ),
      )}
      {...props}
    >
      {!!additionalLeftControls && (
        <div className="md:col-span-1 md:col-start-1 flex items-center">
          {additionalLeftControls}
        </div>
      )}
      <div className="md:col-span-1 md:col-start-2 flex md:flex-grow justify-center items-center gap-5">
        <AudioControlComponent openHelpModal={openHelpModal} />
        <VideoControlComponent openHelpModal={openHelpModal} />
        {chatButtonComponent}
        <ScreenShareControlComponent />
        {clientInformationButtonComponent}
        {RecordingControlComponent && <RecordingControlComponent />}
        {additionalOptionsComponent}
      </div>
      {!!additionalRightControls && (
        <div className="md:col-span-1 md:col-start-3 justify-end flex items-center">
          {additionalRightControls}
        </div>
      )}
    </div>
  );
}

export function AudioVideoControlsWrapper(
  props: Omit<
    AudioVideoControlsProps,
    | "audioControlComponent"
    | "videoControlComponent"
    | "screenShareControlComponent"
  >,
) {
  return (
    <AudioVideoControls
      {...props}
      videoControlComponent={VideoControl}
      audioControlComponent={AudioControl}
      screenShareControlComponent={ScreenShareControl}
    />
  );
}
