import { useAtomValue } from "jotai";
import { visitorNameAtom } from "./state";
import {
  LocalAudioVideoThumbnail,
  LocalAudioVideoThumbnailProps,
} from "../twilio/session-grid/LocalAudioVideoThumbnail";

export function SelfVideoThumbnail(
  props: Omit<LocalAudioVideoThumbnailProps, "name">,
) {
  const visitorName = useAtomValue(visitorNameAtom);

  return <LocalAudioVideoThumbnail {...props} name={visitorName} />;
}
