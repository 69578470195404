import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Server } from "../apollo/types";
import { logger } from "../datadog/logger";
import { useSetAtom } from "jotai";
import {
  selectedClientUserShortIdAtom,
  selectedPatientPreferredNameAtom,
  selectedVisitorNotesAtomData,
  selectedClientMeasuresData,
} from "./state";
import { sortMeasureBundlesBySentAt } from "./measures/utils";
import { formDataByPatientShortIdAtom } from "./client-information/state";
import { NoteTypeEnum, QuestionTypeEnum } from "./client-information/types";
import { useCallback } from "react";
import { MICTypes } from "@grow-therapy-team/sprout-ui";

export type GetPatientByIdQueryData = {
  patientChart?: {
    __typename?: "PatientObject";
    id: string;
    shortId: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    preferredFullName?: string | null;
    preferredShortName?: string | null;
    pronouns?: string | null;
    archived: boolean;
    archivedReason?: string | null;
    email?: string | null;
    telephone?: string | null;
    gender?: string | null;
    readableDob?: string | null;
    age?: number | null;
    isSelfPay: boolean;
    formattedAddress?: string | null;
    customerId: string;
    status: string;
    seeingProviderInOffice: boolean;
    invalidClaimStatus: boolean;
    reasonForTherapy?: string | null;
    primaryInsuranceCoverage?: {
      __typename?: "InsuranceCoverageObject";
      uuid: unknown;
      groupNumber?: string | null;
      memberId: string;
      payorClientBookingName: {
        __typename?: "PayorClientBookingNameObject";
        name: string;
      };
    } | null;
    diagnoses: Array<{
      __typename?: "DiagnosisObject";
      diagnosis: string;
      icdCode: string;
    }>;
    provider?: { __typename?: "PublicProviderObject"; id: string } | null;
    nextAppointment?: {
      __typename?: "AppointmentObject";
      id: string;
      timeStart: unknown;
    } | null;
    lastAppointment?: {
      __typename?: "AppointmentObject";
      id: string;
      timeStart: unknown;
      timeEnd: unknown;
      notes: Array<{
        __typename?: "NoteObject";
        id: number;
        noteType: NoteTypeEnum;
        noteSummary?: string | null;
        noteContent?: string | null;
      }>;
    } | null;
    clientUser?: {
      __typename?: "ClientUserObject";
      clientUserShortId: string;
      gender?: string | null;
      stateOfResidence?: string | null;
      streamUserId?: string | null;
    } | null;
    formResponses: Array<{
      __typename?: "FormResponseObject";
      id: string;
      formId: string;
      highAcuity: boolean;
      reviewedAt?: unknown | null;
      formType: string;
      createdAt: string;
      score: number;
      formResponseModel: {
        pages: {
          elements: {
            name: string;
            value: string;
          }[];
        }[];
      };
    }>;
    allProviderForms: Array<{
      formId: string;
      formTitle: string;
      formVersion: number;
      formType: string;
      formModel: {
        formType: string;
        formPrompt: string;
        formTitle: string;
        pages: {
          sectionTitle: string;
          elements: {
            name: string;
            required: boolean;
            type: QuestionTypeEnum;
            title: string;
            maxLength?: number | null;
            visibleIf?: string | null;
            layout?: string | null;
            placeholder?: string | null;
            options: {
              value: string;
              label: string;
            }[];
          }[];
        }[];
      };
    }>;
    lastSubmittedClaim?: {
      __typename?: "ClaimObject";
    } | null;
    measureBundles: MICTypes.MeasureBundle[];
  } | null;
};

export type GetPatientByShortIdVariables = {
  patientShortId?: string;
};

export const GET_PATIENT_BY_PATIENT_SHORT_ID_GQL = gql`
  query GetPatientByPatientId($patientShortId: ID!) {
    patientChart(values: { patientShortId: $patientShortId }) {
      id
      shortId
      name
      firstName
      lastName
      preferredFullName
      preferredShortName
      pronouns
      archived
      archivedReason
      email
      telephone
      gender
      readableDob
      age
      isSelfPay
      primaryInsuranceCoverage {
        uuid
        payorClientBookingName {
          name
        }
        groupNumber
        memberId
      }
      formattedAddress
      customerId
      status
      seeingProviderInOffice
      invalidClaimStatus
      reasonForTherapy
      diagnoses {
        diagnosis
        icdCode
      }
      provider {
        id
      }
      nextAppointment {
        id
        timeStart
      }
      lastAppointment {
        id
        timeStart
        timeEnd
        notes {
          id
          noteType
          noteSummary
          noteContent
        }
      }
      clientUser {
        clientUserShortId
        gender
        stateOfResidence
        streamUserId
      }
      formResponses {
        id
        formId
        formType
        score
        highAcuity
        createdAt
        reviewedAt
        formResponseModel {
          pages {
            elements {
              name
              value
            }
          }
        }
      }
      allProviderForms {
        formId
        formTitle
        formType
        formVersion
        formModel {
          formTitle
          formPrompt
          pages {
            sectionTitle
            elements {
              type
              placeholder
              maxLength
              visibleIf
              layout
              name
              title
              required
              options {
                value
                label
              }
            }
          }
        }
      }
      lastSubmittedClaim {
        sessionDate
      }
      measureBundles {
        measureBundleShortId
        createdAt
        sentAt
        completedAt
        timeClose
        additionalDetails
        measureBundleItems {
          measureBundleItemId
          measureResponse {
            measureResponseShortId
            score
            scoreCategoryLabel
            completedAt
            responseContent
          }
          measureSchema {
            measureSchemaShortId
            isProviderSpecific
            type
            sections {
              key
              visibleConditionName
              fields {
                key
                visibleConditionName
                options {
                  score
                  key
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useSetPatientDataCallback = () => {
  const setNotes = useSetAtom(selectedVisitorNotesAtomData);
  const setFormDataForPatient = useSetAtom(formDataByPatientShortIdAtom);
  const setClientUserShortId = useSetAtom(selectedClientUserShortIdAtom);
  const setMeasuresData = useSetAtom(selectedClientMeasuresData);
  const setPreferredPatientName = useSetAtom(selectedPatientPreferredNameAtom);
  return useCallback(
    (data: GetPatientByIdQueryData) => {
      const patientShortId = data?.patientChart?.shortId;
      if (!patientShortId) return;

      setClientUserShortId(data?.patientChart?.clientUser?.clientUserShortId);
      setPreferredPatientName({
        patientFirstName: data?.patientChart?.preferredShortName,
        patientLastName: data?.patientChart?.lastName,
      });
      setFormDataForPatient((prev) => ({
        ...prev,
        [patientShortId]: {
          ...prev[patientShortId],
          formResponses: data.patientChart?.formResponses ?? [],
          providerForms: data.patientChart?.allProviderForms ?? [],
        },
      }));

      const sortedMeasureData = sortMeasureBundlesBySentAt(
        data?.patientChart?.measureBundles ?? [],
      );
      setMeasuresData(sortedMeasureData);
      if (
        data.patientChart?.lastAppointment?.notes &&
        data.patientChart.lastAppointment.notes.length
      ) {
        setNotes(
          data.patientChart.lastAppointment.notes[0]?.noteContent as string,
          parseInt(data.patientChart.id),
          data.patientChart.lastAppointment.notes[0].noteType,
        );
      }
    },
    [
      setNotes,
      setMeasuresData,
      setFormDataForPatient,
      setPreferredPatientName,
      setClientUserShortId,
    ],
  );
};

export function useGetPatientByShortId(patientShortId?: string) {
  const onCompleted = useSetPatientDataCallback();

  return useQuery<GetPatientByIdQueryData, GetPatientByShortIdVariables>(
    GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
    {
      variables: { patientShortId },
      skip: !patientShortId,
      context: { server: Server.MONOLITH },
      onError: (error) => {
        logger.error(
          "Unable to fetch client information",
          { patientShortId },
          error,
        );
      },
      onCompleted,
    },
  );
}

export function useLazyGetPatientByShortId(patientShortId?: string) {
  const onCompleted = useSetPatientDataCallback();

  return useLazyQuery<GetPatientByIdQueryData, GetPatientByShortIdVariables>(
    GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
    {
      variables: { patientShortId },
      context: { server: Server.MONOLITH },
      onError: (error) => {
        logger.error(
          "Unable to fetch client information",
          { patientShortId },
          error,
        );
      },
      onCompleted,
    },
  );
}

export function useGetCachedPatientByShortIdCallback() {
  const apolloClient = useApolloClient();
  return useCallback(
    (variables: GetPatientByShortIdVariables) => {
      try {
        return apolloClient.readQuery<GetPatientByIdQueryData>({
          query: GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
          variables,
        })?.patientChart;
      } catch (error) {
        logger.error(
          "Unable to read current provider from cache",
          {},
          error as Error,
        );
      }
    },
    [apolloClient],
  );
}
