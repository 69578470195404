import { gql, useQuery } from "@apollo/client";

import { ConsentStatus } from "../../twilio/types";
import { useAtomValue, useSetAtom } from "jotai";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";

export type ProviderPatientConsentVariable = {
  providerShortId?: string;
  patientShortId?: string;
};
export type ProviderPatientConsentData = {
  clientTranscriptionConsent?: {
    isOptedIn: boolean;
  };
  providerTranscriptionConsent?: {
    isOptedIn: boolean;
  };
};
export const GET_PROVIDER_PATIENT_CONSENT = gql`
  query GetProviderPatientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
  ) {
    clientTranscriptionConsent(patientShortId: $patientShortId) {
      isOptedIn
    }
    providerTranscriptionConsent(providerShortId: $providerShortId) {
      isOptedIn
    }
  }
`;

export function optedInToConsentStatus(optedIn?: boolean): ConsentStatus {
  if (optedIn === undefined) return ConsentStatus.UNDECIDED;

  return optedIn ? ConsentStatus.OPTED_IN : ConsentStatus.OPTED_OUT;
}

export function useGetProviderPatientConsent(
  providerShortId?: string,
  patientShortId?: string,
) {
  const setProviderTranscriptionConsent = useSetAtom(providerConsentAtom);
  const setPatientTranscriptionConsent = useSetAtom(patientConsentAtom);
  const consents = useQuery<
    ProviderPatientConsentData,
    ProviderPatientConsentVariable
  >(GET_PROVIDER_PATIENT_CONSENT, {
    variables: { providerShortId, patientShortId },
    skip: !providerShortId || !patientShortId,
    context: { skipAuth: false },
    onCompleted: (data) => {
      const providerOptIn = data?.providerTranscriptionConsent?.isOptedIn;
      const patientOptIn = consents.data?.clientTranscriptionConsent?.isOptedIn;

      const providerConsent = optedInToConsentStatus(providerOptIn);
      const patientConsent = optedInToConsentStatus(patientOptIn);
      setProviderTranscriptionConsent({
        value: providerConsent,
        lastUpdated: Date.now(),
      });
      setPatientTranscriptionConsent({
        value: patientConsent,
        lastUpdated: Date.now(),
      });
    },
  });

  const providerConsent = useAtomValue(providerConsentAtom).value;
  const patientConsent = useAtomValue(patientConsentAtom).value;
  return {
    providerConsent,
    patientConsent,
  };
}
