import { gracefullyPlayAudio } from "../../audio";
import { NOTIFICATION_VOLUME } from "../../config";
import { debounce } from "../../utils";
import outOfNowhereToneUri from "../../assets/audio/out-of-nowhere-tone.mp3?url";
import { useRecordProviderNoShowReportCallback } from "./useRecordProviderNoShowReportCallback";
import { useEffect } from "react";
import { updateFaviconAndTitle } from "../../updateFaviconAndTitle";
import {
  PROVIDER_NO_SHOW_TITLE,
  useClearProviderNoShowTabTitleCallback,
} from "./useClearProviderNoShowTabTitle";

const DEBOUNCE_TIME_MS = 1000;

const playPastProviderGracePeriodSound = debounce(() => {
  const ringtone = new Audio(outOfNowhereToneUri);
  ringtone.volume = NOTIFICATION_VOLUME;
  gracefullyPlayAudio(ringtone);
}, DEBOUNCE_TIME_MS);

export function useUpdateTitleOnProviderPastNoShowThreshold() {
  const { isPastNoShowThreshold } = useRecordProviderNoShowReportCallback();
  const clearProviderNoShowTabTitle = useClearProviderNoShowTabTitleCallback();

  useEffect(
    function showProviderNotHereTabTitle() {
      if (!isPastNoShowThreshold) return;
      updateFaviconAndTitle(
        "../../assets/favicon-alert.png",
        PROVIDER_NO_SHOW_TITLE,
      );
      playPastProviderGracePeriodSound();
    },
    [isPastNoShowThreshold],
  );

  useEffect(function resetProviderNoShowTitleOnLeaveWaitingRoom() {
    return () => {
      if (document.title !== PROVIDER_NO_SHOW_TITLE) return;
      clearProviderNoShowTabTitle();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
