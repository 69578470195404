import { useCallback, useEffect } from "react";
import { useAtomCallback } from "jotai/utils";

import { logger } from "../datadog";
import { useInitBackgroundProcessorsCallback } from "../twilio/useInitBackgroundProcessorsCallback";
import { localVideoTrackAtom, twilioRoomAtom } from "../twilio/state";
import { BackgroundType, getStoredAudioVideoSettings } from "../twilio/config";
import { useOnInactiveScreenDetected } from "./useOnInactiveScreenDetected";

export function useInactiveTabPerformanceOptimization() {
  const getLocalVideoTrack = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom).track, []),
  );
  const getIsInSession = useAtomCallback(
    useCallback((get) => !!get(twilioRoomAtom), []),
  );

  const setBackground = useInitBackgroundProcessorsCallback();

  const handleLeaveCallback = useCallback(async () => {
    const localVideoTrack = getLocalVideoTrack();
    const { backgroundType = BackgroundType.NONE, backgroundIndex } =
      getStoredAudioVideoSettings();
    const isInSession = getIsInSession();

    // if there's no video track or the video track is disabled,
    // we don't need to do anything
    if (!localVideoTrack?.isEnabled) return;
    // if there's no background, we don't need to do anything
    if (backgroundType === BackgroundType.NONE) return;
    // if someone could be viewing the video, don't disable the background
    if (isInSession) return;

    logger.debug("Disabling video background processor while tab is inactive", {
      backgroundType,
      backgroundIndex,
    });

    await setBackground(
      { backgroundType: BackgroundType.NONE },
      localVideoTrack,
      { persistChanges: false },
    );
  }, [getIsInSession, getLocalVideoTrack, setBackground]);

  const handleReturnCallback = useCallback(async () => {
    const localVideoTrack = getLocalVideoTrack();

    // if there's no video track or it is disabled, and since we didn't modify
    // saved settings, nothing to do, allowing the onEnable action to restore
    // the background
    if (!localVideoTrack?.isEnabled) return;

    const { backgroundType = BackgroundType.NONE, backgroundIndex } =
      getStoredAudioVideoSettings();

    if (backgroundType === BackgroundType.NONE) return;

    logger.debug(
      "Restoring video background processor from previously active tab",
      { backgroundType, backgroundIndex },
    );

    await setBackground({ backgroundType, backgroundIndex }, localVideoTrack, {
      persistChanges: false,
    });
  }, [getLocalVideoTrack, setBackground]);

  useOnInactiveScreenDetected(handleLeaveCallback, handleReturnCallback);

  // cleanup on unmount
  // we use a reference to a video component *shared* across pages, so if,
  // for example, a Provider admitted a Client, and we display a new page,
  // the background would not be restored when in the session
  useEffect(() => {
    return function cleanup() {
      // useEffect doesn't like async functions
      handleReturnCallback();
    };
  }, [handleReturnCallback]);
}
