import { useCallback, useMemo } from "react";
import { useTwilioConversationCallbacks } from "../../twilio/chat/useTwilioConversationCallbacks";
import { useSetAtom } from "jotai";
import { DrawerState, sessionConversationSidAtom } from "../state";
import { useShowNewMessageToastCallback } from "./useShowNewMessageToastCallback";
import { useAtomCallback } from "jotai/utils";

export function useSessionConversationCallbacks() {
  const { joinConversation, leaveConversation } =
    useTwilioConversationCallbacks();
  const setSessionConversationSid = useSetAtom(sessionConversationSidAtom);
  const showNewMessageToast = useShowNewMessageToastCallback();
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );

  return useMemo(
    () => ({
      joinSessionConversation: async (sessionConversationSid: string) => {
        const conversationResult = await joinConversation(
          sessionConversationSid,
          {
            onMessageAdded: (message) => {
              showNewMessageToast(message, DrawerState.SESSION_CHAT);
            },
          },
        );
        if (conversationResult.status === "success") {
          setSessionConversationSid(sessionConversationSid);
        }
        return conversationResult;
      },
      leaveSessionConversation: async (
        sessionConversationSidOverride?: string,
      ) => {
        const sessionConversationSid =
          sessionConversationSidOverride ?? getSessionConversationSid();
        if (!sessionConversationSid) return;
        const conversationResult = await leaveConversation(
          sessionConversationSid,
        );
        if (conversationResult.status === "success") {
          setSessionConversationSid(undefined);
        }
        return conversationResult;
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
