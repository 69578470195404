import { useEffect } from "react";
import { usePublishLocalTrackCallback } from "../audio-video-controls/usePublishLocalTrackCallback";
import { useChangeCameraDeviceCallback } from "../audio-video-controls/useChangeCameraDeviceCallback";
import { logger } from "../../datadog/logger";
import { useUnpublishLocalTrackCallback } from "../audio-video-controls/useUnpublishLocalTrackCallback";
import { useValidateStoredDeviceIdsCallback } from "./useValidateStoredDeviceIdsCallback";

/**
 * When a device change event is detected, this hook will update the list of
 * available devices and switch to the first available devices if the current
 * devices are no longer available.
 */
export function useHandleDeviceChanges() {
  const validateStoredDeviceIds = useValidateStoredDeviceIdsCallback();
  const changeMicrophone = usePublishLocalTrackCallback("audio");
  const changeCamera = useChangeCameraDeviceCallback();
  const unpublishLocalVideoTrack = useUnpublishLocalTrackCallback("video");
  const unpublishLocalAudioTrack = useUnpublishLocalTrackCallback("audio");
  const releaseCamera = () => {
    return unpublishLocalVideoTrack({ shouldReleaseTrack: true });
  };
  const releaseMicrophone = () => {
    return unpublishLocalAudioTrack({ shouldReleaseTrack: true });
  };

  const handleDeviceDisconnects = async () => {
    const { status, value: validationResponse } =
      await validateStoredDeviceIds();
    if (status !== "failure") return;
    const { invalidTrackKinds, availableDevices } = validationResponse;
    for (const kind of invalidTrackKinds) {
      switch (kind) {
        case "audio": {
          const [firstAvailableDevice] = availableDevices.audioInputDevices;
          const firstAvailableDeviceId = firstAvailableDevice?.deviceId;
          if (!firstAvailableDeviceId) {
            logger.info(
              "Microphone disconnected. No other devices found. Releasing track.",
            );
            await releaseMicrophone();
            break;
          }
          logger.info(
            "Microphone disconnected. Changing to different device.",
            {
              deviceId: firstAvailableDeviceId,
              label: firstAvailableDevice.label,
            },
          );
          await changeMicrophone({
            trackOptions: { deviceId: firstAvailableDeviceId },
          });
          break;
        }
        case "video": {
          const [firstAvailableDevice] = availableDevices.videoInputDevices;
          const firstAvailableDeviceId = firstAvailableDevice?.deviceId;
          if (!firstAvailableDeviceId) {
            logger.info(
              "Camera disconnected. No other devices found. Releasing track.",
            );
            await releaseCamera();
            break;
          }
          logger.info("Camera disconnected. Changing to different device.", {
            deviceId: firstAvailableDeviceId,
            label: firstAvailableDevice.label,
          });
          await changeCamera(firstAvailableDeviceId);
          break;
        }
      }
    }
  };

  return useEffect(
    function handleDeviceChangeListener() {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handleDeviceDisconnects,
      );
      return function cleanup() {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          handleDeviceDisconnects,
        );
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
