import { DrawerFooter, Button } from "@grow-therapy-team/sprout-ui";
import { useSetAtom } from "jotai";
import { DrawerState, drawerStateAtom } from "../state";

export function ClientInformationSchedulingDrawerFooter({
  onClickEditAppointment,
}: {
  onClickEditAppointment: () => void;
}) {
  return (
    <DrawerFooter>
      <Button use="secondary" onClick={onClickEditAppointment}>
        Edit appointment
      </Button>
    </DrawerFooter>
  );
}

export function ClientInformationSchedulingDrawerFooterWrapper() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  return (
    <ClientInformationSchedulingDrawerFooter
      onClickEditAppointment={() =>
        setDrawerState(DrawerState.EDIT_APPOINTMENT)
      }
    />
  );
}
