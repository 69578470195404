import { useContext } from "react";
import { THEME_CONTEXT_DEFAULT_VALUE, ThemeContext } from "../state";
import { logger } from "../datadog";

export const useTheme = () => {
  const contextValue = useContext(ThemeContext);

  if (!contextValue) {
    // NOTE: Defensively logging instead of throwing an error to avoid
    // unintentional runtime exceptions, since this context supports
    // non-critical functionality.
    logger.error("useTheme must be used within a ThemeProvider");
    return THEME_CONTEXT_DEFAULT_VALUE;
  }

  return contextValue;
};
