import { atom } from "jotai";
import { ScheduledPatientInformation } from "./types";
import { createDerivedWritableAtom } from "../../utils";

export type SchedulePreviewAtom = {
  scheduledPatientInformation?: ScheduledPatientInformation | undefined;
};

export const DEFAULT_SCHEDULE_PREVIEW_ATOM: SchedulePreviewAtom = {
  scheduledPatientInformation: undefined,
};

export const schedulePreviewAtom = atom<SchedulePreviewAtom>(
  DEFAULT_SCHEDULE_PREVIEW_ATOM,
);

export const scheduledPatientInformationAtom = createDerivedWritableAtom(
  schedulePreviewAtom,
  "scheduledPatientInformation",
);
