import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import type { RemoteParticipant, TwilioError } from "twilio-video";
import { useDebounceCallback } from "usehooks-ts";
import { toast } from "@grow-therapy-team/sprout-ui";
import {
  dominantSpeakerIdAtom,
  participantsAtom,
  twilioRoomAtom,
} from "./state";
import {
  TwilioRoomEventListeners,
  ParticipantConnectionEventHandler,
  RoomDisconnectedEventHandler,
  RoomReconnectingEventHandler,
} from "./types";
import { useDisconnectTwilioRoomCallback } from "./useDisconnectTwilioRoomCallback";
import { parseIdentity } from "./utils";
import { sendDataDogEvent } from "../datadog/events";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { logger } from "../datadog";
import { Mutex } from "async-mutex";
import { mapValues } from "remeda";
import { useAtomCallback } from "jotai/utils";

type UseTwilioRoomProps = {
  onRoomDisconnect?: RoomDisconnectedEventHandler;
  onParticipantDisconnect?: ParticipantConnectionEventHandler;
  onParticipantConnect?: ParticipantConnectionEventHandler;
  onPageUnload?: () => void;
};

export const RAPID_RECONNECT_THRESHOLD = 1000;
export const CHECK_FOR_EXISTING_PARTICIPANTS_INTERVAL = 3000;

function filterOutByParticipantId(
  participants: RemoteParticipant[],
  id: string,
) {
  return participants.filter((p) => p.identity !== id);
}

export const twilioRoomEventMutex = new Mutex();

/**
 * Wraps the given event handlers in the given mutex, ensuring that only one
 * handler can run at a time.
 */
export function mutexifyEventHandlers(
  handlers: TwilioRoomEventListeners,
): TwilioRoomEventListeners {
  return mapValues(
    handlers,
    (handler) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (...args: any[]) =>
        twilioRoomEventMutex.runExclusive(() => handler(...args)),
  );
}

function useGetTwilioRoomEventListeners({
  onClearReconnectionNotification,
  onNotifyOfReconnection,
  onParticipantConnect,
  onParticipantDisconnect,
  onRoomDisconnect,
}: Omit<UseTwilioRoomProps, "onPageUnload"> & {
  onClearReconnectionNotification?: () => void;
  onNotifyOfReconnection?: (error: TwilioError) => void;
} = {}): Partial<TwilioRoomEventListeners> {
  const setParticipants = useSetAtom(participantsAtom);
  const setDominantSpeakerId = useSetAtom(dominantSpeakerIdAtom);
  const disconnectTwilioRoom = useDisconnectTwilioRoomCallback();

  return useMemo<Partial<TwilioRoomEventListeners>>(
    () =>
      mutexifyEventHandlers({
        participantConnected: (participant) => {
          setParticipants((prev) => [
            ...filterOutByParticipantId(prev, participant.identity),
            participant,
          ]);
          onParticipantConnect?.(participant);
        },
        participantDisconnected: (participant) => {
          setParticipants((prev) =>
            filterOutByParticipantId(prev, participant.identity),
          );
          onParticipantDisconnect?.(participant);
        },
        disconnected: (room, error) => {
          disconnectTwilioRoom();
          onRoomDisconnect?.(room, error);
        },
        dominantSpeakerChanged: (participant) => {
          if (!participant) return;

          const { sid, identity } = participant;
          const entityId = parseIdentity(identity).id;
          setDominantSpeakerId(identity);
          sendDataDogEvent("twilio.dominantSpeakerChanged", { sid, entityId });
        },
        reconnecting: (error) => {
          onNotifyOfReconnection?.(error);

          sendLoggingEvents(
            TrackingEvents.ROOM_RECONNECTING,
            {},
            { error, logLevel: "warn", message: "Reconnecting to Twilio room" },
          );

          logger.debug("Twilio connection lost... reconnecting", { error });
        },
        reconnected: () => {
          onClearReconnectionNotification?.();

          sendLoggingEvents(
            TrackingEvents.ROOM_RECONNECTED,
            {},
            { logLevel: "info", message: "Reconnected to Twilio room" },
          );

          logger.debug("Twilio connection restored.");
        },
      } as TwilioRoomEventListeners),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onClearReconnectionNotification,
      onNotifyOfReconnection,
      onParticipantConnect,
      onParticipantDisconnect,
      onRoomDisconnect,
    ],
  );
}

export function useTwilioRoomListeners({
  onPageUnload,
  ...twilioRoomEventCallbacks
}: UseTwilioRoomProps = {}) {
  const toastIdRef = useRef<string | null>(null);
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const getTwilioRoom = useAtomCallback(
    useAtomCallback((get) => get(twilioRoomAtom)),
  );
  const onNotifyOfReconnection = useDebounceCallback(
    useCallback<RoomReconnectingEventHandler>(() => {
      // repeated reconnection attempts will not trigger multiple notifications
      if (toastIdRef.current) return;

      toastIdRef.current = toast.error(
        "We’re trying to reconnect you. The session may end if we’re unable to.",
        { duration: Number.POSITIVE_INFINITY },
      );
    }, []),
    RAPID_RECONNECT_THRESHOLD,
  );
  const onClearReconnectionNotification = useCallback(() => {
    // if reconnected before the notification, cancel the delayed notification
    // (this is ignored if it has already happened)
    onNotifyOfReconnection.cancel();

    if (toastIdRef.current) toast.dismiss(toastIdRef.current);

    toastIdRef.current = null;
  }, [onNotifyOfReconnection]);
  const twilioRoomEventListeners = useGetTwilioRoomEventListeners({
    ...twilioRoomEventCallbacks,
    onClearReconnectionNotification,
    onNotifyOfReconnection,
  });
  const handleExistingParticipants = useCallback(
    () => {
      const twilioRoom = getTwilioRoom();
      if (!twilioRoom) return;

      twilioRoom.participants.forEach(async (participant) => {
        await twilioRoomEventListeners?.participantConnected?.(participant);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twilioRoomEventListeners.participantConnected],
  );

  useEffect(
    function handleTwilioRoomListeners() {
      if (!twilioRoom) {
        onClearReconnectionNotification();
        return;
      }

      Object.entries(twilioRoomEventListeners).forEach(([event, callback]) => {
        // @ts-ignore Object.entries forces keys to be string, which is a shame
        twilioRoom.on(event, callback);
      });

      // Handle already connected participants
      handleExistingParticipants();

      return function cleanup() {
        Object.entries(twilioRoomEventListeners).forEach(
          ([event, callback]) => {
            twilioRoom.off(event, callback);
          },
        );
      };
    },
    [
      handleExistingParticipants,
      twilioRoom,
      twilioRoomEventListeners,
      onClearReconnectionNotification,
    ],
  );

  useEffect(
    /**
     * For resiliency, periodically check for existing participants and handle
     * them.
     */
    function checkForExistingParticipantsPeriodically() {
      if (!twilioRoom) return;

      const interval = setInterval(
        handleExistingParticipants,
        CHECK_FOR_EXISTING_PARTICIPANTS_INTERVAL,
      );

      return function cleanup() {
        clearInterval(interval);
      };
    },
    [twilioRoom, handleExistingParticipants],
  );

  useEffect(
    function attemptDisconnectBeforePageUnload() {
      const beforeUnload = () => {
        twilioRoom?.disconnect();
        onPageUnload?.();
      };
      window.addEventListener("beforeunload", beforeUnload);
      return function cleanup() {
        window.removeEventListener("beforeunload", beforeUnload);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twilioRoom],
  );

  useEffect(
    function cleanUpTimersAndNotificationsOnUnmount() {
      // on unmount, clear any notifications behavior
      return onClearReconnectionNotification;
    },
    [onClearReconnectionNotification],
  );

  return twilioRoom;
}
