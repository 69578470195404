import { useAtomValue } from "jotai";
import { participantCountAtom } from "../state";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { isRecordingAtom } from "../messages/state";

export function useRecordingControl(
  providerShortId?: string,
  patientShortId?: string,
  featureEnabled: boolean = false,
) {
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;
  const isRecording = useAtomValue(isRecordingAtom).value;
  const inSession = !!useAtomValue(participantCountAtom);
  const isVisible = inSession && appointmentShortId && featureEnabled;

  return {
    isVisible,
    isRecording,
  };
}
