import { useCallback, useEffect } from "react";

export const useOnInactiveScreenDetected = (
  onLeaveCallback: () => void,
  onReturnCallback?: () => void,
) => {
  const handleOnVisibilityChange = useCallback(() => {
    if (document.hidden) {
      onLeaveCallback();
    } else {
      onReturnCallback?.();
    }
  }, [onLeaveCallback, onReturnCallback]);

  useEffect(
    function handleMobileLockOrTabAway() {
      document.addEventListener("visibilitychange", handleOnVisibilityChange);

      return function cleanup() {
        document.removeEventListener(
          "visibilitychange",
          handleOnVisibilityChange,
        );
      };
    },
    [handleOnVisibilityChange],
  );
};
