import { useAtomValue, useSetAtom } from "jotai";
import {
  participantIdsAtom,
  userDataByParticipantIdAtom,
} from "../../twilio/state";
import { useEffect } from "react";
import { UserData, UserType } from "../../types";
import { parseIdentity } from "../../twilio/utils";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks";

export function useSetProviderParticipantData() {
  const setUserData = useSetAtom(userDataByParticipantIdAtom);
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const currentProvider = data?.telehealthSessionInfo?.provider;
  const participantIds = useAtomValue(participantIdsAtom);

  useEffect(
    function setProviderParticipantData() {
      if (!participantIds.length || !currentProvider) return;
      (async () => {
        if (!currentProvider) return;
        const providerParticipantId = participantIds.find(
          (identity) => parseIdentity(identity).id === providerShortId,
        );
        if (!providerParticipantId) return;
        const currentProviderUserData: UserData = {
          ...currentProvider,
          id: providerShortId ?? "",
          userType: UserType.PROVIDER,
        };
        setUserData((prev) => ({
          ...prev,
          [providerParticipantId]: {
            ...prev[providerParticipantId],
            ...currentProviderUserData,
          },
        }));
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProvider, participantIds],
  );
}
