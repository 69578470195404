import { useOpenClientInformationCallback } from ".";
import { participantCountAtom } from "../../twilio";
import { useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
} from "../state";
import { faMemo } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from "../../components";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import {
  hasNewFormsAtomFamily,
  inSessionClientHasPendingFormsAtom,
} from "./state";
import { useClientMood } from "../../hooks/measures/useClientMood";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { useState } from "react";
import { getIsClientInfoDrawerOpen } from "../utils";
import { DrawerButton } from "../../components/DrawerButton";
import { DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON } from "./constants";

export function ClientInformationButton({
  drawerId,
  drawerState,
  onClientInformationButtonClick,
  showVisualIndicator,
}: {
  drawerId: string;
  drawerState: DrawerState | null;
  onClientInformationButtonClick: () => void;
  showVisualIndicator?: boolean;
}) {
  const isClientInfoDrawerOpen = getIsClientInfoDrawerOpen(drawerState);
  const ariaLabel = isClientInfoDrawerOpen
    ? "Close client information"
    : "View client information";
  return (
    <Tooltip
      text="Client Information"
      forceInteractive={false}
      className="z-0"
      containerClassName="hidden md:inline-block"
    >
      <DrawerButton
        as={ControlPanelButton}
        // NOTE: This list overlaps with the drawer states controlled by the
        // `ClientsDrawerButton`. This is intentional and ensures that when this
        // button is not available because a provider is not in session, that
        // button can still be focused on drawer close instead.
        // NOTE: Technically a provider can have client info open for a
        // different client than the one they are currently in a session with,
        // in which case it doesn't necessarily make sense to focus this button
        // on closing the drawer, since this button is implied to mean _for the
        // client in the session_. But the focus transference is best-effort,
        // and this is a case where doing it "right" probably isn't worth the
        // added complexity.
        forDrawerStates={DRAWER_STATES_CONTROLLED_BY_CLIENT_INFO_BUTTON}
        containerClassName="hidden md:block"
        aria-label={ariaLabel}
        aria-expanded={isClientInfoDrawerOpen}
        aria-controls={drawerId}
        iconDefinition={faMemo}
        onClick={onClientInformationButtonClick}
        showVisualIndicator={showVisualIndicator}
      />
    </Tooltip>
  );
}

export function ClientInformationButtonWrapper({
  drawerId,
}: {
  drawerId: string;
}) {
  const openClientInformationDrawer = useOpenClientInformationCallback();
  const inSessionClientInformation = useAtomValue(
    inSessionPatientInformationAtom,
  );

  const { userClicked } = useTrackEvent();
  const setDrawerState = useSetAtom(drawerStateAtom);
  const drawerState = useAtomValue(drawerStateAtom);
  const clientInfoDrawerIsOpen = getIsClientInfoDrawerOpen(drawerState);
  const isInSession = useAtomValue(participantCountAtom) > 0;
  const hasInSessionClientInformation = useAtomValue(
    inSessionClientHasPendingFormsAtom,
  );
  const hasNewForms = useAtomValue(
    hasNewFormsAtomFamily(inSessionClientInformation?.patientShortId ?? ""),
  );
  const hasRecordedMeasure = !!useClientMood(
    inSessionClientInformation?.visitorUuid,
  );
  const [hasOpenedDrawer, setHasOpenedDrawer] = useState(false);
  const showVisualIndicator =
    !hasOpenedDrawer &&
    (hasInSessionClientInformation || hasNewForms || hasRecordedMeasure);

  if (!isInSession || !inSessionClientInformation?.visitorUuid) {
    return;
  }

  const onClientInformationButtonClick = () => {
    openClientInformationDrawer(
      // @ts-ignore - visitorUuid is not undefined as it is checked in the early return above
      inSessionClientInformation.visitorUuid,
    );
    setHasOpenedDrawer(true);
    userClicked(EventTypeEnum.CLIENT_INFO, {
      patientShortId: inSessionClientInformation.patientShortId,
    });
  };
  return (
    <ClientInformationButton
      drawerId={drawerId}
      drawerState={drawerState}
      showVisualIndicator={showVisualIndicator}
      onClientInformationButtonClick={
        clientInfoDrawerIsOpen
          ? () => setDrawerState(null)
          : () => onClientInformationButtonClick()
      }
    />
  );
}
