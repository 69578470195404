import { syntheticShortIds } from "./constants";

import { v4 as uuid } from "uuid";

import { addSeconds, isAfter, subMonths, parseISO, format } from "date-fns";
import Cookies from "js-cookie";

import { parse } from "tldts";

const ONE_YEAR_SECONDS = 31536000;
const GROW_UNIVERSAL_ID_COOKIE_KEY = "grow_universal_id";
const GROW_UNIVERSAL_ID_COOKIE_EXPIRATION_KEY = "grow_universal_id_expiration";

export function isAutomatedUser(shortId?: string): boolean {
  return syntheticShortIds.includes(shortId ?? "");
}

function getCookieDomain(url: string): string {
  const { domain } = parse(url);
  return `.${domain}`;
}

function getCookieOptions() {
  const maxAge = ONE_YEAR_SECONDS;

  const href = typeof window !== "undefined" && window.location?.href;
  return {
    domain: href ? getCookieDomain(href) : undefined,
    expires: maxAge,
  };
}

export function getCookie(name: string): string | undefined {
  return Cookies.get(name);
}

export function setCookie(name: string, value: string): void {
  Cookies.set(name, value, getCookieOptions());
}

const updateGrowUniversalId = (existingUniversalId?: string): string => {
  const universalId = existingUniversalId ?? uuid();
  setCookie(GROW_UNIVERSAL_ID_COOKIE_KEY, universalId);
  const expirationDate = addSeconds(new Date(), ONE_YEAR_SECONDS);
  const formattedExpirationDate = format(expirationDate, "yyyy-MM-dd");
  setCookie(GROW_UNIVERSAL_ID_COOKIE_EXPIRATION_KEY, formattedExpirationDate);
  return universalId;
};

/**
 * Gets the grow_universal_id cookie value.
 * If the cookie value does not exist, it will be created.
 * If the cookie value is within a month of expiration, it will be refreshed.
 */
export const getGrowUniversalId = (): string => {
  const universalId = getCookie(GROW_UNIVERSAL_ID_COOKIE_KEY);

  const expiration = getCookie(GROW_UNIVERSAL_ID_COOKIE_EXPIRATION_KEY);
  if (!universalId || typeof universalId !== "string") {
    return updateGrowUniversalId();
  }

  // Checks if the current date is after one month before the parsed expiration date.
  const isCookieAboutToExpire = isAfter(
    new Date(),
    subMonths(parseISO(expiration as string), 1),
  );

  if ((expiration && isCookieAboutToExpire) || !expiration) {
    return updateGrowUniversalId(universalId);
  }

  return universalId;
};
