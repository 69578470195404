import { ALLOWED_ACCENTED_CHARACTERS } from "./constants";

export const isOverCharacterLimit = (input: string, limit: number) => {
  return input.length > limit;
};

//identify if the input contains non-ascii characters except for most common accents
export const containsNonAsciiCharacters = (input: string) => {
  return new RegExp(`[^\\x00-\\x7F${ALLOWED_ACCENTED_CHARACTERS}]`).test(input);
};

export const containsMoreThanFiveAccentedCharacters = (input: string) => {
  const accentedCharRegExp = new RegExp(
    `[${ALLOWED_ACCENTED_CHARACTERS}]`,
    "g",
  );
  return (input.match(accentedCharRegExp) || []).length > 5;
};

//determines if the input contains characters that may throw an error for twilio's length limit, which appear to be triggered by a high density of special characters
export const shouldWarnForUnsafeCharacters = (input: string) => {
  return (
    containsNonAsciiCharacters(input) ||
    containsMoreThanFiveAccentedCharacters(input)
  );
};
