import { gql, useMutation } from "@apollo/client";
import { AppointmentRescheduledSource } from "./useRescheduleAppointmentMutation";
import { Server } from "../apollo/types";
import { GET_UPCOMING_APPOINTMENTS } from "./useGetScheduleForToday";
import { Scalars, InputMaybe } from "../types";

export type UpdateRecurringAppointmentMutationData = {
  __typename?: "Mutations";
  updateRecurringAppointment?: {
    __typename?: "UpdateRecurringAppointment";
    ok?: boolean | null;
  } | null;
};

export type UpdateRecurringAppointmentVariables = {
  /** should only update appointments in grouping after this datetime */
  afterTimeStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** should update location of all appoinments in grouping */
  inOffice?: InputMaybe<Scalars["Boolean"]["input"]>;
  patientId: Scalars["ID"]["input"];
  providerId?: Scalars["ID"]["input"];
  providerShortId?: Scalars["ID"]["input"];
  recurringAppointmentGroupingId: Scalars["ID"]["input"];
  rescheduledSource?: InputMaybe<AppointmentRescheduledSource>;
  /** should update time_end on all appointments in grouping */
  timeEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** should update time_start on all appointments in grouping */
  timeStart?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export const updateRecurringAppointmentDocument = gql`
  mutation UpdateRecurringAppointment(
    $values: UpdateRecurringAppointmentInput!
  ) {
    updateRecurringAppointment(values: $values) {
      ok
    }
  }
`;

export function useRescheduleRecurringAppointmentMutation(
  onCompleted?: () => void,
) {
  return useMutation<
    UpdateRecurringAppointmentMutationData,
    { values: UpdateRecurringAppointmentVariables }
  >(updateRecurringAppointmentDocument, {
    context: {
      server: Server.MONOLITH,
    },
    refetchQueries: [GET_UPCOMING_APPOINTMENTS],
    onCompleted,
  });
}
