import classnames from "classnames";
import { GrowLogo } from "./GrowLogo";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";
import { Button, IconButton } from "@grow-therapy-team/sprout-ui";
import { HTMLAttributes } from "react";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { DrawerState } from "../provider/state";
import { VisitorDrawerState } from "../visitor/state";
import { getIsSettingsDrawerOpen } from "./utils";
import { DrawerButton } from "./DrawerButton";

type BaseNavbarProps = {
  className?: string;
  drawerId?: string;
  drawerState: DrawerState | VisitorDrawerState | null;
  onClickNeedHelpButton?: () => void;
  TranscriptionPillComponent?: React.FC;
} & HTMLAttributes<HTMLDivElement>;

type NavbarPropsWithSettings = BaseNavbarProps & {
  forSettingsButtonDrawerState: DrawerState | VisitorDrawerState;
  onClickSettingsButton: () => void;
};

type NavbarPropsWithoutSettings = BaseNavbarProps & {
  onClickSettingsButton?: never;
  forSettingsButtonDrawerState?: never;
};

export function Navbar({
  className,
  drawerId,
  drawerState,
  forSettingsButtonDrawerState,
  onClickSettingsButton,
  onClickNeedHelpButton,
  TranscriptionPillComponent,
  ...props
}: NavbarPropsWithSettings | NavbarPropsWithoutSettings): JSX.Element {
  const { theme } = useTheme();
  const showNeedHelp = !!onClickNeedHelpButton;

  const isSettingsDrawerOpen = getIsSettingsDrawerOpen(drawerState);
  const ariaLabel = isSettingsDrawerOpen ? "Close settings" : "Open settings";

  return (
    <div
      className={twMerge(
        classnames(
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          "relative flex items-center justify-between px-6 py-7 h-18",
        ),
        className,
      )}
      {...props}
    >
      <div className={"flex content-start items-center gap-4"}>
        <GrowLogo />
        {TranscriptionPillComponent && <TranscriptionPillComponent />}
      </div>
      <div className="flex flex-row gap-6">
        {showNeedHelp && (
          <Button
            use="unstyled"
            className="px-0 py-0 text-neutral-000 underline hover:brightness-85 active:brightness-70"
            onClick={onClickNeedHelpButton}
          >
            <span className="hidden sm:block">Need help?</span>
            <span className="block sm:hidden">?</span>
          </Button>
        )}
        {!!onClickSettingsButton && forSettingsButtonDrawerState && (
          <DrawerButton
            forDrawerStates={forSettingsButtonDrawerState}
            as={IconButton}
            className="hidden sm:flex ml-0"
            aria-label={ariaLabel}
            aria-expanded={isSettingsDrawerOpen}
            aria-controls={drawerId}
            iconDefinition={faGear}
            onClick={onClickSettingsButton}
          />
        )}
      </div>
    </div>
  );
}
