import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { minutesToMilliseconds } from "date-fns";
import { LeaveSessionNudgeToast } from "./LeaveSessionNudgeToast";
import { deviceIsMobile } from "../../utils";
import { useEndOfSessionToast } from "../../hooks/useEndOfSessionToast";

const NUDGE_PRIOR_END_TIME_MS = minutesToMilliseconds(2);

export function useLeaveSessionNudgeToast() {
  const isMobile = deviceIsMobile();
  const { providerShortId, patientShortId } = useParams();

  const hideToast = (toastId: string) => {
    if (!toastId) return;
    toast.remove(toastId);
  };

  const showToast = () => {
    const position = isMobile ? "bottom-center" : "bottom-right";
    toast.custom(
      ({ id: toastId }) => {
        return (
          <LeaveSessionNudgeToast
            onClose={() => hideToast(toastId)}
            className={`${position}-toast`}
          />
        );
      },
      {
        duration: minutesToMilliseconds(1),
        position: position,
      },
    );
  };

  useEndOfSessionToast(
    providerShortId,
    patientShortId,
    showToast,
    NUDGE_PRIOR_END_TIME_MS,
    true,
  );
}
