import toast from "react-hot-toast";
import { RestrictedAudioPlaybackToast } from "./RestrictedAudioPlaybackToast";
import { useCallback, useRef } from "react";
import { Mutex } from "async-mutex";
import { RemoteAudioTrack } from "twilio-video";

const restrictedAudioPlaybackMutex = new Mutex();

export function useShowRestrictedAudioPlaybackToastCallback() {
  const toastIdRef = useRef<string | null>(null);

  const showRestrictedAudioPlaybackToast = useCallback(async () => {
    if (restrictedAudioPlaybackMutex.isLocked()) return;
    const release = await restrictedAudioPlaybackMutex.acquire();
    toastIdRef.current = toast.custom(
      (t) => (
        <RestrictedAudioPlaybackToast
          onClose={() => {
            release();
            toastIdRef.current = null;
            toast.dismiss(t.id);
          }}
        />
      ),
      {
        duration: Infinity,
        position: "top-center",
      },
    );
  }, []);

  const detectRestrictedAudioPlaybackTimeout = useCallback(
    (
      audioTrack: RemoteAudioTrack,
      audioElement: HTMLAudioElement,
      timeoutMs = 100,
    ) => {
      setTimeout(() => {
        const isUnexpectedlyPaused =
          audioTrack.isEnabled &&
          audioElement &&
          audioElement.srcObject &&
          !audioElement.ended &&
          audioElement.paused;
        if (isUnexpectedlyPaused) {
          showRestrictedAudioPlaybackToast();
        }
      }, timeoutMs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    showRestrictedAudioPlaybackToast,
    detectRestrictedAudioPlaybackTimeout,
  };
}
