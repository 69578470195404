import { useCallback } from "react";
import { deviceIsMobile } from "../utils";
import { useOnInactiveScreenDetected } from "./useOnInactiveScreenDetected";

export const useOnInactiveMobileScreenDetected = (
  onLeaveCallback: () => void,
  onReturnCallback?: () => void,
) => {
  const handleLeaveCallback = useCallback(() => {
    if (!deviceIsMobile()) return;

    onLeaveCallback();
  }, [onLeaveCallback]);

  const handleReturnCallback = useCallback(() => {
    if (!deviceIsMobile()) return;

    onReturnCallback?.();
  }, [onReturnCallback]);

  useOnInactiveScreenDetected(handleLeaveCallback, handleReturnCallback);
};
