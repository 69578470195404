import { faPauseCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  Alert,
  Button,
  ButtonSize,
  Card,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { VisitorDrawerState } from "../state";
import { HelpfulTipsDrawerButtonMenu } from "./DrawerButtonMenu";

export interface PreSessionPreparationProps {
  name: string;
  openDrawer: (state: VisitorDrawerState) => void;
  openHelpModal: () => void;
  showTabSwitchWarning?: boolean;
}

export function PreSessionPreparation({
  name,
  openDrawer,
  openHelpModal,
  showTabSwitchWarning = false,
}: PreSessionPreparationProps) {
  return (
    <Card
      className="flex flex-col gap-y-6 sm:gap-y-8 w-[35.5rem] p-6 sm:p-8 max-w-[calc(100vw-1.5rem)] mobile:border-x rounded-xl"
      data-testid="pre-session-preparation"
    >
      <div>
        <Text
          data-testid="session-info-section.greeting"
          data-dd-privacy="mask"
          data-dd-action-name="Click on session info"
          as="h1"
          variant="lg"
          className="text-center font-medium fs-exclude hidden sm:block pb-3"
        >
          Hi {name}!
        </Text>

        <Text
          as="h2"
          variant="xl"
          className="text-center font-medium sm:text-ivy-500 sm:text-2xlarge"
        >
          You&apos;re in the right place.
        </Text>

        <Text className="text-center text-neutral-700 max-w-sm pt-2 sm:pt-5 sm:pb-5 m-auto">
          Your provider should be arriving shortly. They have a 10-min grace
          period.
        </Text>
      </div>

      {showTabSwitchWarning && (
        <div data-testid="tab-switch-warning-callout">
          <Alert
            use="warning"
            heading="Please stay here while you wait."
            icon={faPauseCircle}
          >
            <Text variant="sm">
              Switching tabs or apps will remove you from the waiting room and
              will require you to join the session again.
            </Text>
          </Alert>
        </div>
      )}

      <HelpfulTipsDrawerButtonMenu openTipsDrawer={openDrawer} />

      <div className="flex justify-center">
        <Button
          onClick={openHelpModal}
          use="link"
          size={ButtonSize.Default}
          className="pt-2"
        >
          Need help?
        </Button>
      </div>
    </Card>
  );
}
