import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { noop } from "../../utils";
import { Toast } from "../../components";
import { AutomaticallyTransferringTextWrapper as AutomaticallyTransferringText } from "./AutomaticallyTransferringText";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

type ClientFinishingUpToastProps = Omit<
  ClientFinishingUpToastWrapperProps,
  "visitorId"
> & { countdownComponent: React.ReactNode };

export function ClientFinishingUpToast({
  countdownComponent,
  className,
  onClose = noop,
  onOpenQueue = noop,
  participantName,
}: ClientFinishingUpToastProps) {
  return (
    <Toast
      className={className}
      data-testid="client-finishing-up-toast"
      onClose={onClose}
    >
      <div className="flex justify-between items-center w-full">
        <button
          className="flex items-center"
          onClick={() => {
            onOpenQueue();
            onClose();
          }}
          aria-label="Open client queue"
        >
          <Icon
            aria-hidden
            icon={faUser}
            className="text-neutral-800 p-4 bg-lilac-100 rounded-full mr-4"
          />
          <div>
            <Text
              variant="sm"
              className="text-left fs-exclude"
              data-dd-privacy="mask"
              data-dd-action-name="Click on queue toast title"
            >
              {participantName} is finishing up in the waiting room
            </Text>
            {countdownComponent}
          </div>
        </button>
      </div>
    </Toast>
  );
}

type ClientFinishingUpToastWrapperProps = {
  visitorId: string;
  participantName: string;
  className?: string;
  onClose?: () => void;
  onOpenQueue?: () => void;
};

export function ClientFinishingUpToastWrapper(
  props: ClientFinishingUpToastWrapperProps,
) {
  return (
    <ClientFinishingUpToast
      countdownComponent={
        <AutomaticallyTransferringText
          visitorId={props.visitorId}
          onFinish={props.onClose}
        />
      }
      {...props}
    />
  );
}
