import { Auth0ProviderWithNav as Auth0Provider } from "./auth";
import { AuthedApolloProvider } from "./apollo";
import { Routes } from "./nav";
import { BrowserRouter } from "react-router-dom";
import { Toaster as SproutToaster } from "@grow-therapy-team/sprout-ui";
import { Toaster as DefaultToaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./error/ErrorPage";
import { DevTools as JotaiDevTools, useAtomsDebugValue } from "jotai-devtools";
import { useBackForwardCacheReload } from "./nav/useBackForwardCacheReload";
// Support for React Devtools
function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

function App() {
  useBackForwardCacheReload();
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {/* Rest assured that the below dev tools are not available in the prod build */}
      <JotaiDevTools />
      <DebugAtoms />
      <BrowserRouter>
        <Auth0Provider>
          <AuthedApolloProvider>
            {/* Toasts should always be visible e.g. should not be covered by modals */}
            <DefaultToaster containerClassName="z-[1000]" />
            <SproutToaster />
            <Routes />
          </AuthedApolloProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
