import { DrawerBody } from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import {
  AppointmentType,
  RecurringAppointmentGrouping,
} from "../schedule-preview/types";
import {
  getConflictingAppointmentMessage,
  getNumberOfReccurrences,
} from "./utils";
import { useEffect, useState } from "react";
import { useGetEventConflicts } from "./useGetEventConflicts";
import { EditAppointmentFormValues } from "./EditAppointmentDrawer";
import { ScheduleAppointmentForm } from "./ScheduleAppointmentForm";

interface EditAppointmentDrawerBodyProps {
  formValues: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  appointmentType: AppointmentType;
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null;
  conflictMessage?: string;
  timeInputErrorMessage?: string;
}

export function EditAppointmentDrawerBody({
  formValues,
  onUpdateValues,
  appointmentType,
  recurringAppointmentGrouping,
  conflictMessage,
  timeInputErrorMessage,
}: EditAppointmentDrawerBodyProps) {
  return (
    <DrawerBody>
      <ScheduleAppointmentForm
        formValues={formValues}
        onUpdateValues={onUpdateValues}
        appointmentType={appointmentType}
        recurringAppointmentGrouping={recurringAppointmentGrouping}
        conflictMessage={conflictMessage}
        timeInputErrorMessage={timeInputErrorMessage}
        isRecurrenceEditable={false}
      />
    </DrawerBody>
  );
}

export function EditAppointmentDrawerBodyWrapper({
  ...props
}: Omit<
  EditAppointmentDrawerBodyProps,
  "appointmentType" | "recurringGroupingId" | "conflictMessage"
>) {
  const appointmentData = useAtomValue(scheduledPatientInformationAtom);
  const [conflictMessage, setConflictMessage] = useState<string | undefined>(
    undefined,
  );

  const { startDate, endDate } = props.formValues;

  const { recurringAppointmentGrouping } = appointmentData ?? {};
  const isRecurring = !!recurringAppointmentGrouping;
  const numRecurrences = getNumberOfReccurrences(recurringAppointmentGrouping);
  const weeksBetween = recurringAppointmentGrouping?.weeksBetween ?? 1;

  const { error, loading, data } = useGetEventConflicts(
    startDate,
    endDate,
    isRecurring,
    numRecurrences,
    weeksBetween,
  );

  useEffect(
    function updateConflictMessage() {
      if (!appointmentData) {
        return;
      }

      if (error || loading) {
        setConflictMessage("");
        return;
      }

      setConflictMessage(
        getConflictingAppointmentMessage(data, appointmentData),
      );
    },
    [data, loading, error, appointmentData],
  );

  if (!appointmentData) {
    return;
  }

  return (
    <EditAppointmentDrawerBody
      {...props}
      appointmentType={
        appointmentData.appointmentType ?? AppointmentType.FollowUp
      }
      recurringAppointmentGrouping={
        appointmentData.recurringAppointmentGrouping
      }
      conflictMessage={conflictMessage}
    />
  );
}
