import { LoadingScreen as SproutLoadingScreen } from "@grow-therapy-team/sprout-ui";
import { getAuth0Config } from "../auth";
import {
  GetCurrentProviderQueryData,
  useGetCurrentProviderQueryCallback,
} from "./useGetCurrentProviderQuery";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { logger } from "../datadog/logger";
import { QueryResult } from "@apollo/client";
import { useAtom, useSetAtom } from "jotai";
import { idClaimsAtom } from "../auth/state";
import { setQueryParams } from "../utils";
import { Provider } from "../types";
import { userDataAtom } from "./state";

function LoadingScreen() {
  return <SproutLoadingScreen className="bg-neutral-100" />;
}

type ProviderProtectedProps = {
  auth0Config: ReturnType<typeof getAuth0Config>;
  children: React.ReactNode;
  getCurrentProvider: ReturnType<typeof useGetCurrentProviderQueryCallback>[0];
  isLoadingAuth: boolean;
  onAuthSuccess?: (provider: Required<Provider>) => void;
  hasAuthedBefore?: boolean;
} & Pick<
  ReturnType<typeof useAuth0>,
  "isAuthenticated" | "loginWithRedirect" | "logout"
>;

export function ProviderProtected({
  auth0Config: { loginUri },
  children,
  getCurrentProvider,
  isAuthenticated,
  isLoadingAuth,
  loginWithRedirect,
  logout,
  onAuthSuccess,
  hasAuthedBefore,
}: ProviderProtectedProps) {
  const Loader = <LoadingScreen key="provider-protected-loader" />;
  const requiresLogin = !isLoadingAuth && !isAuthenticated;

  useEffect(
    function handleAuth() {
      if (isLoadingAuth || hasAuthedBefore) return;
      if (requiresLogin) {
        loginWithRedirect();
        return;
      }
      (async function authorize() {
        let result: QueryResult<GetCurrentProviderQueryData> | null = null;
        let error: Error | null = null;
        try {
          // Getting provider manually to prevent the entire page from loading when the
          // Apollo context changes e.g. when a new access token is fetched.
          result = await getCurrentProvider();
        } catch (e) {
          error = e as Error;
        } finally {
          const provider = result?.data?.currentProvider;
          if (!provider?.shortId) {
            logger.warn(
              "Unable to get current provider. Logging out.",
              {},
              ...(error ? [error] : []),
            );
            logout({
              logoutParams: {
                returnTo: setQueryParams(loginUri, {
                  error_description:
                    "Unable to find a Telehealth account for the user you logged in as. If you’re a client, please use the link provided to you in your confirmation email.",
                }),
              },
            });
          } else {
            logger.info("Authenticated successfully", {
              providerShortId: provider.shortId,
            });
            onAuthSuccess?.(provider);
          }
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAuthedBefore, isLoadingAuth, requiresLogin],
  );

  // To minimize disruptions to the provider, don't show loader if the user had
  // authed successfully before i.e. if we already have user info
  if (hasAuthedBefore) return children;

  return Loader;
}

export function ProviderProtectedWrapper({
  children,
}: Pick<ProviderProtectedProps, "children">) {
  const [getCurrentProvider] = useGetCurrentProviderQueryCallback();
  const { getIdTokenClaims, isLoading, logout, ...auth0Props } = useAuth0();
  const [idClaims, setIdClaims_] = useAtom(idClaimsAtom);
  const setUserData = useSetAtom(userDataAtom);
  const setIdClaims = useCallback(
    async (provider: Required<Provider>) => {
      const claims = await getIdTokenClaims();
      setIdClaims_(claims);
      setUserData(provider);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ProviderProtected
      {...{
        auth0Config: getAuth0Config(),
        ...auth0Props,
        getCurrentProvider,
        isLoadingAuth: isLoading,
        logout,
        onAuthSuccess: setIdClaims,
        hasAuthedBefore: !!idClaims,
      }}
    >
      {children}
    </ProviderProtected>
  );
}
