export type ChoiceValue = string;
export type ChoiceStructure = {
  allowFreeFormEntry?: boolean;
  label?: string;
  children?: ChoiceStructure;
  value: ChoiceValue;
}[];

/**
 * choices is a nested structure of choices, where each choice has a value and
 * may have children. This function returns the maximum depth of the choices
 * structure. Presumably, one choice could have several layers of depth, where
 * others have fewer.
 * this method was originally written to inform the PaginatedDrawer of how many
 * steps it should show.
 */
export function getMaxDepth(choices: ChoiceStructure): number {
  if (!choices.length) return 0;

  return choices.reduce((max, choice) => {
    const childrenDepth = choice.children?.length
      ? getMaxDepth(choice.children) + 1
      : 0;
    return Math.max(max, childrenDepth);
  }, 1);
}

// based on the current value of the selection, traverse through the choices
// structure to find the next displayable set of choices
export function getChoicesForDisplay(
  choices: ChoiceStructure | undefined,
  selection: ChoiceValue[],
  depth = selection?.length ?? choices?.length ?? 0,
) {
  let choicesForDisplay = choices;
  const cappedDepth = Math.min(depth, selection.length);

  for (let i = 0; i < cappedDepth; i++) {
    const selected = selection[i];
    const choice = choicesForDisplay?.find((c) => c.value === selected);
    choicesForDisplay = choice?.children;
    if (!choicesForDisplay) break;
  }

  return choicesForDisplay;
}

export const MOOD_CHECKIN_CHOICES: ChoiceStructure = [
  {
    value: "Happy",
    children: [
      { value: "Content" },
      { value: "Proud" },
      { value: "Accepted" },
      { value: "Peaceful" },
      { value: "Trusting" },
      { value: "Optimistic" },
    ],
  },
  {
    value: "Sad",
    children: [
      { value: "Lonely" },
      { value: "Vulnerable" },
      { value: "Despairing" },
      { value: "Guilty" },
      { value: "Depressed" },
      { value: "Hurt" },
    ],
  },
  {
    value: "Angry",
    children: [
      { value: "Let down" },
      { value: "Humiliated" },
      { value: "Bitter" },
      { value: "Mad" },
      { value: "Frustrated" },
      { value: "Distant" },
    ],
  },
  {
    value: "Afraid",
    children: [
      { value: "Scared" },
      { value: "Anxious" },
      { value: "Insecure" },
      { value: "Weak" },
      { value: "Rejected" },
      { value: "Threatened" },
    ],
  },
  {
    value: "Surprised",
    children: [
      { value: "Startled" },
      { value: "Confused" },
      { value: "Amazed" },
      { value: "Excited" },
    ],
  },
  {
    value: "Disgusted",
    children: [
      { value: "Disapproving" },
      { value: "Disappointed" },
      { value: "Awful" },
      { value: "Repelled" },
    ],
  },
];
