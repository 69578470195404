import { useContext } from "react";
import {
  ButtonRefsContext,
  DEFAULT_BUTTON_REFS_CONTEXT_VALUE,
} from "../state/drawerButtonRefs";
import { logger } from "../datadog";

/**
 * Hook to get context for managing drawer button refs.
 */
export const useDrawerButtonRefs = () => {
  const contextValue = useContext(ButtonRefsContext);

  if (!contextValue) {
    // NOTE: Defensively logging instead of throwing an error to avoid
    // unintentional runtime exceptions, since this context supports
    // non-critical functionality.
    logger.error(
      "useDrawerButtonRefs must be used within a ButtonRefsProvider",
    );
    return DEFAULT_BUTTON_REFS_CONTEXT_VALUE;
  }

  return contextValue;
};
