import { hoursToMilliseconds } from "date-fns";
import {
  RecurringAppointmentGrouping,
  ScheduledPatientInformation,
} from "../schedule-preview/types";
import {
  APPOINTMENT_CONFLICT_MESSAGE,
  DOES_NOT_REPEAT_TEXT,
  MAX_APPOINTMENT_LENGTH_HOURS,
  MAX_RECURRENCES,
  NUM_INDEFINITE_APPOINTMENTS,
  REPEATS_INDEFINITELY_TEXT,
  TIME_RANGE_VALIDATION_MESSAGE,
} from "./constants";
import {
  AppointmentConflict,
  BusyBlockConflict,
  GetEventConflictsQueryData,
  ThirdPartyEventConflict,
} from "./useGetEventConflicts";
import { getAppConfig } from "../../config";
import qs from "qs";
import { EditAppointmentFormValues } from "./EditAppointmentDrawer";

export interface ValidationResult {
  isFrequencyValid?: boolean;
  isRecurrencesValid?: boolean;
}

/** Example output: 6:30 PM - 7:30 PM */
export const formatTimeRange = (
  appointmentStartTime?: string | null,
  appointmentEndTime?: string | null,
): string | JSX.Element => {
  if (!appointmentStartTime || !appointmentEndTime) {
    return "";
  }

  const formattedStartTime = new Date(appointmentStartTime).toLocaleTimeString(
    [],
    {
      hour: "numeric",
      minute: "numeric",
    },
  );

  const formattedEndTime = new Date(appointmentEndTime).toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      {formattedStartTime} <span className="sr-only">to</span>
      <span aria-hidden>-</span> {formattedEndTime}
    </>
  );
};

/** Example output: "June 24, 2024 • 6:30 PM - 7:30 PM" */
export const formatAppointmentTime = (
  appointmentStartTime?: string | null,
  appointmentEndTime?: string | null,
) => {
  if (!appointmentStartTime || !appointmentEndTime) {
    return "";
  }

  const formattedDate = new Date(appointmentStartTime).toLocaleString(
    undefined,
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
  );

  return `${formattedDate} • ${formatTimeRange(
    appointmentStartTime,
    appointmentEndTime,
  )}`;
};

/** example output: "Mon, Jun 24" */
export const formatShortAppointmentDate = (date: string) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
};

export const getRecurringAppointmentText = (
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null,
): string => {
  if (!recurringAppointmentGrouping) {
    return DOES_NOT_REPEAT_TEXT;
  }

  const { indefinite, weeksBetween, appointments } =
    recurringAppointmentGrouping;
  if (indefinite) {
    return REPEATS_INDEFINITELY_TEXT;
  }
  if (!appointments) {
    return "";
  }
  const lastAppointment = appointments[appointments.length - 1];
  const frequencyText =
    weeksBetween === 1 ? "weekly" : `every ${weeksBetween} weeks`;
  return `Repeats ${frequencyText} until ${new Date(
    lastAppointment?.timeStart,
  )?.toLocaleString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
  })}`;
};

/** example output: "PDT" */
export const getBrowserTimezone = () => {
  return new Date()
    .toLocaleTimeString(undefined, { timeZoneName: "short" })
    .split(" ")[2];
};

/** example output: "Timezone • PDT" */
export const getBrowserTimezoneText = () => {
  return `Timezone • ${getBrowserTimezone()}`;
};

export const getTimeInputValidationMessage = (
  timeStart: Date,
  timeEnd?: Date,
) => {
  const isFutureTime = timeStart.valueOf() > new Date().valueOf();
  if (!isFutureTime) {
    return TIME_RANGE_VALIDATION_MESSAGE.INVALID_START;
  }
  if (!timeEnd) {
    return;
  }

  const isLengthValid =
    timeEnd.valueOf() - timeStart.valueOf() <=
    hoursToMilliseconds(MAX_APPOINTMENT_LENGTH_HOURS);
  const isTimeRangeValid = timeEnd.valueOf() > timeStart.valueOf();
  if (!isTimeRangeValid) {
    return TIME_RANGE_VALIDATION_MESSAGE.INVALID_RANGE;
  }

  if (!isLengthValid) {
    return TIME_RANGE_VALIDATION_MESSAGE.INVALID_LENGTH;
  }
  return;
};

enum ConflictType {
  Appointment = "Appointment",
  BusyBlock = "BusyBlock",
  ThirdPartyEvent = "ThirdPartyEvent",
}

type Conflict =
  | (AppointmentConflict & { type: ConflictType.Appointment })
  | (BusyBlockConflict & { type: ConflictType.BusyBlock })
  | (ThirdPartyEventConflict & { type: ConflictType.ThirdPartyEvent });

export const getConflictingAppointmentMessage = (
  appointmentConflicts: GetEventConflictsQueryData | undefined,
  currentAppointment: ScheduledPatientInformation | EditAppointmentFormValues,
): string | undefined => {
  const appointmentConflictsWithInput: Conflict[] = [
    ...(appointmentConflicts?.conflictAppointments?.map((conflict) => ({
      type: ConflictType.Appointment,
      ...conflict,
    })) || []),
    ...(appointmentConflicts?.conflictBusyBlocks?.map((conflict) => ({
      type: ConflictType.BusyBlock,
      ...conflict,
    })) || []),
    ...(appointmentConflicts?.conflictThirdPartyEvents?.map((conflict) => ({
      type: ConflictType.ThirdPartyEvent,
      ...conflict,
    })) || []),
  ] as Conflict[];

  let firstConflict;

  if (!appointmentConflictsWithInput.length) {
    return;
  }

  firstConflict = appointmentConflictsWithInput[0];
  // Check that conflict does not have the same recurring appointment id, if it does check next conflict
  const currentAppointmentRecurringGroupingId =
    currentAppointment?.recurringAppointmentGrouping
      ?.recurringAppointmentGroupingId;
  if (currentAppointmentRecurringGroupingId) {
    firstConflict = appointmentConflictsWithInput.find(
      (conflict) =>
        (conflict?.type === ConflictType.Appointment &&
          conflict.recurringAppointmentGroupingId !==
            Number(currentAppointmentRecurringGroupingId)) ||
        conflict?.type === ConflictType.BusyBlock,
    );

    if (!firstConflict) {
      return;
    }
  }
  //If appointment is not recurring, only check that the conflict is not identical to appointment
  else if (firstConflict?.type === ConflictType.Appointment) {
    if (
      appointmentConflictsWithInput &&
      Number(currentAppointment.appointmentId) !== firstConflict.appointmentId
    ) {
      [firstConflict] = appointmentConflictsWithInput;
    } else {
      return;
    }
  }
  switch (firstConflict?.type) {
    case ConflictType.Appointment:
      return APPOINTMENT_CONFLICT_MESSAGE.EXISTING_APPOINTMENT;
    case ConflictType.BusyBlock:
      return APPOINTMENT_CONFLICT_MESSAGE.EXISTING_BUSY_BLOCK;
    case ConflictType.ThirdPartyEvent:
      return APPOINTMENT_CONFLICT_MESSAGE.EXISTING_EVENT;
    default:
      return;
  }
};

export const getNumberOfReccurrences = (
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null,
) => {
  if (!recurringAppointmentGrouping) {
    return 1;
  }

  if (recurringAppointmentGrouping.indefinite) {
    return NUM_INDEFINITE_APPOINTMENTS;
  }

  return recurringAppointmentGrouping.appointments.length;
};
export function getAppointmentCancelLink({
  appointmentShortId,
  gid,
}: {
  appointmentShortId: string;
  gid?: string;
}): string {
  return `${getAppConfig().providerOrigin}/cancel-react?${qs.stringify({
    aid: appointmentShortId,
    ...(gid && { gid }),
  })}`;
}

const HOURS_IN_WEEK = 168;

export const getOneWeekFromTargetDate = (targetDate: Date) => {
  const newDate = new Date(
    targetDate.getTime() + hoursToMilliseconds(HOURS_IN_WEEK),
  );
  // Round to the nearest 15 minutes
  newDate.setMinutes(Math.round(newDate.getMinutes() / 15) * 15);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

export const getSuggestedNextStartAndEndTimes = (
  startDate?: Date,
  endDate?: Date,
) => {
  const suggestedStartDate = getOneWeekFromTargetDate(startDate || new Date());
  const suggestedEndDate = getOneWeekFromTargetDate(
    endDate || new Date(Date.now() + hoursToMilliseconds(1)),
  );
  return { suggestedStartDate, suggestedEndDate };
};

export const checkRecurrenceValidity = (
  values: Partial<EditAppointmentFormValues>,
): ValidationResult => {
  const isRecurrencesValid =
    !values.isRecurring ||
    values.indefinite ||
    !!(
      values.numRecurrences &&
      values.numRecurrences <= MAX_RECURRENCES &&
      values.numRecurrences >= 1
    );
  const isFrequencyValid =
    !values.isRecurring ||
    !!(
      values.weeksBetween &&
      values.weeksBetween <= MAX_RECURRENCES &&
      values.weeksBetween >= 1
    );

  return {
    isFrequencyValid,
    isRecurrencesValid,
  };
};
