import { useGetJwtInterval } from "../../auth/useGetJwtInterval";
import { logger } from "../../datadog/logger";
import { useCreateProviderChatAccessCallback } from "./useCreateProviderChatAccess";

export function useInitProviderChatAccess() {
  const getChatJwt = useCreateProviderChatAccessCallback();

  return useGetJwtInterval({
    getJwt: getChatJwt,
    onError: (error) => logger.error(error.message, {}, error),
  });
}
