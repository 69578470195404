import { useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  dominantSpeakerIdAtom,
  pictureInPictureParticipantIdAtom,
  trackStatusByParticipantIdAtom,
} from "./state";
import { logger } from "../datadog/logger";
import { exitPictureInPicture } from "./utils";

export function usePictureInPicture() {
  const dominantSpeakerId = useAtomValue(dominantSpeakerIdAtom);
  const trackStatusByParticipantId = useAtomValue(
    trackStatusByParticipantIdAtom,
  );

  const setPictureInPictureParticipantId = useSetAtom(
    pictureInPictureParticipantIdAtom,
  );

  /**
   * Picture in picture should display the most recent speaker if they still have their video on
   * Otherwise we'll try to find any participant with their video on
   */
  const participantIdToDisplay =
    dominantSpeakerId &&
    !trackStatusByParticipantId[dominantSpeakerId]?.isVideoOff
      ? dominantSpeakerId
      : Object.keys(trackStatusByParticipantId).find(
          (participantId) =>
            !trackStatusByParticipantId[participantId].isVideoOff,
        );

  useEffect(
    function updatePictureInPictureParticipantId() {
      /**
       * If all remote participants turn their cameras off so there's no one to show,
       * we should exit out of picture in picture entirely
       */
      if (!participantIdToDisplay) {
        exitPictureInPicture();
      }
      setPictureInPictureParticipantId(participantIdToDisplay);
      logger.info("Picture in picture target updated", {
        participantIdToDisplay,
      });
    },
    [participantIdToDisplay, setPictureInPictureParticipantId],
  );

  useEffect(function cleanupOldActionHandlers() {
    return () => {
      try {
        window.navigator.mediaSession.setActionHandler(
          "enterpictureinpicture" as MediaSessionAction,
          null,
        );
      } catch (error) {
        logger.warn(
          "Could not clean up enterpictureinpicture action handler",
          {},
          error as Error,
        );
      }
    };
  }, []);
}
