import { EditAppointmentDrawerFooterWrapper as EditAppointmentDrawerFooter } from "./EditAppointmentDrawerFooter";
import { EditAppointmentDrawerHeaderWrapper as EditAppointmentDrawerHeader } from "./EditAppointmentDrawerHeader";
import { EditAppointmentDrawerBodyWrapper as EditAppointmentDrawerBody } from "./EditAppointmentDrawerBody";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import {
  AppointmentLocation,
  RecurringAppointmentGrouping,
} from "../schedule-preview/types";
import { getTimeInputValidationMessage } from "./utils";

export type EditAppointmentFormValues = {
  appointmentLocation?: AppointmentLocation;
  startDate: Date;
  endDate: Date;
  isRecurring?: boolean;
  weeksBetween?: number;
  numRecurrences?: number;
  indefinite?: boolean;
  recurringAppointmentGrouping?: RecurringAppointmentGrouping;
  appointmentId?: string;
};

export function EditAppointmentDrawer({
  formValues,
  onUpdateValues,
  timeInputErrorMessage,
}: {
  formValues: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  timeInputErrorMessage?: string;
}) {
  return (
    <>
      <EditAppointmentDrawerHeader />
      <EditAppointmentDrawerBody
        formValues={formValues}
        onUpdateValues={onUpdateValues}
        timeInputErrorMessage={timeInputErrorMessage}
      />
      <EditAppointmentDrawerFooter
        formValues={formValues}
        isSaveDisabled={!!timeInputErrorMessage}
      />
    </>
  );
}

export function EditAppointmentDrawerWrapper() {
  const appointmentData = useAtomValue(scheduledPatientInformationAtom);
  const [formValues, setFormValues] = useState<
    Partial<EditAppointmentFormValues>
  >({});

  if (!appointmentData) {
    return;
  }

  const { appointmentLocation, appointmentStartTime, appointmentEndTime } =
    appointmentData;
  const values = {
    appointmentLocation: formValues.appointmentLocation || appointmentLocation,
    startDate: formValues.startDate || new Date(appointmentStartTime),
    endDate: formValues.endDate || new Date(appointmentEndTime),
  };

  const updateValues = (updates: Partial<EditAppointmentFormValues>): void => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...updates,
    }));
  };

  const timeInputErrorMessage = getTimeInputValidationMessage(
    values.startDate,
    values.endDate,
  );

  return (
    <EditAppointmentDrawer
      formValues={values}
      onUpdateValues={updateValues}
      timeInputErrorMessage={timeInputErrorMessage}
    />
  );
}
