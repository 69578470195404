import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { getAppConfig } from "../../config";
import qs from "qs";
import { useCallback } from "react";
import { useDisconnectTwilioRoomCallback } from "./useDisconnectTwilioRoomCallback";
import { TrackingEvents, sendLoggingEvents } from "../../events";

export function useLeaveSessionCallback() {
  const { providerShortId, patientShortId } = useParams() as {
    providerShortId: string;
    patientShortId: string;
  };
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const disconnectTwilioRoom = useDisconnectTwilioRoomCallback();

  const callback = useCallback(
    function redirectToPostSession() {
      if (!data) return;
      sendLoggingEvents(TrackingEvents.CLIENT_POST_SESSION_REDIRECT);
      const appointmentShortId =
        data.telehealthSessionInfo?.appointment?.shortId;
      const searchQueryParams = {
        prsid: providerShortId,
        pid: patientShortId,
        utm_source: "telehealth-rematching",
        ...(appointmentShortId && { appointment_id: appointmentShortId }),
      };

      const postSessionLink = `${
        getAppConfig().marketplaceOrigin
      }/session-completed?${qs.stringify(searchQueryParams)}`;

      disconnectTwilioRoom(); // gracefully disconnect from the room
      window.location.href = postSessionLink;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, providerShortId, patientShortId],
  );

  return callback;
}
