import { useRemoteScreenShare } from "../useRemoteScreenShare";
import { ScreenShareVideo } from "./ScreenShareVideo";

export function RemoteScreenShare() {
  const {
    remoteParticipantScreenShareRef,
    remoteParticipantAudioScreenShareRef,
  } = useRemoteScreenShare();

  return (
    <ScreenShareVideo
      screenShareRef={remoteParticipantScreenShareRef}
      audioRef={remoteParticipantAudioScreenShareRef}
    />
  );
}
