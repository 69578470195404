import { gql, useQuery } from "@apollo/client";
import { Server } from "../../apollo/types";
import { logger } from "../../datadog/logger";

type GetReadyAvsCountQueryData = {
  afterVisitSummariesReady: {
    totalReadyForReview: number;
  };
};

const GET_READY_AVS_COUNT_QUERY = gql`
  query GetReadyAfterVisitSummaryCount {
    afterVisitSummariesReady {
      totalReadyForReview
    }
  }
`;

export function useGetReadyAvsCountQuery() {
  return useQuery<GetReadyAvsCountQueryData>(GET_READY_AVS_COUNT_QUERY, {
    onError: (error) => {
      logger.error("Failed to fetch ready AVS count", {}, error);
    },
    context: {
      server: Server.MONOLITH,
    },
  });
}
