import { QuestionTypeEnum, GroupedResponse, MedicationValue } from "./types";
import { Text } from "@grow-therapy-team/sprout-ui";

type IntakeFormAnswerProps = {
  questionWithAnswer: GroupedResponse;
  isDivider: boolean;
  isSectionTitle: boolean;
};

export function IntakeFormAnswer({
  questionWithAnswer,
  isDivider,
  isSectionTitle,
}: IntakeFormAnswerProps) {
  const answer = questionWithAnswer.answer;

  if (answer === "null" && !isDivider && !isSectionTitle) {
    return null;
  }

  switch (questionWithAnswer.type) {
    case QuestionTypeEnum.SELECT:
      return <Text className="text-small mb-2">{answer as string}</Text>;
    case QuestionTypeEnum.MEDICATION_INPUT:
      return (answer as MedicationValue[])?.map((a) => {
        return (
          <div className="pt-2 pb-2" key={a.medicationName}>
            <Text>Medication: {a.medicationName}</Text>
            <Text>Dosage: {a.dosage}</Text>
          </div>
        );
      });
    case QuestionTypeEnum.CHECKBOX:
    case QuestionTypeEnum.MULTI_SELECT:
      return <Text>{(answer as string[])?.join(", ")}</Text>;
    default:
      return <Text>{answer as string}</Text>;
  }
}

export default IntakeFormAnswer;
