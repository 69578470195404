import { gql, useMutation } from "@apollo/client";
import { UserType } from "../types";

type RecordUnaccompaniedParticipantVariables = {
  entityShortId: string;
  entityType: UserType;
  name: string;
};

type RecordUnaccompaniedParticipantData = {
  unaccompaniedParticipant: {
    entityShortId: string;
  };
};

const recordUnaccompaniedParticipantMutation = gql`
  mutation RecordUnaccompaniedParticipant(
    $entityShortId: String!
    $entityType: UserType!
    $name: String!
  ) {
    recordUnaccompaniedParticipant(
      entityShortId: $entityShortId
      entityType: $entityType
      name: $name
    ) {
      entityShortId
    }
  }
`;

export function useRecordUnaccompaniedParticipantMutation() {
  return useMutation<
    RecordUnaccompaniedParticipantData,
    RecordUnaccompaniedParticipantVariables
  >(recordUnaccompaniedParticipantMutation);
}
