import { gql, useMutation } from "@apollo/client";

type RecordProviderNoShowReportData = {
  recordProviderNoShowReport: {
    appointmentShortId: string;
    createdAt: string;
  };
};

type RecordProviderNoShowReportVariables = {
  appointmentShortId: string;
  patientName: string;
};

const recordProviderNoShowReportMutation = gql`
  mutation RecordProviderNoShowReport(
    $appointmentShortId: String!
    $patientName: String!
  ) {
    recordProviderNoShowReport(
      appointmentShortId: $appointmentShortId
      patientName: $patientName
    ) {
      appointmentShortId
      createdAt
    }
  }
`;

export function useRecordProviderNoShowReportMutation() {
  return useMutation<
    RecordProviderNoShowReportData,
    RecordProviderNoShowReportVariables
  >(recordProviderNoShowReportMutation, { context: { skipAuth: true } });
}
