import { ProviderForm } from "../../components/forms/types";

export enum QuestionTypeEnum {
  DIVIDER = "DIVIDER",
  SELECT = "SELECT",
  SECTION_TITLE = "SECTION_TITLE",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  MULTI_SELECT = "MULTI_SELECT",
  MEDICATION_INPUT = "MEDICATION_INPUT",
  PHONE_NUMBER_INPUT = "PHONE_NUMBER_INPUT",
  EMERGENCY_CONTACT_INPUT = "EMERGENCY_CONTACT_INPUT",
  PROFESSIONAL_RESOURCE_INPUT = "PROFESSIONAL_RESOURCE_INPUT",
  COMPLEX_ERROR = "COMPLEX_ERROR",
}

export type AnswerValue =
  | string
  | string[]
  | ContactValue[]
  | ProfessionalResourceValue
  | MedicationValue[];

export enum FormType {
  Gad7 = "GAD7",
  Intake = "INTAKE",
  NoteToProvider = "NOTE_TO_PROVIDER",
  Phq9 = "PHQ9",
  Safetyplan = "SAFETYPLAN",
}

export enum NoteTypeEnum {
  GROW_PROGRESS = "GROW_PROGRESS",
}

export type NoteResponseType = Record<string, string | string[] | boolean>;

export enum ProgressNoteSectionEnum {
  APPOINTMENT_DETAIL = "Appointment details",
  CLINICAL_PRESENTATION = "Clinical Presentation",
  THERAPIST_INTERVENTIONS = "Therapist Interventions",
  CLIENT_PROGRESS = "Client Progress",
}

export type NoteType = {
  question: string;
  answer: string | string[] | boolean;
  name: string;
  margin: string;
  textVariant: string | null;
  details: string[];
  fieldType?: string | null;
};

export enum FieldTypeEnum {
  CHECKBOX = "checkbox",
  RADIO = "radio",
  TEXTAREA = "textarea",
  TEXTINPUT = "textinput",
  TEXT = "text",
  MULTISELECT = "multiselect",
  GOALS_INPUT = "goals_input",
}

export type MedicationValue = { medicationName: string; dosage: string };

export interface ContactValue {
  name: string;
  phoneNumber: string;
}

export interface ProfessionalResourceValue {
  address: string;
  phoneNumber: string;
  resources: ContactValue[];
}

export type QuestionOption = {
  value: string;
  label: string;
};

export type GroupedResponse = {
  question?: string;
  questionName?: string;
  answerElement?: FormResponseElement;
  type?: QuestionTypeEnum;
  questionElement?: ProviderFormElement;
  answer?: AnswerValue;
  sectionTitle?: string;
};

export type SectionGroup = Record<string, GroupedResponse[]>;

export type GroupedFormData = {
  formId: string;
  formPrompt: string;
  formVersion: number;
  formResponseId: string;
  formAnswers: FormResponse["formResponseModel"]["pages"];
  createdAt: string;
  formTitle: ProviderForm["formModel"]["formTitle"];
  formQuestionsWithAnswers: SectionGroup;
} & Pick<FormResponse, "formId" | "formType" | "score" | "highAcuity">;

export type ProviderFormElement<QuestionType = QuestionTypeEnum> = {
  name: string;
  required: boolean;
  type: QuestionType;
  title: string;
  sectionTitle?: string;
  placeholder?: string | null;
  maxLength?: number | null;
  visibleIf?: string | null;
  layout?: string | null;
  options: QuestionOption[];
};

export type FormResponseElement = {
  name: string;
  value: string;
};

export type FormResponse = {
  id: string;
  formId: string;
  formType: string;
  highAcuity: boolean;
  score: number;
  createdAt: string;
  formResponseModel: {
    pages: {
      elements: FormResponseElement[];
    }[];
  };
};

export type CombinedFormDataEntriesByFormTitle = Record<
  string,
  GroupedFormData[]
>;
