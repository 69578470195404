import { DrawerState, drawerStateAtom } from "../state";
import { FormType, GroupedFormData } from "./types";

import { useSetAtom, useAtomValue } from "jotai";
import { DrawerBody } from "@grow-therapy-team/sprout-ui";
import { FormListItem } from "./form-list-item";
import { ClientInformationDrawerHeader } from "./ClientInformationDrawerHeader";
import { useState } from "react";
import { selectedGroupedFormAtom, selectedFormTitleAtom } from "./state";
import { FormResponse } from "./FormResponse";

type ClientInformationFormResponseDrawerProps = {
  selectedForms: GroupedFormData[];
  revealResults?: boolean;
  selectedFormTitle: string;
  onClickForm: (i: number) => void;
  onClickBackButton: () => void;
  formEntry: number;
};

export function ClientInformationFormResponseDrawer({
  selectedForms,
  selectedFormTitle,
  formEntry,
  onClickBackButton,
  onClickForm,
  revealResults,
}: ClientInformationFormResponseDrawerProps) {
  const drawerTitle =
    selectedForms[0].formType === FormType.Gad7 ||
    selectedForms[0].formType === FormType.Phq9
      ? `${selectedForms[0].formType} - ${selectedFormTitle}`
      : selectedFormTitle;

  return (
    <>
      <ClientInformationDrawerHeader
        drawerTitle={drawerTitle}
        onClickBackButton={onClickBackButton}
      />
      <DrawerBody>
        {!revealResults ? (
          <span data-testid="client-information.forms.form-list">
            {selectedForms.map((form, i) => (
              <FormListItem
                form={form}
                key={`${form.formType}-${i}`}
                onClick={() => {
                  onClickForm(i);
                }}
              />
            ))}
          </span>
        ) : (
          <FormResponse selectedForm={selectedForms[formEntry]} />
        )}
      </DrawerBody>
    </>
  );
}

export function ClientInformationFormResponseDrawerWrapper() {
  const selectedFormTitle = useAtomValue(selectedFormTitleAtom);
  const selectedFormResponses = useAtomValue(selectedGroupedFormAtom);
  const hasSingleSubmission =
    selectedFormResponses?.length === 1 ? true : false;
  const setDrawerState = useSetAtom(drawerStateAtom);
  const [revealResults, setRevealResults] = useState(hasSingleSubmission);
  const [formEntry, setFormEntry] = useState(0);

  const onClickBackButton = () => {
    if (revealResults && !hasSingleSubmission) {
      setRevealResults(!revealResults);
    } else {
      setDrawerState(DrawerState.CLIENT_INFORMATION);
    }
  };

  const onClickForm = (i: number) => {
    setFormEntry(i);
    setRevealResults(!revealResults);
  };

  if (!selectedFormTitle || !selectedFormResponses) return;

  return (
    <ClientInformationFormResponseDrawer
      selectedFormTitle={selectedFormTitle}
      revealResults={revealResults}
      selectedForms={selectedFormResponses}
      onClickBackButton={onClickBackButton}
      onClickForm={onClickForm}
      formEntry={formEntry}
    />
  );
}
