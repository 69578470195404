import { DrawerBody, Tabs, Tab } from "@grow-therapy-team/sprout-ui";
import { useAtom } from "jotai";
import { activeClientInformationTabAtom } from "./state";
import { ClientInformationTabWrapper as ClientInformationTab } from "./ClientInformationTab";
import { UpcomingClientAppointmentsTabWrapper as UpcomingClientAppointmentsTab } from "./client-appointments-tab/UpcomingClientAppointmentsTab";
import { ClientInformationDrawerTab } from "../state";
import { faCalendar, faUser } from "@fortawesome/pro-solid-svg-icons";

export function ClientInformationDrawerBody({
  tabContent,
  setActiveTab,
  activeTab,
}: {
  tabContent: React.ReactNode;
  setActiveTab: (tab: ClientInformationDrawerTab) => void;
  activeTab: ClientInformationDrawerTab;
}) {
  return (
    <DrawerBody>
      <Tabs
        selectedTabId={activeTab}
        onSelectTab={(newTab: ClientInformationDrawerTab) => {
          setActiveTab(newTab);
        }}
        className="mb-0"
      >
        <Tab
          tabId={ClientInformationDrawerTab.INFORMATION}
          className="whitespace-nowrap"
          icon={faUser}
          title="Client Information"
        />
        <Tab
          tabId={ClientInformationDrawerTab.APPOINTMENTS}
          className="whitespace-nowrap"
          icon={faCalendar}
          title="Appointments"
        />
      </Tabs>
      {tabContent}
    </DrawerBody>
  );
}

export function ClientInformationDrawerBodyWrapper({
  isEmpty,
  selectedPatientShortId,
}: {
  isEmpty: boolean;
  selectedPatientShortId: string;
}) {
  const [activeTab, setActiveTab] = useAtom(activeClientInformationTabAtom);
  const clientTabMap = {
    [ClientInformationDrawerTab.INFORMATION]: (
      <div className="pt-5">
        <ClientInformationTab
          isEmpty={isEmpty}
          selectedPatientShortId={selectedPatientShortId}
        />
      </div>
    ),
    [ClientInformationDrawerTab.APPOINTMENTS]: (
      <UpcomingClientAppointmentsTab
        selectedPatientShortId={selectedPatientShortId}
      />
    ),
  };

  const tabContent = clientTabMap[activeTab];

  return (
    <ClientInformationDrawerBody
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabContent={tabContent}
    />
  );
}
