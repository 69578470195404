import { useCallback } from "react";
import { CallbackError, CallbackFailure, CallbackSuccess } from "../../types";
import { TrackKind } from "../types";
import { useUpdateAvailableDevicesCallback } from "./useDevices";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import { getStoredAudioVideoSettings } from "../config";
import { omit } from "remeda";
import { AvailableDevices } from "../state";
import { logger } from "../../datadog/logger";

export enum ValidateStoredDeviceIdsStatusCode {
  VALID = "valid",
  INVALID = "invalid",
  UNABLE_TO_GET_AVAILABLE_DEVICES = "unable_to_get_available_devices",
}

export type ValidateStoredDeviceIdsCallbackResponse =
  | CallbackSuccess<true, ValidateStoredDeviceIdsStatusCode>
  | CallbackFailure<
      { invalidTrackKinds: TrackKind[]; availableDevices: AvailableDevices },
      ValidateStoredDeviceIdsStatusCode
    >
  | CallbackError<ValidateStoredDeviceIdsStatusCode>;

function warnOnMissingDevices({
  audioInputDevices,
  videoInputDevices,
}: AvailableDevices) {
  if (!audioInputDevices.length) {
    logger.warn("No audio devices available.");
  }
  if (!videoInputDevices.length) {
    logger.warn("No video devices available.");
  }
}

/**
 * Validates the stored audio and video device IDs and removes them if they are
 * no longer available. It also sets the list of available devices in Jotai.
 */
export function useValidateStoredDeviceIdsCallback() {
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const updateAvailableDevices = useUpdateAvailableDevicesCallback();

  return useCallback(
    async (): Promise<ValidateStoredDeviceIdsCallbackResponse> => {
      const { inputAudioDeviceId, inputVideoDeviceId } =
        getStoredAudioVideoSettings();
      const availableDevices = await updateAvailableDevices();
      if (!availableDevices)
        return {
          status: "error",
          code: ValidateStoredDeviceIdsStatusCode.UNABLE_TO_GET_AVAILABLE_DEVICES,
        };
      warnOnMissingDevices(availableDevices);
      const invalidDeviceIdFields: Array<
        "inputAudioDeviceId" | "inputVideoDeviceId"
      > = [];
      if (
        inputAudioDeviceId &&
        !availableDevices.audioInputDevices.find(
          ({ deviceId }) => deviceId === inputAudioDeviceId,
        )
      ) {
        invalidDeviceIdFields.push("inputAudioDeviceId");
      }
      if (
        inputVideoDeviceId &&
        !availableDevices.videoInputDevices.find(
          ({ deviceId }) => deviceId === inputVideoDeviceId,
        )
      ) {
        invalidDeviceIdFields.push("inputVideoDeviceId");
      }
      if (invalidDeviceIdFields.length) {
        setStoredAudioVideoSettings((prev) =>
          omit(prev, invalidDeviceIdFields),
        );
        return {
          status: "failure",
          code: ValidateStoredDeviceIdsStatusCode.INVALID,
          value: {
            invalidTrackKinds: invalidDeviceIdFields.map((field) =>
              field === "inputAudioDeviceId" ? "audio" : "video",
            ),
            availableDevices,
          },
        };
      }
      return {
        status: "success",
        code: ValidateStoredDeviceIdsStatusCode.VALID,
        value: true,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
