import {
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  Tooltip,
} from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import {
  localScreenShareTrackAtom,
  participantCountAtom,
  remoteScreenShareParticipantAtom,
} from "../state";
import useScreenShareCallbacks from "./useScreenShareCallbacks";
import { deviceIsMobile } from "../../utils";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { TakeOverScreenShareModal } from "./TakeOverScreenShareModal";
import { ControlPanelButton, Toast, ToastVariant } from "../../components";
import { faAirplay, faSquareXmark } from "@fortawesome/pro-solid-svg-icons";

export function ScreenShareMenuItems({
  isSharing,
  onShare,
  onStopShare,
}: {
  isSharing: boolean;
  onShare: () => void;
  onStopShare: () => void;
}) {
  return (
    <>
      {isSharing ? (
        <>
          <MenuItem
            onClick={onStopShare}
            destructive
            className="flex items-center gap-2"
          >
            <Icon aria-hidden icon={faSquareXmark} className="h-4 w-4" />
            Stop sharing
          </MenuItem>
          <MenuItem onClick={onShare} className="flex items-center gap-2">
            <Icon aria-hidden icon={faAirplay} className="h-4 w-4" />
            Share different screen
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={onShare} className="flex items-center gap-2">
            <Icon aria-hidden icon={faAirplay} className="h-4 w-4" />
            Share screen
          </MenuItem>
        </>
      )}
    </>
  );
}

export function ScreenShareControl({
  isSharing,
  isSomeoneElseSharing,
  isLoading,
  onShare,
  onStopShare,
}: {
  isSharing: boolean;
  isSomeoneElseSharing: boolean;
  isLoading?: boolean;
  onShare: () => void;
  onStopShare: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ariaLabel = isSharing ? "See screenshare options" : "Share your screen";
  const tooltipText = isSharing ? "You are sharing screen" : "Share screen";
  const onCloseModal = () => setIsModalOpen(false);
  const onClickShare = () =>
    isSomeoneElseSharing ? setIsModalOpen(true) : onShare();
  const onConfirmShare = () => {
    setIsModalOpen(false);
    onShare();
  };

  const shareButton = (
    <Tooltip text={tooltipText} forceInteractive={false}>
      <ControlPanelButton
        // When sharing, this button is actually wrapped by a
        // `MenuToggleButton`, which itself renders a button. To avoid
        // double-focusing, we remove the inner button from the tab order.
        tabIndex={isSharing ? -1 : undefined}
        aria-label={ariaLabel}
        loading={isLoading}
        iconDefinition={faAirplay}
        data-testid="screen-share-control"
        {...(isSharing && { as: "span" })}
        {...(!isSharing && { onClick: () => onClickShare() })}
      />
    </Tooltip>
  );

  return (
    <div className="hidden md:block">
      <TakeOverScreenShareModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        onConfirmShare={onConfirmShare}
      />
      {isSharing ? (
        <Menu
          menuClassName="translate-x-[calc(50%-1.5rem)] -translate-y-[calc(100%+3.875rem)]"
          buttonClassName="p-0"
          toggleButton={
            <MenuToggleButton visibleCaret={false}>
              {shareButton}
            </MenuToggleButton>
          }
        >
          <ScreenShareMenuItems
            isSharing={isSharing}
            onShare={onShare}
            onStopShare={onStopShare}
          />
        </Menu>
      ) : (
        shareButton
      )}
    </div>
  );
}

export function ScreenShareControlWrapper() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isSharing = !!useAtomValue(localScreenShareTrackAtom);
  const isSomeoneElseSharing = !!useAtomValue(remoteScreenShareParticipantAtom);

  const { triggerBrowserScreenShare, stopScreenShare } =
    useScreenShareCallbacks(() => {
      toast.custom(
        ({ id: toastId }) => {
          return (
            <Toast
              variant={ToastVariant.Error}
              onClose={() => {
                toast.remove(toastId);
              }}
            >
              {"There was an error sharing your screen."}
            </Toast>
          );
        },
        {
          position: "bottom-center",
        },
      );
    });

  if (!inSession || deviceIsMobile()) return;

  return (
    <ScreenShareControl
      isSharing={isSharing}
      isSomeoneElseSharing={isSomeoneElseSharing}
      onShare={triggerBrowserScreenShare}
      onStopShare={stopScreenShare}
    />
  );
}
