import { Text } from "@grow-therapy-team/sprout-ui";

export function BulletedList({ items }: { items: string[] }) {
  return (
    <Text as="ul" className="list-disc pt-2 pl-4 pb-4">
      {items.map((item) => (
        <Text as="li" className="pb-2" key={item}>
          {item}
        </Text>
      ))}
    </Text>
  );
}
