import { useAtomValue } from "jotai";
import { AudioTrack } from "twilio-video";
import {
  recordingAudioContextAtom,
  recordingAudioDestinationNodeAtom,
} from "./state";
import { useEffect } from "react";
import { allAudioTracksAtom } from "../../twilio/state";

type AudioRecordingConnectionOptions = {
  sourceAudioTracks: AudioTrack[];
  audioContext: AudioContext;
  destinationMediaStreamAudioNode: MediaStreamAudioDestinationNode;
};

export function combineAudioTracks({
  audioContext,
  destinationMediaStreamAudioNode,
  sourceAudioTracks,
}: AudioRecordingConnectionOptions) {
  const mediaStreamSourceNodes = sourceAudioTracks.map((audioTrack) =>
    audioContext.createMediaStreamSource(
      new MediaStream([audioTrack.mediaStreamTrack]),
    ),
  );
  for (const mediaStreamSourceNode of mediaStreamSourceNodes) {
    mediaStreamSourceNode.connect(destinationMediaStreamAudioNode);
  }
  return mediaStreamSourceNodes;
}

/**
 * Connects the audio tracks to the destination media stream audio node.
 * Effectively combining the outputs of the audio tracks into a single audio
 * stream.
 *
 * Will use the Jotai-stored audio tracks and audio destination node if none are
 * provided.
 */
export function useCombineAudioTrackMediaStreams({
  audioContext: givenAudioContext,
  sourceAudioTracks: givenSourceAudioTracks,
  destinationMediaStreamAudioNode: givenDestinationMediaStreamAudioNode,
}: Partial<AudioRecordingConnectionOptions> = {}) {
  const storedAudioContext = useAtomValue(recordingAudioContextAtom);
  const audioContext = givenAudioContext ?? storedAudioContext;
  const storedDestinationMediaStreamAudioNode = useAtomValue(
    recordingAudioDestinationNodeAtom,
  );
  const destinationMediaStreamAudioNode =
    givenDestinationMediaStreamAudioNode ??
    storedDestinationMediaStreamAudioNode;
  const storedSourceAudioTracks = useAtomValue(allAudioTracksAtom);
  const sourceAudioTracks = givenSourceAudioTracks ?? storedSourceAudioTracks;

  useEffect(
    function handleAudioTrackConnections() {
      if (
        !audioContext ||
        !destinationMediaStreamAudioNode ||
        !sourceAudioTracks.length
      )
        return;

      const mediaStreamSourceNodes = combineAudioTracks({
        sourceAudioTracks,
        audioContext,
        destinationMediaStreamAudioNode,
      });

      return function disconnectAudioTrackConnections() {
        mediaStreamSourceNodes.forEach((mediaStreamSourceNode) => {
          mediaStreamSourceNode.disconnect();
        });
      };
    },
    [audioContext, destinationMediaStreamAudioNode, sourceAudioTracks],
  );
}
