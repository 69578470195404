import { useCallback, useEffect, useRef, useState } from "react";
import { useInterval } from "usehooks-ts";
import { expiredJwt, parseJwt } from "./utils";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";

function oneMinuteAhead() {
  return Date.now() + minutesToMilliseconds(1);
}

/**
 * Given a function to get a JSON web token, this hook will periodically check
 * to make sure the token has not expired. One minute before the token expires,
 * it will fetch a new token.
 *
 * The token should not have an expiration of 1 minute or less.
 *
 * Returns the fetched token.
 */
export function useGetJwtInterval({
  getJwt,
  onNewJwt,
  onError,
}: {
  getJwt: () => Promise<string>;
  onNewJwt?: (jwt: string) => void;
  onError?: (error: Error) => void;
}) {
  const [jwt, setJwt] = useState<string>();
  const jwtRef = useRef<string>();

  const getAndSetJwt = async () => {
    try {
      const jwt = await getJwt();
      const { exp } = parseJwt(jwt) ?? {};
      if (!exp)
        throw new Error("Unable to parse the JWT for an expiration timestamp");
      if (expiredJwt(jwt, oneMinuteAhead())) {
        throw new Error("Received an expired JWT");
      }
      jwtRef.current = jwt;
      setJwt(jwt);
      onNewJwt?.(jwt);
    } catch (error) {
      onError?.(error as Error);
    }
  };

  useEffect(
    function initJwt() {
      getAndSetJwt();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useInterval(
    useCallback(
      async function refreshJwt() {
        const jwt = jwtRef.current;
        if (!jwt) return;
        try {
          if (!expiredJwt(jwt, oneMinuteAhead())) return;
          await getAndSetJwt();
        } catch (error) {
          onError?.(error as Error);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    secondsToMilliseconds(30),
  );

  return jwt;
}
