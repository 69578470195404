import {
  faPause,
  faPenLine,
  faPencilSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { Icon, Tag, Tooltip } from "@grow-therapy-team/sprout-ui";
import { ConsentStatus } from "./types";

type TranscriptionPillProps = {
  isRecording: boolean;
  patientConsent: ConsentStatus;
};

function TranscriptionPillTag({
  isRecording,
  patientConsent,
}: TranscriptionPillProps) {
  if (isRecording) {
    return (
      <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
        <Icon aria-hidden icon={faPenLine} className="text-lilac-500" />
        <span aria-hidden>Transcribing</span>
        {/* For live announcement */}
        <span className="sr-only normal-case">Transcription in progress.</span>
      </Tag>
    );
  }

  if (
    patientConsent === ConsentStatus.UNDECIDED ||
    patientConsent === ConsentStatus.SOFT_NO ||
    patientConsent === ConsentStatus.DECIDING
  ) {
    return (
      <Tooltip
        className={"rebrand:bg-neutral-900"}
        text={
          <div className="max-w-[80vw] sm:max-w-[24.5rem]">
            To transcribe this session, press the{" "}
            <span className="sr-only">request transcription consent</span>
            <Icon aria-hidden icon={faPenLine} /> button in the action bar below
            to request client consent for transcription.
          </div>
        }
      >
        <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
          <Icon aria-hidden icon={faPencilSlash} />
          <span aria-hidden>Not transcribing</span>
          {/* For live announcement */}
          <span className="sr-only normal-case">
            The session is not being transcribed.
          </span>
        </Tag>
      </Tooltip>
    );
  }

  if (patientConsent === ConsentStatus.UNKNOWN) {
    return null;
  }

  return (
    <Tag className="rebrand:bg-neutral-900 rebrand:text-neutral-500 gap-2 flex items-center">
      <Icon aria-hidden icon={faPause} className="text-lilac-500" />
      <span aria-hidden>Transcription paused</span>
      {/* For live announcement */}
      <span className="sr-only normal-case">
        Transcription is paused for this session.
      </span>
    </Tag>
  );
}

export function TranscriptionPill({
  isRecording,
  patientConsent,
}: TranscriptionPillProps) {
  return (
    <div aria-live="polite">
      <TranscriptionPillTag
        isRecording={isRecording}
        patientConsent={patientConsent}
      />
    </div>
  );
}
