import { useCallback } from "react";
import { unreadMessagesCountFamily } from "../../twilio/state";
import { setFaviconAndTitleBasedOnUnreadMessagesCount } from "../chat/utils";
import { activeConversationSidAtom } from "../state";
import { useAtomCallback } from "jotai/utils";

export const PROVIDER_NO_SHOW_TITLE =
  "👋 Provider not here? - Grow Therapy Telehealth";

export function useClearProviderNoShowTabTitleCallback() {
  const getActiveConversationSid = useAtomCallback(
    useCallback((get) => get(activeConversationSidAtom), []),
  );
  const getUnreadMessagesCount = useAtomCallback(
    useCallback(
      (get, _set, activeConversationSid?: string) =>
        get(unreadMessagesCountFamily(activeConversationSid ?? "")),
      [],
    ),
  );

  return useCallback(
    function clearProviderNoShowTabTitle() {
      if (document.title !== PROVIDER_NO_SHOW_TITLE) {
        return;
      }

      const activeConversationSid = getActiveConversationSid();
      const unreadMessagesCount = getUnreadMessagesCount(activeConversationSid);
      setFaviconAndTitleBasedOnUnreadMessagesCount(unreadMessagesCount);
    },
    [getActiveConversationSid, getUnreadMessagesCount],
  );
}
