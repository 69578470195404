import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastVariant } from "../components";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { hasRecordingAtom } from "./session-recording/state";
import { useAtomValue } from "jotai";

type Props = {
  className: string;
  onClose: () => void;
  onCleanup: () => void;
};

export function SessionCompletedToast({
  className,
  hasRecording,
  onClose,
  onCleanup,
}: Props & {
  hasRecording: boolean;
}) {
  const text = hasRecording
    ? "Session completed. Transcription processing in progress."
    : "Session completed.";
  return (
    <Toast
      className={twMerge("p-4", className)}
      variant={ToastVariant.Success}
      onClose={onClose}
      onCleanup={onCleanup}
    >
      <div className="flex flex-row w-full gap-3 items-center">
        <Icon
          aria-hidden
          icon={faCircleCheck}
          className="text-xl text-neutral_rebrand-800"
        />
        <Text variant="sm" className="grow block text-left">
          {text}
        </Text>
      </div>
    </Toast>
  );
}

export function SessionCompletedToastWrapper(props: Props) {
  const hasRecording = useAtomValue(hasRecordingAtom);
  return <SessionCompletedToast {...props} hasRecording={hasRecording} />;
}
