import { Button, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastVariant } from "../../components";
import toast from "react-hot-toast";
import classNames from "classnames";

export enum NoAccessVariant {
  Camera = "camera",
  Microphone = "microphone",
}

export function NoAccessToast({
  className,
  onClose,
  openHelpModal,
  variant,
}: {
  className?: string;
  onClose: () => void;
  openHelpModal: () => void;
  variant: NoAccessVariant;
}) {
  return (
    <Toast
      variant={ToastVariant.Error}
      className={classNames("max-w-full w-auto", className)}
      onClose={onClose}
    >
      <Text>
        Your browser doesn’t have access to your {variant}.{" "}
        <Button
          use="link"
          onClick={() => {
            openHelpModal();
            Object.values(NoAccessVariant).forEach((variant) =>
              toast.remove(`no-access-${variant}`),
            );
          }}
          className="px-0 rebrand:text-neutral-800 rebrand:font-medium"
        >
          Learn how to allow access.
        </Button>
      </Text>
    </Toast>
  );
}
