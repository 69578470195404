import { Conversation } from "@twilio/conversations";
import { logger } from "../../datadog/logger";

/**
 * Marks all messages in the given conversation as read.
 *
 * Fails gracefully, logging any errors that occur as warnings.
 */
export function gracefullySetAllMessagesRead(conversation?: Conversation) {
  if (!conversation) return;
  conversation.setAllMessagesRead().catch((error: Error) => {
    logger.warn(
      "Failed to set conversation's messages as read",
      { conversationSid: conversation.sid },
      error,
    );
  });
}
