import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import { Toast, ToastVariant } from "../../components";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

export function MessageSuccessToast({
  senderName,
  className,
  onClose,
}: {
  senderName: string;
  className: string;
  onClose: () => void;
}) {
  return (
    <Toast
      className={twMerge("p-4", className)}
      variant={ToastVariant.Success}
      onClose={onClose}
    >
      <div className="flex flex-row w-full gap-3 items-center">
        <Icon
          aria-hidden
          icon={faCircleCheck}
          className="text-xl text-neutral_rebrand-800"
        />
        <Text
          data-dd-privacy="mask"
          className="grow block text-left fs-exclude"
        >
          Message to {senderName} sent!
        </Text>
      </div>
    </Toast>
  );
}
