import { LocalTrackState, localAudioTrackAtom } from "../state";
import { useAtomValue } from "jotai";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import { useUnpublishLocalTrackCallback } from "./useUnpublishLocalTrackCallback";
import classNames from "classnames";
import toast from "react-hot-toast";
import { NoAccessToast, NoAccessVariant } from "./NoAccessToast";
import { secondsToMilliseconds } from "date-fns";
import { ControlPanelButton, Toast, ToastVariant } from "../../components";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { ComponentProps, useCallback } from "react";
import { getSmoothingFunction, useGetInputVolume } from "../useGetInputVolume";
import { Tooltip } from "@grow-therapy-team/sprout-ui";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
const VOLUME_INDICATOR_COUNT = 20;
const volumeSmoothingFunction = getSmoothingFunction({
  maxVolume: VOLUME_INDICATOR_COUNT,
});

type ControlPanelButtonProps = Omit<
  ComponentProps<typeof ControlPanelButton>,
  "aria-label" | "icon" | "iconName" | "iconVariant" | "iconDefinition"
>;
type AudioControlProps = ControlPanelButtonProps & {
  isEnabled?: boolean;
  loading?: boolean;
  onToggle: (enable: boolean) => void;
  volume?: number;
};

export function AudioControl({
  isEnabled,
  loading,
  onToggle,
  volume,
  ...props
}: AudioControlProps) {
  const fillPercentage = `${(
    ((volume ?? 0) * 100) /
    VOLUME_INDICATOR_COUNT
  ).toFixed(0)}%`;

  return (
    <Tooltip
      forceInteractive={false}
      text={`Toggle audio ${isEnabled ? "off" : "on"}`}
      className="z-0"
    >
      <ControlPanelButton
        {...props}
        aria-label={`Toggle audio ${isEnabled ? "off" : "on"}`}
        loading={loading}
        className={classNames({ "rebrand:text-coral-600": !isEnabled })}
        iconDefinition={isEnabled ? faMicrophone : faMicrophoneSlash}
        data-testid="audio-control"
        onClick={() => onToggle(!isEnabled)}
        style={{
          // @ts-ignore --volume-level is a custom property
          "--volume-level": fillPercentage,
          backgroundImage:
            "linear-gradient(to top, hsl(var(--twc-green-400)) 0%, hsl(var(--twc-green-400)) var(--volume-level), transparent var(--volume-level), transparent 100%)",
        }}
      />
    </Tooltip>
  );
}

type AudioControlWrapperProps = ControlPanelButtonProps & {
  openHelpModal?: () => void;
  showVolumeIndicator?: boolean;
};
export function AudioControlWrapper({
  openHelpModal,
  showVolumeIndicator,
  ...props
}: AudioControlWrapperProps) {
  const { track: localAudioTrackValue, state: localAudioTrackState } =
    useAtomValue(localAudioTrackAtom);
  const publishLocalTrack = usePublishLocalTrackCallback("audio");
  const unpublishLocalTrack = useUnpublishLocalTrackCallback("audio");
  const isLoading = localAudioTrackState === LocalTrackState.LOADING;
  const isEnabled =
    localAudioTrackValue && localAudioTrackState === LocalTrackState.READY;
  const micVolume = useGetInputVolume({
    smoothingFunction: volumeSmoothingFunction,
  });
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const setStoredAudioControl = useCallback(
    (enabled: boolean) =>
      setStoredAudioVideoSettings((prev) => ({
        ...prev,
        isAudioEnabled: enabled,
      })),
    [setStoredAudioVideoSettings],
  );

  return (
    <AudioControl
      {...props}
      isEnabled={isEnabled}
      loading={isLoading}
      onToggle={async (enable) => {
        if (enable) {
          if (await publishLocalTrack()) {
            setStoredAudioControl(true);
            return;
          }
          if (!openHelpModal) return;
          toast.custom(
            (t) => (
              <NoAccessToast
                className="bottom-center-toast"
                onClose={() => toast.remove(t.id)}
                openHelpModal={openHelpModal}
                variant={NoAccessVariant.Microphone}
              />
            ),
            {
              duration: secondsToMilliseconds(10),
              position: "bottom-center",
              id: `no-access-${NoAccessVariant.Microphone}`,
            },
          );
          return;
        }
        if (await unpublishLocalTrack()) {
          setStoredAudioControl(false);
          return;
        }
        toast.custom(
          (t) => (
            <Toast
              variant={ToastVariant.Error}
              onClose={() => toast.remove(t.id)}
              className="bottom-center-toast max-w-full w-auto"
            >
              An unexpected error occurred while trying to disable your
              microphone.
            </Toast>
          ),
          {
            duration: secondsToMilliseconds(10),
            position: "bottom-center",
          },
        );
      }}
      volume={showVolumeIndicator ? micVolume : 0}
    />
  );
}
