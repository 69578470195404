import { gql, useMutation } from "@apollo/client";
import { logger } from "../../datadog/logger";

const CREATE_PROVIDER_CHAT_ACCESS_GQL = gql`
  mutation CreateTwilioProviderChatAccess {
    createTwilioProviderChatAccess {
      jwt
    }
  }
`;

type CreateProviderChatAccessMutationData = {
  createTwilioProviderChatAccess: { jwt: string };
};

export function useCreateProviderChatAccessMutation() {
  return useMutation<CreateProviderChatAccessMutationData>(
    CREATE_PROVIDER_CHAT_ACCESS_GQL,
  );
}

export function useCreateProviderChatAccessCallback() {
  const [createProviderChatAccess] = useCreateProviderChatAccessMutation();

  return async function createProviderChatAccessCallback() {
    try {
      const { jwt } =
        (await createProviderChatAccess())?.data
          ?.createTwilioProviderChatAccess ?? {};
      if (!jwt) return "";
      return jwt;
    } catch (error) {
      logger.error("Unable to get a new Twilio chat token for provider", {
        error,
      });
    }
    return "";
  };
}
