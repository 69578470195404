import { NoteType, NoteResponseType, FieldTypeEnum } from "./types";
import { GetCurrentNoteSchemaResponse } from "../useGetCurrentNoteSchema";
import { groupBy } from "remeda";
import {
  TimelineAppointment,
  TimelineAppointmentEdge,
} from "../useGetUpcomingAppointments";

export function formatNotes(
  notes: NoteResponseType,
  currentNoteSchema?: GetCurrentNoteSchemaResponse,
): Record<string, NoteType[]> {
  if (!currentNoteSchema || !notes) return {};
  const formattedNotes: Record<string, NoteType[]> = {};

  currentNoteSchema.currentNoteSchema.sections.forEach((section) => {
    formattedNotes[section.label] = [];

    section.fields.forEach((field) => {
      let answer: string | string[] | boolean = "";
      if (field.fieldType === FieldTypeEnum.CHECKBOX) {
        answer =
          field.name === "risk_issues" && notes[field.name] === false
            ? "No suicidal or homicidal ideation suspected or disclosed"
            : notes[field.name];
      } else if (field.fieldType === FieldTypeEnum.MULTISELECT) {
        answer = Array.isArray(notes[field.name]) ? notes[field.name] : [];
      } else {
        answer = notes[field.name] || answer;
      }

      let details: string[] = [];

      // Many questions have supplemental questions that are appended by _details.
      // It's easier to keep track of by storing these details in the same section object rather than treating it like a separate question
      const baseFieldName = field.name.replace(/_details$/, "");
      const isDetailsField = baseFieldName !== field.name;

      if (isDetailsField) {
        const fieldValue = notes[field.name];
        details = Array.isArray(fieldValue)
          ? fieldValue
          : ([fieldValue] as string[]).filter(Boolean);

        const baseFieldFormattedSection = formattedNotes[section.label].find(
          (sectionItem) => sectionItem.name === baseFieldName,
        );

        if (baseFieldFormattedSection) {
          baseFieldFormattedSection.details = details;
        }
      } else {
        const formattedSection: NoteType = {
          question: field.label,
          name: field.name,
          answer: answer,
          margin: field.margin,
          textVariant: field.textVariant,
          fieldType: field.fieldType as FieldTypeEnum,
          details: details,
        };
        formattedNotes[section.label].push(formattedSection);
      }
    });
  });

  return formattedNotes;
}

/** Groups upcoming appointments so that recurring appointments show only the next upcoming entry and sorts them in date order */
export function formatUpcomingAppointments(
  appointments: TimelineAppointmentEdge[] | undefined,
): TimelineAppointment[] {
  const groupedAppointments = appointments
    ? groupBy(
        appointments,
        (appointment) =>
          appointment?.node?.recurringAppointmentGroupingId ??
          appointment?.node?.id,
      )
    : {};
  const upcomingAppointments = Object.values(groupedAppointments).map(
    (appointmentGroup) => appointmentGroup[0].node,
  );
  return upcomingAppointments.sort((a, b) => {
    return new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime();
  });
}
