import toast from "react-hot-toast";
import { Toast, ToastVariant } from "../../components";

export const showErrorToast = () => {
  toast.custom(
    ({ id: toastId }) => {
      return (
        <Toast
          variant={ToastVariant.Error}
          onClose={() => {
            toast.remove(toastId);
          }}
        >
          Failed to save changes
        </Toast>
      );
    },
    {
      duration: Infinity,
      position: "bottom-right",
    },
  );
};

export const showAppointmentCreatedToast = () => {
  toast.custom(
    ({ id: toastId }) => {
      return (
        <Toast
          variant={ToastVariant.Success}
          onClose={() => {
            toast.remove(toastId);
          }}
        >
          Appointment added!
        </Toast>
      );
    },
    {
      position: "bottom-right",
    },
  );
};

export const showAppointmentUpdatedToast = () => {
  toast.custom(
    ({ id: toastId }) => {
      return (
        <Toast
          variant={ToastVariant.Success}
          onClose={() => {
            toast.remove(toastId);
          }}
        >
          Appointment updated!
        </Toast>
      );
    },
    {
      position: "bottom-right",
    },
  );
};
