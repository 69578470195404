import { useAtomValue } from "jotai";
import { localScreenShareTrackAtom } from "..";
import useScreenShareCallbacks from "./useScreenShareCallbacks";
import { Button, ButtonSize, Icon, Text } from "@grow-therapy-team/sprout-ui";
import { faSquareXmark } from "@fortawesome/pro-solid-svg-icons";

export function StopScreenShareButton({ onClick }: { onClick: () => void }) {
  return (
    <Text
      className="flex z-10 w-full text-neutral-100 justify-center items-center pb-2"
      variant="sm"
    >
      <span className="mr-2">You are sharing screen</span>
      <Button
        onClick={onClick}
        use="destructive"
        size={ButtonSize.Small}
        className="flex items-center gap-2"
      >
        <Icon aria-hidden icon={faSquareXmark} />
        Stop sharing<span className="sr-only"> screen</span>
      </Button>
    </Text>
  );
}

export function StopScreenShareButtonWrapper() {
  const isSharing = !!useAtomValue(localScreenShareTrackAtom);
  const { stopScreenShare } = useScreenShareCallbacks();

  if (!isSharing) return null;

  return <StopScreenShareButton onClick={stopScreenShare} />;
}
