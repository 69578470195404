import { useCallback } from "react";
import { Possibly } from "../../types";
import { useLocalStorage } from "usehooks-ts";
import { PreSessionPromptVariant, VariantComponents } from "./PreSessionPrompt";

function isSupportedPreSessionPromptVariant(
  variant: Possibly<PreSessionPromptVariant>,
): variant is PreSessionPromptVariant {
  return !!variant && String(variant) in VariantComponents;
}
export function usePreSessionPromptState() {
  const [preSessionPrompt, setPreSessionPrompt] = useLocalStorage<
    Possibly<PreSessionPromptVariant> | undefined
  >("preSessionPrompt", undefined);

  const clearPreSessionPrompt = useCallback(() => {
    setPreSessionPrompt(undefined);
  }, [setPreSessionPrompt]);

  let validPreSessionPrompt: PreSessionPromptVariant | undefined;

  if (isSupportedPreSessionPromptVariant(preSessionPrompt)) {
    validPreSessionPrompt = preSessionPrompt;
  } else {
    validPreSessionPrompt = undefined;
  }

  return {
    preSessionPrompt: validPreSessionPrompt,
    setPreSessionPrompt,
    rawPreSessionPrompt: preSessionPrompt,
    clearPreSessionPrompt,
  };
}
