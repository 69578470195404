import { logger } from "./datadog/logger";

export async function gracefullyPlayAudio(
  audio: HTMLAudioElement,
): Promise<HTMLAudioElement | void> {
  try {
    await audio.play();
    return audio;
  } catch (error) {
    // Permission issues are often unavoidable, so we don't want to log at an
    // error level
    logger.warn(
      "Unable to play audio",
      { audioFile: audio.src },
      error as Error,
    );
  }
}
