import { useMutation, gql } from "@apollo/client";
import { Server } from "../apollo/types";
import { useAtomValue } from "jotai";
import { visitorUuidAtom } from "./state";
export type RecordClientMeasureData = {
  recordClientMeasure: {
    recordedAt: string;
  };
};

export type RecordClientMeasureVariables = {
  visitorId: string;
};
export const RECORD_CLIENT_MEASURE = gql`
  mutation RecordClientMeasure($visitorId: ID!) {
    recordClientMeasure(visitorId: $visitorId) {
      recordedAt
    }
  }
`;

export function useRecordClientMeasure() {
  const visitorId = useAtomValue(visitorUuidAtom);
  return useMutation<RecordClientMeasureData, RecordClientMeasureVariables>(
    RECORD_CLIENT_MEASURE,
    {
      variables: { visitorId: visitorId },
      context: { server: Server.TELEHEALTH },
    },
  );
}
