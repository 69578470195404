import { useMemo } from "react";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";
import { useInitUserTracking } from "../hooks/useInitUserTracking";

export function useInitProviderUserTracking() {
  const providerShortId =
    useGetCurrentProviderQuery().data?.currentProvider?.shortId;
  const additionalVars = useMemo(
    () =>
      providerShortId
        ? {
            providerShortId,
          }
        : {},
    [providerShortId],
  );
  useInitUserTracking(providerShortId, additionalVars);
}
