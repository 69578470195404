import { gql, useMutation } from "@apollo/client";
import { GET_PROVIDER_PATIENT_CONSENT } from "./useGetProviderPatientConsent";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useCallback } from "react";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { useAtomValue, useSetAtom } from "jotai";
import { patientConsentAtom } from "../../twilio/messages/state";
import { ConsentStatus } from "../../twilio/types";

type RecordPatientConsentVariables = {
  providerShortId: string;
  patientShortId: string;
  isOptedIn: boolean;
  updateSource: string;
  appointmentShortId?: string;
};

type RecordPatientConsentData = {
  recordProviderTranscriptionConsent: {
    providerShortId: string;
    isOptedIn: boolean;
    createdAt: Date;
  };
};

const PATIENT_TRANSCRIPTION_CONSENT_MUTATION = gql`
  mutation RecordClientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
    $isOptedIn: Boolean!
    $updateSource: String!
    $appointmentShortId: String
  ) {
    recordClientTranscriptionConsent(
      patientShortId: $patientShortId
      providerShortId: $providerShortId
      isOptedIn: $isOptedIn
      updateSource: $updateSource
      appointmentShortId: $appointmentShortId
    ) {
      patientShortId
      isOptedIn
      createdAt
    }
  }
`;

export function useRecordClientTranscriptionConsentMutation() {
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const setPatientConsent = useSetAtom(patientConsentAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;

  const [recordPatientConsent] = useMutation<
    RecordPatientConsentData,
    RecordPatientConsentVariables
  >(PATIENT_TRANSCRIPTION_CONSENT_MUTATION, {
    refetchQueries: [GET_PROVIDER_PATIENT_CONSENT],
    onCompleted: (data) => {
      const isOptedIn = data?.recordProviderTranscriptionConsent?.isOptedIn;
      if (isOptedIn) {
        setPatientConsent({
          value: ConsentStatus.OPTED_IN,
          lastUpdated: Date.now(),
        });
      }
    },
  });

  return useCallback(
    async function recordPatientConsentCallback(isOptedIn: boolean) {
      return await recordPatientConsent({
        variables: {
          appointmentShortId,
          patientShortId: patientShortId!,
          providerShortId: providerShortId!,
          updateSource: "telehealth",
          isOptedIn,
        },
      });
    },
    [recordPatientConsent, appointmentShortId, providerShortId, patientShortId],
  );
}
