import { useCallback, useEffect } from "react";
import { useAtomCallback } from "jotai/utils";
import { usePublishLocalTrackCallback } from "./audio-video-controls/usePublishLocalTrackCallback";
import { localVideoTrackAtom } from "./state";
import { TrackingEvents, sendLoggingEvents } from "../events";

export const useResetLocalVideoOnScreenOrientationChange = () => {
  const publishLocalVideoTrack = usePublishLocalTrackCallback("video");
  const getLocalVideoTrackValue = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom).track, []),
  );

  useEffect(
    function handleOrientationChange() {
      const republishTrack = async () => {
        const localVideoTrack = getLocalVideoTrackValue();
        sendLoggingEvents(TrackingEvents.DEVICE_ORIENTATION_CHANGE, {
          hasLocalVideoTrack: !!localVideoTrack,
          windowOrientation: window.orientation,
          screenOrientation: screen?.orientation?.type,
        });
        if (!localVideoTrack?.isEnabled) return;
        publishLocalVideoTrack();
      };
      window.addEventListener("orientationchange", republishTrack);
      return function cleanup() {
        window.removeEventListener("orientationchange", republishTrack);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
