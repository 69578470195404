import { secondsToMilliseconds } from "date-fns";
import { JwtPayload, LoginRequiredErrors } from "./types";

export function loginRequired(authErrorMsg: string) {
  return Object.values(LoginRequiredErrors).some(
    (invalidRefreshTokenErrorMsg) =>
      authErrorMsg === invalidRefreshTokenErrorMsg,
  );
}

export function parseJwt<T = Record<string, unknown>>(
  jwt: string,
): (JwtPayload & T) | null {
  try {
    return JSON.parse(atob(jwt.split(".")[1]));
  } catch (e) {
    return null;
  }
}

export function expiredJwt(jwt: string, comparatorTimestamp?: number) {
  const { exp: jwtExp } = parseJwt(jwt) ?? {};
  if (
    !jwtExp ||
    (comparatorTimestamp ?? Date.now()) < secondsToMilliseconds(jwtExp)
  )
    return false;
  return true;
}
