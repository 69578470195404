import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

import { participantCountAtom } from "../twilio/state";
import { useDisconnectTwilioRoomCallback } from "./useDisconnectTwilioRoomCallback";
import { useTwilioRoomListeners } from "../twilio/useTwilioRoomListeners";
import { TrackingEvents, sendLoggingEvents } from "../events";

export function useProviderTwilioRoomListeners() {
  const getParticipantCount = useAtomCallback(
    useCallback((get) => get(participantCountAtom), []),
  );
  const onPageUnload = () => {
    sendLoggingEvents(TrackingEvents.PROVIDER_TAB_CLOSE);
  };
  const disconnectTwilioRoom = useDisconnectTwilioRoomCallback();
  const onRoomDisconnect = useCallback(
    () => {
      disconnectTwilioRoom();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const onParticipantDisconnect = useCallback(
    function disconnectFromEmptyRoom() {
      if (getParticipantCount()) return;
      disconnectTwilioRoom();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useTwilioRoomListeners({
    onParticipantDisconnect,
    onRoomDisconnect,
    onPageUnload,
  });
}
