import { gql, useMutation } from "@apollo/client";
import { GET_UPCOMING_APPOINTMENTS } from "./useGetScheduleForToday";
import { Server } from "../apollo/types";

import { InputMaybe, Exact, Scalars } from "../types";

export enum AppointmentFollowUpSource {
  Telehealth = "TELEHEALTH",
}

export type CreateFollowUpAppointmentInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  followUpSource: AppointmentFollowUpSource;
  inOffice: Scalars["String"]["input"];
  indefinite?: InputMaybe<Scalars["Boolean"]["input"]>;
  numRecurrences?: InputMaybe<Scalars["Int"]["input"]>;
  patientCustomerId?: Scalars["String"]["input"];
  providerId?: Scalars["ID"]["input"];
  startDate: Scalars["DateTime"]["input"];
  weekInterval?: InputMaybe<Scalars["Int"]["input"]>;
  weeksBetween?: InputMaybe<Scalars["Int"]["input"]>;
  providerShortId?: Scalars["String"]["input"];
  patientShortId?: Scalars["String"]["input"];
};

export type CreateFollowUpAppointmentVariables = Exact<{
  values: CreateFollowUpAppointmentInput;
}>;

export type CreateFollowUpAppointmentMutationData = {
  __typename?: "Mutations";
  createFollowUpAppointment?: {
    __typename?: "CreateFollowUpAppointment";
    appointment?: {
      __typename?: "PublicAppointmentObject";
      id: string;
      timeStart: string;
      firstAppointment?: boolean | null;
      inOffice: string;
      appointmentShortId: string;
    } | null;
    patient?: {
      __typename?: "PublicPatientObject";
      id: string;
      customerId: string;
    } | null;
    provider?: {
      __typename?: "PublicProviderObject";
      id: string;
      name?: string | null;
      license?: string | null;
      imgUrl: string;
    } | null;
    recurringAppointmentGrouping?: {
      __typename?: "RecurringAppointmentGroupingObject";
      recurringAppointmentGroupingId: string;
      weeksBetween: number;
      appointments: Array<{
        __typename?: "PublicAppointmentObject";
        id: string;
      }>;
    } | null;
  } | null;
};

export const providerFollowUpAppointmentDocument = gql(`
  mutation ProviderFollowUpAppointment($values: CreateFollowUpAppointmentInput!) {
    createFollowUpAppointment(values: $values) {
      appointment {
        id
        timeStart
        firstAppointment
        inOffice
        appointmentShortId
      }
      patient {
        id
        customerId
      }
      provider {
        id
        name
        license
        imgUrl
      }
      recurringAppointmentGrouping {
        recurringAppointmentGroupingId
        weeksBetween
        appointments {
          id
        }
      }
    }
  }
`);

/* eslint-enable @typescript-eslint/no-explicit-any */
export function useCreateFollowUpAppointmentMutation(onCompleted?: () => void) {
  return useMutation<
    CreateFollowUpAppointmentMutationData,
    CreateFollowUpAppointmentVariables
  >(providerFollowUpAppointmentDocument, {
    context: {
      server: Server.MONOLITH,
    },
    refetchQueries: [GET_UPCOMING_APPOINTMENTS],
    onCompleted,
  });
}
