import { PropsWithChildren } from "react";
import { THEME_CONTEXT_DEFAULT_VALUE, Theme, ThemeContext } from "../state";

export type ThemeProviderProps = PropsWithChildren<{
  theme?: Theme;
}>;

export function ThemeProvider({ children, theme }: ThemeProviderProps) {
  return (
    <ThemeContext.Provider
      value={{
        ...THEME_CONTEXT_DEFAULT_VALUE,
        ...(theme && { theme }),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
