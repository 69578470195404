import { gql, useQuery } from "@apollo/client";
import {
  GetUpcomingAppointmentsQuery,
  GetUpcomingAppointmentsQueryVariables,
} from "./schedule-preview/types";
import { endOfToday, minutesToMilliseconds } from "date-fns";
import { Server } from "../apollo/types";
import { useState } from "react";
import { useInterval } from "react-use";
import { logger } from "../datadog";

const TEN_MINUTES = minutesToMilliseconds(10);
const FIFTEEN_MINUTES = minutesToMilliseconds(15);

export const GET_UPCOMING_APPOINTMENTS = gql`
  query GetUpcomingAppointments($filters: AppointmentsFilter) {
    allAppointments(filters: $filters) {
      edges {
        node {
          id
          appointmentShortId
          status
          timeStart
          timeEnd
          length
          status
          inOffice
          appointmentType
          videoLink
          patient {
            id
            name
            customerId
            isZocdocPatient
            isZocdocConfirmed
            preferredFullName
            pronouns
            shortId
            latestIntakeFormResponse {
              id
              reviewedAt
            }
          }
          recurringAppointmentGrouping {
            indefinite
            recurringAppointmentGroupingId
            weeksBetween
            appointments {
              id
              timeStart
            }
          }
        }
      }
    }
  }
`;

export function useGetSchedulePreviewForToday() {
  const currentTime = new Date();
  const fifteenMinutesAgo = new Date(currentTime.getTime() - FIFTEEN_MINUTES);

  const [startFilter, setStartFilter] = useState<string>(
    fifteenMinutesAgo.toISOString(),
  );
  const endFilter = endOfToday().toISOString();

  useInterval(() => {
    setStartFilter(fifteenMinutesAgo.toISOString());
  }, TEN_MINUTES);

  return useQuery<
    GetUpcomingAppointmentsQuery,
    GetUpcomingAppointmentsQueryVariables
  >(GET_UPCOMING_APPOINTMENTS, {
    variables: {
      filters: {
        timeStartRange: {
          begin: startFilter,
          end: endFilter,
        },
        canceled: false,
      },
    },
    fetchPolicy: "cache-and-network",
    context: { server: Server.MONOLITH },
    onError: (error) => {
      logger.error(
        "Unable to fetch appointments",
        { startFilter, endFilter },
        error,
      );
    },
  });
}
