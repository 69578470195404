import { useAtomValue } from "jotai";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { visitorNameAtom } from "../state";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { logger } from "../../datadog/logger";
import { useInterval } from "usehooks-ts";
import { minutesToMilliseconds } from "date-fns";
import { useRecordProviderNoShowReportMutation } from "./useRecordProviderNoShowReportMutation";
import { toast } from "@grow-therapy-team/sprout-ui";

const PROVIDER_NO_SHOW_THRESHOLD = minutesToMilliseconds(10);

export function useRecordProviderNoShowReportCallback() {
  const { providerShortId, patientShortId } = useParams();
  const patientName = useAtomValue(visitorNameAtom);
  const [recordProviderNoShowReport] = useRecordProviderNoShowReportMutation();

  const [waitingRoomJoinTime, _] = useState(Date.now());
  const [isPastNoShowThreshold, setIsPastNoShowThreshold] = useState(false);

  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointment = data?.telehealthSessionInfo?.appointment;
  const {
    shortId: appointmentShortId,
    timeStart: appointmentStartTime,
    hasProviderNoShowReport,
  } = appointment ?? {};

  const clientJoinedAfterWindowToReport =
    new Date(waitingRoomJoinTime).getTime() >=
    new Date(appointmentStartTime || "").getTime() + minutesToMilliseconds(20);

  const shouldCheckForProviderNoShow =
    appointmentStartTime &&
    !hasProviderNoShowReport &&
    !isPastNoShowThreshold &&
    !clientJoinedAfterWindowToReport;

  const recordProviderNoShowAndRedirect = useCallback(async () => {
    if (!appointmentShortId) return;

    try {
      await recordProviderNoShowReport({
        variables: { patientName, appointmentShortId },
      });
      window.location.href = `${window.location.origin}/${providerShortId}/${patientShortId}/no-show`;
    } catch (error) {
      logger.error(
        "Unable to record provider no show report",
        { providerShortId, patientShortId, appointmentShortId },
        error as Error,
      );
      toast.error(
        "There was an issue recording that your provider is not here.",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientName, appointmentShortId]);

  useInterval(
    useCallback(
      function setProviderNoShowWhenPastThreshold() {
        if (!appointmentStartTime) return;

        const appointmentStartTimeThreshold =
          new Date(appointmentStartTime).getTime() + PROVIDER_NO_SHOW_THRESHOLD;

        const patientWaitingThreshold =
          new Date(waitingRoomJoinTime).getTime() + PROVIDER_NO_SHOW_THRESHOLD;

        const currentTime = Date.now();

        if (
          currentTime > patientWaitingThreshold &&
          currentTime > appointmentStartTimeThreshold
        ) {
          setIsPastNoShowThreshold(true);
        }
      },
      [appointmentStartTime, waitingRoomJoinTime],
    ),
    shouldCheckForProviderNoShow ? 1000 : null,
  );

  return {
    recordProviderNoShowAndRedirect,
    isPastNoShowThreshold,
    clientJoinedAfterWindowToReport,
  };
}
