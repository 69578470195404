import { twMerge } from "tailwind-merge";
import { Card } from "@grow-therapy-team/sprout-ui";
import {
  InputControlsWrapper as InputControls,
  InputControlsProps,
} from "../InputControls";
import {
  ProviderAvatarSectionWrapper as ProviderAvatarSection,
  ProviderAvatarSectionProps,
} from "../ProviderAvatarSection";
import {
  ProviderLoginWrapper as ProviderLogin,
  ProviderLoginBaseProps,
} from "../ProviderLogin";
import { FC } from "react";

export interface MinimalPageContentProps {
  className?: string;
  inputControlsComponent: FC<Partial<InputControlsProps>>;
  providerLoginComponent: FC<ProviderLoginBaseProps>;
  providerAvatarSectionComponent: FC<ProviderAvatarSectionProps>;
}

export function MinimalPageContent({
  className,
  inputControlsComponent: InputControlsComponent,
  providerLoginComponent: ProviderLoginComponent,
  providerAvatarSectionComponent: ProviderAvatarComponent,
}: MinimalPageContentProps) {
  return (
    <Card
      className={twMerge(
        "self-center sm:w-[34rem] z-10 sm:p-0 flex flex-col items-center w-full px-6 py-6 sm:px-12 sm:py-12 gap-8 border-0 sm:border",
        className,
      )}
      data-testid="client-intro-state--control"
    >
      <ProviderAvatarComponent />
      <InputControlsComponent className="flex flex-col items-center gap-8" />
      <ProviderLoginComponent />
    </Card>
  );
}

export type MinimalPageContentWrapperProps = Pick<
  MinimalPageContentProps,
  "className"
>;

export function MinimalPageContentWrapper({
  className,
}: MinimalPageContentWrapperProps) {
  return (
    <MinimalPageContent
      className={className}
      inputControlsComponent={InputControls}
      providerLoginComponent={ProviderLogin}
      providerAvatarSectionComponent={ProviderAvatarSection}
    />
  );
}
