import { IconButtonProps, Tooltip } from "@grow-therapy-team/sprout-ui";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from ".";
import { VisualIndicator } from "./VisualIndicator";
import { DrawerState } from "../provider/state";
import { getIsAnyChatDrawerOpen } from "./utils";
import { VisitorDrawerState } from "../visitor/state";
import { DrawerButton, DrawerButtonProps } from "./DrawerButton";

export function ChatDrawerButton({
  drawerId,
  drawerState,
  messageCount = 0,
  loading,
  forDrawerStates,
  ...otherProps
}: {
  drawerId: string;
  drawerState: DrawerState | VisitorDrawerState | null;
  messageCount?: number;
} & Omit<
  IconButtonProps,
  "aria-label" | "icon" | "iconName" | "iconDefinition" | "iconVariant"
> &
  Pick<DrawerButtonProps<IconButtonProps>, "forDrawerStates">) {
  const isChatDrawerOpen = getIsAnyChatDrawerOpen(drawerState);

  let ariaLabel;
  if (isChatDrawerOpen) {
    ariaLabel = "Close chat";
  } else {
    ariaLabel = `Open chat, ${messageCount} unread ${
      messageCount === 1 ? "message" : "messages"
    }`;
  }

  return (
    <div className="hidden md:block relative">
      <Tooltip forceInteractive={false} text="Chat" className="z-0">
        <>
          <DrawerButton
            as={ControlPanelButton}
            forDrawerStates={forDrawerStates}
            aria-label={ariaLabel}
            aria-expanded={isChatDrawerOpen}
            aria-controls={drawerId}
            iconDefinition={faMessage}
            loading={loading}
            disabled={loading}
            {...otherProps}
          />
          {!!messageCount && (
            <div aria-hidden data-testid="chat-drawer.unread-messages">
              <VisualIndicator className="absolute -top-1 -right-1" />
            </div>
          )}
        </>
      </Tooltip>
    </div>
  );
}
