import { logger } from "../../datadog/logger";
import { secondsToMilliseconds } from "date-fns";
import { DrawerState, WaitingRoomConversationSidsByVisitorId } from "../state";
import { toast } from "@grow-therapy-team/sprout-ui";
import { TrackingEvents, sendLoggingEvents } from "../../events";

export const openWaitingRoomConversation = (
  visitorUuid: string,
  getWaitingRoomConversationSids: () => WaitingRoomConversationSidsByVisitorId,
  openConversation: (
    conversationKind: DrawerState.WAITING_ROOM_CHAT | DrawerState.SESSION_CHAT,
    conversationSid: string,
  ) => void,
) => {
  sendLoggingEvents(TrackingEvents.PROVIDER_CLIENT_CHAT);
  const conversations = getWaitingRoomConversationSids();
  const conversationSid = conversations[visitorUuid];

  if (!conversationSid) {
    logger.error("Waiting room conversation not found for visitor", {
      visitorId: visitorUuid,
      conversationSids: Object.keys(conversations),
    });
    toast.error("Unable to open chat with client", {
      duration: secondsToMilliseconds(10),
    });
    return;
  }
  openConversation(DrawerState.WAITING_ROOM_CHAT, conversationSid);
};
