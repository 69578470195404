import { PropsWithChildren, useEffect, useRef } from "react";
import { DrawerState } from "../provider/state";
import { VisitorDrawerState } from "../visitor/state";
import { ButtonProps } from "@grow-therapy-team/sprout-ui";
import { useDrawerButtonRefs } from "../hooks/useDrawerButtonRefs";

type OneDrawerState = DrawerState | VisitorDrawerState;
type MultipleDrawerStates = OneDrawerState[];

function isMultipleDrawerStates(
  state: OneDrawerState | MultipleDrawerStates,
): state is MultipleDrawerStates {
  return Array.isArray(state);
}

export type DrawerButtonProps<BP extends ButtonProps> = PropsWithChildren<
  {
    as: React.ForwardRefExoticComponent<
      BP & React.RefAttributes<HTMLButtonElement>
    >;
    forDrawerStates: OneDrawerState | MultipleDrawerStates;
  } & BP
>;

/**
 * A button wrapper component that integrates with the drawer button context.
 * This component registers and unregisters a React ref for the button
 * corresponding to the value of `forDrawerStates`. This allows the drawer to
 * lookup and re-focus the appropriate triggering button, if it still exists,
 * when the drawer is closed, so that focus is not lost.
 */
export function DrawerButton<BP extends ButtonProps>({
  as: ButtonComponent,
  forDrawerStates,
  ...props
}: DrawerButtonProps<BP>): JSX.Element {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { addButtonRef, removeButtonRef } = useDrawerButtonRefs();

  // Register the button reference when the component mounts, and unregister it
  // when it unmounts.
  useEffect(() => {
    if (!isMultipleDrawerStates(forDrawerStates)) {
      addButtonRef(forDrawerStates, buttonRef);
      return () => {
        removeButtonRef(forDrawerStates);
      };
    } else {
      for (const state of forDrawerStates) {
        addButtonRef(state, buttonRef);
      }
      return () => {
        for (const state of forDrawerStates) {
          removeButtonRef(state);
        }
      };
    }
  }, [addButtonRef, removeButtonRef, forDrawerStates]);

  return (
    <ButtonComponent ref={buttonRef} {...(props as BP)}>
      {props.children}
    </ButtonComponent>
  );
}
