import { useCallback } from "react";

import { useTwilioRoomListeners } from "../../twilio/useTwilioRoomListeners";
import { useLeaveSessionCallback } from "./useLeaveSessionCallback";
import { parseIdentity } from "../../twilio/utils";
import { UserType } from "../../types";
import { ParticipantConnectionEventHandler } from "../../twilio";
import { TrackingEvents } from "../../events";
import { sendFullStoryEvent } from "../../fullstory/events";

export function useClientTwilioRoomListeners() {
  const leaveSession = useLeaveSessionCallback();
  const onPageUnload = () => {
    sendFullStoryEvent(TrackingEvents.CLIENT_TAB_CLOSE);
  };
  const onParticipantDisconnect =
    useCallback<ParticipantConnectionEventHandler>(
      function leaveIfProviderLeft(participant) {
        const { userType } = parseIdentity(participant.identity);
        if (userType !== UserType.PROVIDER) return;
        leaveSession();
      },
      [leaveSession],
    );

  useTwilioRoomListeners({
    onParticipantDisconnect,
    onRoomDisconnect: leaveSession,
    onPageUnload,
  });
}
