import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { DrawerState, drawerStateAtom } from "../state";
import {
  currentConversationAtom,
  currentConversationSidAtom,
  unreadMessageCountByConversationSidAtom,
} from "../../twilio/state";
import toast from "react-hot-toast";
import { useAtomCallback } from "jotai/utils";
import { gracefullySetAllMessagesRead } from "../../twilio/chat/utils";

export function useOpenConversationCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getCurrentConversation = useAtomCallback(
    useCallback((get) => get(currentConversationAtom), []),
  );

  return useCallback(
    (
      conversationKind:
        | DrawerState.WAITING_ROOM_CHAT
        | DrawerState.SESSION_CHAT,
      conversationSid?: string,
    ) => {
      toast.remove();
      setDrawerState(conversationKind);
      if (!conversationSid) return;
      setCurrentConversationSid(conversationSid);
      setUnreadMessageCount((prev) => ({ ...prev, [conversationSid]: 0 }));

      gracefullySetAllMessagesRead(getCurrentConversation());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
