import { RemoteParticipant, RemoteTrack, TwilioError } from "twilio-video";
import { DataTrackMessage, TrackSubscriptionHandler } from "./types";
import {
  useRemoteVideo as useRemoteVideoV1,
  useRemoteVideoV2,
} from "./useRemoteVideo";
import {
  useRemoteAudio as useRemoveVideoV1,
  useRemoteAudioV2,
} from "./useRemoteAudio";
import { useEffect, useMemo } from "react";
import { TrackingEvents, sendLoggingEvents } from "../events";
import {
  remoteParticipantToDiagnosticInfo,
  trackToDiagnosticInfo,
} from "./utils";
import { logger } from "../datadog/logger";
import { useRemoteData } from "./useRemoteData";

export type UseRemoteTracksProps = {
  remoteParticipant: RemoteParticipant;
  onVideoTrackSubscribed?: TrackSubscriptionHandler;
  onAudioTrackSubscribed?: TrackSubscriptionHandler;
  onDataTrackSubscribed?: TrackSubscriptionHandler;
  onVideoTrackUnsubscribed?: TrackSubscriptionHandler;
  onAudioTrackUnsubscribed?: TrackSubscriptionHandler;
  onDataTrackUnsubscribed?: TrackSubscriptionHandler;
  onMessageReceived?: (message: DataTrackMessage) => void;
};
function getUseRemoteTrackHooks() {
  const shouldUseV2 =
    // @ts-ignore for Cypress testing purposes
    !window.testConfig || window.testConfig?.shouldUseStatsig;
  return {
    useRemoteVideo: shouldUseV2 ? useRemoteVideoV2 : useRemoteVideoV1,
    useRemoteAudio: shouldUseV2 ? useRemoteAudioV2 : useRemoveVideoV1,
  };
}

export function useRemoteTracks({
  remoteParticipant,
  onVideoTrackSubscribed,
  onAudioTrackSubscribed,
  onDataTrackSubscribed,
  onVideoTrackUnsubscribed,
  onAudioTrackUnsubscribed,
  onDataTrackUnsubscribed,
  onMessageReceived,
}: UseRemoteTracksProps) {
  const { useRemoteVideo, useRemoteAudio } = useMemo(
    getUseRemoteTrackHooks,
    [],
  );
  const videoRef = useRemoteVideo({
    remoteParticipant,
    onTrackSubscribed: onVideoTrackSubscribed,
    onTrackUnsubscribed: onVideoTrackUnsubscribed,
  });
  const audioRef = useRemoteAudio({
    remoteParticipant,
    onTrackSubscribed: onAudioTrackSubscribed,
    onTrackUnsubscribed: onAudioTrackUnsubscribed,
  });
  useRemoteData({
    remoteParticipant,
    onMessageReceived,
    onTrackSubscribed: onDataTrackSubscribed,
    onTrackUnsubscribed: onDataTrackUnsubscribed,
  });

  useEffect(
    function handleGenericTrackListeners() {
      const eventToCallbackMap: {
        trackSwitchedOff: TrackSubscriptionHandler;
        trackSwitchedOn: TrackSubscriptionHandler;
        trackSubscriptionFailed: (
          error: TwilioError,
          track: RemoteTrack,
        ) => void;
      } = {
        trackSwitchedOff: (track) => {
          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SWITCHED_OFF,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant track switched off",
            },
          );
        },
        // https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#track-switch-offs
        trackSwitchedOn: (track) => {
          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SWITCHED_ON,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant track switched on",
            },
          );
        },
        trackSubscriptionFailed: (error, track) => {
          logger.error(
            `Remote participant track subscription failed`,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            error,
          );
        },
      };

      Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
        remoteParticipant.on(event, callback);
      });

      return function cleanup() {
        Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
          remoteParticipant.off(event, callback);
        });
      };
    },
    [remoteParticipant],
  );

  return { videoRef, audioRef };
}
