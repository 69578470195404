import { Button, DrawerFooter } from "@grow-therapy-team/sprout-ui";
import { EditAppointmentFormValues } from "../EditAppointmentDrawer";
import { useSetAtom } from "jotai";
import {
  ClientInformationDrawerTab,
  DrawerState,
  drawerStateAtom,
} from "../../state";
import { activeClientInformationTabAtom } from "../../client-information/state";
import { useTrackEvent } from "../../../segment/segment";
import {
  AppointmentFollowUpSource,
  useCreateFollowUpAppointmentMutation,
} from "../../useCreateFollowUpAppointmentMutation";
import { showErrorToast, showAppointmentCreatedToast } from "../toasts";
import { logger } from "../../../datadog";
import { AppointmentLocation } from "../../schedule-preview/types";
import { ADD_APPOINTMENT_BUTTON_TITLE } from "../constants";
import { EventTypeEnum } from "../../../segment/types";

export function AddAppointmentDrawerFooter({
  isSaveDisabled,
  onAddAppointment,
  isLoading,
}: {
  isSaveDisabled: boolean;
  onAddAppointment: () => void;
  isLoading?: boolean;
}) {
  return (
    <DrawerFooter>
      <div className="flex w-full justify-end">
        <Button
          disabled={isSaveDisabled}
          onClick={onAddAppointment}
          loading={isLoading}
        >
          {ADD_APPOINTMENT_BUTTON_TITLE}
        </Button>
      </div>
    </DrawerFooter>
  );
}

export function AddAppointmentDrawerFooterWrapper({
  isSaveDisabled,
  formValues,
  isLoading,
  providerId,
  patientCustomerId,
  patientShortId,
}: {
  isSaveDisabled: boolean;
  formValues: EditAppointmentFormValues;
  isLoading?: boolean;
  providerId: string;
  patientCustomerId: string;
  patientShortId: string;
}) {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setActiveTab = useSetAtom(activeClientInformationTabAtom);

  const [addAppointment, { loading: isAppointmentLoading }] =
    useCreateFollowUpAppointmentMutation();
  const { formSubmitted } = useTrackEvent();
  const onAddAppointment = async () => {
    try {
      const result = await addAppointment({
        variables: {
          values: {
            providerId,
            patientCustomerId,
            startDate: formValues.startDate,
            endDate: formValues.endDate,
            indefinite: formValues.indefinite,
            numRecurrences: formValues.isRecurring
              ? formValues.numRecurrences
              : undefined,
            weeksBetween: formValues.isRecurring
              ? formValues.weeksBetween
              : undefined,
            inOffice:
              formValues.appointmentLocation ?? AppointmentLocation.Virtual,
            followUpSource: AppointmentFollowUpSource.Telehealth,
          },
        },
      });

      if (result?.data?.createFollowUpAppointment?.appointment?.id) {
        setDrawerState(DrawerState.CLIENT_INFORMATION);
        setActiveTab(ClientInformationDrawerTab.APPOINTMENTS);
        showAppointmentCreatedToast();
        formSubmitted(EventTypeEnum.WAITING_ROOM_APPOINTMENT_CREATED, {
          appointmentShortId:
            result?.data?.createFollowUpAppointment?.appointment
              ?.appointmentShortId,
          patientShortId: patientShortId,
        });
      }
    } catch {
      showErrorToast();
      logger.error("Failed to create new appointment");
    }
  };

  return (
    <AddAppointmentDrawerFooter
      isSaveDisabled={isSaveDisabled}
      onAddAppointment={onAddAppointment}
      isLoading={isAppointmentLoading || isLoading}
    />
  );
}
