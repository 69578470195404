import {
  AvatarAccent,
  InitialsAvatar,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { nameToInitials } from "../../utils";

type ClientActionsProps = {
  clientName: string;
  clientNameAnnotation?: React.ReactNode | null;
  actions?: React.ReactNode;
};

export function ClientActions({
  clientName,
  clientNameAnnotation,
  actions,
}: ClientActionsProps) {
  const initials = nameToInitials(clientName);

  return (
    <div
      data-testid={`client-actions:${clientName}`}
      className="flex items-center justify-between gap-x-4"
    >
      <div className="flex items-center gap-x-4">
        <InitialsAvatar
          className="flex justify-center items-center flex-shrink-0"
          initials={initials}
          accent={AvatarAccent.Lilac}
          size="sm"
        />
        <div className="flex flex-col">
          <Text
            className="font-semibold rebrand:font-medium fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name={`Click on client action for ${initials}`}
            data-testid="client-actions.client-name"
          >
            {clientName}
          </Text>
          {clientNameAnnotation}
        </div>
      </div>
      {actions}
    </div>
  );
}
