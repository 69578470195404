import { useCallback } from "react";
import { useTwilioConversationCallbacks } from "../../twilio/chat/useTwilioConversationCallbacks";
import { useSetAtom } from "jotai";
import { useShowNewMessageToastCallback } from "./useShowNewMessageToastCallback";
import { currentConversationSidAtom } from "../../twilio";

export function useConversationCallbacks() {
  const {
    joinConversation: joinTwilioConversation,
    leaveConversation: leaveTwilioConversation,
  } = useTwilioConversationCallbacks();
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);
  const showNewMessageToast = useShowNewMessageToastCallback();
  const joinConversation = useCallback(
    async function handleActiveConversation(activeConversationSid: string) {
      await joinTwilioConversation(activeConversationSid, {
        onMessageAdded: (message) => {
          showNewMessageToast(message);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const leaveConversation = useCallback(
    function leaveConversationOnDismount(activeConversationSid: string) {
      setCurrentConversationSid("");
      leaveTwilioConversation(activeConversationSid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { joinConversation, leaveConversation };
}
