import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { Theme } from "../../state";
import { useClientTwilioRoomListeners } from "./useClientTwilioRoomListeners";
import {
  AudioVideoControlsWrapper as VideoControls,
  isScreenShareModeAtom,
  useHandleDeviceChanges,
} from "../../twilio";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import { LeaveSessionButtonWrapper as LeaveSessionButton } from "./LeaveSessionButton";
import { useNetworkQualityLogging } from "../../twilio/useNetworkQualityLogging";
import { useSessionFocus, nonEssentialElementProps } from "../../hooks";
import { useResetLocalVideoOnScreenOrientationChange } from "../../twilio/useResetLocalVideoOnScreenOrientationChange";
import { useResetVideoOnMobileNavigation } from "../../twilio/useResetVideoOnMobileNavigation";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { SessionGrid } from "../../twilio/session-grid/SessionGrid";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../chat/ChatDrawerButton";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "../AdditionalOptionsMenu";
import { useLeaveSessionNudgeToast } from "./useLeaveSessionNudgeToast";
import { sendLoggingEvents, TrackingEvents } from "../../events";
import { useSessionConversation } from "./useSessionConversation";
import classNames from "classnames";
import { useSetProviderParticipantData } from "./useSetProviderParticipantData";
import { useUpdateTrackStatsInterval } from "../../twilio/stats/useUpdateTrackStatsInterval";
import { RecordingControl } from "../session-recording/RecordingControl";
import { RemoteParticipant } from "../RemoteParticipant";
import { TranscriptionPillWrapper } from "../session-recording/TranscriptionPill";
import { usePeriodicallySendParticipantState } from "../../twilio/messages/usePeriodicallySendParticipantState";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";

function StageEffects() {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const sendParticipantState = useSendVisitorParticipantStateCallback();
  useClientTwilioRoomListeners();
  useHandleDeviceChanges();
  useNetworkQualityLogging({
    onOpenSettings: () => {
      setDrawerState(VisitorDrawerState.SETTINGS);
    },
  });
  useSessionFocus();
  useResetLocalVideoOnScreenOrientationChange();
  useResetVideoOnMobileNavigation();
  useLeaveSessionNudgeToast();
  useSetProviderParticipantData();
  useUpdateTrackStatsInterval();
  usePeriodicallySendParticipantState(sendParticipantState);

  return null;
}

const Stage = memo(function Stage() {
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const thumbnail = useMemo(
    () => (
      <SelfVideoThumbnail
        showExpandVideoToggle={false}
        shouldShowHideSelf={false}
        showSetPositionMenu={false}
        videoClassName={classNames({
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 flex-1 border border-neutral-700 rounded-2xl rebrand:border-neutral-800 rebrand:border":
            isScreenShareMode,
        })}
        className={classNames(
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mr-2 sm:mr-0 flex-1 border border-neutral-700 rounded-2xl rebrand:border-neutral-800 rebrand:border",
          {
            "flex-1 min-w-[10rem] sm:min-w-[unset] min-h-[7.875rem] w-auto h-full sm:mb-2":
              isScreenShareMode,
          },
        )}
      />
    ),
    [isScreenShareMode],
  );

  return (
    <>
      <StageEffects />
      <StageContainer rounded>
        <SessionGrid
          remoteParticipantComponent={RemoteParticipant}
          thumbnail={thumbnail}
        />
        {!isScreenShareMode && (
          <SelfVideoThumbnail
            className="absolute bottom-2"
            hideSelfViewClassName="bottom-2"
            showSetPositionMenu
            shouldShowHideSelf
            showExpandVideoToggle={false}
          />
        )}
      </StageContainer>
    </>
  );
});

const PUSH_DRAWER_ARIA_CONTROLS_ID = "meeting-room-push-drawer";

export function Page() {
  useSessionConversation();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const [visitorDrawerState, setVisitorDrawerState] = useAtom(
    visitorDrawerStateAtom,
  );
  const toggleDrawerState = useCallback(
    (drawer: VisitorDrawerState): void => {
      if (visitorDrawerState === drawer) {
        setVisitorDrawerState(null);
        return;
      }
      setVisitorDrawerState(drawer);
    },
    [visitorDrawerState, setVisitorDrawerState],
  );

  const toggleSettingsDrawer = useCallback(() => {
    toggleDrawerState(VisitorDrawerState.SETTINGS);
  }, [toggleDrawerState]);

  useEffect(function sendLoggingEventsOnPageLoad() {
    sendLoggingEvents(TrackingEvents.CLIENT_ENTER_SESSION_ROOM);
  }, []);

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
    setIsHelpModalOpen(true);
  }, []);

  return (
    <PageProviders theme={Theme.DARK}>
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
      <PageContainer>
        <Navbar
          drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
          forSettingsButtonDrawerState={VisitorDrawerState.SETTINGS}
          onClickSettingsButton={toggleSettingsDrawer}
          drawerState={visitorDrawerState}
          onClickNeedHelpButton={openHelpModal}
          TranscriptionPillComponent={TranscriptionPillWrapper}
          {...nonEssentialElementProps}
          className={classNames(nonEssentialElementProps.className, "order-1")}
        />
        <VideoControls
          openHelpModal={openHelpModal}
          recordingControlComponent={RecordingControl}
          additionalRightControls={<LeaveSessionButton />}
          chatButtonComponent={
            <ChatDrawerButton drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID} />
          }
          additionalOptionsComponent={
            <AdditionalOptionsMenu
              drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
              onClickSettingsButton={toggleSettingsDrawer}
            />
          }
          {...nonEssentialElementProps}
          className={classNames(nonEssentialElementProps.className, "order-3")}
        />
        <PushDrawerContainer
          id={PUSH_DRAWER_ARIA_CONTROLS_ID}
          drawer={<PushDrawer />}
          // The push drawer must come _after_ the buttons that can trigger it
          // in the DOM. This is so that a keyboard user can tab forward from
          // the triggering button to the drawer. However, the drawer and stage
          // are meant to be visually positioned _before_ the control buttons,
          // so we reorder them within their flex container.
          className="order-2"
        >
          <Stage />
        </PushDrawerContainer>
      </PageContainer>
    </PageProviders>
  );
}
