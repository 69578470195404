import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ButtonSize, IconButton } from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import { HTMLAttributes, useEffect } from "react";
import { twMerge } from "tailwind-merge";

export enum ToastVariant {
  Success = "success",
  Neutral = "neutral",
  Error = "error",
}

export type ToastProps = {
  children: React.ReactNode;
  variant?: ToastVariant;
  onClose?: () => void;
  onCleanup?: () => void;
  closeButtonClassName?: string;
  isBanner?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function Toast({
  className,
  children,
  variant = ToastVariant.Neutral,
  onClose,
  onCleanup,
  closeButtonClassName,
  isBanner,
  ...rest
}: ToastProps) {
  useEffect(
    function cleanup() {
      return () => {
        onCleanup?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      {...rest}
      className={twMerge(
        "bg-neutral-000",
        classNames(
          {
            "rebrand:bg-neutral-200": variant === ToastVariant.Neutral,
            "rebrand:bg-green-100": variant === ToastVariant.Success,
            "rebrand:bg-coral-300": variant === ToastVariant.Error,
          },
          "p-6 rebrand:p-4 rounded-lg flex justify-between items-center rebrand:border-[1px] rebrand:border-neutral-800 rebrand:border-solid",
        ),
        className,
      )}
    >
      {children}
      {!!onClose && (
        <IconButton
          iconDefinition={faXmark}
          onClick={onClose}
          className={twMerge(
            "rebrand:ml-3 rebrand:text-neutral-800 no-underline rebrand:hover:rebrand:text-neutral-800",
            classNames({
              "h-9 w-9": isBanner,
              "h-6 w-6 !min-w-[1.5rem] flex justify-center items-center rebrand:p-0 ":
                !isBanner,
            }),
            closeButtonClassName,
          )}
          use="secondary"
          aria-label="Dismiss"
          size={ButtonSize.Small}
        />
      )}
    </div>
  );
}
