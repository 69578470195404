import { PhotoAvatar, Skeleton, Text } from "@grow-therapy-team/sprout-ui";
import { Provider } from "../../types";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { useParams } from "react-router-dom";

export type ProviderAvatarSectionProps = {
  provider?: Pick<Provider, "image" | "name" | "pronouns">;
  loading?: boolean;
};

export function ProviderAvatarSection({
  provider,
  loading,
}: ProviderAvatarSectionProps) {
  return (
    <div
      data-testid="provider-avatar-section"
      className="flex flex-col gap-2 items-center"
    >
      <Text as="h1" variant="xl" className="font-semibold rebrand:font-medium">
        Join session with
      </Text>
      {loading ? (
        <>
          <span data-testid="provider-avatar-section.image-skeleton">
            <Skeleton className="my-0 mx-0 rounded-full w-24 h-24" />
          </span>
          <span data-testid="provider-avatar-section.name-skeleton">
            <Skeleton className="my-0 mx-0 w-28" />
          </span>
        </>
      ) : (
        <div
          data-dd-privacy="mask"
          className="fs-exclude flex flex-col gap-2 items-center"
          data-testid="provider-avatar-section.image-and-name"
        >
          <PhotoAvatar
            alt={`Provider ${provider?.name ?? "picture"}`}
            src={provider?.image ?? ""}
            size="lg"
          />
          <Text
            data-testid="provider-avatar-section.provider-name"
            variant="lg"
            className="text-ivy-500 font-semibold rebrand:font-medium text-xl leading-8"
            data-dd-action-name="Click on provider avatar"
          >
            {provider?.name}
            {(provider?.pronouns?.length ?? 0) > 0 && (
              <span
                className="font-normal"
                data-testid="provider-avatar-section.provider-pronouns"
              >
                {" "}
                ({provider?.pronouns![0]})
              </span>
            )}
          </Text>
        </div>
      )}
    </div>
  );
}

export function ProviderAvatarSectionWrapper() {
  const { providerShortId, patientShortId } = useParams();
  const { data, loading } = useGetTelehealthSessionInfo(
    providerShortId,
    patientShortId,
  );
  const provider = data?.telehealthSessionInfo?.provider;
  const props = { provider, loading };
  return <ProviderAvatarSection {...props} />;
}
