// based on https://auth0.com/blog/complete-guide-to-react-user-authentication/
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth0Config } from "./config";

export const Auth0ProviderWithNav = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}): JSX.Element => {
  const navigate = useNavigate();
  const { audience, scope, clientId, domain, redirectUri } = getAuth0Config();

  const onRedirectCallback = (appState?: AppState): void => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{ redirect_uri: redirectUri, audience, scope }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
      clientId={clientId}
      domain={domain}
    >
      {children}
    </Auth0Provider>
  );
};
