// Many of the types in this file are copied from the monolith
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from "@apollo/client";
import { Server } from "../apollo/types";
import { GET_UPCOMING_APPOINTMENTS } from "./useGetScheduleForToday";
import { InputMaybe, Scalars } from "../types";

export enum AppointmentRescheduledSource {
  ClientCalendarInvite = "CLIENT_CALENDAR_INVITE",
  ClientPortal = "CLIENT_PORTAL",
  ProviderCalendar = "PROVIDER_CALENDAR",
  ProviderStandalone = "PROVIDER_STANDALONE",
  Telehealth = "TELEHEALTH",
}

export type RescheduleAppointmentVariables = {
  appointmentId?: InputMaybe<Scalars["String"]["input"]>;
  inOffice: Scalars["String"]["input"];
  rescheduledSource?: InputMaybe<AppointmentRescheduledSource>;
  startDate: Scalars["DateTime"]["input"];
  timeEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RescheduleAppointmentMutationData = {
  __typename?: "Mutations";
  rescheduleAppointment?: {
    __typename?: "RescheduleAppointment";
    appointment?: {
      __typename?: "PublicAppointmentObject";
      id: string;
      timeStart: any;
      timeEnd: any;
      inOffice: string;
    } | null;
    patient?: { __typename?: "PublicPatientObject"; id: string } | null;
    provider?: {
      __typename?: "PublicProviderObject";
      id: string;
      name?: string | null;
      license?: string | null;
      imgUrl: string;
    } | null;
  } | null;
};

export const rescheduleAppointmentFragmentDocument = gql`
  fragment RescheduleAppointmentFragment on AppointmentObject {
    timeStart
    timeEnd
    inOffice
  }
`;

export const rescheduleAppointmentDocument = gql`
  mutation RescheduleAppointment($values: RescheduleAppointmentInput!) {
    rescheduleAppointment(values: $values) {
      appointment {
        id
        timeStart
        timeEnd
        inOffice
      }
      patient {
        id
      }
      provider {
        id

        name
        license
        imgUrl
      }
    }
  }
`;

/* eslint-enable @typescript-eslint/no-explicit-any */
export function useRescheduleAppointmentMutation(onCompleted?: () => void) {
  return useMutation<
    RescheduleAppointmentMutationData,
    { values: RescheduleAppointmentVariables }
  >(rescheduleAppointmentDocument, {
    context: {
      server: Server.MONOLITH,
    },
    refetchQueries: [GET_UPCOMING_APPOINTMENTS],
    onCompleted,
  });
}
