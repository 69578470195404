import { getEnvFromHostName } from "../utils";
import { Env } from "../types";

export function getSegmentConfig(): { segmentWriteKey: string } {
  const env = getEnvFromHostName(window.location.hostname);
  switch (env) {
    case Env.PROD:
      return {
        segmentWriteKey: "h4fMRkcCYrVqDzBGMzj7yRbXa0vYilHN",
      };
    case Env.STAGING:
      return {
        segmentWriteKey: "SXuhdNATcYd1Hf2RMy4EFAQDkp05nx9g",
      };
    case Env.LOCAL:
      return {
        segmentWriteKey: "oOsNlwHUnzTwyf1ZStjhsYeW0R2U8utZ",
      };
    default:
      return {
        segmentWriteKey: "oOsNlwHUnzTwyf1ZStjhsYeW0R2U8utZ",
      };
  }
}
