import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { ConsentStatus } from "../../twilio/types";
import { telehealthTranscriptionGate } from "../../statsig/gates";
import { useGate } from "statsig-react";

export function TranscriptionPillWrapper() {
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { value: telehealthTranscriptionIsEnabled } = useGate(
    telehealthTranscriptionGate,
  );

  const { isRecording, isVisible } = useRecordingControl(
    providerShortId,
    patientShortId,
    telehealthTranscriptionIsEnabled,
  );
  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );

  if (!isVisible || providerConsent !== ConsentStatus.OPTED_IN) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
