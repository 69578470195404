import { DrawerBody, DrawerTitle } from "@grow-therapy-team/sprout-ui";
import { ClientQueueWrapper as ClientQueue } from "./ClientQueue";
import { ClientsInSessionWrapper as ClientsInSession } from "./ClientsInSession";
import "./index.css";
import { useAtomValue } from "jotai";
import { syncClientConnectionStateAtom } from "../../twilio";
import { ConnectionErrorToast } from "../sync/ConnectionErrorToast";
import { DrawerHeader } from "../../components/DrawerHeader";

type ClientsDrawerProps = {
  clientQueueComponent: typeof ClientQueue;
  clientsInSessionComponent: typeof ClientsInSession;
  isConnectionError?: boolean;
};

export function ClientsDrawer({
  clientQueueComponent: ClientQueueComponent,
  clientsInSessionComponent: ClientsInSessionComponent,
  isConnectionError,
}: ClientsDrawerProps) {
  return (
    <>
      <DrawerHeader data-testid="waiting-room.header">
        <DrawerTitle>Clients</DrawerTitle>
      </DrawerHeader>
      <DrawerBody
        className="flex flex-col gap-y-4"
        data-testid="waiting-room.body"
      >
        {isConnectionError ? (
          <ConnectionErrorToast />
        ) : (
          <>
            <ClientQueueComponent />
            <div className="my-4 border-b border-neutral-300" />
            <ClientsInSessionComponent />
          </>
        )}
      </DrawerBody>
    </>
  );
}

export function ClientsDrawerWrapper() {
  const isConnectionError =
    useAtomValue(syncClientConnectionStateAtom) === "error";

  return (
    <ClientsDrawer
      clientsInSessionComponent={ClientsInSession}
      clientQueueComponent={ClientQueue}
      isConnectionError={isConnectionError}
    />
  );
}
