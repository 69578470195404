import {
  AvatarAccent,
  InitialsAvatar,
  Text,
  Tooltip,
} from "@grow-therapy-team/sprout-ui";
import { nameToInitials } from "../../utils";
import { Message } from "@twilio/conversations";
import classNames from "classnames";
import { parseIdentity } from "../../twilio";
import { detectLinks } from "../utils";

export function ChatBubble({
  message,
  isSelf,
}: {
  message: Message;
  isSelf: boolean;
}) {
  const { name } = parseIdentity(message.author ?? "Participant");

  return (
    <li
      className="mb-4 flex"
      aria-label={`Message from ${isSelf ? "you" : name}`}
    >
      {!isSelf && (
        <Tooltip
          text={name}
          forceInteractive={false}
          data-dd-privacy="mask"
          containerClassName="mr-3"
          className="fs-exclude"
        >
          <InitialsAvatar
            aria-hidden
            size="xs"
            initials={nameToInitials(name)}
            accent={AvatarAccent.Lilac}
          />
        </Tooltip>
      )}
      <div
        className={classNames(
          {
            "max-w-[calc(100%-3rem)]": !isSelf,
            "max-w-full ml-auto": isSelf,
          },
          "flex flex-col",
        )}
      >
        <Text
          variant="sm"
          data-dd-privacy="mask"
          data-dd-action-name="Click on message"
          className={classNames(
            {
              "bg-ivy-500 text-neutral-000 [&>a]:text-neutral-000": !isSelf,
              "text-neutral-900  ml-auto rebrand:bg-neutral-400": isSelf,
            },
            "rounded-xl px-4 py-3 fs-exclude [&>a]:underline max-w-full break-words",
          )}
        >
          {detectLinks(message.body)}
        </Text>
        <Text
          variant="xs"
          className="text-neutral-500 rebrand:text-neutral-700 ml-auto pt-1"
        >
          <span className="sr-only">Sent at&nbsp;</span>
          {message.dateCreated?.toLocaleTimeString([], {
            hour: "numeric",
            minute: "numeric",
          })}
        </Text>
      </div>
    </li>
  );
}
