import { useMutation, gql } from "@apollo/client";
import {
  FormSubmissionMutationData,
  FormSubmissionMutationVariables,
  FormSubmissionCallbackResponse,
  GroupedFormData,
  FormSubmissionStatusCode,
} from "../components/forms/types";
import { logger } from "../datadog";
import { pipe } from "remeda";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { Server } from "../apollo/types";
import { combinedFormToQuestions } from "../components/forms/utils";
import { visitorAtom, visitorFormValues } from "./state";
import {
  trimUnusedFormValues,
  validateForm,
  formValuesToFormResponse,
} from "../components/forms/question/utils";

export const SUBMIT_CLIENT_FORM_GQL = gql`
  mutation SubmitFormResponse(
    $providerId: ID!
    $customerId: String!
    $formResponse: String!
    $formId: ID!
    $isLastForm: Boolean!
    $startedAt: DateTime!
    $completedBy: CompletedBy
  ) {
    submitFormResponse(
      values: {
        providerId: $providerId
        customerId: $customerId
        formResponse: $formResponse
        formId: $formId
        isLastForm: $isLastForm
        startedAt: $startedAt
        completedBy: $completedBy
      }
    ) {
      formSubmission {
        formId
        formResponseId: id
      }
    }
  }
`;

export function useSubmitClientFormMutation() {
  return useMutation<
    FormSubmissionMutationData,
    FormSubmissionMutationVariables
  >(SUBMIT_CLIENT_FORM_GQL, {
    context: {
      server: Server.MONOLITH,
      skipAuth: true,
    },
  });
}

export function useSubmitClientFormCallback() {
  const [submitFormMutation] = useSubmitClientFormMutation();

  const getFormValuesByTitle = useAtomCallback(
    useCallback((get) => get(visitorFormValues), []),
  );
  const { customerId, providerId } = useAtomValue(visitorAtom);

  return useCallback(
    async ({
      pendingForm,
      isLastForm,
      startedAt,
      completedBy,
    }: {
      pendingForm: GroupedFormData;
      startedAt: string;
      isLastForm?: boolean;
      completedBy?: "CLIENT";
    }): Promise<FormSubmissionCallbackResponse> => {
      const formValuesByTitle = getFormValuesByTitle();
      const questions = combinedFormToQuestions(pendingForm);
      const formTitle = pendingForm.formTitle;
      const formValues = formValuesByTitle && formValuesByTitle[formTitle];

      const validationErrors = validateForm(questions, formValues);
      if (!formValues) {
        return {
          status: "failure",
          code: FormSubmissionStatusCode.MISSING_FORM_VALUES,
          value: null,
        };
      }

      if (!providerId) {
        logger.error("Missing provider ID when submitting form");
        return {
          status: "failure",
          code: FormSubmissionStatusCode.MISSING_REQUIRED_FIELDS,
          value: new Set(["providerId"]),
        };
      }

      if (validationErrors.size) {
        return {
          status: "failure",
          code: FormSubmissionStatusCode.VALIDATION_ERROR,
          value: validationErrors,
        };
      }

      try {
        const response = await submitFormMutation({
          variables: {
            customerId: customerId!,
            formId: pendingForm.formId,
            formResponse: pipe(
              formValues,
              (formValues) =>
                trimUnusedFormValues(
                  formValues,
                  questions.map((q) => q.questionElement!),
                ),
              formValuesToFormResponse,
              JSON.stringify,
            ),
            isLastForm: !!isLastForm,
            providerId: providerId.toString()!,
            startedAt,
            completedBy,
          },
        });
        return {
          status: "success",
          code: FormSubmissionStatusCode.SUCCESS,
          value: response.data?.submitFormResponse?.formSubmission,
        };
      } catch (e) {
        const error = e as Error;
        logger.error("Error while submitting form", {}, error);
        return {
          status: "failure",
          code: FormSubmissionStatusCode.ERROR,
          value: error,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
