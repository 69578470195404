import {
  Button,
  ButtonType,
  InputControl,
  TextInput,
} from "@grow-therapy-team/sprout-ui";
import { ChangeEvent, useState } from "react";
import { useSetAtom } from "jotai";
import { VisitorState, visitorNameAtom, visitorStateAtom } from "../state";
import { AVPermissionCallout } from "./AVPermissionCallout";
import {
  EMPTY_INPUT_ERROR,
  MAX_NAME_LENGTH,
  TOO_LONG_INPUT_ERROR,
  UNSAFE_CHARACTERS_INPUT_ERROR,
} from "./constants";
import {
  containsNonAsciiCharacters,
  isOverCharacterLimit,
  shouldWarnForUnsafeCharacters,
} from "./utils";
import { twMerge } from "tailwind-merge";

type WrapperPassThroughProps = {
  buttonLabel?: string;
  buttonClassName?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  shouldDisableSubmitWhenInvalid?: boolean;
  showAVPermissionCallout?: boolean;
};
export interface InputControlsProps extends WrapperPassThroughProps {
  children?: React.ReactNode;
  onEnterWaitingRoom: (visitorName: string) => void;
}

export function InputControls({
  buttonLabel = "Enter waiting room",
  buttonClassName,
  className,
  onChange,
  onEnterWaitingRoom,
  shouldDisableSubmitWhenInvalid = false,
  showAVPermissionCallout = true,
}: InputControlsProps) {
  const [visitorNameInputError, setVisitorNameInputError] = useState("");
  const [visitorName, setVisitorName] = useState("");

  const validateAndEnter = () => {
    if (!visitorName || visitorName.trim().length === 0) {
      setVisitorNameInputError(EMPTY_INPUT_ERROR);
    } else {
      setVisitorNameInputError("");
      onEnterWaitingRoom(visitorName.trim());
    }
  };

  const isErrorBlockingSubmit = visitorNameInputError === EMPTY_INPUT_ERROR;

  const isDisabled =
    shouldDisableSubmitWhenInvalid && (isErrorBlockingSubmit || !visitorName);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (
      visitorNameInputError &&
      (input.trim().length > 0 ||
        !isOverCharacterLimit(input, MAX_NAME_LENGTH) ||
        !containsNonAsciiCharacters(input))
    ) {
      setVisitorNameInputError("");
    }
    if (isOverCharacterLimit(input, MAX_NAME_LENGTH)) {
      setVisitorNameInputError(TOO_LONG_INPUT_ERROR);
    }
    if (shouldWarnForUnsafeCharacters(input)) {
      setVisitorNameInputError(UNSAFE_CHARACTERS_INPUT_ERROR);
    }
    setVisitorName(input);

    onChange?.(event);
  };

  return (
    <div className={twMerge("flex flex-col gap-y-4", className)}>
      <InputControl
        error={visitorNameInputError}
        label="Enter your first and last name"
        className="w-full mb-0"
      >
        <TextInput
          value={visitorName}
          name="visitor-name"
          onChange={onInputChange}
          onEnter={validateAndEnter}
          error={!!visitorNameInputError}
        />
      </InputControl>
      {showAVPermissionCallout && <AVPermissionCallout />}
      <Button
        disabled={isDisabled}
        onClick={validateAndEnter}
        type={ButtonType.Submit}
        use="primary"
        className={buttonClassName}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}

export type InputControlsWrapperProps = WrapperPassThroughProps & {
  showAVPermissionCallout?: boolean;
};

export function InputControlsWrapper(props: InputControlsWrapperProps) {
  const setVisitorName = useSetAtom(visitorNameAtom);
  const setVisitorState = useSetAtom(visitorStateAtom);

  return (
    <InputControls
      {...props}
      onEnterWaitingRoom={(visitorName: string) => {
        setVisitorName(visitorName);
        setVisitorState(VisitorState.WAITING);
      }}
    ></InputControls>
  );
}
