import { useCallback } from "react";
import { useDisconnectTwilioRoomCallback as useBaseDisconnectTwilioRoomCallback } from "../twilio/useDisconnectTwilioRoomCallback";
import { useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  sessionConversationSidAtom,
} from "./state";
import { useTwilioConversationCallbacks } from "../twilio/chat/useTwilioConversationCallbacks";
import { useAtomCallback } from "jotai/utils";
import { useRecordAudioCallbacks } from "./session-recording/useRecordAudioCallbacks";

export function useDisconnectTwilioRoomCallback() {
  const disconnectTwilioRoom = useBaseDisconnectTwilioRoomCallback();
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSessionConversationSid = useSetAtom(sessionConversationSidAtom);
  const setInSessionPatientInformation = useSetAtom(
    inSessionPatientInformationAtom,
  );
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );
  const { leaveConversation } = useTwilioConversationCallbacks();
  const { stopRecording } = useRecordAudioCallbacks();

  return useCallback(
    () => {
      const room = disconnectTwilioRoom();
      stopRecording({ shouldTeardown: true });
      const conversationSid = getSessionConversationSid();
      const drawerState = getDrawerState();
      // attempt to leave the session conversation gracefully as a side effect
      if (conversationSid) {
        leaveConversation(conversationSid);
      }
      if (drawerState === DrawerState.SESSION_CHAT) {
        setDrawerState(DrawerState.PARTICIPANT_DISCONNECTED_CHAT);
      }
      setSessionConversationSid(undefined);
      setInSessionPatientInformation(undefined);
      return room;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
