import { Dispatch, createContext } from "react";
import { ValidationErrors, ValidationErrorsByFormTitle } from "./types";

export enum PendingFormsContextActionType {
  SET_VALIDATION_ERRORS = "set-validation-errors",
}

type PendingFormsValidationAction = {
  type: PendingFormsContextActionType.SET_VALIDATION_ERRORS;
  formTitle: string;
  validationErrors: ValidationErrors;
};

export type PendingFormsContextAction = PendingFormsValidationAction;

type PendingFormsContextValue = {
  validationErrorsByFormTitle: ValidationErrorsByFormTitle;
  dispatchPendingFormsContextAction?: Dispatch<PendingFormsContextAction>;
};

export const DEFAULT_PENDING_FORMS_CONTEXT_VALUE: PendingFormsContextValue = {
  validationErrorsByFormTitle: {},
};
export const PendingFormsContext = createContext<PendingFormsContextValue>(
  DEFAULT_PENDING_FORMS_CONTEXT_VALUE,
);

export function pendingFormsContextReducer(
  state: PendingFormsContextValue,
  action: PendingFormsContextAction,
): PendingFormsContextValue {
  switch (action.type) {
    case PendingFormsContextActionType.SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrorsByFormTitle: {
          ...state.validationErrorsByFormTitle,
          [action.formTitle]: action.validationErrors,
        },
      };
    default:
      return state;
  }
}
