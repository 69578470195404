import { PrimitiveAtom, WritableAtom, atom } from "jotai";
import { ConsentStatus } from "../types";
import { createDerivedWritableAtom } from "../../utils";
import { DataTrackState, DataTrackProperty } from "./types";
import { DataTrackUpdateStatus, getNewestDataTrackState } from "./utils";

const DEFAULT_LAST_UPDATED = 0;

export const DEFAULT_DATA_TRACK_STATE = {
  provider: {
    consent: {
      value: ConsentStatus.UNKNOWN,
      lastUpdated: DEFAULT_LAST_UPDATED,
    },
  },
  patient: {
    consent: {
      value: ConsentStatus.UNKNOWN,
      lastUpdated: DEFAULT_LAST_UPDATED,
    },
  },
  isRecording: { value: false, lastUpdated: DEFAULT_LAST_UPDATED },
};

export const dataTrackStateAtom = atom<DataTrackState>(
  DEFAULT_DATA_TRACK_STATE,
);

type WritableDataTrackAtom<Value> = WritableAtom<
  DataTrackProperty<Value>,
  [update: DataTrackProperty<Value>],
  void
>;

/**
 *
 * @param baseAtom The parent atom of the data track property
 * @param key The key of the of the data track property
 * @returns A read/write atom with setter guards against updating the value with stale info
 */
function createDataTrackPropertyAtom<
  Value, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Key extends keyof any,
  Base extends Record<Key, DataTrackProperty<Value>>,
>(baseAtom: PrimitiveAtom<Base>, key: Key): WritableDataTrackAtom<Value> {
  return atom(
    (get) => get(baseAtom)[key],
    (get, set, update: DataTrackProperty<Value>) => {
      const current = get(baseAtom)[key];
      /**
       * If the current value is newer than the update,
       * we don't update the atom
       */
      const [_newest, comparisonResult] = getNewestDataTrackState({
        receivedState: update,
        userState: current,
      });
      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }
      set(baseAtom, {
        ...get(baseAtom),
        [key]: { value: update.value, lastUpdated: update.lastUpdated },
      });
    },
  );
}

export const isRecordingAtom: WritableDataTrackAtom<boolean> =
  createDataTrackPropertyAtom(dataTrackStateAtom, "isRecording");

const providerAtom = createDerivedWritableAtom(dataTrackStateAtom, "provider");

export const providerConsentAtom: WritableDataTrackAtom<ConsentStatus> =
  createDataTrackPropertyAtom(providerAtom, "consent");

const patientAtom = createDerivedWritableAtom(dataTrackStateAtom, "patient");

export const patientConsentAtom: WritableDataTrackAtom<ConsentStatus> =
  createDataTrackPropertyAtom(patientAtom, "consent");
