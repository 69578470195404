import { Fragment, useEffect, useState } from "react";
import { AnswerValue, GroupedFormData, ProviderFormElement } from "./types";
import { Question } from "./question/Question";
import { GenericFormAnswers, QuestionProps } from "./question/types";
import { shouldShowQuestion } from "./question/utils";
import { Text } from "@grow-therapy-team/sprout-ui";
import { combinedFormToQuestions } from "./utils";
import { EventTypeEnum } from "../../segment/types";
import { useTrackEvent } from "../../segment/segment";
export enum ValidationError {
  INCOMPLETE_FORM = "incomplete-form",
}

export type ValidationErrors = Set<ValidationError>;
export type ValidationErrorsByFormTitle = Record<string, Set<ValidationError>>;

type PendingFormProps = {
  form: GroupedFormData;
  initialValues?: GenericFormAnswers;
  questionComponent?: typeof Question;
  onChange?: (
    value:
      | GenericFormAnswers
      | ((prev?: GenericFormAnswers) => GenericFormAnswers),
  ) => void;
  validationErrors?: ValidationErrors;
};

/**
 * This function is primarily for typing purposes. It converts a question and
 * value to the props that the Question component expects.
 */
function toQuestionProps(
  onChange: NonNullable<PendingFormProps["onChange"]>,
  question: ProviderFormElement,
  value?: unknown,
): QuestionProps {
  const props = {
    question,
    type: question.type,
    value,
    onChange: (question: ProviderFormElement, value: AnswerValue) => {
      onChange((prev) => ({ ...prev, [question.name]: value }));
    },
  };

  return props as QuestionProps;
}

/**
 * Renders an editable form with the given form data and initial values
 * (answers). The form is uncontrolled and will maintain state locally.
 */
export function PendingForm({
  form,
  initialValues,
  questionComponent: QuestionComponent = Question,
  onChange: givenOnChange,
  validationErrors,
}: PendingFormProps) {
  const [values, setValues] = useState<GenericFormAnswers>(initialValues ?? {});
  const questions = combinedFormToQuestions(form);
  const onChange: PendingFormProps["onChange"] = (valuesOrFunction) => {
    setValues(valuesOrFunction);
    givenOnChange?.(valuesOrFunction);
  };

  const { userViewed } = useTrackEvent();
  useEffect(() => {
    userViewed(EventTypeEnum.MEASURE_VIEWED, { type: form.formType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formType]);

  return (
    <div className="flex flex-col gap-6">
      <Text as="h3" className="text-neutral-700">
        {form.formPrompt}
      </Text>
      {questions.map(({ questionElement }, questionIndex) => {
        if (!questionElement) return;

        const { name, title, visibleIf } = questionElement;

        if (!shouldShowQuestion(visibleIf, values)) return;

        const value = values?.[name];
        const isLastQuestion = questionIndex === questions.length - 1;

        return (
          <Fragment key={title}>
            <QuestionComponent
              validationErrors={validationErrors}
              {...toQuestionProps(onChange, questionElement, value)}
            />
            {!isLastQuestion && <hr className="p-0 m-0 text-neutral-400" />}
          </Fragment>
        );
      })}
    </div>
  );
}
