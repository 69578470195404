import {
  Tabs,
  Tab,
  DrawerBody,
  DrawerTitle,
} from "@grow-therapy-team/sprout-ui";
import { useState } from "react";
import { DrawerHeader } from "../DrawerHeader";
import {
  faImageUser,
  faMicrophone,
  faVideo,
} from "@fortawesome/pro-solid-svg-icons";

export enum SettingsDrawerTab {
  VIDEO = "video",
  AUDIO = "audio",
  BACKGROUND = "background",
}

export function SettingsDrawer({
  tabMap,
}: {
  tabMap: Record<SettingsDrawerTab, JSX.Element>;
}) {
  const [selectedTab, setSelectedTab] = useState<SettingsDrawerTab>(
    SettingsDrawerTab.VIDEO,
  );

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>Settings</DrawerTitle>
      </DrawerHeader>
      <DrawerBody>
        <Tabs
          selectedTabId={selectedTab}
          onSelectTab={(newTab: SettingsDrawerTab) => {
            setSelectedTab(newTab);
          }}
          className="w-full overflow-x-scroll no-scrollbar flex gap-2"
        >
          <Tab
            tabId="video"
            className="whitespace-nowrap"
            icon={faVideo}
            title="Video"
          />
          <Tab
            tabId="audio"
            className="whitespace-nowrap"
            icon={faMicrophone}
            title="Audio"
          />
          <Tab
            tabId="background"
            className="whitespace-nowrap"
            icon={faImageUser}
            title="Background"
          />
        </Tabs>
        {tabMap[selectedTab]}
      </DrawerBody>
    </>
  );
}
