import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { localAudioTrackAtom } from "../state";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import { logger } from "../../datadog/logger";
import { CallbackError, CallbackSuccess } from "../../types";
import { LocalAudioTrack } from "twilio-video";
import {
  disableNoiseCancellation as disableNoiseCancellation_,
  enableNoiseCancellation as enableNoiseCancellation_,
} from "../tracks";
import { getStoredAudioVideoSettings } from "../config";

export enum NoiseCancellationCallbackResponseCode {
  MISSING_TRACK = "missing_track",
  ERROR = "error",
  NOOP = "noop",
}

type NoiseCancellationCallbackResponse =
  | CallbackSuccess<LocalAudioTrack, NoiseCancellationCallbackResponseCode>
  | CallbackSuccess<null, NoiseCancellationCallbackResponseCode.NOOP> // for feature flag disabled
  | CallbackError<NoiseCancellationCallbackResponseCode>;

/**
 * Provides callbacks for enabling and disabling noise cancellation.
 */
export function useNoiseCancellationCallbacks() {
  const getLocalAudioTrack = useAtomCallback(
    useCallback((get) => get(localAudioTrackAtom).track, []),
  );
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const enableNoiseCancellation = useCallback(
    async (
      givenTrack?: LocalAudioTrack,
    ): Promise<NoiseCancellationCallbackResponse> => {
      const localAudioTrack = givenTrack ?? getLocalAudioTrack();
      if (!localAudioTrack)
        return {
          status: "error",
          code: NoiseCancellationCallbackResponseCode.MISSING_TRACK,
        };
      try {
        await enableNoiseCancellation_(localAudioTrack);
      } catch (e) {
        const error = e as Error;
        logger.error("Failed to enable noise cancellation", {}, error);
        return {
          status: "error",
          code: NoiseCancellationCallbackResponseCode.ERROR,
          value: error,
        };
      }
      setStoredAudioVideoSettings((prev) => ({
        ...prev,
        noiseCancellationIsEnabled: true,
      }));
      return {
        status: "success",
        value: localAudioTrack,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const disableNoiseCancellation = async (
    givenTrack?: LocalAudioTrack,
  ): Promise<NoiseCancellationCallbackResponse> => {
    const localAudioTrack = givenTrack ?? getLocalAudioTrack();
    if (!localAudioTrack)
      return {
        status: "error",
        code: NoiseCancellationCallbackResponseCode.MISSING_TRACK,
      };
    try {
      await disableNoiseCancellation_(localAudioTrack);
    } catch (e) {
      const error = e as Error;
      logger.error("Failed to disable noise cancellation", {}, error);
      return {
        status: "error",
        code: NoiseCancellationCallbackResponseCode.ERROR,
        value: error,
      };
    }
    setStoredAudioVideoSettings((prev) => ({
      ...prev,
      noiseCancellationIsEnabled: false,
    }));
    return {
      status: "success",
      value: localAudioTrack,
    };
  };

  const initNoiseCancellation = useCallback(
    async (
      track?: LocalAudioTrack,
    ): Promise<NoiseCancellationCallbackResponse> => {
      const { noiseCancellationIsEnabled } = getStoredAudioVideoSettings();
      if (noiseCancellationIsEnabled) {
        return await enableNoiseCancellation(track);
      }
      return await disableNoiseCancellation(track);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    initNoiseCancellation,
    enableNoiseCancellation,
    disableNoiseCancellation,
  };
}
