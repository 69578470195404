import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError, getAuth0Config, loginRequired } from "../auth";
import { getAppConfig, GRAPHQL_PATH } from "../config";
import { logger } from "../datadog/logger";
import { Button, toast } from "@grow-therapy-team/sprout-ui";
import { useAtomCallback } from "jotai/utils";
import { idClaimsAtom, isShowingTimeoutToastAtom } from "../auth/state";
import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { MISSING_AUTH_HEADER_ERROR_MESSAGE } from "./utils";
import { Server } from "./types";

const appConfig = getAppConfig();

function showTimedOutSessionToast({
  onLogout,
  setIsShowingTimeoutToast,
}: {
  onLogout: () => void;
  setIsShowingTimeoutToast: (value: boolean) => void;
}) {
  toast.error(
    <>
      Your session has expired. Please{" "}
      {
        <Button className="p-0" use="link" onClick={() => onLogout()}>
          click here
        </Button>
      }{" "}
      to log in again.
    </>,
    {
      duration: Infinity,
      onClose: (t) => {
        toast.dismiss(t.id);
        setIsShowingTimeoutToast(false);
      },
    },
  );
  setIsShowingTimeoutToast(true);
}

const authLink = new ApolloLink((operation, forward) => {
  const { skipAuth, headers } = operation.getContext();
  if (!skipAuth && !headers?.Authorization) {
    return new Observable((observer) => {
      observer.error(new Error(MISSING_AUTH_HEADER_ERROR_MESSAGE));
    });
  }

  return forward(operation);
});

const telehealthHttpLink = createHttpLink({
  uri: GRAPHQL_PATH,
});

const monolithHttpLink = createHttpLink({
  uri: appConfig.monolithBackendOrigin + appConfig.monolithGqlPath,
});

class NamedAuthError extends Error {
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

const httpLink = split(
  (operation) => {
    return operation.getContext().server === Server.MONOLITH;
  },
  monolithHttpLink,
  telehealthHttpLink,
);

/**
 * Wraps the given children with an ApolloProvider that attaches an Auth0 JWT to
 * GQL request headers, if a token is available.
 *
 * In the event that the refresh token is invalid i.e. the session timed out,
 * then the user will be logged out and returned to the login screen.
 */
export function AuthedApolloProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { getAccessTokenSilently, logout } = useAuth0();
  const getUser = useAtomCallback(useCallback((get) => get(idClaimsAtom), []));
  const getIsShowingTimeoutToast = useAtomCallback(
    useCallback((get) => get(isShowingTimeoutToastAtom), []),
  );
  const setIsShowingTimeoutToast = useSetAtom(isShowingTimeoutToastAtom);

  const withAccessTokenLink = setContext(async (_, ctx) => {
    if (ctx.skipAuth) return ctx;

    const logoutToLoginPage = () => {
      const { loginUri } = getAuth0Config();
      logout({
        logoutParams: {
          returnTo: loginUri,
        },
      });
    };

    try {
      const token = await getAccessTokenSilently();
      const { headers } = ctx;
      return {
        ...ctx,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (e) {
      const error = e as AuthError;
      if (loginRequired(error.error)) {
        logger.warn(
          "Login required",
          {},
          new NamedAuthError(error.error, error.error_description),
        );
        if (getUser() && !getIsShowingTimeoutToast()) {
          showTimedOutSessionToast({
            onLogout: logoutToLoginPage,
            setIsShowingTimeoutToast,
          });
        }
        return ctx;
      }
      logger.error(
        "An unexpected auth error occurred",
        {},
        new NamedAuthError(error.error, error.error_description),
      );
    }
    return ctx;
  });

  const apolloClient = new ApolloClient({
    link: from([withAccessTokenLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
