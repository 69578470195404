import * as FS from "@fullstory/browser";
import { getEnvFromHostName } from "../utils";
import { Env } from "../types";

export function sendFullStoryEvent(
  eventName: string,
  eventProperties: object = {},
) {
  if (
    FS.isInitialized() &&
    getEnvFromHostName(location.hostname) === Env.PROD
  ) {
    FS.event(eventName, eventProperties);
  }
}
