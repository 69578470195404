import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { Button, CalloutBanner, Icon } from "@grow-therapy-team/sprout-ui";

export function RefreshAppointmentsCallout({
  onClickRefresh,
}: {
  onClickRefresh: () => void;
}) {
  return (
    <CalloutBanner
      heading="Cancelled an appointment?"
      headingVariant="md"
      use="lilac"
      className="bg-neutral-100"
      actions={
        <Button
          use="secondary"
          aria-label="Refresh appointment list"
          className="flex gap-x-2 items-center"
          onClick={onClickRefresh}
        >
          Refresh
          <Icon icon={faArrowsRotate} />
        </Button>
      }
    >
      You may need to refresh this list to see the update.
    </CalloutBanner>
  );
}
