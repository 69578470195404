import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { logger } from "../../../datadog";

const CREATE_VISITOR_CHAT_EXPERIENCE = gql`
  mutation CreateTwilioVisitorChatExperience(
    $providerShortId: String!
    $visitorName: String!
    $visitorId: String!
  ) {
    createTwilioVisitorChatAccess(
      visitorName: $visitorName
      visitorId: $visitorId
    ) {
      jwt
    }
    createTwilioWaitingRoomConversation(
      providerShortId: $providerShortId
      visitorUuid: $visitorId
      visitorName: $visitorName
    ) {
      conversationSid
    }
  }
`;

type CreateVisitorChatExperienceMutationData = {
  createTwilioVisitorChatAccess: { jwt: string };
  createTwilioWaitingRoomConversation: {
    conversationSid: string;
  };
};

type CreateVisitorChatExperienceMutationVariables = {
  providerShortId: string;
  visitorName: string;
  visitorId: string;
};

export function useCreateVisitorChatExperienceMutation() {
  return useMutation<
    CreateVisitorChatExperienceMutationData,
    CreateVisitorChatExperienceMutationVariables
  >(CREATE_VISITOR_CHAT_EXPERIENCE, { context: { skipAuth: true } });
}

type CreateVisitorChatExperienceCallback = (
  providerShortId: string,
  visitorName: string,
  visitorId: string,
) => Promise<FetchResult<CreateVisitorChatExperienceMutationData> | undefined>;

export function useCreateVisitorChatExperienceCallback(): [
  CreateVisitorChatExperienceCallback,
  MutationResult<CreateVisitorChatExperienceMutationData>,
] {
  const [createVisitorChatExperience, result] =
    useCreateVisitorChatExperienceMutation();

  const createVisitorChatExperienceCallback = async (
    providerShortId: string,
    visitorName: string,
    visitorId: string,
  ) => {
    try {
      return await createVisitorChatExperience({
        variables: {
          providerShortId,
          visitorName,
          visitorId,
        },
      });
    } catch (error) {
      logger.error(
        "Unable to set up waiting room chat",
        {
          providerShortId,
          visitorId,
        },
        error as Error,
      );
    }
  };

  return [createVisitorChatExperienceCallback, result];
}
