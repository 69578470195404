import { Text } from "@grow-therapy-team/sprout-ui";
import { combinedFormToQuestions } from "../../components/forms/utils";
import {
  ContactValue,
  GroupedFormData,
  ProfessionalResourceValue,
  QuestionTypeEnum,
} from "./types";
import { isString } from "remeda";

type SafetyPlanFormSectionProps = {
  selectedForm: GroupedFormData;
};

// FIXME: The fields below do not match the appearence of the the Safety Plan in
// the Provider Dashboard

function EmergencyContactValue({ contact }: { contact: ContactValue }) {
  return (
    <>
      {contact.name && (
        <Text data-dd-privacy="mask" className="fs-exclude space-x-4 pt-4">
          Name: {contact.name}
        </Text>
      )}
      {contact.phoneNumber && (
        <Text data-dd-privacy="mask" className="fs-exclide">
          Phone Number: {contact.phoneNumber}
        </Text>
      )}
    </>
  );
}

function EmergencyContactField({
  question,
  contact,
}: {
  question: string;
  contact: ContactValue;
}) {
  return (
    <div
      className="relative rounded py-4 text-start rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600 fs-exclude"
      data-dd-privacy="mask"
    >
      <Text className="font-semibold rebrand:font-medium">{question}</Text>
      <EmergencyContactValue contact={contact} />
    </div>
  );
}

function EmergencyContactFields({
  question,
  contacts,
}: {
  question: string;
  contacts: ContactValue[];
}) {
  return (
    <>
      {contacts.map((contact) => (
        <EmergencyContactField
          key={question}
          question={question}
          contact={contact}
        />
      ))}
    </>
  );
}

function ProfessionalResourceField({
  question,
  answer,
}: {
  question: string;
  answer: ProfessionalResourceValue;
}) {
  const hasProfessionalResource = answer?.address || answer?.phoneNumber;
  return (
    <div
      className="relative rounded py-4 text-start rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600 fs-exclude"
      data-dd-privacy="mask"
    >
      {hasProfessionalResource && (
        <>
          <Text className="font-semibold rebrand:font-medium">{question}</Text>
          <p className="space-x-4 pt-4">Address: {answer?.address}</p>
          <p>Phone Number: {answer?.phoneNumber}</p>
        </>
      )}

      {answer?.resources.map((resource, index) => (
        <EmergencyContactValue
          key={resource.name ?? resource.phoneNumber ?? index}
          contact={resource}
        />
      ))}
    </div>
  );
}

export function SafetyPlanFormSection({
  selectedForm,
}: SafetyPlanFormSectionProps) {
  const questions = combinedFormToQuestions(selectedForm);

  return (
    <div
      className="flex flex-col"
      data-testid="client-information.forms.safety-form"
    >
      {questions.map(({ type, answer, question }) => {
        switch (type) {
          case QuestionTypeEnum.EMERGENCY_CONTACT_INPUT:
            return (
              <EmergencyContactFields
                question={question!}
                contacts={answer as ContactValue[]}
              />
            );

          case QuestionTypeEnum.PROFESSIONAL_RESOURCE_INPUT:
            return (
              <ProfessionalResourceField
                question={question!}
                answer={answer as ProfessionalResourceValue}
              />
            );

          default:
            return (
              <div
                className="relative rounded py-4 text-start rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600 fs-exclude"
                data-dd-privacy="mask"
                key={question}
              >
                <>
                  <Text className="font-semibold rebrand:font-medium">
                    {question}
                  </Text>
                  {isString(answer) && <Text>{answer}</Text>}
                </>
              </div>
            );
        }
      })}
    </div>
  );
}
