export type RecordingMetadata = {
  startTime: number | null;
  endTime: number | null;
};
export type AudioEventData = BlobEvent["data"];
export type AudioEventDataList = AudioEventData[];
export enum RecordingState {
  INACTIVE = "inactive",
  RECORDING = "recording",
  PAUSED = "paused",
}
