import { useSetAtom } from "jotai";
import { createVisitor, visitorAtom } from "../state";
import { useCallback } from "react";
import { useUnpublishLocalTrackCallback } from "../../twilio";

export function useResetUserStateCallback() {
  const setVisitor = useSetAtom(visitorAtom);
  const unpublishLocalAudioTrack = useUnpublishLocalTrackCallback("audio");
  const unpublishLocalVideoTrack = useUnpublishLocalTrackCallback("video");
  const resetUserState = useCallback(
    () => {
      setVisitor(createVisitor());
      unpublishLocalAudioTrack();
      unpublishLocalVideoTrack();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return resetUserState;
}
