import { useCallback, useEffect, useMemo, useRef } from "react";
import { RemoteParticipant, RemoteTrackPublication } from "twilio-video";
import { TrackSubscriptionHandler, TrackEvent, TrackSource } from "./types";
import { currentOutputDeviceIdAtom } from "./state";
import { useAtomValue, useSetAtom } from "jotai";
import {
  addTrackToParticipant,
  parseTrackName,
  remoteParticipantToDiagnosticInfo,
  remoteScreenShareAudioTrackAtom,
  remoteScreenShareParticipantAtom,
  remoteTracksByParticipantIdAtom,
  removeTrackFromParticipant,
  trackStatusByParticipantIdAtom,
  trackToDiagnosticInfo,
  useShowRestrictedAudioPlaybackToastCallback,
  gracefullyHandleTrackEvents,
} from ".";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { Mutex } from "async-mutex";
import { useRemoteTrackEventListeners } from "./useRemoteTrackEventListeners";

export type UseRemoteAudioProps = {
  remoteParticipant: RemoteParticipant;
  onTrackSubscribed?: TrackSubscriptionHandler;
  onTrackUnsubscribed?: TrackSubscriptionHandler;
};

export function useRemoteAudio({
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
}: UseRemoteAudioProps) {
  const remoteParticipantAudioRef = useRef<HTMLAudioElement>(null);
  const currentOutputDeviceId = useAtomValue(currentOutputDeviceIdAtom);
  const setRemoteScreenShareParticipant = useSetAtom(
    remoteScreenShareParticipantAtom,
  );
  const setRemoteScreensShareAudioTrack = useSetAtom(
    remoteScreenShareAudioTrackAtom,
  );
  const { detectRestrictedAudioPlaybackTimeout } =
    useShowRestrictedAudioPlaybackToastCallback();
  const setAudioTrackState = useSetAtom(trackStatusByParticipantIdAtom);
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);

  useEffect(
    function handleParticipantTrackSubscriptions() {
      if (!remoteParticipant) return;

      const eventToCallbackMap: Omit<
        Record<TrackEvent, TrackSubscriptionHandler>,
        "trackSwitchedOff" | "trackSwitchedOn"
      > = {
        trackSubscribed: (track, ...otherArgs) => {
          const element = remoteParticipantAudioRef.current;
          if (track.kind !== "audio" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);
          if (source === TrackSource.Screen && remoteParticipant) {
            // TODO: We should be able to get rid of setting the screen share track
            setRemoteScreensShareAudioTrack(track);
            setRemoteScreenShareParticipant(remoteParticipant);
          }
          if (source === TrackSource.Microphone) {
            track.attach(element);
            detectRestrictedAudioPlaybackTimeout(track, element, 300);
            onTrackSubscribed?.(track, ...otherArgs);
            setAudioTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isAudioMuted: !track.isEnabled,
              },
            }));
          }

          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant audio track subscribed",
            },
          );
        },
        trackEnabled: (track) => {
          if ((track as unknown as RemoteTrackPublication).kind !== "audio")
            return;
          setAudioTrackState((prev) => ({
            ...prev,
            [remoteParticipant.identity]: {
              ...prev[remoteParticipant.identity],
              isAudioMuted: false,
            },
          }));
        },
        trackUnsubscribed: (track, ...otherArgs) => {
          const element = remoteParticipantAudioRef.current;
          if (track.kind !== "audio" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);
          if (source === TrackSource.Screen && remoteParticipant) {
            setRemoteScreenShareParticipant(undefined);
            setRemoteScreensShareAudioTrack(undefined);
          }
          if (source === TrackSource.Microphone) {
            track.detach(element);
            element.srcObject = null;
            onTrackUnsubscribed?.(track, ...otherArgs);
            setAudioTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isAudioMuted: true,
              },
            }));
          }

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant audio track unsubscribed",
            },
          );
        },
      };

      // Attach already published tracks
      for (const trackPublication of remoteParticipant.audioTracks.values()) {
        if (!trackPublication.isSubscribed) continue;
        const track = trackPublication.track;
        if (!track) continue;
        eventToCallbackMap.trackSubscribed(
          track,
          trackPublication,
          remoteParticipant,
        );
        break;
      }

      Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
        remoteParticipant.on(event, callback);
      });

      return function cleanup() {
        if (!remoteParticipant) return;
        Object.entries(eventToCallbackMap).forEach(([event, callback]) => {
          remoteParticipant.off(event, callback);
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      remoteParticipant,
      remoteParticipantAudioRef,
      onTrackSubscribed,
      onTrackUnsubscribed,
    ],
  );

  useEffect(
    function useDesignatedOutputDevice() {
      if (!remoteParticipantAudioRef.current || !currentOutputDeviceId) return;
      //@ts-ignore setSinkId is technically experimental
      remoteParticipantAudioRef.current?.setSinkId?.(currentOutputDeviceId);
    },
    [remoteParticipantAudioRef, currentOutputDeviceId],
  );

  return remoteParticipantAudioRef;
}

// V2

const audioTrackEventMutex = new Mutex();

function useGetRemoteAudioTrackEventListeners({
  getAudioElement,
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
}: UseRemoteAudioProps & {
  getAudioElement: () => HTMLAudioElement | null;
}) {
  const setRemoteScreenShareParticipant = useSetAtom(
    remoteScreenShareParticipantAtom,
  );
  const setRemoteScreensShareAudioTrack = useSetAtom(
    remoteScreenShareAudioTrackAtom,
  );
  const { detectRestrictedAudioPlaybackTimeout } =
    useShowRestrictedAudioPlaybackToastCallback();
  const setAudioTrackState = useSetAtom(trackStatusByParticipantIdAtom);
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);

  return useMemo(
    () => {
      if (!remoteParticipant) return;
      return gracefullyHandleTrackEvents(audioTrackEventMutex, {
        trackSubscribed: (track, ...otherArgs) => {
          const element = getAudioElement();
          if (track.kind !== "audio" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);
          if (source === TrackSource.Screen && remoteParticipant) {
            // TODO: We should be able to get rid of setting the screen share track
            setRemoteScreensShareAudioTrack(track);
            setRemoteScreenShareParticipant(remoteParticipant);
          }
          if (source === TrackSource.Microphone) {
            track.attach(element);
            detectRestrictedAudioPlaybackTimeout(track, element, 300);
            onTrackSubscribed?.(track, ...otherArgs);
            setAudioTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isAudioMuted: !track.isEnabled,
              },
            }));
          }

          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant audio track subscribed",
            },
          );
        },
        trackEnabled: (track) => {
          if ((track as unknown as RemoteTrackPublication).kind !== "audio")
            return;
          setAudioTrackState((prev) => ({
            ...prev,
            [remoteParticipant.identity]: {
              ...prev[remoteParticipant.identity],
              isAudioMuted: false,
            },
          }));
        },
        trackUnsubscribed: (track, ...otherArgs) => {
          const element = getAudioElement();
          if (track.kind !== "audio" || !element) return;
          const { source } = parseTrackName(track.name, track.kind);
          if (source === TrackSource.Screen && remoteParticipant) {
            setRemoteScreenShareParticipant(undefined);
            setRemoteScreensShareAudioTrack(undefined);
          }
          if (source === TrackSource.Microphone) {
            track.detach(element);
            element.srcObject = null;
            onTrackUnsubscribed?.(track, ...otherArgs);
            setAudioTrackState((prev) => ({
              ...prev,
              [remoteParticipant.identity]: {
                ...prev[remoteParticipant.identity],
                isAudioMuted: true,
              },
            }));
          }

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant audio track unsubscribed",
            },
          );
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getAudioElement,
      onTrackSubscribed,
      onTrackUnsubscribed,
      remoteParticipant,
    ],
  );
}

export function useRemoteAudioV2({
  remoteParticipant,
  ...otherProps
}: UseRemoteAudioProps) {
  const remoteParticipantAudioRef = useRef<HTMLAudioElement>(null);
  const getAudioElement = useCallback(
    () => remoteParticipantAudioRef.current,
    [],
  );
  const currentOutputDeviceId = useAtomValue(currentOutputDeviceIdAtom);
  const remoteTrackEventListeners = useGetRemoteAudioTrackEventListeners({
    ...otherProps,
    remoteParticipant,
    getAudioElement,
  });

  useRemoteTrackEventListeners({
    trackKind: "audio",
    remoteParticipant,
    remoteTrackEventListeners,
  });

  useEffect(
    function useDesignatedOutputDevice() {
      if (!remoteParticipantAudioRef.current || !currentOutputDeviceId) return;
      //@ts-ignore setSinkId is technically experimental
      remoteParticipantAudioRef.current?.setSinkId?.(currentOutputDeviceId);
    },
    [remoteParticipantAudioRef, currentOutputDeviceId],
  );

  return remoteParticipantAudioRef;
}
