import { useEffect, useState } from "react";
import { localAudioTrackAtom } from "./state";
import { useAtomValue } from "jotai";
import { deviceIsIOS } from "../utils";

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;

export function setVolumeWithAnalyser(
  analyser: AnalyserNode,
  setVolume: (volume: number) => void,
) {
  const sampleArray = new Uint8Array(analyser.frequencyBinCount);

  const interval = setInterval(() => {
    analyser.getByteFrequencyData(sampleArray);
    let values = 0;

    const length = sampleArray.length;
    for (let i = 0; i < length; i++) {
      values += sampleArray[i];
    }

    // consider Math.round here to cut down on the number of state updates
    const averageValue = values / length;

    // sets volume as number between 0 and 255 (but unlikely to reach 255)
    setVolume(averageValue);
  }, 200);

  return function cleanUp() {
    setVolume(0);
    clearInterval(interval);
  };
}

export function initializeAnalyser(stream: MediaStream) {
  const audioContext = new AudioContext(); // Create a new audioContext for each audio indicator
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.5;
  analyser.fftSize = 256;

  audioSource.connect(analyser);

  // Here we provide a way for the audioContext to be closed.
  // Closing the audioContext allows the unused audioSource to be garbage collected.
  stream.addEventListener("cleanup", () => {
    if (audioContext.state !== "closed") {
      audioContext.close();
    }
  });

  return analyser;
}

type SmoothingFunction = (volume: number) => number;
/**
 * Be sure to call this function in a static space, not at render time, or the
 * function will be recreated on every render, causing extra renders (and
 * possibly errors!)
 * @param options.minVolume the minimum volume to return
 * @param options.maxVolume the maximum volume to return
 * @param options.asInteger whether to return the volume as an integer
 * @param options.isLogarithmic whether to use a logarithmic scale
 * @param options.uiScaleFactor a factor to scale the volume by
 * @returns a Smoothing Function used to reduce renders while updating volume
 */
export function getSmoothingFunction({
  minVolume = 0,
  maxVolume = 5,
  asInteger = true,
  isLogarithmic = true,
  uiScaleFactor = 1.0,
} = {}): SmoothingFunction {
  return function smoothingFunction(volume0to255: number) {
    let scaledValue: number;

    if (isLogarithmic) {
      scaledValue = Math.log10(volume0to255) / Math.log10(255);
    } else {
      scaledValue = volume0to255 / 255;
    }

    let normalizedVolume = Math.min(
      1,
      Math.max(0, scaledValue * uiScaleFactor),
    );

    normalizedVolume = Math.min(
      maxVolume,
      Math.max(minVolume, normalizedVolume * (maxVolume - minVolume)),
    );

    if (asInteger) normalizedVolume = Math.round(normalizedVolume);

    return normalizedVolume;
  };
}

const getInputVolumeDefaultSmoothingFunction = getSmoothingFunction();
export function useGetInputVolume({
  smoothingFunction = getInputVolumeDefaultSmoothingFunction,
}: {
  smoothingFunction?: SmoothingFunction;
} = {}) {
  const isIOS = deviceIsIOS();

  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const [inputVolume, setInputVolume] = useState<number>(0);
  const audioTrack = useAtomValue(localAudioTrackAtom);
  const mediaStreamTrack = audioTrack?.track?.mediaStreamTrack;

  useEffect(() => {
    if (!mediaStreamTrack) {
      return;
    }

    const newMediaStream = new MediaStream([
      isIOS ? mediaStreamTrack.clone() : mediaStreamTrack,
    ]);

    setAnalyser(initializeAnalyser(newMediaStream));
  }, [mediaStreamTrack, isIOS]);

  useEffect(() => {
    if (analyser) {
      return setVolumeWithAnalyser(analyser, setInputVolume);
    }
  }, [analyser]);

  return smoothingFunction(inputVolume);
}
