import { useEffect, useRef } from "react";
import { useAtomValue } from "jotai";
import { localScreenShareTrackAtom } from "..";
import { ScreenShareVideo } from "./ScreenShareVideo";

export function LocalScreenShare() {
  const screenShareRef = useRef<HTMLVideoElement>(null);
  const localScreenShare = useAtomValue(localScreenShareTrackAtom);

  useEffect(
    function handleScreenShareTrackAttachment() {
      const element = screenShareRef.current;
      if (!element) return;

      if (localScreenShare) {
        element.srcObject = new MediaStream([localScreenShare]);
        element.play();
      } else {
        element.srcObject = null;
      }
    },
    [localScreenShare, screenShareRef],
  );

  return <ScreenShareVideo screenShareRef={screenShareRef} />;
}
