import { gql, useQuery } from "@apollo/client";
import { useAtomValue, useSetAtom } from "jotai";
import { visitorClientShortIdAtom, visitorMeasuresAtom } from "../state";
import { useParams } from "react-router-dom";
import { Server } from "../../apollo/types";
import { MICTypes } from "@grow-therapy-team/sprout-ui";

// TODO: Move this into the shared MICTypes file in Sprout
// Copied from the monolith generated response type
export type OutstandingMeasuresResponse = {
  outstandingMeasures?: Array<{
    __typename?: "OutstandingMeasureObject";
    clientUserAnsweredBefore?: boolean | null;
    schema: {
      __typename?: "MeasureSchemaObject";
      measureSchemaShortId: string;
      type: MICTypes.MeasureSchemaType;
      isProviderSpecific: boolean;
      sections?: Array<{
        __typename?: "MeasureSchemaSectionObject";
        key: string;
        visibleConditionName?: MICTypes.MeasureSchemaSectionVisibleCondition | null;
        fields?: Array<{
          __typename?: "MeasureSchemaFieldObject";
          key: string;
          visibleConditionName?: MICTypes.MeasureSchemaSectionVisibleCondition | null;
          options?: Array<{
            __typename?: "MeasureSchemaFieldOptionObject";
            score: number;
            key: string;
          }> | null;
        }> | null;
      }> | null;
    };
    providers?: Array<{
      __typename?: "PublicProviderObject";
      id: string;
      shortId: string;
      thumbnailUrl: string;
      name?: string | null;
    }> | null;
  }> | null;
  outstandingMeasureBundles?: Array<{
    __typename?: "PublicMeasureBundleObject";
    id: string;
    measureBundleShortId: string;
  }> | null;
};

export const GET_CLIENT_USER_PAPERWORK = gql`
  query GetOutstandingMeasures($clientUserShortId: ID!, $providerShortId: ID) {
    outstandingMeasures(
      clientUserShortId: $clientUserShortId
      providerShortId: $providerShortId
    ) {
      schema {
        measureSchemaShortId
        type
        isProviderSpecific
        sections {
          key
          visibleConditionName
          fields {
            key
            visibleConditionName
            options {
              score
              key
            }
          }
        }
      }
      clientUserAnsweredBefore
      providers {
        id
        shortId
        thumbnailUrl
        name
      }
    }
    outstandingMeasureBundles(clientUserShortId: $clientUserShortId) {
      id
      measureBundleShortId
    }
  }
`;

export function useGetClientUserPaperwork() {
  const clientUserShortId = useAtomValue(visitorClientShortIdAtom);
  const { providerShortId } = useParams() as { providerShortId: string };

  const setVisitorMeasures = useSetAtom(visitorMeasuresAtom);

  return useQuery<OutstandingMeasuresResponse>(GET_CLIENT_USER_PAPERWORK, {
    // providerShortId: while not strictly required, omitting will return _all_ measures
    // rather than the ones for a specific patient-provider relationship
    skip: !clientUserShortId || !providerShortId,
    variables: { clientUserShortId, providerShortId },
    context: { server: Server.MONOLITH, skipAuth: true },
    onCompleted(data) {
      setVisitorMeasures(data.outstandingMeasures || []);
    },
  });
}
