import { useAuth0 } from "@auth0/auth0-react";

export enum LoginRequiredErrors {
  INVALID_GRANT = "invalid_grant",
  LOGIN_REQUIRED = "login_required",
  MISSING_REFRESH_TOKEN = "missing_refresh_token",
}

export type GetAccessTokenSilently = ReturnType<
  typeof useAuth0
>["getAccessTokenSilently"];
export type AuthError = { error: string; error_description: string };

export type JwtPayload = {
  sub: string;
  iat: number;
  exp: number;
  iss: string;
  grants: Record<string, unknown>;
};
