import { differenceInSeconds, format } from "date-fns";
import { useEffect } from "react";
import { useCountdown as useCountdownBase } from "usehooks-ts";

export function useCountdown({
  deadline,
  earlyTerminateCallback,
  onFinish,
  formatString = "mm:ss",
}: {
  deadline: Date | undefined | null;
  earlyTerminateCallback?: () => boolean;
  onFinish?: () => void;
  formatString?: string;
}) {
  const countStart = deadline ? differenceInSeconds(deadline, new Date()) : -1;
  const [counter, { startCountdown, resetCountdown }] = useCountdownBase({
    countStart,
  });
  useEffect(
    function checkForNewDeadline() {
      if (deadline) {
        resetCountdown();
        startCountdown();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deadline],
  );

  useEffect(
    function checkIfFinished() {
      if (counter === 0 || earlyTerminateCallback?.()) {
        onFinish?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [counter],
  );

  if (!deadline) {
    return null;
  }

  return format(counter * 1000, formatString);
}
