import { LogsEvent, datadogLogs } from "@datadog/browser-logs";
import { flatten } from "flat";
import type { LogsInitConfiguration } from "@datadog/browser-logs";
import type { RumInitConfiguration } from "@datadog/browser-rum";
import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum";
import { getDataDogConfig } from "./config";
import {
  ABORT_ERROR,
  MISSING_AUTH_HEADER_ERROR_MESSAGE,
} from "../apollo/utils";
import {
  FAILED_TO_REAQUIRE_TRACK_ERROR_MESSAGE,
  FAILED_TO_RESTART_TRACK_ERROR_MESSAGE,
} from "../twilio/tracks";
import { WEBGL_EXECUTION_ERROR } from "../twilio/processors";

const OMITTABLE_MESSAGES = [
  ABORT_ERROR.toLowerCase(), // Aborted requests
  MISSING_AUTH_HEADER_ERROR_MESSAGE.toLowerCase(), // Unauthed requests
  "fullstory", // FullStory errors
  WEBGL_EXECUTION_ERROR.toLowerCase(), // WebGL execution errors
  // Twilio console.error messages due to insufficient permissions
  FAILED_TO_REAQUIRE_TRACK_ERROR_MESSAGE.toLowerCase(),
  FAILED_TO_RESTART_TRACK_ERROR_MESSAGE.toLowerCase(),
];

function shouldOmitLog(event: LogsEvent) {
  if (event.status === "debug") return true;
  const eventStr = `${event.message}${event?.error?.stack}`.toLowerCase();
  return OMITTABLE_MESSAGES.some((omittable) => eventStr.includes(omittable));
}

function initLogs() {
  const datadogConfig = getDataDogConfig();

  if (!datadogConfig) return;

  const logConfig = datadogConfig as LogsInitConfiguration;

  datadogLogs.init({
    forwardErrorsToLogs: true,
    sessionSampleRate: 100, // Sample rate must be between 0 and 100
    beforeSend(event) {
      if (shouldOmitLog(event)) return false;
      return true;
    },
    ...logConfig,
  });
}

export function stopRum() {
  if (!getDataDogConfig()) return;
  datadogRum.stopSessionReplayRecording();
}

export function startRum() {
  if (!getDataDogConfig()) return;
  datadogRum.startSessionReplayRecording();
}

function initRum() {
  const datadogConfig = getDataDogConfig();

  if (!datadogConfig) return;

  const rumConfig = datadogConfig as RumInitConfiguration;

  datadogRum.init({
    beforeSend: (_event, _context) => {
      // TODO: Redact any PII/PHI from the event here
    },
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    sessionSampleRate: 100, // Sample rate must be between 0 and 100
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    ...rumConfig,
  });
  startRum();
}

export function initDataDog() {
  initLogs();
  initRum();
}

export function setUserId(userId: string) {
  if (!getDataDogConfig()) return;
  datadogRum.setUser({ id: userId });
}

export function setUserVars(userVars: Record<string, unknown>) {
  if (!getDataDogConfig()) return;
  Object.entries(flatten(userVars) as Record<string, unknown>).forEach(
    ([key, value]) => datadogRum.setUserProperty(key, value),
  );
}
