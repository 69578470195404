import { useCallback } from "react";
import { isRecordingAtom } from "../../twilio/messages/state";
import { useSetAtom } from "jotai";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";
import { useAtomCallback } from "jotai/utils";

export function useToggleRecordingCallback() {
  const getIsRecording = useAtomCallback(
    useCallback((get) => get(isRecordingAtom), []),
  );
  const setIsRecording = useSetAtom(isRecordingAtom);
  const sendParticipantState = useSendVisitorParticipantStateCallback();

  return useCallback(
    (shouldRecord?: boolean) => {
      setIsRecording({
        value: shouldRecord ?? !getIsRecording(),
        lastUpdated: Date.now(),
      });
      sendParticipantState();
    },
    [getIsRecording, sendParticipantState, setIsRecording],
  );
}
