import { StatusDot, StatusDotUse } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";

export function VisualIndicator({ className }: { className?: string }) {
  return (
    <StatusDot
      use={StatusDotUse.Success}
      className={twMerge("bg-lilac-400", className)}
    />
  );
}
