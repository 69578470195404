import {
  Button,
  ButtonSize,
  Card,
  IconButton,
  InputControl,
  PhoneNumberInput,
  Text,
  TextInput,
} from "@grow-therapy-team/sprout-ui";
import classNames from "classnames";
import type { ProfessionResourceValueQuestionProps } from "./types";
import {
  ContactValue,
  ProfessionalResourceValue,
  ValidationError,
} from "../types";
import { useState } from "react";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

function getEmptyProfessionalResourceValue(): ProfessionalResourceValue {
  return {
    address: "",
    phoneNumber: "",
    resources: [{ name: "", phoneNumber: "" }],
  };
}

/**
 * Ported over from
 * https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/apps/csr-app/src/app/components/ProfessionalResourceInput.tsx#L17-L18
 */
export default function ProfessionalResourceInput({
  value: initialValue,
  onChange: givenOnChange,
  question,
  validationErrors,
}: ProfessionResourceValueQuestionProps): JSX.Element {
  const [value, setValue] = useState<ProfessionalResourceValue>(
    initialValue ?? getEmptyProfessionalResourceValue(),
  );
  const isFormIncomplete = validationErrors?.has(
    ValidationError.INCOMPLETE_FORM,
  );

  const onChange = (newValue: ProfessionalResourceValue) => {
    setValue(newValue);
    givenOnChange?.(question, newValue);
  };

  const onChangeContactProperty = (
    newValue: string,
    index: number,
    property: keyof ContactValue,
  ) => {
    const updatedContact = { ...value.resources[index] };
    updatedContact[property] = newValue;
    const updatedResources = [...value.resources];
    updatedResources[index] = updatedContact;
    onChange({ ...value, resources: updatedResources });
  };

  const removeResource = (index: number) => {
    value.resources = [
      ...value.resources.slice(0, index),
      ...value.resources.slice(index + 1),
    ];
    onChange?.(value);
  };

  const shouldShowError = (value: string): string => {
    return isFormIncomplete && !value ? "This field is required" : "";
  };

  return (
    <>
      <Card className="border border-neutral-400 sm:p-5">
        <Text variant="sm" className="pb-4 font-semibold rebrand:font-medium">
          988 Suicide & Crisis Prevention Lifeline
        </Text>
        <Text>
          Call or text{" "}
          <span className="font-semibold rebrand:font-medium">988</span> (free
          confidential support available 24/7)
        </Text>
      </Card>
      <Card className="border border-neutral-400 sm:p-5">
        <p className="pb-4 text-sm font-semibold rebrand:font-medium">
          Local urgent or emergency care services
        </p>
        <InputControl
          error={shouldShowError(value.address)}
          label="Address"
          labelClassName="text-base"
          errorClassName="text-base"
        >
          <TextInput
            data-testid="professional-resource-address-input"
            value={value.address}
            onChange={(e) => {
              onChange?.({ ...value, address: e.target.value });
            }}
            placeholder="123 Alphabet St, San Francisco CA 94105"
          />
        </InputControl>
        <InputControl
          error={shouldShowError(value.phoneNumber)}
          label="Phone number"
          labelClassName="text-base"
          errorClassName="text-base"
        >
          <PhoneNumberInput
            id="professional-resource-phone-number-input"
            name={`${name}-phone-number`}
            className="fs-exclude"
            value={value.phoneNumber}
            placeholder="e.g. (123) 456-7890"
            onChange={(phoneNumber) => {
              onChange?.({ ...value, phoneNumber });
            }}
          />
        </InputControl>
      </Card>
      {value.resources?.map((resource: ContactValue, index: number) => (
        <Card
          key={`${index}-resource`}
          className="relative mb-2 rounded-lg border border-neutral-400 sm:p-5"
        >
          <>
            <InputControl
              error={shouldShowError(resource.name)}
              label="Name"
              labelClassName="text-base"
              errorClassName="text-base"
            >
              <TextInput
                name={`${name}-name-${index}}`}
                className="fs-exclude"
                value={resource.name}
                placeholder="e.g. John Smith"
                id={`resource-name-${index}`}
                onChange={(e) => {
                  onChangeContactProperty(e.target.value, index, "name");
                }}
              />
            </InputControl>
            <InputControl
              error={shouldShowError(resource.phoneNumber)}
              label="Phone number"
              labelClassName="text-base"
              errorClassName="text-base"
            >
              <PhoneNumberInput
                name={`${name}-phone-number-${index}`}
                id={`resource-phone-${index}`}
                className="fs-exclude"
                value={resource.phoneNumber}
                placeholder="e.g. (123) 456-7890"
                onChange={(phoneNumber) => {
                  onChangeContactProperty(phoneNumber, index, "phoneNumber");
                }}
              />
            </InputControl>
            {value.resources.length > 1 && (
              <IconButton
                aria-label="Remove resource"
                data-testid="remove-resource-button"
                iconDefinition={faXmark}
                onClick={() => removeResource(index)}
                size={ButtonSize.Small}
                className={classNames("absolute right-1 top-1 no-underline")}
                use="link"
              />
            )}
          </>
        </Card>
      ))}
      <Button
        data-testid="add-additional-professional-resource-button"
        className="ml-0 mt-2"
        onClick={() => {
          value.resources = value.resources.concat(
            getEmptyProfessionalResourceValue().resources[0],
          );
          onChange?.(value);
        }}
        use="secondary"
      >
        Add resource
      </Button>
    </>
  );
}
