import classNames from "classnames";
import { ReactNode } from "react";
import { FormListItemBadgeVariant } from "./types";

export function FormListItemBadge({
  children,
  className,
  variant,
}: {
  children: ReactNode;
  className?: string | Record<string, boolean>;
  variant?: FormListItemBadgeVariant;
}) {
  return (
    <div
      className={classNames(
        "flex h-12 max-h-[3rem] w-16 max-w-[4rem] items-center justify-center rounded font-bold rebrand:text-neutral_rebrand-800",
        {
          "bg-avocado-100 rebrand:bg-green-100":
            variant === FormListItemBadgeVariant.Low,
          "bg-ruby-100 rebrand:bg-coral-300 rebrand:text-neutral_rebrand-800":
            variant === FormListItemBadgeVariant.High,
          "bg-lilac-100 rebrand:bg-lilac-100":
            variant === FormListItemBadgeVariant.Task,
        },
        className,
      )}
    >
      {" "}
      <div className="flex-row text-center leading-tight">{children}</div>
    </div>
  );
}
