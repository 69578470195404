import {
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  MenuDivider,
  Tag,
  TagUse,
} from "@grow-therapy-team/sprout-ui";
import {
  faEllipsis,
  faGear,
  faMemo,
  faMessage,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { deviceIsMobile } from "../utils";
import { ScreenShareMenuItems } from "../twilio/audio-video-controls/ScreenShareControl";
import useScreenShareCallbacks from "../twilio/audio-video-controls/useScreenShareCallbacks";
import { TakeOverScreenShareModal } from "../twilio/audio-video-controls/TakeOverScreenShareModal";
import { ControlPanelButton } from "./ControlPanelButton";
import { DrawerState } from "../provider/state";
import {
  getIsClientInfoDrawerOpen,
  getIsClientListDrawerOpen,
} from "../provider/utils";
import { VisitorDrawerState } from "../visitor/state";
import { getIsAnyChatDrawerOpen, getIsSettingsDrawerOpen } from "./utils";

export function AdditionalOptionsMenu({
  drawerId,
  drawerState,
  isInSession,
  onClickSettingsButton,
  onClickChatButton,
  unreadMessageCount,
  isChatButtonDisabled,
  onClickClientInformationButton,
  onClickClientsButton,
  numberOfWaitingClients,
  isScreenSharing,
  isSomeoneElseSharing,
  hasNewClientInfo,
}: {
  drawerId: string;
  drawerState: DrawerState | VisitorDrawerState | null;
  isInSession: boolean;
  onClickSettingsButton: () => void;
  onClickChatButton: () => void;
  unreadMessageCount: number;
  isChatButtonDisabled?: boolean;
  onClickClientInformationButton?: () => void;
  onClickClientsButton?: () => void;
  isScreenSharing: boolean;
  isSomeoneElseSharing: boolean;
  hasNewClientInfo?: boolean;
  numberOfWaitingClients?: number;
}) {
  const [isScreenShareModalOpen, setIsScreenshareModalOpen] =
    useState<boolean>(false);
  const shouldShowVisualIndicator =
    !!unreadMessageCount || hasNewClientInfo || !!numberOfWaitingClients;

  const { triggerBrowserScreenShare, stopScreenShare } =
    useScreenShareCallbacks();
  const onShare = triggerBrowserScreenShare;
  const onStopShare = stopScreenShare;

  const isChatDrawerOpen = getIsAnyChatDrawerOpen(drawerState);
  const isClientInfoDrawerOpen = getIsClientInfoDrawerOpen(
    // This state and check are only relevant for the provider
    drawerState as DrawerState | null,
  );
  const shouldShowScreenShareControl = isInSession && !deviceIsMobile();

  const isClientListDrawerOpen = getIsClientListDrawerOpen(
    // This state and check are only relevant for the provider
    drawerState as DrawerState | null,
  );
  const isSettingsDrawerOpen = getIsSettingsDrawerOpen(drawerState);

  const onCloseModal = () => setIsScreenshareModalOpen(false);
  const onClickShare = () =>
    isSomeoneElseSharing ? setIsScreenshareModalOpen(true) : onShare();

  const onConfirmShare = () => {
    setIsScreenshareModalOpen(false);
    onShare();
  };

  return (
    <div>
      <TakeOverScreenShareModal
        isModalOpen={isScreenShareModalOpen}
        onCloseModal={onCloseModal}
        onConfirmShare={onConfirmShare}
      />
      <Menu
        placement="bottom-left"
        menuClassName="translate-x-[calc(50%-1.5rem)] -translate-y-[calc(100%+3.875rem)]"
        buttonClassName="p-0 md:hidden"
        toggleButton={
          <MenuToggleButton visibleCaret={false}>
            <ControlPanelButton
              // Since MenuToggleButton itself is a button, we remove the inner
              // button from the tab order to prevent double-focusing.
              tabIndex={-1}
              showVisualIndicator={shouldShowVisualIndicator}
              aria-label="View additional options"
              iconDefinition={faEllipsis}
            />
          </MenuToggleButton>
        }
      >
        {shouldShowScreenShareControl && (
          <ScreenShareMenuItems
            isSharing={isScreenSharing}
            onShare={onClickShare}
            onStopShare={onStopShare}
          />
        )}
        {isScreenSharing && <MenuDivider />}
        {!!onClickClientsButton && (
          <MenuItem
            aria-controls={drawerId}
            aria-expanded={isClientListDrawerOpen}
            onClick={onClickClientsButton}
            className="flex items-center gap-2"
          >
            <Icon aria-hidden icon={faUser} className="h-4 w-4" />
            <span className="sr-only">
              {isClientListDrawerOpen ? "Close" : "Open"} clients
              {!!numberOfWaitingClients && ", "}
            </span>
            <span aria-hidden>Clients</span>
            {!!numberOfWaitingClients && (
              <>
                <Tag use={TagUse.Lilac}>{numberOfWaitingClients}</Tag>
                <span className="sr-only">waiting</span>
              </>
            )}
          </MenuItem>
        )}
        <MenuItem
          aria-controls={drawerId}
          aria-expanded={isChatDrawerOpen}
          className="flex items-center gap-2"
          onClick={onClickChatButton}
          disabled={isChatButtonDisabled}
        >
          <Icon aria-hidden icon={faMessage} className="h-4 w-4" />
          <span className="sr-only">
            {isChatDrawerOpen ? "Close" : "Open"} chat,
          </span>
          <span aria-hidden>Chat</span>
          {unreadMessageCount ? (
            <Tag use={TagUse.Lilac}>{unreadMessageCount}</Tag>
          ) : (
            <span className="sr-only">0</span>
          )}
          <span className="sr-only">
            unread {unreadMessageCount === 1 ? "message" : "messages"}
          </span>
        </MenuItem>
        <MenuItem
          aria-controls={drawerId}
          aria-expanded={isSettingsDrawerOpen}
          onClick={onClickSettingsButton}
          className="flex items-center gap-2"
        >
          <Icon aria-hidden icon={faGear} className="h-4 w-4" />
          <span className="sr-only">
            {isSettingsDrawerOpen ? "Close" : "Open"} settings
          </span>
          <span aria-hidden>Settings</span>
        </MenuItem>

        {!!onClickClientInformationButton && (
          <MenuItem
            aria-controls={drawerId}
            aria-expanded={isClientInfoDrawerOpen}
            onClick={onClickClientInformationButton}
            className="flex items-center gap-2"
          >
            <Icon aria-hidden icon={faMemo} className="h-4 w-4" />
            <span className="sr-only">
              {isClientInfoDrawerOpen ? "Close" : "Open"} client information
            </span>
            <span aria-hidden>Client info</span>
            {hasNewClientInfo && (
              <Tag aria-label="new info available" use={TagUse.Lilac}>
                New
              </Tag>
            )}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
