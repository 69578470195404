import { gql, useMutation as useMutationHook } from "@apollo/client";

const SYNC_CLIENT_MOOD_GQL = gql`
  mutation RecordClientMood($visitorId: String!, $emotions: [String!]!) {
    syncClientMood(visitorId: $visitorId, emotions: $emotions) {
      recordedAt
    }
  }
`;

// placeholder type that I'd love to turn into a string template for more
// confident parsing of date strings
type ISO8601Timestamp = string;
type SyncClientMoodMutationData = {
  syncClientMood: {
    __typename: "RecordedMeasure";
    recordedAt?: ISO8601Timestamp;
    emotions?: string[];
  };
};
type SyncClientMoodMutationVariables = {
  visitorId: string;
  emotions: string[];
};

const useMutation = useMutationHook<
  SyncClientMoodMutationData,
  SyncClientMoodMutationVariables
>;

export function useSyncClientMoodMutation(
  defaultOptions?: Parameters<typeof useMutation>[1],
) {
  return useMutation(SYNC_CLIENT_MOOD_GQL, {
    context: { skipAuth: true },
    ...defaultOptions,
  });
}
