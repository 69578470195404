import {
  DrawerHeader as BaseDrawerHeader,
  Heading,
} from "@grow-therapy-team/sprout-ui";
import { ReactNode } from "react";

type DrawerHeaderProps = Omit<
  Parameters<typeof BaseDrawerHeader>[0],
  "children"
>;
type HeadingProps = Omit<Parameters<typeof BaseDrawerHeader>[0], "children">;

export function DrawerHeader({
  children,
  drawerHeaderProps,
  headingProps,
}: {
  children: ReactNode;
  drawerHeaderProps?: DrawerHeaderProps;
  headingProps?: HeadingProps;
}) {
  return (
    <BaseDrawerHeader {...drawerHeaderProps}>
      <Heading as="h4" {...headingProps}>
        {children}
      </Heading>
    </BaseDrawerHeader>
  );
}
