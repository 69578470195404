import { useLayer } from "statsig-react";

import { EnhancedPageContentWrapper as EnhancedPageContent } from "./EnhancedPageContent";
import { LoadingPageContent } from "./LoadingPageContent";
import { MinimalPageContentWrapper as MinimalPageContent } from "./MinimalPageContent";
import { ExperimentLayers } from "../../../statsig/experiments";

export type PageContentProps = {
  isLoading: boolean;
  clientIntroStateV2IsEnabled: boolean;
  className?: string;
  minimalPageContentComponent: typeof MinimalPageContent;
  drawerId: string;
};
export function PageContentComponent({
  isLoading,
  clientIntroStateV2IsEnabled,
  className,
  minimalPageContentComponent: MinimalPageContentComponent,
  drawerId,
}: PageContentProps) {
  if (isLoading) return <LoadingPageContent />;

  if (clientIntroStateV2IsEnabled)
    return <EnhancedPageContent className={className} drawerId={drawerId} />;

  return <MinimalPageContentComponent className={className} />;
}

export type PageContentWrapperProps = { className?: string; drawerId: string };

export function PageContent({ className, drawerId }: PageContentWrapperProps) {
  const [layerName, layerConfig] = ExperimentLayers.ClientWaitingRoom;
  const { isLoading, layer } = useLayer(layerName);
  const shouldShowAVSetup = layer.get<boolean>(
    layerConfig.ShouldShowAvSetup,
    false,
  );

  return (
    <PageContentComponent
      isLoading={isLoading}
      clientIntroStateV2IsEnabled={shouldShowAVSetup}
      className={className}
      minimalPageContentComponent={MinimalPageContent}
      drawerId={drawerId}
    />
  );
}
