import { GroupedFormData } from "./types";
import { FormListItem } from "./form-list-item";
import { useOpenClientFormsCallback } from "./useOpenClientFormsCallback";

type FormEntryProps = {
  form: GroupedFormData;
};

export function FormEntry({ form }: FormEntryProps) {
  const openClientForms = useOpenClientFormsCallback();

  const onClickForm = () => {
    openClientForms(form.formTitle);
  };
  return <FormListItem form={form} onClick={onClickForm} />;
}
