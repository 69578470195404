import { RemoteParticipant } from "twilio-video";
import { TrackEvent, TrackKind, TrackSubscriptionHandler } from "./types";
import { useEffect } from "react";

export type RemoteTrackEventListeners = Partial<
  Record<TrackEvent, TrackSubscriptionHandler>
>;

function trackKindToTracksKey(trackKind: TrackKind) {
  switch (trackKind) {
    case "audio":
      return "audioTracks";
    case "video":
      return "videoTracks";
    case "data":
      return "dataTracks";
    default:
      return null;
  }
}

/**
 * Attaches and detaches event listeners to a remote participant for track
 * events. It also handles already published tracks.
 */
export function useRemoteTrackEventListeners({
  trackKind,
  remoteParticipant,
  remoteTrackEventListeners,
}: {
  trackKind: TrackKind;
  remoteParticipant?: RemoteParticipant;
  remoteTrackEventListeners?: RemoteTrackEventListeners;
}) {
  useEffect(
    function handleRemoteParticipantTrackEvents() {
      const tracksKey = trackKindToTracksKey(trackKind);
      if (!remoteParticipant || !remoteTrackEventListeners || !tracksKey)
        return;

      // Attach listeners
      Object.entries(remoteTrackEventListeners).forEach(([event, callback]) => {
        remoteParticipant.on(event, callback);
      });

      // Handle already published tracks
      for (const trackPublication of remoteParticipant[tracksKey].values()) {
        if (!trackPublication.isSubscribed) continue;
        const track = trackPublication.track;
        if (!track) continue;
        remoteTrackEventListeners.trackSubscribed?.(
          track,
          trackPublication,
          remoteParticipant,
        );
        break;
      }

      return function removeListeners() {
        if (!remoteParticipant) return;
        Object.entries(remoteTrackEventListeners).forEach(
          ([event, callback]) => {
            remoteParticipant.off(event, callback);
          },
        );
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteParticipant, remoteTrackEventListeners, trackKind],
  );
}
