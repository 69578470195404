import { localDataTrackAtom } from "../state";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { dataTrackStateAtom } from "./state";
import { DataTrackSender } from "..";
import { DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS } from "../../config";
import { debounce } from "../../utils";

const sendDebouncedLoggingEvents = debounce(
  sendLoggingEvents,
  DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS,
);

export const useSendParticipantStateCallback = () => {
  const getDataTrack = useAtomCallback(
    useCallback((get) => get(localDataTrackAtom), []),
  );

  const getDataTrackState = useAtomCallback(
    useCallback((get) => get(dataTrackStateAtom), []),
  );

  return useCallback(
    (senderIdentity: DataTrackSender) => {
      const participantState = getDataTrackState();

      const formattedMessage = {
        participantState,
        sender: senderIdentity,
      };

      const localDataTrack = getDataTrack().track;
      if (localDataTrack) {
        localDataTrack.send(JSON.stringify(formattedMessage));
        sendDebouncedLoggingEvents(
          TrackingEvents.REMOTE_DATA_TRACK_MESSAGE_SENT,
          {
            isRecording: participantState.isRecording,
            patientConsent: participantState.patient.consent,
            providerConsent: participantState.provider.consent,
          },
          {
            logLevel: "info",
            message:
              "Local participant sent a message on the data track (DEBOUNCED)",
          },
        );
      }
    },
    [getDataTrack, getDataTrackState],
  );
};
