import { updateFaviconAndTitle } from "../../updateFaviconAndTitle";

export function setFaviconAndTitleBasedOnUnreadMessagesCount(
  unreadMessagesCount: number,
) {
  if (!unreadMessagesCount) return updateFaviconAndTitle();
  const unreadMessagesTitle = `(${unreadMessagesCount}) message${
    unreadMessagesCount > 1 ? "s" : ""
  }`;
  updateFaviconAndTitle("../../assets/favicon-alert.png", unreadMessagesTitle);
}
