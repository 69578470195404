import { useAtomValue } from "jotai";
import { visitorUuidAtom } from "./state";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { logger } from "../datadog/logger";

/**
 * Returns a concatenation of the visitor's UUID and the patient's short ID.
 *
 * Please use this hook to get the visitor ID. It should be used anywhere a
 * visitor ID is needed.
 *
 * Should be used on routes containing patientShortId.
 */
export function useVisitorId() {
  const visitorUuid = useAtomValue(visitorUuidAtom);
  const { patientShortId } = useParams();

  useEffect(
    function warnWhenMissingPatientShortId() {
      if (!patientShortId)
        logger.warn("Missing patientShortId", { visitorId: visitorUuid });
    },
    [patientShortId, visitorUuid],
  );

  return `${patientShortId}_${visitorUuid}`;
}
