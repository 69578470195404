import { useCallback } from "react";
import { DataTrackMessage } from "../../twilio/types";

import { Mutex } from "async-mutex";

import { useUpdateDataTrackStateCallback } from "./useUpdateDataTrackStateCallback";

const handleReceivedMessageMutex = new Mutex();

/**
 * Returns a callback that handles received messages on the data track for the
 * client
 */
export function useHandleReceivedMessageCallback() {
  const updateDataTrackState = useUpdateDataTrackStateCallback();

  return useCallback(
    (message: DataTrackMessage) => {
      return handleReceivedMessageMutex.runExclusive(async () => {
        updateDataTrackState(message);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateDataTrackState],
  );
}
