import { Mutex } from "async-mutex";
import {
  AudioEventData,
  AudioEventDataList,
  RecordingMetadata,
  RecordingState,
} from "../provider/session-recording/types";
import { RECORDED_AUDIO_TYPE } from "../provider/session-recording/constants";
import { secondsToMilliseconds } from "date-fns";

export const RECORDED_AUDIO_INTERVAL_MS = secondsToMilliseconds(30);

type RecordingResult = {
  audioBlob: Blob;
  audioURL: string;
  recordingMetadata: RecordingMetadata;
};

type OnIntervalProc = (recordingResult: RecordingResult) => void;

/**
 * Exposes methods to get and control audio recordings from a given media
 * stream. The instantiated object will maintain state related to recording
 * internally.
 *
 * Please stop recording before discarding the object.
 */
export class AudioRecorder {
  #audioEvents: AudioEventDataList = [];
  #controlRecordingMutex = new Mutex();
  #getRecordingMutex = new Mutex();
  #mediaRecorder: MediaRecorder;
  #recordingInterval: NodeJS.Timeout | null = null;
  #recordingMetadata: RecordingMetadata = {
    startTime: null,
    endTime: null,
  };

  constructor(sourceStream: MediaStream) {
    this.#mediaRecorder = this.#initMediaRecorder(sourceStream);
  }

  #clearAudioEvents() {
    this.#audioEvents = [];
  }

  #pushAudioEventData(eventData: AudioEventData) {
    this.#audioEvents.push(eventData);
  }

  #initMediaRecorder(sourceStream: MediaStream) {
    const mediaRecorder = new MediaRecorder(sourceStream);
    mediaRecorder.ondataavailable = (event) =>
      this.#pushAudioEventData(event.data);
    return mediaRecorder;
  }

  /**
   * Initializes an interval that periodically gets the current audio events and
   * passes it to the `onIntervalProc` callback if available. Does nothing if no
   * `onIntervalCallback` was provided.
   */
  #initRecordingInterval(onIntervalProc: OnIntervalProc) {
    this.#recordingInterval = setInterval(async () => {
      // Prevents multiple calls to getRecording from queuing up
      const isAlreadyGettingRecording = this.#getRecordingMutex.isLocked();
      const isRecording = this.getRecordingState() === RecordingState.RECORDING;
      if (isAlreadyGettingRecording || !isRecording) return;
      const recordingResult = await this.getRecording({
        shouldContinueRecordingAfter: true,
      });
      onIntervalProc(recordingResult);
    }, RECORDED_AUDIO_INTERVAL_MS);
  }

  /**
   * Clears the recording interval if it exists.
   */
  #clearRecordingInterval() {
    if (this.#recordingInterval) {
      clearInterval(this.#recordingInterval);
      this.#recordingInterval = null;
    }
  }

  getRecordingState() {
    return this.#mediaRecorder.state;
  }

  getRecordingMetadata() {
    return { ...this.#recordingMetadata };
  }

  getRecordingInterval() {
    return this.#recordingInterval;
  }

  /**
   * Starts recording audio and, if specified, kicks off an interval that
   * periodically gets recordings. Idempotent and thread safe.
   */
  startRecording({ onIntervalProc }: { onIntervalProc?: OnIntervalProc } = {}) {
    return this.#controlRecordingMutex.runExclusive(() => {
      const isRecording = this.getRecordingState() === RecordingState.RECORDING;
      if (isRecording) return;
      this.#mediaRecorder.start();
      this.#recordingMetadata = {
        startTime: Date.now(),
        endTime: null,
      };
      if (onIntervalProc) {
        this.#initRecordingInterval(onIntervalProc);
      }
    });
  }

  /**
   * Returns a promise that stops the recording, clears the current audio
   * events, clears the recording interval, and resolves with a copy of the
   * aforementioned audio events after the recording has fully stopped.
   * Idempotent and thread safe.
   */
  stopRecording(
    { shouldClearInterval }: { shouldClearInterval?: boolean } = {
      shouldClearInterval: true,
    },
  ) {
    return this.#controlRecordingMutex.runExclusive(() => {
      const isInactive = this.#mediaRecorder.state === RecordingState.INACTIVE;
      if (isInactive) return this.#audioEvents;
      return new Promise<AudioEventDataList>((resolve) => {
        this.#mediaRecorder.onstop = () => {
          this.#recordingMetadata.endTime = Date.now();
          const audioEventsCopy = [...this.#audioEvents];
          this.#clearAudioEvents();
          if (shouldClearInterval) {
            this.#clearRecordingInterval();
          }
          resolve(audioEventsCopy);
        };
        this.#mediaRecorder.stop();
      });
    });
  }

  /**
   * Stops the recording and then consumes the recorded audio events to create
   * audio blob data. It won't start recording again unless otherwise
   * specified. Thread safe.
   */
  async getRecording({
    shouldContinueRecordingAfter,
  }: {
    shouldContinueRecordingAfter?: boolean;
  } = {}): Promise<RecordingResult> {
    // Stopping the recording is vital before consuming the audio events,
    // else the created blob may be empty
    return this.#getRecordingMutex.runExclusive(async () => {
      const audioEvents = await this.stopRecording({
        shouldClearInterval: !shouldContinueRecordingAfter,
      });
      const audioBlob = new Blob(audioEvents, {
        type: RECORDED_AUDIO_TYPE,
      });
      const audioURL = URL.createObjectURL(audioBlob);
      const recordingMetadata = { ...this.#recordingMetadata };

      if (shouldContinueRecordingAfter) {
        await this.startRecording();
      }

      return {
        audioBlob,
        audioURL,
        recordingMetadata,
      };
    });
  }
}
