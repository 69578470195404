import { gql, useMutation } from "@apollo/client";
import { useGetTelehealthSessionInfo } from "../../hooks";
import { useVisitorId } from "../useVisitorId";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { activeConversationSidAtom, visitorNameAtom } from "../state";
import { useParams } from "react-router-dom";
import { logger } from "../../datadog/logger";
import { useAtomValue } from "jotai";

type UpdateVisitorPresenceVariables = {
  providerShortId: string;
  patientShortId: string;
  appointmentShortId?: string;
  visitorUuid: string;
  visitorName: string;
  waitingRoomSid?: string;
};

type UpdateVisitorPresenceData = {
  updateTwilioVisitorPresence: {
    updatedAt: string;
  };
};

const updateVisitorPresenceMutationGql = gql`
  mutation UpdateVisitorPresence(
    $providerShortId: String!
    $patientShortId: String!
    $appointmentShortId: String
    $visitorUuid: String!
    $visitorName: String!
    $waitingRoomConversationSid: String
  ) {
    updateTwilioVisitorPresence(
      providerShortId: $providerShortId
      patientShortId: $patientShortId
      appointmentShortId: $appointmentShortId
      visitorUuid: $visitorUuid
      visitorName: $visitorName
      waitingRoomConversationSid: $waitingRoomConversationSid
    ) {
      updatedAt
    }
  }
`;

export function useUpdateVisitorPresenceMutation() {
  return useMutation<UpdateVisitorPresenceData, UpdateVisitorPresenceVariables>(
    updateVisitorPresenceMutationGql,
  );
}

export function useUpdateVisitorPresenceCallback() {
  const visitorUuid = useVisitorId();
  const [updateVisitorPresence] = useUpdateVisitorPresenceMutation();
  const { providerShortId, patientShortId } = useParams() as {
    providerShortId: string;
    patientShortId: string;
  };
  const visitorName = useAtomValue(visitorNameAtom);
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const getActiveConversationSid = useAtomCallback(
    useCallback((get) => get(activeConversationSidAtom), []),
  );

  return useCallback(
    async () => {
      const activeConversationSid = getActiveConversationSid();
      try {
        await updateVisitorPresence({
          variables: {
            providerShortId,
            patientShortId,
            appointmentShortId:
              data?.telehealthSessionInfo?.appointment?.shortId,
            visitorUuid,
            visitorName,
            ...(activeConversationSid && {
              waitingRoomConversationSid: activeConversationSid,
            }),
          },
          context: {
            skipAuth: true,
          },
        });
      } catch (e) {
        const error = e as Error;
        logger.error("Unable to update visitor presence", {}, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providerShortId, patientShortId, data, visitorUuid, visitorName],
  );
}
