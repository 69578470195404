import { useCallback } from "react";
import { useDisconnectTwilioRoomCallback as useBaseDisconnectTwilioRoomCallback } from "../../twilio/useDisconnectTwilioRoomCallback";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { activeConversationSidAtom } from "../state";
import { useTwilioConversationCallbacks } from "../../twilio/chat/useTwilioConversationCallbacks";

export function useDisconnectTwilioRoomCallback() {
  const disconnectTwilioRoom = useBaseDisconnectTwilioRoomCallback();
  const setSessionConversationSid = useSetAtom(activeConversationSidAtom);
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(activeConversationSidAtom), []),
  );
  const { leaveConversation } = useTwilioConversationCallbacks();

  return useCallback(
    () => {
      const room = disconnectTwilioRoom();
      const conversationSid = getSessionConversationSid();
      // attempt to leave the session conversation gracefully as a side effect
      if (conversationSid) {
        leaveConversation(conversationSid);
      }
      setSessionConversationSid(undefined);
      return room;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
