import {
  ButtonRefsProvider,
  ButtonRefsProviderProps,
} from "./DrawerButtonRefsProvider";
import { ThemeProvider, ThemeProviderProps } from "./ThemeProvider";

export function PageProviders({
  children,
  theme,
}: ThemeProviderProps & ButtonRefsProviderProps) {
  return (
    <ThemeProvider theme={theme}>
      <ButtonRefsProvider>{children}</ButtonRefsProvider>
    </ThemeProvider>
  );
}
