export enum AppointmentObjectSortEnum {
  APPOINTMENT_BENEFITS_CHECK_ID_ASC = "APPOINTMENT_BENEFITS_CHECK_ID_ASC",
  APPOINTMENT_BENEFITS_CHECK_ID_DESC = "APPOINTMENT_BENEFITS_CHECK_ID_DESC",
  APPOINTMENT_ID_ASC = "APPOINTMENT_ID_ASC",
  APPOINTMENT_ID_DESC = "APPOINTMENT_ID_DESC",
  APPOINTMENT_SHORT_ID_ASC = "APPOINTMENT_SHORT_ID_ASC",
  APPOINTMENT_SHORT_ID_DESC = "APPOINTMENT_SHORT_ID_DESC",
  APPOINTMENT_TYPE_ASC = "APPOINTMENT_TYPE_ASC",
  APPOINTMENT_TYPE_DESC = "APPOINTMENT_TYPE_DESC",
  CANCELED_ASC = "CANCELED_ASC",
  CANCELED_AT_ASC = "CANCELED_AT_ASC",
  CANCELED_AT_DESC = "CANCELED_AT_DESC",
  CANCELED_BY_ASC = "CANCELED_BY_ASC",
  CANCELED_BY_DESC = "CANCELED_BY_DESC",
  CANCELED_DESC = "CANCELED_DESC",
  CANCELLATION_NOTE_ASC = "CANCELLATION_NOTE_ASC",
  CANCELLATION_NOTE_DESC = "CANCELLATION_NOTE_DESC",
  CANCELLATION_REASON_ASC = "CANCELLATION_REASON_ASC",
  CANCELLATION_REASON_DESC = "CANCELLATION_REASON_DESC",
  CLAIM_STATUS_ASC = "CLAIM_STATUS_ASC",
  CLAIM_STATUS_DESC = "CLAIM_STATUS_DESC",
  CLIENT_USER_ID_ASC = "CLIENT_USER_ID_ASC",
  CLIENT_USER_ID_DESC = "CLIENT_USER_ID_DESC",
  DATE_CREATED_ASC = "DATE_CREATED_ASC",
  DATE_CREATED_DESC = "DATE_CREATED_DESC",
  EVENT_ID_ASC = "EVENT_ID_ASC",
  EVENT_ID_DESC = "EVENT_ID_DESC",
  EXPIRED_AT_ASC = "EXPIRED_AT_ASC",
  EXPIRED_AT_DESC = "EXPIRED_AT_DESC",
  FIRST_APPOINTMENT_ASC = "FIRST_APPOINTMENT_ASC",
  FIRST_APPOINTMENT_DESC = "FIRST_APPOINTMENT_DESC",
  INTERNAL_NOTES_ASC = "INTERNAL_NOTES_ASC",
  INTERNAL_NOTES_DESC = "INTERNAL_NOTES_DESC",
  LAST_MODIFIED_ASC = "LAST_MODIFIED_ASC",
  LAST_MODIFIED_DESC = "LAST_MODIFIED_DESC",
  LENGTH_ASC = "LENGTH_ASC",
  LENGTH_DESC = "LENGTH_DESC",
  MANUAL_CANCELLATION_REASON_ASC = "MANUAL_CANCELLATION_REASON_ASC",
  MANUAL_CANCELLATION_REASON_DESC = "MANUAL_CANCELLATION_REASON_DESC",
  MODIFIED_BY_ASC = "MODIFIED_BY_ASC",
  MODIFIED_BY_DESC = "MODIFIED_BY_DESC",
  PATIENT_ID_ASC = "PATIENT_ID_ASC",
  PATIENT_ID_DESC = "PATIENT_ID_DESC",
  PATIENT_NAME_ASC = "PATIENT_NAME_ASC",
  PATIENT_NAME_DESC = "PATIENT_NAME_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  RECURRING_APPOINTMENT_GROUPING_ID_ASC = "RECURRING_APPOINTMENT_GROUPING_ID_ASC",
  RECURRING_APPOINTMENT_GROUPING_ID_DESC = "RECURRING_APPOINTMENT_GROUPING_ID_DESC",
  SCHEDULING_BENEFITS_CHECK_ID_ASC = "SCHEDULING_BENEFITS_CHECK_ID_ASC",
  SCHEDULING_BENEFITS_CHECK_ID_DESC = "SCHEDULING_BENEFITS_CHECK_ID_DESC",
  TIME_END_ASC = "TIME_END_ASC",
  TIME_END_DESC = "TIME_END_DESC",
  TIME_START_ASC = "TIME_START_ASC",
  TIME_START_DESC = "TIME_START_DESC",
  VERIFIED_ASC = "VERIFIED_ASC",
  VERIFIED_DESC = "VERIFIED_DESC",
  ZOCDOC_CANCELLATION_EMAILED_AT_ASC = "ZOCDOC_CANCELLATION_EMAILED_AT_ASC",
  ZOCDOC_CANCELLATION_EMAILED_AT_DESC = "ZOCDOC_CANCELLATION_EMAILED_AT_DESC",
}
