import {
  RemoteParticipantWrapper as BaseRemoteParticipant,
  RemoteParticipantWrapperProps,
} from "../twilio";
import { useHandleReceivedMessageCallback } from "./messages/useHandleReceivedMessageCallback";

export function RemoteParticipant(
  props: Omit<RemoteParticipantWrapperProps, "onMessageReceived">,
) {
  const handleReceivedMessage = useHandleReceivedMessageCallback();
  return (
    <BaseRemoteParticipant
      {...props}
      onMessageReceived={handleReceivedMessage}
    />
  );
}
