import { AudioSettingsTabWrapper as AudioSettingsTab } from "../components/settings-drawer/AudioSettingsTab";
import { BackgroundSettingsTabWrapper as BackgroundSettingsTab } from "../components/settings-drawer/BackgroundSettingsTab";
import { VideoSettingsTabWrapper as VideoSettingsTab } from "../components/settings-drawer/VideoSettingsTab";
import { SettingsDrawerTab } from "../components";
import { SelfThumbnailVideo } from "./SelfThumbnailVideo";

export const tabMap = {
  [SettingsDrawerTab.VIDEO]: (
    <VideoSettingsTab thumbnailComponent={SelfThumbnailVideo} />
  ),
  [SettingsDrawerTab.AUDIO]: <AudioSettingsTab />,
  [SettingsDrawerTab.BACKGROUND]: <BackgroundSettingsTab />,
};
