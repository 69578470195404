import { useCallback, useState } from "react";
import {
  localAudioTrackAtom,
  localVideoTrackAtom,
  localDataTrackAtom,
  twilioAtom,
  twilioRoomTokenAtom,
} from "./state";
import { useAtomCallback } from "jotai/utils";
import { Room, connect } from "twilio-video";
import { useSetAtom } from "jotai";
import { logger } from "../datadog/logger";
import { getConnectSettings } from "./config";

export type ConnectTwilioRoomCallback = (options?: {
  tokenOverride?: string;
  onError?: (error: unknown) => void;
}) => Promise<Room | undefined>;

/**
 * Returns a callback function that disconnects from the current Twilio room and
 * connects to a new Twilio room. This callback will not set/handle any room
 * listeners.
 */
export function useConnectTwilioRoomCallback(): [
  ConnectTwilioRoomCallback,
  { loading?: boolean },
] {
  const [loading, setLoading] = useState(false);
  const getLocalAudioTrack = useAtomCallback(
    useCallback((get) => get(localAudioTrackAtom), []),
  );
  const getLocalVideoTrack = useAtomCallback(
    useCallback((get) => get(localVideoTrackAtom), []),
  );
  const getLocalDataTrack = useAtomCallback(
    useCallback((get) => get(localDataTrackAtom), []),
  );
  const setTwilioState = useSetAtom(twilioAtom);
  const getTwilioRoomToken = useAtomCallback(
    useCallback((get) => get(twilioRoomTokenAtom), []),
  );

  const callback = useCallback(
    async function connectTwilioRoom({
      tokenOverride,
      onError,
    }: {
      tokenOverride?: string;
      onError?: (error: unknown) => void;
    } = {}) {
      const localAudioTrackValue = getLocalAudioTrack().track;
      const localVideoTrackValue = getLocalVideoTrack().track;
      const localDataTrackValue = getLocalDataTrack().track;
      const token = tokenOverride || getTwilioRoomToken();
      if (!token) return;
      setLoading(true);
      try {
        const room = await connect(token, {
          tracks: [
            ...(localAudioTrackValue ? [localAudioTrackValue] : []),
            ...(localVideoTrackValue ? [localVideoTrackValue] : []),
            ...(localDataTrackValue ? [localDataTrackValue] : []),
          ],
          dominantSpeaker: true,
          ...getConnectSettings(),
        });
        setTwilioState((prev) => ({
          ...prev,
          room,
          participants: Array.from(room.participants, ([_, p]) => p),
          connectedAt: Date.now(),
        }));

        return room;
      } catch (error) {
        logger.error("Unable to connect to room", {}, error as Error);
        onError?.(error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [callback, { loading }];
}
