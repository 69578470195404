import { useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { logger } from "../datadog/logger";
import { memoizedDeserializerWithInitialValue } from "../utils";
import {
  AudioVideoSettings,
  BackgroundType,
  DEFAULT_VIDEO_SETTINGS,
  STORED_AUDIO_VIDEO_SETTINGS_KEY,
} from "./config";

const deserializerToEnsureObjectEquality = memoizedDeserializerWithInitialValue(
  DEFAULT_VIDEO_SETTINGS,
  (error) => {
    logger.error("JSON parse error", undefined, error);
  },
);

/**
 * In order for changes to Reactively propagate, please use this hook for
 * writing settings. However, if you only need to _read_ settings ad-hoc, then
 * use `getStoredAudioVideoSettings`.
 */
export const useStoredAudioVideoSettings = () => {
  const [storedAudioVideoSettings, setStoredAudioVideoSettings] =
    useLocalStorage(STORED_AUDIO_VIDEO_SETTINGS_KEY, DEFAULT_VIDEO_SETTINGS, {
      deserializer: deserializerToEnsureObjectEquality,
    });

  return useMemo(
    () => ({
      storedAudioVideoSettings: {
        ...storedAudioVideoSettings,
        backgroundType:
          storedAudioVideoSettings?.backgroundType ?? BackgroundType.NONE,
      } as AudioVideoSettings,
      setStoredAudioVideoSettings: setStoredAudioVideoSettings,
    }),
    [storedAudioVideoSettings, setStoredAudioVideoSettings],
  );
};
