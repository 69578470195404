import {
  hoursToMilliseconds,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from "date-fns";
import { Env } from "./types";
import { getEnvFromHostName } from "./utils";
import { Statsig } from "statsig-react";
import { dynamicConfig } from "./statsig/gates";
import { logger } from "./datadog/logger";

export const isDevMode = import.meta.env.DEV;
export const IS_DEV_APP_SETTINGS_ENABLED_KEY = "is-dev-app-settings-enabled";
export const DEV_APP_SETTINGS_KEY = "dev-app-settings";
export const PROVIDER_PATH = "/provider";
export const GRAPHQL_PATH = "/api/graphql";
export const getVisitorPresenceUpdateIntervalMs = () =>
  secondsToMilliseconds(
    Statsig.getConfig(dynamicConfig).get(
      "unaccompanied_participant_interval",
      15,
    ),
  );
export const NOTIFICATION_VOLUME = 0.33;
// Should correspond with VISITOR_ACCESS_DOC_TTL_SECONDS on the backend
export const VISITOR_ACCESS_DOC_TTL_MS = hoursToMilliseconds(1);
export const STAGING_MONOLITH_GQL_PATH = "/staging/monolith/graphql";
export const DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS = minutesToMilliseconds(1);

export interface AppConfig {
  marketplaceOrigin: string;
  providerOrigin: string;
  version: string;
  monolithGqlPath: string;
  providerPath: string;
  graphqlPath: string;
  monolithBackendOrigin: string;
}

const BASE_CONFIG = {
  version: import.meta.env.VITE_VERSION as string,
  providerPath: PROVIDER_PATH,
  graphqlPath: GRAPHQL_PATH,
};

export function getDevAppSettings() {
  if (!isDevMode) {
    return {};
  }
  try {
    const isDevConnectionSettingsEnabled = !!parseInt(
      localStorage.getItem(IS_DEV_APP_SETTINGS_ENABLED_KEY) ?? "0",
    );
    if (!isDevConnectionSettingsEnabled) return {};
    const settings = JSON.parse(
      localStorage.getItem(DEV_APP_SETTINGS_KEY) || "{}",
    );
    logger.debug("Using dev app settings", settings);
    return settings;
  } catch (error) {
    logger.debug("Error parsing dev app settings", {}, error as Error);
    return {};
  }
}

export function getAppConfig(): AppConfig {
  const env = getEnvFromHostName(window.location.hostname);
  const devConfig = getDevAppSettings();
  switch (env) {
    case Env.PROD:
      return {
        ...BASE_CONFIG,
        marketplaceOrigin: "https://growtherapy.com",
        providerOrigin: "https://provider.growtherapy.com",
        monolithBackendOrigin: "https://provider.growtherapy.com",
        monolithGqlPath: "/graphql",
        ...devConfig,
      };
    case Env.DEV:
      return {
        ...BASE_CONFIG,
        marketplaceOrigin: `https://${
          import.meta.env.VITE_DEVELOPMENT_MONOLITH_BASE_URL
        }`,
        providerOrigin: `https://provider.${
          import.meta.env.VITE_DEVELOPMENT_MONOLITH_BASE_URL
        }`,
        monolithBackendOrigin: `https://provider.${
          import.meta.env.VITE_DEVELOPMENT_MONOLITH_BASE_URL
        }`,
        monolithGqlPath: "/graphql",
        ...devConfig,
      };
    case Env.STAGING:
      return {
        ...BASE_CONFIG,
        marketplaceOrigin: "https://growtherapystaging.com",
        providerOrigin: "https://provider.growtherapystaging.com",
        monolithBackendOrigin: "https://provider.growtherapystaging.com",
        monolithGqlPath: "/graphql",
        ...devConfig,
      };
    default:
      return {
        ...BASE_CONFIG,
        marketplaceOrigin: "https://growtherapylocal.com:3000",
        providerOrigin: "https://provider.growtherapylocal.com:3000",
        monolithBackendOrigin: "https://telehealth.growtherapylocal.com",
        monolithGqlPath: "/monolith/graphql",
        version: "local",
        ...devConfig,
      };
  }
}
