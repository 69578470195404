import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";
import { useAnnouncementToast as useBaseAnnouncementToast } from "../components";
import { AppState, UserType } from "../types";
import { useAtomValue } from "jotai";
import { twilioRoomAtom } from "../twilio";

export function useAnnouncementToast() {
  const { data } = useGetCurrentProviderQuery();
  const providerShortId = data?.currentProvider.shortId;
  const isInSession = !!useAtomValue(twilioRoomAtom);

  useBaseAnnouncementToast({
    userId: providerShortId,
    userType: UserType.PROVIDER,
    appState: isInSession ? AppState.MEETING : AppState.WAITING,
  });
}
