import { useSetAtom } from "jotai";
import { ProviderState, providerStateAtom } from "./state";
import { useCompleteTwilioSessionMutation } from "./useCompleteTwilioSessionMutation";
import { useCallback } from "react";
import { logger } from "../datadog/logger";
import { Room } from "twilio-video";
import { useDisconnectTwilioRoomCallback } from "./useDisconnectTwilioRoomCallback";

export type CompleteTwilioSessionCallback = () => Room | undefined;

/**
 * Returns a callback function that completes the current Twilio session on the
 * server side. Will provide a strong guarantee that the session is completed,
 * but will take more time then a simple `room.disconnect()` call.
 *
 * Should be used as a fire-and-forget side-effect after a `room.disconnect()`
 * call.
 */
export function useCompleteSessionSideEffectCallback() {
  const [completeSession] = useCompleteTwilioSessionMutation();

  return useCallback(
    async (room: Room) => {
      try {
        await completeSession({
          variables: { roomSid: room.sid },
        });
        // Ensures that the room completion reason is set to "disconnected_via_api"
        room.disconnect();
        return room;
      } catch (error) {
        logger.error(
          "Unable to complete session via GraphQL",
          {},
          error as Error,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/**
 * Returns a callback function that completes the current Twilio session and
 * sets the provider state to completed.
 */
export function useCompleteTwilioSessionCallback(): CompleteTwilioSessionCallback {
  const disconnectTwilioRoom = useDisconnectTwilioRoomCallback();
  const setProviderState = useSetAtom(providerStateAtom);
  const dispatchSessionCompletionSideEffect =
    useCompleteSessionSideEffectCallback();
  const callback = useCallback<CompleteTwilioSessionCallback>(
    () => {
      const room = disconnectTwilioRoom();
      if (!room) return;
      setProviderState(ProviderState.COMPLETED);
      dispatchSessionCompletionSideEffect(room);
      return room;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return callback;
}
