export function updateFaviconAndTitle(favIconHref?: string, title?: string) {
  let iconLink: HTMLLinkElement | null =
    document.querySelector("link[rel='icon']");
  let appleTouchLink: HTMLLinkElement | null = document.querySelector(
    "link[rel='apple-touch-icon']",
  );
  const favIcon = favIconHref ? favIconHref : "/assets/favicon.png";
  if (!iconLink) {
    iconLink = document.createElement("link");
    iconLink.rel = "icon";
    document.head.appendChild(iconLink);
  }
  if (!appleTouchLink) {
    appleTouchLink = document.createElement("link");
    appleTouchLink.rel = "apple-touch-icon";
    document.head.appendChild(appleTouchLink);
  }
  iconLink.href = favIcon;
  appleTouchLink.href = favIcon;
  document.title = title ? title : "Grow Therapy Telehealth";
}
