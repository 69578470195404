import { Env } from "../types";
import { getEnvFromHostName } from "../utils";
import type { LogsInitConfiguration } from "@datadog/browser-logs";
import type { RumInitConfiguration } from "@datadog/browser-rum";

export function getDataDogConfig():
  | (LogsInitConfiguration & RumInitConfiguration)
  | null {
  const env = getEnvFromHostName(window.location.hostname);
  const base = {
    applicationId: "7739d4d0-5448-43a0-a45b-1698285d55aa",
    clientToken: "pub13ed140a1e6ea7012731bc59e90b550e",
    enableExperimentalFeatures: ["feature_flags"],
    site: "datadoghq.com",
    service: "telehealth.spa",
    version: import.meta.env.VITE_VERSION,
  } satisfies Partial<RumInitConfiguration>;

  switch (env) {
    case Env.PROD:
      return {
        ...base,
        env: "production",
        allowedTracingUrls: [
          "https://telehealth.growtherapy.com/api",
          "https://provider.growtherapy.com/graphql",
        ],
      };
    case Env.DEV:
      return {
        ...base,
        env: "development",
        allowedTracingUrls: [
          `https://provider.${
            import.meta.env.VITE_DEVELOPMENT_MONOLITH_BASE_URL
          }/graphql`,
          `https://${import.meta.env.VITE_DEVELOPMENT_TELEHEALTH_BASE_URL}/api`,
        ],
      };
    case Env.STAGING:
      return {
        ...base,
        env: "staging",
        allowedTracingUrls: [
          "https://telehealth.growtherapystaging.com/api",
          "https://provider.growtherapystaging.com/graphql",
        ],
      };
    default:
      return null;
  }
}
