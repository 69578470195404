import {
  Modal,
  ModalBody,
  Text,
  Button,
  Heading,
} from "@grow-therapy-team/sprout-ui";
import HandHoldingPencil from "../../assets/images/hand-holding-pencil.svg?react";
import { ConsentModalText } from "./constants";
import { useCallback, useEffect, useState } from "react";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useAtom, useAtomValue } from "jotai";
import { participantCountAtom } from "../../twilio";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { ConsentStatus } from "../../twilio/types";
import { ConsentDeclinedToast } from "./ConsentDeclinedToast";
import toast from "react-hot-toast";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";
import { useTrackEvent } from "../../segment/segment";
import { UserType } from "../../types";
import { useAtomCallback } from "jotai/utils";
import { sessionInfoAtom } from "../../state";
import { EventTypeEnum } from "../../segment/types";
import { useToggleRecordingCallback } from "./useToggleRecordingCallback";
import { useGracefullyRecordClientTranscriptionConsentCallback } from "./useGracefullyRecordClientTranscriptionConsentCallback";
import { Toast, ToastVariant } from "../../components";

const CONSENT_TOAST_DURATION_MS = secondsToMilliseconds(30);

export type ConsentModalProps = {
  onDeclineConsent: () => void;
  onConsent: () => Promise<void>;
  autoDismissConsent: () => void;
  isOpen: boolean;
  autoDismissTimeout?: number;
  providerName: string;
  isMultiPatientSession: boolean;
};

export function ConsentModal({
  onDeclineConsent,
  onConsent,
  autoDismissConsent,
  isOpen,
  providerName,
  isMultiPatientSession,
  autoDismissTimeout = minutesToMilliseconds(2),
}: ConsentModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const recordConsent = () => {
    setIsLoading(true);
    onConsent().finally(() => setIsLoading(false));
  };

  useEffect(
    function dismissAfterTwoMinutes() {
      if (!isOpen) return;
      const timeout = setTimeout(function dismissModal() {
        autoDismissConsent();
      }, autoDismissTimeout);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [isOpen, autoDismissTimeout, autoDismissConsent],
  );
  return (
    <Modal
      isOpen={isOpen}
      className="shadow-none sm:max-w-[31rem] md:max-w-[31rem] sm:top-[25%]"
    >
      <ModalBody className="flex flex-col items-center gap-4 p-8 text-center">
        <HandHoldingPencil />
        <div className="flex flex-col gap-2 items-center">
          <Heading variant="md">{`${providerName} ${ConsentModalText.HEADING}`}</Heading>
        </div>
        <div className="w-full flex flex-col items-center text-center">
          {isMultiPatientSession && (
            <Text variant="sm">
              {ConsentModalText.MULTI_PARTICIPANT_DISCLAIMER}
            </Text>
          )}
          <Text variant="sm">{ConsentModalText.FOOTER}</Text>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <Button
            disabled={isLoading}
            onClick={onDeclineConsent}
            use="secondary"
            className="w-full"
          >
            {ConsentModalText.DONT_ENABLE_BUTTON}
          </Button>
          <Button
            loading={isLoading}
            onClick={recordConsent}
            use="primary"
            className="w-full m-0"
          >
            {ConsentModalText.ENABLE_BUTTON}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

enum RecordConsentResponse {
  "CONSENTED",
  "ERROR",
  "CONSENTED_AND_RECORDING",
}

function useRecordConsentCallback() {
  const { userClicked } = useTrackEvent();
  const getSessionInfo = useAtomCallback(
    useCallback((get) => get(sessionInfoAtom), []),
  );
  const getProviderConsent = useAtomCallback(
    useCallback((get) => get(providerConsentAtom).value, []),
  );
  const toggleRecording = useToggleRecordingCallback();
  const recordConsent = useGracefullyRecordClientTranscriptionConsentCallback();

  return useCallback(async () => {
    const sessionInfo = getSessionInfo();
    const appointmentShortId = sessionInfo?.appointment?.shortId;
    userClicked(EventTypeEnum.GAVE_TRANSCRIPTION_CONSENT, {
      appointmentShortId: appointmentShortId!,
      entityType: UserType.CLIENT,
    });

    const recordingResult = await recordConsent();

    if (recordingResult.status === "failure") {
      toast.custom(
        <Toast className="bottom-center-toast" variant={ToastVariant.Error}>
          An unexpected error occurred while trying to record your consent.
          Please try again.
        </Toast>,
        { position: "bottom-center" },
      );
      return RecordConsentResponse.ERROR;
    }

    // Start recording if provider has consented
    const providerConsent = getProviderConsent();
    if (providerConsent === ConsentStatus.OPTED_IN) {
      toggleRecording(true);
      return RecordConsentResponse.CONSENTED_AND_RECORDING;
    }

    return RecordConsentResponse.CONSENTED;
  }, [
    getProviderConsent,
    getSessionInfo,
    recordConsent,
    toggleRecording,
    userClicked,
  ]);
}

export function ConsentModalWrapper({
  onStartRecording,
}: {
  onStartRecording: () => void;
}) {
  const [patientConsentState, setPatientConsent] = useAtom(patientConsentAtom);
  const participantCount = useAtomValue(participantCountAtom);
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const providerName =
    data?.telehealthSessionInfo?.provider?.name ??
    ConsentModalText.PROVIDER_NAME_FALLBACK;
  const isOpen = patientConsentState.value === ConsentStatus.DECIDING;
  const sendParticipantState = useSendVisitorParticipantStateCallback();

  const showConsentToast = () => {
    toast.custom(
      (t) => (
        <ConsentDeclinedToast
          onClose={() => toast.remove(t.id)}
          visitorDeclinedConsent
        />
      ),
      {
        position: "bottom-center",
        duration: CONSENT_TOAST_DURATION_MS,
      },
    );
  };
  const declineConsent = useCallback(() => {
    setPatientConsent({
      value: ConsentStatus.SOFT_NO,
      lastUpdated: Date.now(),
    });
    sendParticipantState();
    showConsentToast();
  }, [sendParticipantState, setPatientConsent]);

  const autoDismissConsent = useCallback(() => {
    setPatientConsent({
      value: ConsentStatus.SOFT_NO,
      lastUpdated: Date.now(),
    });
    sendParticipantState();
  }, [sendParticipantState, setPatientConsent]);

  const recordConsent = useRecordConsentCallback();

  return (
    <ConsentModal
      autoDismissConsent={autoDismissConsent}
      onDeclineConsent={declineConsent}
      isOpen={isOpen}
      providerName={providerName}
      isMultiPatientSession={participantCount > 1}
      onConsent={async () => {
        const startedRecording =
          (await recordConsent()) ===
          RecordConsentResponse.CONSENTED_AND_RECORDING;
        if (startedRecording) {
          onStartRecording();
        }
      }}
    />
  );
}
