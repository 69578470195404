import { useAtomValue } from "jotai";
import { AdditionalOptionsMenu } from "../components/AdditionalOptionsMenu";
import { useChatDrawerButtonUtils } from "./chat/useChatDrawerButtonUtils";
import { hasAnyNewFormsAtom } from "./client-information/state";
import { DrawerState, numWaitingVisitorsAtom } from "./state";
import {
  participantCountAtom,
  localScreenShareTrackAtom,
  remoteScreenShareParticipantAtom,
} from "../twilio";
export function AdditionalOptionsMenuWrapper({
  drawerId,
  drawerState,
  onClickSettingsButton,
  onClickClientInformationButton,
  onClickClientsButton,
}: {
  drawerId: string;
  drawerState: DrawerState | null;
  onClickSettingsButton: () => void;
  onClickClientInformationButton?: () => void;
  onClickClientsButton?: () => void;
}) {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();
  const numberOfWaitingClients = useAtomValue(numWaitingVisitorsAtom);
  const hasNewForms = useAtomValue(hasAnyNewFormsAtom);
  const isInSession = !!useAtomValue(participantCountAtom);
  const isScreenSharing = !!useAtomValue(localScreenShareTrackAtom);
  const isSomeoneElseSharing = !!useAtomValue(remoteScreenShareParticipantAtom);

  return (
    <AdditionalOptionsMenu
      drawerId={drawerId}
      drawerState={drawerState}
      isInSession={isInSession}
      isSomeoneElseSharing={isSomeoneElseSharing}
      isScreenSharing={isScreenSharing}
      onClickSettingsButton={onClickSettingsButton}
      unreadMessageCount={unreadMessageCount}
      onClickChatButton={toggleChatDrawer}
      onClickClientInformationButton={onClickClientInformationButton}
      hasNewClientInfo={!!onClickClientInformationButton && hasNewForms}
      numberOfWaitingClients={numberOfWaitingClients}
      onClickClientsButton={onClickClientsButton}
    />
  );
}
