import { RemoteParticipant } from "twilio-video";
import { participantsAtom } from "../state";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useMatch } from "react-router-dom";
import { PROVIDER_PATH } from "../../config";
import { RemoteParticipantWrapper } from "./RemoteParticipant";

export interface RemoteParticipantGridProps {
  remoteParticipants: RemoteParticipant[];
  helpText?: string;
  isProviderView?: boolean;
  remoteParticipantComponent: typeof RemoteParticipantWrapper;
}

/**
 * Displays remote participants in a grid layout.
 */
export function RemoteParticipantGrid({
  remoteParticipants,
  remoteParticipantComponent: RemoteParticipantComponent,
}: RemoteParticipantGridProps) {
  const numParticipants = remoteParticipants.length;

  return (
    <div
      data-testid="remote-audio-video-grid"
      className={twMerge(
        "w-screen h-full sm:w-full gap-4 grid grid-cols-12 grid-rows-12",
        classNames({
          "h-full": numParticipants === 1,
          "max-w-[41rem]": numParticipants === 2,
          "max-w-[62rem]": numParticipants > 2 && numParticipants < 5,
        }),
      )}
    >
      {remoteParticipants.map((remoteParticipant) => {
        return (
          <RemoteParticipantComponent
            className={classNames({
              "row-span-full col-span-full": numParticipants === 1,
              "row-span-6 col-span-full": numParticipants === 2,
              "col-span-6 row-span-6 last:col-start-4 last:col-end-10":
                numParticipants === 3,
              "col-span-6 row-span-6": numParticipants === 4,
              "col-span-4 row-span-6 [&:nth-child(4)]:col-start-3 [&:nth-child(4)]:col-end-7 last:col-start-7 last:col-end-11":
                numParticipants === 5,
              "col-span-4 row-span-4": numParticipants >= 6, // Not optimized for more than 6
            })}
            key={remoteParticipant.identity}
            remoteParticipant={remoteParticipant}
          />
        );
      })}
    </div>
  );
}

export function RemoteParticipantGridWrapper({
  helpText,
  remoteParticipantComponent: RemoteParticipantComponent,
}: Pick<
  RemoteParticipantGridProps,
  "helpText" | "remoteParticipantComponent"
>) {
  const participants = useAtomValue(participantsAtom);
  const isProviderView = !!useMatch(PROVIDER_PATH);

  return (
    <RemoteParticipantGrid
      helpText={helpText}
      isProviderView={isProviderView}
      remoteParticipants={participants}
      remoteParticipantComponent={RemoteParticipantComponent}
    />
  );
}
