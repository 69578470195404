import { useInitTwilioChatClient } from "../../twilio/chat/useInitTwilioChatClient";
import { useInitProviderChatAccess } from "./useInitProviderChatAccess";

/**
 * Initializes the provider's Twilio chat client
 */
export function useInitProviderChatClient() {
  const twilioChatToken = useInitProviderChatAccess();

  useInitTwilioChatClient({ twilioChatToken });
}
