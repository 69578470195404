import { atomFamily } from "jotai/utils";
import { providerAtom } from "../state";
import { atom } from "jotai";
import { createDerivedWritableAtom } from "../../utils";

export const deadlinesByVisitorIdAtom = createDerivedWritableAtom(
  providerAtom,
  "deadlinesByVisitorId",
);

export const deadlinesByVisitorIdFamily = atomFamily((visitorId: string) => {
  return atom(
    (get) => get(deadlinesByVisitorIdAtom)[visitorId],
    (get, set, deadline: Date | null) => {
      if (!deadline) {
        const { [visitorId]: _, ...remaining } = get(deadlinesByVisitorIdAtom);
        set(deadlinesByVisitorIdAtom, remaining);
      } else {
        const deadlines = {
          ...get(deadlinesByVisitorIdAtom),
          [visitorId]: deadline,
        };
        set(deadlinesByVisitorIdAtom, deadlines);
      }
    },
  );
});
