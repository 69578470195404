import { gql, useMutation } from "@apollo/client";

type CompleteTwilioSessionMutationVariables = {
  roomSid: string;
};

export type CompleteTwilioSessionMutationData = {
  completeTwilioSession: {
    roomSid: string;
  };
};

const COMPLETE_TWILIO_SESSION_MUTATION_GQL = gql`
  mutation useCompleteTwilioSessionMutation($roomSid: String!) {
    completeTwilioSession(roomSid: $roomSid) {
      roomSid
    }
  }
`;

export function useCompleteTwilioSessionMutation() {
  return useMutation<
    CompleteTwilioSessionMutationData,
    CompleteTwilioSessionMutationVariables
  >(COMPLETE_TWILIO_SESSION_MUTATION_GQL);
}
