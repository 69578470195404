import { useAtom } from "jotai";
import {
  LocalTrackState,
  localVideoTrackAtom,
  usePublishLocalTrackCallback,
  useUnpublishLocalTrackCallback,
  videoWasOnAtScreenLeaveAtom,
} from ".";
import { useOnInactiveMobileScreenDetected } from "../hooks/useOnInactiveMobileScreenDetected";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

export function useResetVideoOnMobileNavigation() {
  const [_, setVideoWasOnAtScreenLeave] = useAtom(videoWasOnAtScreenLeaveAtom);
  const getVideoWasOnAtScreenLeave = useAtomCallback(
    useCallback((get) => {
      return get(videoWasOnAtScreenLeaveAtom);
    }, []),
  );
  const getLocalVideoTrack = useAtomCallback(
    useCallback((get) => {
      return get(localVideoTrackAtom);
    }, []),
  );

  const unpublishLocalVideoTrack = useUnpublishLocalTrackCallback("video");
  const publishLocalVideoTrack = usePublishLocalTrackCallback("video");

  const screenLeaveCallback = () => {
    const videoIsOn = getLocalVideoTrack()?.state === LocalTrackState.READY;
    setVideoWasOnAtScreenLeave(videoIsOn);
    if (videoIsOn) {
      unpublishLocalVideoTrack();
    }
  };
  const screenReturnCallback = () => {
    if (getVideoWasOnAtScreenLeave()) {
      publishLocalVideoTrack();
    }
    setVideoWasOnAtScreenLeave(null);
  };

  useOnInactiveMobileScreenDetected(screenLeaveCallback, screenReturnCallback);
}
