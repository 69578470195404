import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import {
  GET_CURRENT_PROVIDER_GQL,
  GetCurrentProviderQueryData,
} from "./useGetCurrentProviderQuery";
import { logger } from "../datadog/logger";

export function useGetCurrentCachedProviderCallback() {
  const apolloClient = useApolloClient();
  return useCallback(async () => {
    try {
      return await apolloClient.readQuery<GetCurrentProviderQueryData>({
        query: GET_CURRENT_PROVIDER_GQL,
      })?.currentProvider;
    } catch (error) {
      logger.error(
        "Unable to read current provider from cache",
        {},
        error as Error,
      );
    }
  }, [apolloClient]);
}
