import { useCallback, useEffect } from "react";
import {
  localAudioTrackStatsAtom,
  remoteAudioTrackStatsBySidAtom,
} from "./state";

import { useAtomValue, useSetAtom } from "jotai";
import { Mutex } from "async-mutex";
import { logger } from "../../datadog/logger";
import { RemoteAudioTrackStats } from "twilio-video";
import { twilioRoomAtom } from "../state";
import { sendDataDogEvent } from "../../datadog/events";
import { useAtomCallback } from "jotai/utils";
const UPDATE_TRACK_STATS_INTERVAL_MS = 500;
const SEND_TRACK_LOGS_INTERVAL_MS = 10000;
const statsReportMutex = new Mutex();

const indexRemoteAudioTrackStatsBySid = (
  remoteAudioTrackStats: RemoteAudioTrackStats[],
) => {
  return remoteAudioTrackStats.reduce(
    (acc, stats) => ({
      ...acc,
      [stats.trackSid]: stats,
    }),
    {},
  );
};

export function useUpdateTrackStatsInterval() {
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const setLocalAudioTrackStats = useSetAtom(localAudioTrackStatsAtom);
  const setRemoteAudioTrackStats = useSetAtom(remoteAudioTrackStatsBySidAtom);
  const getLocalAudioTrackStats = useAtomCallback(
    useCallback((get) => get(localAudioTrackStatsAtom), []),
  );

  useEffect(
    function handleLoggingInterval() {
      if (!twilioRoom) return;
      const interval = setInterval(function sendLogs() {
        const localAudioTrackStats = getLocalAudioTrackStats();
        const audioLevel = localAudioTrackStats?.audioLevel;
        // audioLevel will be null in browsers that don't support audio stats
        if (audioLevel || audioLevel === 0) {
          sendDataDogEvent("twilio-stats", {
            "audio.stats.local.audioLevel.microphone": audioLevel,
          });
        }
      }, SEND_TRACK_LOGS_INTERVAL_MS);

      return function cleanup() {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twilioRoom],
  );

  useEffect(
    function handleStatsInterval() {
      if (!twilioRoom) return;

      const interval = setInterval(async function updateTrackStats() {
        if (statsReportMutex.isLocked()) return;
        const release = await statsReportMutex.acquire();
        try {
          const [statsReport] = await twilioRoom.getStats();

          if (!statsReport) return;
          const [localAudioTrackStats] = statsReport.localAudioTrackStats;
          const { remoteAudioTrackStats } = statsReport;

          if (localAudioTrackStats) {
            setLocalAudioTrackStats(localAudioTrackStats);
          }
          setRemoteAudioTrackStats((prev) => ({
            ...prev,
            ...indexRemoteAudioTrackStatsBySid(remoteAudioTrackStats),
          }));
        } catch (error) {
          logger.error(
            "Error updating track stats",
            { roomSid: twilioRoom.sid },
            error as Error,
          );
        } finally {
          release();
        }
      }, UPDATE_TRACK_STATS_INTERVAL_MS);

      return function cleanup() {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twilioRoom],
  );
}
