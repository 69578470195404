import { deviceIsMobile } from "../../utils";
import { HelpModalItem } from "./HelpModalItem";
import { BlockedPermissionDescription } from "../BlockedPermissionDescription";

export function BlockedPermissionsActionItem({
  isMobile,
}: {
  isMobile: boolean;
}) {
  return (
    <HelpModalItem
      heading="My camera and microphone aren’t working"
      description={
        <div className="flex flex-col gap-4">
          <BlockedPermissionDescription isMobile={isMobile} />
        </div>
      }
    />
  );
}

export function BlockedPermissionsActionItemWrapper() {
  const isMobile = deviceIsMobile();
  return <BlockedPermissionsActionItem isMobile={isMobile} />;
}
