import { useCallback } from "react";
import { useInterval } from "usehooks-ts";
import { secondsToMilliseconds } from "date-fns";
import { useAtomValue } from "jotai";
import { participantCountAtom } from "..";

const SYNC_INTERVAL = secondsToMilliseconds(3);

export function usePeriodicallySendParticipantState(
  sendParticipantState: () => void,
) {
  const participantCount = useAtomValue(participantCountAtom);
  const shouldSendParticipantState = participantCount > 0;

  useInterval(
    useCallback(() => {
      sendParticipantState();
    }, [sendParticipantState]),
    shouldSendParticipantState ? SYNC_INTERVAL : null,
  );
}
