import { gql, useMutation } from "@apollo/client";

export type DisconnectTwilioParticipantMutationVariables = {
  roomName: string;
  participantSid: string;
};

export type DisconnectTwilioParticipantMutationData = {
  disconnectTwilioParticipant: {
    roomName: string;
    participantSid: string;
  };
};

const DISCONNECT_TWILIO_PARTICIPANT_MUTATION_GQL = gql`
  mutation DisconnectTwilioParticipant(
    $roomName: String!
    $participantSid: String!
  ) {
    disconnectTwilioParticipant(
      roomName: $roomName
      participantSid: $participantSid
    ) {
      roomName
      participantSid
    }
  }
`;

export function useDisconnectTwilioParticipantMutation() {
  return useMutation<
    DisconnectTwilioParticipantMutationData,
    DisconnectTwilioParticipantMutationVariables
  >(DISCONNECT_TWILIO_PARTICIPANT_MUTATION_GQL);
}
