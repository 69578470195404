import { Button, ButtonSize } from "@grow-therapy-team/sprout-ui";
import enableAudioToneUri from "../../assets/audio/enable-audio-tone.mp3?url";
import { gracefullyPlayAudio } from "../../audio";
import { NOTIFICATION_VOLUME } from "../../config";
import { Toast } from "../../components";
import { useEffect } from "react";

export function RestrictedAudioPlaybackToast({
  onClose,
}: {
  onClose: () => void;
}) {
  const enableAudio = async () => {
    const audio = new Audio(enableAudioToneUri);
    audio.volume = NOTIFICATION_VOLUME;
    await gracefullyPlayAudio(audio);
    onClose();
  };

  // It's important to call onClose on dismount to ensure proper cleanup occurs
  useEffect(
    function handleDismount() {
      return onClose;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Toast onClose={onClose} isBanner>
      <span className="mr-4">
        Your browser may have restricted audio from playing.
      </span>
      <Button
        className="whitespace-nowrap h-12 mr-2"
        size={ButtonSize.Small}
        onClick={enableAudio}
      >
        Enable audio
      </Button>
    </Toast>
  );
}
