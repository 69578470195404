import { useCallback, useEffect } from "react";
import { useResetLocalVideoOnScreenOrientationChange } from "../useResetLocalVideoOnScreenOrientationChange";
import { useSetAtom } from "jotai";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import {
  LocalTrackState,
  TwilioAtom,
  LocalTrackAtom,
  localTrackFamily,
  twilioAtom,
} from "../state";
import { createLocalTracks } from "../tracks";
import { useHandleTrackErrorCallback } from "./useGetOrCreateLocalTrackCallback";
import { useInitBackgroundProcessorsCallback } from "../useInitBackgroundProcessorsCallback";
import { BackgroundType, getStoredAudioVideoSettings } from "../config";
import { useNoiseCancellationCallbacks } from "./useNoiseCancellationCallbacks";
import { useValidateStoredDeviceIdsCallback } from "../devices/useValidateStoredDeviceIdsCallback";

/**
 * Returns a callback function that publishes the local audio, video, and data tracks.
 * Good for efficiently initializing both tracks at once.
 */
export function usePublishLocalTracksCallback() {
  const publishLocalAudioTrack = usePublishLocalTrackCallback("audio");
  const publishLocalVideoTrack = usePublishLocalTrackCallback("video");
  const setLocalDataTrack = useSetAtom(localTrackFamily("data"));

  const handleAudioTrackError = useHandleTrackErrorCallback("audio");
  const handleVideoTrackError = useHandleTrackErrorCallback("video");
  const initializeBackground = useInitBackgroundProcessorsCallback();
  const setTwilioContext = useSetAtom(twilioAtom);
  const { initNoiseCancellation } = useNoiseCancellationCallbacks();

  const publishTracks = useCallback(
    async () => {
      const { isAudioEnabled, isVideoEnabled } = getStoredAudioVideoSettings();

      setTwilioContext((prev: TwilioAtom) => ({
        ...prev,
        localAudioTrack: {
          ...prev.localAudioTrack,
          state: isAudioEnabled
            ? LocalTrackState.LOADING
            : LocalTrackState.DISABLED,
        },
        localVideoTrack: {
          ...prev.localVideoTrack,
          state: isVideoEnabled
            ? LocalTrackState.LOADING
            : LocalTrackState.DISABLED,
        },
        localDataTrack: {
          ...prev.localDataTrack,
          state: LocalTrackState.LOADING,
        },
      }));

      const tracks = await createLocalTracks(isAudioEnabled, isVideoEnabled);
      for (const track of tracks) {
        switch (track.kind) {
          case "audio":
            await publishLocalAudioTrack({ track });
            await initNoiseCancellation(track);
            break;
          case "video": {
            const storedAudioVideoSettings = getStoredAudioVideoSettings();
            const backgroundType = storedAudioVideoSettings.backgroundType;
            await publishLocalVideoTrack({ track });
            if (backgroundType !== BackgroundType.NONE) {
              await initializeBackground(
                {
                  backgroundIndex: storedAudioVideoSettings.backgroundIndex,
                  backgroundType,
                },
                track,
              );
            }
            break;
          }
          case "data":
            setLocalDataTrack((prev: LocalTrackAtom) => {
              return {
                ...prev,
                track: track,
                state: LocalTrackState.READY,
              };
            });
            break;
        }
      }
      return tracks;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publishLocalAudioTrack, publishLocalVideoTrack],
  );

  return useCallback(async () => {
    try {
      return await publishTracks();
    } catch (e) {
      const error = e as Error;
      handleAudioTrackError(error);
      handleVideoTrackError(error);
    }
  }, [handleAudioTrackError, handleVideoTrackError, publishTracks]);
}

export function useInitLocalTracks(isEnabled: boolean = true) {
  const publishLocalTracks = usePublishLocalTracksCallback();
  useResetLocalVideoOnScreenOrientationChange();
  const validateStoredDeviceIds = useValidateStoredDeviceIdsCallback();

  useEffect(
    function initTracksOnMount() {
      if (!isEnabled) return;

      (async () => {
        await validateStoredDeviceIds();
        publishLocalTracks();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
