import { Route, Routes as RouterRoutes } from "react-router-dom";

import { Page as VisitorSessionPage } from "./Page";
import { Page as NoShowPage } from "./no-show-confirmation-page";
import { Error404Page } from "../error/404Page";

export function Routes() {
  return (
    <RouterRoutes>
      <Route index element={<VisitorSessionPage />} />
      <Route path="/no-show" element={<NoShowPage />} />
      <Route path="*" element={<Error404Page />} />
    </RouterRoutes>
  );
}
