import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CallbackFailure, CallbackSuccess } from "../../types";

const GET_PRESIGNED_URL_MUTATION_GQL = gql`
  mutation GetPresignedUrl($appointmentShortId: String!) {
    getPresignedUrl(appointmentShortId: $appointmentShortId) {
      url
    }
  }
`;

type UseGetPresignedUrlMutationVariables = {
  appointmentShortId: string;
};

type UseGetPresignedUrlMutationData = {
  getPresignedUrl: {
    url?: string;
  };
};

export function useGetPresignedUrlMutation() {
  return useMutation<
    UseGetPresignedUrlMutationData,
    UseGetPresignedUrlMutationVariables
  >(GET_PRESIGNED_URL_MUTATION_GQL);
}

type GetPresignedUrlCallbackResponse =
  | CallbackSuccess<UseGetPresignedUrlMutationData>
  | CallbackFailure<Error>;

export function useGetPresignedUrlCallback() {
  const [getPresignedUrl] = useGetPresignedUrlMutation();

  return useCallback(
    async (
      appointmentShortId: string,
    ): Promise<GetPresignedUrlCallbackResponse> => {
      try {
        const data = (
          await getPresignedUrl({ variables: { appointmentShortId } })
        )?.data;
        if (!data) {
          throw new Error(
            "Failed to fetch presigned URL; missing data in response",
          );
        }
        return {
          status: "success",
          value: data,
        };
      } catch (error) {
        return {
          status: "failure",
          value: error as Error,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
