import { PageComponentProps } from "../../components";
import { PendingForm } from "../../components/forms/PendingForm";
import {
  GroupedFormData,
  PendingFormProps,
} from "../../components/forms/types";
import { visitorFormValues } from "../state";

import { useAtom } from "jotai";
import { isFunction } from "remeda";

export function ClientPendingFormsWrapper({
  page,
}: PageComponentProps<GroupedFormData>) {
  const formTitle = page?.formTitle;
  const [formValuesByTitle, setFormValuesByTitle] = useAtom(visitorFormValues);
  const initialFormValues = formValuesByTitle && formValuesByTitle[formTitle];
  const onChange: PendingFormProps["onChange"] = (valuesOrFunction) => {
    setFormValuesByTitle((prev) => {
      const previousFormValues = prev?.[formTitle];
      return {
        ...prev,
        [formTitle]: isFunction(valuesOrFunction)
          ? valuesOrFunction(previousFormValues)
          : valuesOrFunction,
      };
    });
  };
  return (
    <PendingForm
      form={page}
      onChange={onChange}
      initialValues={initialFormValues}
    />
  );
}
