import { ConsentStatus } from "../types";
import { DataTrackProperty } from "./types";

export enum DataTrackUpdateStatus {
  NO_UPDATE_NEEDED = "NO_UPDATE_NEEDED",
  VALUE_AND_LAST_UPDATED_CHANGED = "VALUE_AND_LAST_UPDATED_CHANGED",
  LAST_UPDATED_CHANGED = "LAST_UPDATED_CHANGED",
}

export function getNewestDataTrackState<T>({
  receivedState,
  userState,
}: {
  receivedState: DataTrackProperty<T>;
  userState: DataTrackProperty<T>;
}): [DataTrackProperty<T>, DataTrackUpdateStatus] {
  if (receivedState.lastUpdated > userState.lastUpdated) {
    return [
      receivedState,
      receivedState.value === userState.value
        ? DataTrackUpdateStatus.LAST_UPDATED_CHANGED
        : DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED,
    ];
  }
  return [userState, DataTrackUpdateStatus.NO_UPDATE_NEEDED];
}

export function resolveCorrectConsentState({
  receivedState,
  userState,
}: {
  receivedState: DataTrackProperty<ConsentStatus>;
  userState: DataTrackProperty<ConsentStatus>;
}): [DataTrackProperty<ConsentStatus>, DataTrackUpdateStatus] {
  const [newestState, comparisonResult] = getNewestDataTrackState({
    receivedState,
    userState,
  });
  const olderState =
    comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED
      ? receivedState
      : userState;

  /** We have some additional guards for consent status updates,
   * so that certain values cannot be overwritten
   */
  const consentStatesHaveDifferentValues =
    olderState.value !== newestState.value;

  /** A soft no should override everything except for an opt in or deciding state,
   * regardless of which is newer.
   */
  if (
    consentStatesHaveDifferentValues &&
    olderState.value === ConsentStatus.SOFT_NO &&
    !(
      newestState.value === ConsentStatus.OPTED_IN ||
      newestState.value === ConsentStatus.DECIDING
    )
  ) {
    return [
      { value: olderState.value, lastUpdated: Date.now() },
      DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED,
    ];
  }

  /** An opt in should override everything except for a soft no,
   * regardless of which is newer.
   */
  if (
    consentStatesHaveDifferentValues &&
    olderState.value === ConsentStatus.OPTED_IN &&
    newestState.value !== ConsentStatus.SOFT_NO
  ) {
    return [
      { value: olderState.value, lastUpdated: Date.now() },
      DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED,
    ];
  }

  return [newestState, comparisonResult];
}
