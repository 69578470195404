import { gql, useQuery } from "@apollo/client";
import { NoteTypeEnum } from "./client-information/types";
import { AppointmentObjectSortEnum } from "./types";
import { Exact, InputMaybe, Scalars } from "../types";
import {
  AppointmentLocation,
  AppointmentType,
  AppointmentsFilter,
  RecurringAppointmentGrouping,
} from "./schedule-preview/types";
import { Server } from "../apollo/types";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type TimelineAppointment = {
  __typename?: "AppointmentObject";
  id: string;
  recurringAppointmentGroupingId?: number | null;
  timeStart: any;
  timeEnd: any;
  patientId?: number | null;
  appointmentShortId: string;
  appointmentType: AppointmentType;
  status: string;
  inOffice: AppointmentLocation;
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null;
  appointmentClaim?: {
    __typename?: "ClaimObject";
    id: string;
    auditNote?: string | null;
    processNote?: string | null;
    progressNote?: string | null;
    payoutDate?: any | null;
    payoutMessage?: string | null;
    expectedPayout: any;
    totalPaidOut?: number | null;
    noteType?: string | null;
  } | null;
  notes?: Array<{
    __typename?: "NoteObject";
    id: number;
    isDraft: boolean;
    noteType: NoteTypeEnum;
    noteSummary?: string | null;
  }> | null;
};

export type TimelineAppointmentEdge = {
  __typename?: "AppointmentObjectEdge";
  cursor: string;
  node: TimelineAppointment;
};

export type GetTimelineAppointmentsQueryData = {
  __typename?: "Query";
  currentProvider?: {
    __typename?: "ProviderObject";
    id: string;
    providerAppointments?: {
      __typename?: "AppointmentObjectConnection";
      totalCount?: number | null;
      edges: Array<TimelineAppointmentEdge>;
    } | null;
  } | null;
};

export type GetTimelineAppointmentsQueryVariables = Exact<{
  num?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<AppointmentsFilter>;
  sort?: InputMaybe<
    | Array<InputMaybe<AppointmentObjectSortEnum>>
    | InputMaybe<AppointmentObjectSortEnum>
  >;
}>;

export const GET_TIMELINE_APPOINTMENTS_QUERY = gql`
  query GetTimelineAppointments(
    $num: Int
    $after: String
    $filters: AppointmentsFilter
    $sort: [AppointmentObjectSortEnum]
  ) {
    currentProvider {
      id
      providerAppointments(
        first: $num
        after: $after
        filters: $filters
        sort: $sort
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            recurringAppointmentGroupingId
            appointmentShortId
            timeStart
            timeEnd
            patientId
            appointmentType
            status
            inOffice
            appointmentClaim {
              id
              auditNote
              processNote
              progressNote
              payoutDate
              payoutMessage
              expectedPayout
              totalPaidOut
              noteType
            }
            attachments {
              id
              fileName
              archivedAt
              presignedS3DownloadUrl
              attachmentType
            }
            notes {
              id
              isDraft
              noteType
              noteSummary
            }
            recurringAppointmentGrouping {
              indefinite
              recurringAppointmentGroupingId
              weeksBetween
              appointments {
                id
                timeStart
              }
            }
          }
        }
      }
    }
  }
`;

export function useGetUpcomingAppointmentsQuery(
  patientId: string,
  timeStart: string,
) {
  return useQuery<
    GetTimelineAppointmentsQueryData,
    GetTimelineAppointmentsQueryVariables
  >(GET_TIMELINE_APPOINTMENTS_QUERY, {
    context: { server: Server.MONOLITH },
    variables: {
      filters: {
        patientShortIds: [patientId],
        timeStartGte: timeStart,
        canceled: false,
      },
      sort: [AppointmentObjectSortEnum.TIME_START_ASC],
    },
  });
}
