import { useCallback } from "react";
import { visitorAtom } from "../state";
import { UserType } from "../../types";
import { useSendParticipantStateCallback as useSendParticipantStateCallback } from "../../twilio/messages/useSendParticipantStateCallback";
import { useAtomValue } from "jotai";
import { transcriptionFeatureEnabledAtom } from "../session-recording/state";
import { useAtomCallback } from "jotai/utils";

export function useSendVisitorParticipantStateCallback() {
  const getVisitor = useAtomCallback(
    useCallback((get) => get(visitorAtom), []),
  );
  const isTranscriptionFeatureEnabled = useAtomValue(
    transcriptionFeatureEnabledAtom,
  );

  const sendParticipantState = useSendParticipantStateCallback();

  return useCallback(() => {
    if (!isTranscriptionFeatureEnabled) {
      return;
    }
    const { visitorUuid } = getVisitor();
    sendParticipantState({
      entityId: visitorUuid,
      entityType: UserType.CLIENT,
    });
  }, [sendParticipantState, isTranscriptionFeatureEnabled, getVisitor]);
}
