import { useAtomValue } from "jotai";
import { useInitGlobalDDLoggerContext } from "../datadog";
import { visitorStateAtom } from "./state";
import { useVisitorId } from "./useVisitorId";
import { useParams } from "react-router-dom";

export function useInitVisitorGlobalDDLoggerContext() {
  const visitorId = useVisitorId();
  const visitorState = useAtomValue(visitorStateAtom);
  const { providerShortId } = useParams<{ providerShortId: string }>();
  useInitGlobalDDLoggerContext({
    providerShortId,
    visitorId,
    visitorState,
  });
}
