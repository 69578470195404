import { InputControl, Text } from "@grow-therapy-team/sprout-ui";
import { QuestionTypeEnum } from "../types";
import classNames from "classnames";
import { getWordCount, validateValue } from "./utils";
import { QuestionProps } from "./types";
import { InputField } from "./InputField";

/**
 * Renders a form question with the appropriate input field based on the
 * question type. This component is uncontrolled and will maintain its own
 * internal state.
 *
 * Does not support complex intake form questions.
 */
export function Question(props: QuestionProps) {
  const { question, type, value } = props;
  const { title, placeholder: _placeholder } = question;
  const label = title;
  const isInlineRadio =
    type === QuestionTypeEnum.RADIO && question.layout === "inline";

  const { inputControlError, shouldShowBasicRequiredError } =
    validateValue(props);
  const wordCount =
    type === QuestionTypeEnum.TEXT_AREA
      ? getWordCount(question, value)
      : undefined;

  return (
    <>
      <InputControl
        id={question.name}
        error={inputControlError}
        label={label}
        required={question.required}
        showNecessityIndicator={false}
        className={classNames("mb-0", {
          "-mt-4": !label && question.visibleIf,
        })}
        errorClassName="text-base"
        layoutClassName={classNames({
          "flex items-center justify-between grow": isInlineRadio,
        })}
        labelClassName={classNames("text-base", { "mb-0": isInlineRadio })}
        wordCount={wordCount}
      >
        <InputField {...props} />
      </InputControl>
      {shouldShowBasicRequiredError && (
        <Text className="mb-2 text-coral-700">This field is required</Text>
      )}
    </>
  );
}
