export const EMPTY_INPUT_ERROR =
  "This field is required. Please enter your first and last name.";
export const TOO_LONG_INPUT_ERROR =
  "This entry is too long. Please choose a name that is less than 40 characters.";
export const UNSAFE_CHARACTERS_INPUT_ERROR =
  "This entry contains characters that may not be accepted. If admission fails, please try again with a different name.";

export const MAX_NAME_LENGTH = 40;
export const ALLOWED_ACCENTED_CHARACTERS =
  "ãñõáéíóúàèìòùâêîôûäëïöüÿåçøÃÑÕÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÄËÏÖÜŸÅÇØ";
