import { useEffect, useState } from "react";
import { logger } from "./datadog/logger";

export function ClearState() {
  const [clearingLocalStorage, setClearingLocalStorage] = useState(true);

  useEffect(function clearState() {
    localStorage.clear();
    sessionStorage.clear();
    setClearingLocalStorage(false);
    logger.info("Cleared local storage");
  }, []);

  return (
    <h1 className="flex gap-4 h-screen flex-col justify-center items-center">
      {clearingLocalStorage ? (
        "Clearing local storage..."
      ) : (
        <>
          🧹 Local storage cleared{" "}
          <h2>
            <a href="/provider">Go to Telehealth</a>
          </h2>
        </>
      )}
    </h1>
  );
}
