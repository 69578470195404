import { gql, useQuery } from "@apollo/client";
import { logger } from "../datadog/logger";
import { Server } from "../apollo/types";
import { QuestionTypeEnum, FormType } from "../components/forms/types";
import { useSetAtom, useAtomValue } from "jotai";
import { visitorAtom, visitorFormData } from "./state";

export type GetPatientFormsData = {
  patientIntakeForms: {
    forms: Array<{
      formId: string;
      formTitle: string;
      formVersion: number;
      formType: string;
      providerId: number;
      formModel: {
        formType: string;
        formPrompt: string;
        formTitle: string;
        pages: {
          sectionTitle: string;
          elements: {
            name: string;
            required: boolean;
            type: QuestionTypeEnum;
            title: string;
            maxLength?: number | null;
            visibleIf?: string | null;
            layout?: string | null;
            placeholder?: string | null;
            options: {
              value: string;
              label: string;
            }[];
          }[];
        }[];
      };
    }>;
    latestFormResponseId: string;
  };
};

export type GetPatientFormsByCustomerIdVariables = {
  customerId?: string;
};

export const GET_PATIENT_FORMS_BY_CUSTOMER_ID_GQL = gql`
  query GetPatientFormsByCustomerId($customerId: ID!) {
    patientIntakeForms(values: { customerId: $customerId }) {
      forms {
        formId
        formTitle
        formVersion
        formType
        providerId
        formModel {
          formPrompt
          formTitle
          pages {
            sectionTitle
            elements {
              type
              placeholder
              maxLength
              visibleIf
              layout
              name
              title
              required
              options {
                value
                label
              }
            }
          }
        }
      }
    }
  }
`;

export function useGetPatientFormsByCustomerId() {
  const setVisitorFormData = useSetAtom(visitorFormData);
  const setVisitorData = useSetAtom(visitorAtom);
  const { customerId } = useAtomValue(visitorAtom);

  return useQuery<GetPatientFormsData, GetPatientFormsByCustomerIdVariables>(
    GET_PATIENT_FORMS_BY_CUSTOMER_ID_GQL,
    {
      variables: { customerId: customerId },
      skip: !customerId,
      context: { server: Server.MONOLITH, skipAuth: true },
      onError: (error) => {
        logger.error("Unable to fetch forms", { customerId }, error);
      },
      onCompleted(data) {
        const forms = data.patientIntakeForms?.forms.filter((form) => {
          return (
            form.formType === FormType.Gad7 || form.formType === FormType.Phq9
          );
        });
        setVisitorFormData((prev) => ({
          ...prev,
          providerForms: forms ?? [],
        }));
        setVisitorData((prev) => ({
          ...prev,
          providerId: forms[0]?.providerId.toString() ?? "",
        }));
      },
    },
  );
}
