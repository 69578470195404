import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useRef } from "react";

import { gracefullyPlayAudio } from "../audio";
import {
  getSmoothingFunction,
  setVolumeWithAnalyser,
} from "../twilio/useGetInputVolume";
import { createDerivedWritableAtomFamily } from "../utils";

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;

type AudioPlayback = {
  isPlaying: boolean;
  volume: number;
};
type AudioFile = string;

// use a common state, so playing one button disables all other buttons that
// play the same audio file, and the volume indicators are in sync
const audioPlaybackAtom = atom<Record<AudioFile, AudioPlayback>>({});
const volumeAtom = createDerivedWritableAtomFamily(
  audioPlaybackAtom,
  "volume",
  { defaultGetValue: 0 },
);
const isPlayingAtom = createDerivedWritableAtomFamily(
  audioPlaybackAtom,
  "isPlaying",
  { defaultGetValue: false },
);

const defaultSmoothingFunction = getSmoothingFunction();
export function usePlayAudioCallback(
  audioFile: AudioFile,
  { smoothingFunction = defaultSmoothingFunction } = {},
) {
  const audioContextRef = useRef<AudioContext>();
  const audioRef = useRef<HTMLAudioElement>();

  const [volume, setVolume] = useAtom(volumeAtom(audioFile));
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom(audioFile));

  const startPlaying = useCallback(() => setIsPlaying(true), [setIsPlaying]);
  const stopPlaying = useCallback(() => setIsPlaying(false), [setIsPlaying]);

  const playAudio = useCallback(
    async (deviceId?: string) => {
      const audioContext = new AudioContext();
      audioContextRef.current = audioContext;
      if (deviceId) {
        // @ts-ignore setSinkId is an experimental feature
        audioContext.setSinkId(deviceId);
      }
      const audio = new Audio(audioFile);
      audioRef.current = audio;

      audio.addEventListener("play", startPlaying);

      audio.addEventListener("ended", () => {
        stopPlaying();
        // To enable garbage collection
        audioContext.close();
        // keeping this around causes problems when you try to replay a sound
        // using a different component (e.g., two buttons that do the same thing)
        audioRef.current = undefined;
      });

      await gracefullyPlayAudio(audio);
    },
    [audioFile], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function handleVolumeAnalyser() {
      const audioContext = audioContextRef.current;
      const audio = audioRef.current;

      if (!isPlaying || !audioContext || !audio) return;

      const sourceNode = audioContext.createMediaElementSource(audio);
      const analyser = audioContext.createAnalyser();
      sourceNode.connect(analyser);
      analyser.connect(audioContext.destination);

      return setVolumeWithAnalyser(analyser, setVolume);
    },
    [isPlaying, setVolume],
  );

  // I would prefer that the smoothingFunction be applied to the value stored in
  // state, but that would make multiple components with different smoothing
  // functions impossible. keep an eye on extra re-renders from this
  return { playAudio, volume: smoothingFunction(volume), isPlaying };
}
