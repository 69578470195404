import { ReactNode, useReducer } from "react";
import {
  DEFAULT_PENDING_FORMS_CONTEXT_VALUE,
  PendingFormsContext,
  pendingFormsContextReducer,
} from "./state";

export function PendingFormsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [pendingFormsContextValue, dispatchPendingFormsContextAction] =
    useReducer(pendingFormsContextReducer, DEFAULT_PENDING_FORMS_CONTEXT_VALUE);

  return (
    <PendingFormsContext.Provider
      value={{ ...pendingFormsContextValue, dispatchPendingFormsContextAction }}
    >
      {children}
    </PendingFormsContext.Provider>
  );
}
