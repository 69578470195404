import { useCallback, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { logger } from "../datadog/logger";
import { memoizedDeserializerWithInitialValue } from "../utils";

export interface Dismissible {
  dismissedAt?: number;
  dismissedUntil?: number;
  updatedAt?: number;
}

export const DISMISSIBLE_KEY_ROOT = "dismissible>";

function getIsDismissed(dismissibleState: Dismissible) {
  const now = Date.now();

  let { dismissedAt, dismissedUntil } = dismissibleState;

  if (!dismissedAt || !Number.isFinite(dismissedAt)) {
    // if dismissedAt is in the past, then isDismissed will be true, so we
    // default to a number sure to be in the future
    dismissedAt = Number.POSITIVE_INFINITY;
  }

  if (!dismissedUntil || !Number.isFinite(dismissedUntil)) {
    // the default behavior for dismissedUntil is "forever" so we default to a
    // number sure to be in the future
    dismissedUntil = Number.POSITIVE_INFINITY;
  }

  return dismissedAt <= now && now < dismissedUntil;
}

function asNumber(n?: Date | number | string, defaultValue?: number) {
  const value = n instanceof Date ? n.getTime() : Number(n);

  // this return value may look strange, but it is designed to preserve NaN in
  // the event no default is passed
  return value || defaultValue || value;
}

const DEFAULT_DISMISSIBLE: Dismissible = {
  dismissedAt: undefined,
  dismissedUntil: undefined,
  updatedAt: undefined,
};
const deserializerToEnsureObjectEquality = memoizedDeserializerWithInitialValue(
  DEFAULT_DISMISSIBLE,
  (error) => {
    logger.error("JSON parse error", undefined, error);
  },
);

export function useDismissible(uniqueName: string) {
  const [dismissibleState, setDismissibleState] = useLocalStorage(
    `${DISMISSIBLE_KEY_ROOT}${uniqueName}`,
    DEFAULT_DISMISSIBLE,
    { deserializer: deserializerToEnsureObjectEquality },
  );
  // don't _need_ state necessarily, but the UI won't update w/o it
  const [isDismissed, setIsDismissed] = useState(
    getIsDismissed(dismissibleState),
  );

  const setDismissibleStateCallback = useCallback(
    (dismissedAt?: Date | number, dismissedUntil?: Date | number) => {
      setDismissibleState((current) => {
        const newState = {
          dismissedAt: asNumber(dismissedAt, current.dismissedAt),
          dismissedUntil: asNumber(dismissedUntil, current.dismissedUntil),
          updatedAt: Date.now(),
        };

        setIsDismissed(getIsDismissed(newState));

        return newState;
      });
    },
    [setDismissibleState],
  );

  return {
    isDismissed,
    dismissibleState,
    setDismissibleState: setDismissibleStateCallback,
  };
}
