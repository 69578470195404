import { useCallback } from "react";
import { useInitBackgroundProcessorsCallback } from "../useInitBackgroundProcessorsCallback";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import { BackgroundType, getStoredBackgroundSettings } from "../config";
import { CallbackError, CallbackSuccess } from "../../types";
import { LocalVideoTrack } from "twilio-video";
import { LocalTrack } from "../types";
import { logger } from "../../datadog/logger";

export enum ChangeCameraStatusCode {
  "CHANGED" = "changed",
  "BACKGROUND_ERROR" = "background_error",
  "ERROR" = "error",
}

export function useChangeCameraDeviceCallback() {
  const publishLocalVideoTrack = usePublishLocalTrackCallback("video");
  const setBackground = useInitBackgroundProcessorsCallback();

  return useCallback(
    async (
      deviceId: string,
    ): Promise<
      | CallbackSuccess<LocalVideoTrack, ChangeCameraStatusCode>
      | CallbackError<ChangeCameraStatusCode>
    > => {
      let track: LocalTrack | undefined;
      if (
        !(track = await publishLocalVideoTrack({
          trackOptions: { deviceId: { exact: deviceId } },
        }))
      ) {
        logger.error("Unable to change camera device");
        return {
          status: "error",
          code: ChangeCameraStatusCode.ERROR,
        };
      }
      const backgroundSettings = getStoredBackgroundSettings();
      const hasBackground =
        backgroundSettings.backgroundType &&
        backgroundSettings.backgroundType !== BackgroundType.NONE;
      if (
        hasBackground &&
        !(await setBackground(getStoredBackgroundSettings()))
      ) {
        logger.error("Unable to set background after changing camera device");
        return {
          status: "error",
          code: ChangeCameraStatusCode.BACKGROUND_ERROR,
        };
      }
      return {
        status: "success",
        code: ChangeCameraStatusCode.CHANGED,
        value: track as LocalVideoTrack,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
