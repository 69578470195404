import { gql, useQuery } from "@apollo/client";
import { Server } from "../apollo/types";
import { NoteTypeEnum } from "./client-information/types";
import { logger } from "../datadog/logger";

export const GET_CURRENT_NOTE_SCHEMA = gql`
  query GetCurrentNoteSchema($patientId: Int!, $noteType: NoteTypeEnum!) {
    currentNoteSchema(values: { patientId: $patientId, noteType: $noteType }) {
      id
      noteType
      sections {
        id
        name
        label
        position
        fields {
          name
          label
          margin
          textVariant
          fieldType
          description
          options {
            value
            label
          }
        }
        description
        fieldGroups
        descriptionReadOnlyHidden
      }
    }
  }
`;

export type GetCurrentNoteSchemaVariables = {
  patientId: number;
  noteType: NoteTypeEnum;
};

export type GetCurrentNoteSchemaResponse = {
  currentNoteSchema: {
    id: string;
    noteType: string;
    sections: {
      id: string;
      name: string;
      label: string;
      position?: number;
      fields: {
        name: string;
        textVariant: string;
        margin: string;
        label: string;
        fieldType: string;
        description: string;
        options: {
          value: string;
          label: string;
        }[];
      }[];
    }[];
  };
};

export function useGetCurrentNoteSchema(
  patientId: number,
  noteType: NoteTypeEnum,
) {
  return useQuery<GetCurrentNoteSchemaResponse, GetCurrentNoteSchemaVariables>(
    GET_CURRENT_NOTE_SCHEMA,

    {
      variables: { patientId, noteType },
      context: { server: Server.MONOLITH },
      skip: !patientId || !noteType,
      onError: (error) => {
        logger.error(
          "Unable to fetch note schema",
          { patientId, noteType },
          error,
        );
      },
    },
  );
}
