import { gql, useQuery } from "@apollo/client";
import { Server } from "../../apollo/types";
import { logger } from "../../datadog/logger";

export type AppointmentConflict = {
  __typename?: "ConflictAppointmentType";
  appointmentId: number;
  appointmentType: string;
  timeStart: string;
  timeEnd: string;
  patientId: number;
  patientName: string;
  recurringAppointmentGroupingId?: number | null;
} | null;

export type BusyBlockConflict = {
  __typename?: "ConflictBusyBlockType";
  busyBlockId: number;
  recurringBusyBlockGroupingId?: number | null;
  timeStart: string;
  timeEnd: string;
} | null;

export type ThirdPartyEventConflict = {
  __typename?: "ConflictThirdPartyEventType";
  isThirdParty: boolean;
  timeStart: string;
  timeEnd: string;
} | null;

export type GetEventConflictsQueryData = {
  __typename?: "Query";
  conflictAppointments?: Array<AppointmentConflict> | null;
  conflictBusyBlocks?: Array<BusyBlockConflict> | null;
  conflictThirdPartyEvents?: Array<ThirdPartyEventConflict> | null;
};

export type GetEventConflictsQueryVariables = {
  values: {
    timeStart?: string;
    timeEnd?: string;
    recurring: boolean;
    numRecurrences: number;
    weeksBetween: number;
  };
};

export const GET_EVENT_CONFLICTS = gql`
  query GetEventConflicts($values: AppointmentConflictsWithInputQueryInput!) {
    conflictAppointments(values: $values) {
      appointmentId
      appointmentType
      timeStart
      timeEnd
      patientId
      patientName
      recurringAppointmentGroupingId
    }
    conflictBusyBlocks(values: $values) {
      busyBlockId
      recurringBusyBlockGroupingId
      timeStart
      timeEnd
    }
    conflictThirdPartyEvents(values: $values) {
      isThirdParty
      timeStart
      timeEnd
    }
  }
`;

export function useGetEventConflicts(
  startDate: Date,
  endDate: Date,
  isRecurring: boolean,
  numRecurrences: number,
  weeksBetween: number,
) {
  return useQuery<GetEventConflictsQueryData, GetEventConflictsQueryVariables>(
    GET_EVENT_CONFLICTS,
    {
      variables: {
        values: {
          timeStart: startDate.toISOString(),
          timeEnd: endDate.toISOString(),
          recurring: isRecurring,
          numRecurrences,
          weeksBetween,
        },
      },
      context: { server: Server.MONOLITH },
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        logger.error(
          "Unable to get event conflicts",
          { startDate, endDate, isRecurring, numRecurrences, weeksBetween },
          error,
        );
      },
    },
  );
}
