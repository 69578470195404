import {
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from "twilio-video";
import { MAX_ITEMS_PER_MAP_PAGE, MAX_MAP_PAGE_COUNT } from "./config";
import { UserData } from "../types";
import { Participant as ChatParticipant } from "@twilio/conversations";
import { OpenMapOptions, SyncMap, Client as SyncClient } from "twilio-sync";
import { logger } from "../datadog/logger";
import { pipe, identity, isArray, isObject, mapValues } from "remeda";
import {
  Constraint,
  MessagesByConversationSid,
  TrackSubscriptionHandler,
} from ".";
import { memoize } from "../utils";
import { Mutex } from "async-mutex";

export async function connectSyncMap(
  client: SyncClient,
  mapOptions: OpenMapOptions,
) {
  const { id: mapSid } = mapOptions;
  try {
    // Times out if we don't connect to the map within 30 seconds
    const map = await client.map(mapOptions);
    logger.info("Connected to sync map", { mapSid });
    return map;
  } catch (error) {
    logger.error("Error connecting to sync map", { mapSid }, error as Error);
  }
}

function decodeMetadata(str?: string) {
  if (!str) return;
  // backend does a urlsafe base64 encoding
  // atob will do a base64 decode, but we also need to replace the urlsafe part '-' and '_' by hand
  try {
    return atob(str.replace(/-/g, "+").replace(/_/g, "/"));
  } catch {
    // preserve legacy behavior
    return str.replace(/~~/g, "/");
  }
}

export function parseIdentity(identity: string): UserData {
  const [userType, id, rawMetadata] = identity.split("|");
  const metadata = decodeMetadata(rawMetadata);
  return { userType, id, ...(metadata && JSON.parse(metadata)) };
}

export async function fetchMapItems<T>(map: SyncMap): Promise<T[]> {
  let hasPage = true;
  let pageCount = 0;
  const result = [];
  while (hasPage && pageCount++ < MAX_MAP_PAGE_COUNT) {
    const { items, hasNextPage } = await map.getItems({
      pageSize: MAX_ITEMS_PER_MAP_PAGE,
    });
    hasPage = hasNextPage;
    result.push(...(items as T[]));
  }
  return result;
}

export function roomToDiagnosticInfo(room: Room) {
  const { localParticipant, sid, name } = room;
  const { id, userType } = parseIdentity(localParticipant.identity);
  return {
    localParticipantSid: localParticipant.sid,
    localUserId: id,
    localUserType: userType,
    roomSid: sid,
    roomName: name,
  };
}

export function trackToDiagnosticInfo(track: RemoteTrack) {
  return {
    trackSid: track.sid,
    trackKind: track.kind,
    trackName: track.name,
    ...(track.kind === "video"
      ? { trackDimensions: (track as RemoteVideoTrack).dimensions }
      : {}),
  };
}

export function remoteParticipantToDiagnosticInfo(
  participant: RemoteParticipant,
) {
  const { identity, sid } = participant;
  const { id, userType } = parseIdentity(identity);
  return {
    participantSid: sid,
    participantUserId: id,
    participantUserType: userType,
  };
}

export function isRemoteVideoOn(remoteParticipant?: RemoteParticipant) {
  if (!remoteParticipant) return false;
  const videoTrack = Array.from(remoteParticipant?.videoTracks.values()).find(
    (publication) => publication.trackName === "video",
  )?.track;

  return !!videoTrack && videoTrack.isEnabled ? true : false;
}

export function chatParticipantToName({ identity }: ChatParticipant) {
  return identity ? parseIdentity(identity).name : "";
}

export function getChatParticipants(
  participantIds: Set<string>,
  chatParticipants: Record<string, ChatParticipant>,
  twilioIdentity?: string,
) {
  return pipe(
    participantIds,
    (idSet) => Array.from(idSet),
    (ids) => ids.filter((id) => id !== twilioIdentity),
    (ids) => ids.map((id) => chatParticipants[id]),
    (participants) => participants.filter(identity),
    (participants) => new Set(participants),
  );
}

export function getMostRecentMessageInConversation(
  messagesByConversationSid: MessagesByConversationSid,
  conversationSid: string,
) {
  const messages = messagesByConversationSid[conversationSid];
  if (!messages) return;
  return messages[messages.length - 1];
}

const OGWebSocket = WebSocket;

class FallbackWebsocket extends OGWebSocket {
  constructor(givenUrl: string | URL, protocols?: string | string[]) {
    const wsFallbackUrlMap: Record<string, string> = {
      "wss://tsock.us1.twilio.com/v3/wsconnect": `wss://${window.location.hostname}/wss`,
    };
    const url = wsFallbackUrlMap[givenUrl.toString()] || givenUrl;

    super(url, protocols);

    this.addEventListener("open", (event) =>
      logger.debug("websocket open", event),
    );
    this.addEventListener("close", (event) =>
      logger.debug("websocket close", event),
    );
    this.addEventListener("error", (event) => {
      logger.warn("websocket error", event);
    });
  }
}

export const reverseProxyWebsockets = memoize(() => {
  logger.info("Using reverse proxy for websockets");
  window.WebSocket = FallbackWebsocket;
});

export function exitPictureInPicture() {
  if (document.pictureInPictureElement) {
    document.exitPictureInPicture();
  }
}

/**
 * Extracts a single value from a constraints object. **Do not use this with
 * multi-value constraint arrays, as this function will only return the first
 * value**.
 */
export function constraintsToValue<ReturnType>(
  constraint: Constraint,
  { shouldLog }: { shouldLog?: boolean } = { shouldLog: true },
) {
  let value;
  const handleArray = (list: Array<string>) => {
    if (list.length > 1 && shouldLog) {
      logger.warn("constraintsToValue called with multi-value array", {
        constraint,
      });
    }
    return list[0];
  };
  if (isArray(constraint)) {
    value = handleArray(constraint);
  } else if (isObject(constraint)) {
    if (isArray(constraint.exact)) {
      value = handleArray(constraint.exact);
    } else if (isArray(constraint.ideal)) {
      value = handleArray(constraint.ideal);
    } else {
      value = constraint.exact ?? constraint.ideal;
    }
  } else {
    value = constraint;
  }

  return value as ReturnType;
}

/**
 * Wraps the given track event handlers in the given mutex, ensuring that only
 * one handler can run at a time. Guaranteeing that global state pertaining to
 * tracks is not modified concurrently when handling track events.
 *
 * This function also ensures that any errors thrown by the handlers are caught
 * and logged.
 */
export function gracefullyHandleTrackEvents<
  Key extends string | number | symbol,
>(mutex: Mutex, handlers: Record<Key, TrackSubscriptionHandler>) {
  return mapValues(
    handlers,
    (handler) =>
      async (...args: Parameters<TrackSubscriptionHandler>) => {
        const release = await mutex.acquire();
        try {
          await handler(...args);
        } catch (error) {
          const [track, _, remoteParticipant] = args;
          const remoteParticipantInfo = remoteParticipant
            ? remoteParticipantToDiagnosticInfo(remoteParticipant)
            : {};
          logger.error(
            "There was an error when handling a remote track event",
            {
              trackKind: track.kind,
              trackName: track.name,
              ...remoteParticipantInfo,
            },
            error as Error,
          );
        } finally {
          release();
        }
      },
  );
}
