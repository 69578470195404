import classNames from "classnames";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastVariant } from "../../../components";
import { twMerge } from "tailwind-merge";

export type JoinWaitingRoomReminderProps = {
  className?: string;
  show?: boolean;
};

export function JoinWaitingRoomReminder({
  className,
  show = true,
}: JoinWaitingRoomReminderProps) {
  return (
    <Toast
      className={twMerge(
        // rebrand:bg-yellow-100 is needed to win a specificity battle with the base Toast component
        "w-full flex flex-row gap-2 bg-yellow-100 rebrand:bg-yellow-100 items-start",
        // consider @starting-style to animate the reveal
        // https://caniuse.com/mdn-css_at-rules_starting-style
        classNames({ hidden: !show }),
        className,
      )}
      data-testid="join-waiting-room-reminder"
      variant={ToastVariant.Neutral}
      isBanner
    >
      <Icon aria-hidden className="mt-1" icon={faArrowUp} />
      <Text variant="sm" className="flex-grow">
        To join the waiting room, enter your name
        <span className="sr-only">&nbsp;above,</span> & press “Continue.”
      </Text>
    </Toast>
  );
}
