import { NewMessageToast } from "../../components/NewMessageToast";
import { Message } from "@twilio/conversations";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useRef } from "react";
import {
  currentConversationSidAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
} from "../../twilio/state";
import {
  VisitorDrawerState,
  activeConversationSidAtom,
  visitorDrawerStateAtom,
} from "../state";
import { parseIdentity } from "../../twilio/utils";
import { toast } from "react-hot-toast";
import { useSetAtom } from "jotai";
import { minutesToMilliseconds } from "date-fns";

export function useShowNewMessageToastCallback() {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);

  const getCurrentConversationSid = useAtomCallback(
    useCallback((get) => get(currentConversationSidAtom), []),
  );
  const getActiveConversationSid = useAtomCallback(
    useCallback((get) => get(activeConversationSidAtom), []),
  );
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);

  const getTwilioIdentity = useAtomCallback(
    useCallback((get) => get(twilioIdentityAtom), []),
  );

  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const toastRef = useRef<string>();
  const removeToast = (givenToastId?: string) => {
    const toastId = givenToastId ?? toastRef.current;
    if (!toastId) return;
    toast.remove(toastId);
    toastRef.current = undefined;
  };

  return useCallback(
    (message: Message) => {
      const isConversationOpen = !!getCurrentConversationSid();
      const isSelf = message.author === getTwilioIdentity();
      if (isSelf || isConversationOpen) return;
      const senderName = message.author
        ? parseIdentity(message.author).name
        : "New message";
      const activeConversationSid = getActiveConversationSid();

      if (toastRef.current) {
        removeToast();
      }

      const toastId = toast.custom(
        ({ id: toastId }) => (
          <NewMessageToast
            className="bottom-left-toast hover:cursor-pointer"
            senderName={senderName}
            messageContent={message.body ?? ""}
            onClick={() => {
              toast.dismiss();
              setDrawerState(VisitorDrawerState.CHAT);
              setCurrentConversationSid(getActiveConversationSid());
              setUnreadMessageCount((prev) =>
                activeConversationSid ? { [activeConversationSid]: 0 } : prev,
              );
            }}
            onClose={() => removeToast(toastId)}
          />
        ),
        { position: "bottom-left", duration: minutesToMilliseconds(1) },
      );
      toastRef.current = toastId;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
