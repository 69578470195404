import { useAtomValue } from "jotai";
import { visitorStateAtom } from "./state";
import { useVisitorId } from "./useVisitorId";
import { useInitUserTracking } from "../hooks/useInitUserTracking";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export function useInitVisitorUserTracking() {
  const visitorId = useVisitorId();
  const visitorState = useAtomValue(visitorStateAtom);
  const { providerShortId } = useParams();
  const additionalVars = useMemo(
    () => ({ providerShortId, visitorState }),
    [providerShortId, visitorState],
  );

  useInitUserTracking(visitorId, additionalVars);
}
