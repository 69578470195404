import {
  GroupedFormData,
  FormResponse,
  ProviderForm,
  CombinedFormDataEntriesByFormTitle,
  ProviderFormElement,
  FormResponseElement,
  QuestionTypeEnum,
  AnswerValue,
  FormType,
  GroupedResponse,
  SectionGroup,
} from "../forms/types";
import { groupBy, indexBy, isEmpty } from "remeda";
import { gracefullyParseJson } from "../../utils";

type ProviderFormObject = Record<string, ProviderForm>;

export function combinedFormToQuestions(combinedForm: GroupedFormData) {
  return Object.values(combinedForm.formQuestionsWithAnswers).flat();
}
export function removeIntakeForm(formData: CombinedFormDataEntriesByFormTitle) {
  return Object.entries(formData).reduce(
    (aggr, [formTitle, groupedFormData]) => {
      const formType = groupedFormData[0]?.formType;
      if (formType === FormType.Intake) {
        return aggr;
      }
      return {
        ...aggr,
        [formTitle]: groupedFormData,
      };
    },
    {} as CombinedFormDataEntriesByFormTitle,
  );
}

export function groupByFormTitle(forms: GroupedFormData[]) {
  return groupBy(forms, (form) => form.formTitle);
}

export const FORM_ORDER = [
  FormType.Phq9,
  FormType.Gad7,
  FormType.NoteToProvider,
  FormType.Intake,
  FormType.Safetyplan,
];
function processAnswer(
  element: { name: string; value: string },
  formType: FormType,
  question?: ProviderFormElement,
): AnswerValue {
  if (
    question?.options &&
    formType !== FormType.Intake &&
    formType !== FormType.NoteToProvider
  ) {
    const option = question.options.find((o) => o.value === element.value);
    return option ? `${option.label} (${element.value})` : element.value;
  }

  const parsedAnswer = gracefullyParseJson<AnswerValue>(element.value)!;
  switch (question?.type) {
    case QuestionTypeEnum.MEDICATION_INPUT:
    case QuestionTypeEnum.PROFESSIONAL_RESOURCE_INPUT:
    case QuestionTypeEnum.EMERGENCY_CONTACT_INPUT:
    case QuestionTypeEnum.MULTI_SELECT:
    case QuestionTypeEnum.CHECKBOX:
      return parsedAnswer;
    default:
      return element.value;
  }
}

export function flattenProviderElements(
  matchedProviderForm?: ProviderForm,
): ProviderFormElement[] {
  return (
    matchedProviderForm?.formModel.pages.reduce<ProviderFormElement[]>(
      (acc, page) => {
        const elements = page.elements.map((element) => ({
          ...element,
          sectionTitle: page.sectionTitle,
        }));
        return acc.concat(elements);
      },
      [],
    ) ?? []
  );
}

export function combineQuestionAndAnswer(
  formType: FormType,
  question: ProviderFormElement,
  answer?: FormResponseElement,
): GroupedResponse {
  const processedAnswer = answer && processAnswer(answer, formType, question);
  return {
    question: question.title,
    questionName: question.name,
    type: question.type as QuestionTypeEnum,
    answerElement: answer,
    questionElement: question,
    /**
     * TODO: Change this field name to indicate that it's a "processed"
     * version of the answer
     */
    answer: processedAnswer,
    sectionTitle: question.sectionTitle,
  };
}
function flattenFormResponseElements(formResponse?: FormResponse) {
  return (
    formResponse?.formResponseModel?.pages.reduce<FormResponseElement[]>(
      (acc, page) => {
        return [...acc, ...page.elements];
      },
      [],
    ) ?? []
  );
}

export function mapQuestionsAndAnswers(
  providerForm: ProviderForm,
  formResponse: FormResponse,
): GroupedResponse[] {
  const questions = flattenProviderElements(providerForm);
  const answers = formResponse ? flattenFormResponseElements(formResponse) : [];
  return questions.map((question) => {
    const answer = answers.find((element) => element.name === question.name);
    return combineQuestionAndAnswer(
      providerForm.formType as FormType,
      question,
      answer,
    );
  });
}
export function checkIfUnwantedForm(form: ProviderForm, hasResponses: boolean) {
  return form.formType === FormType.NoteToProvider && !hasResponses;
}

export function groupResponsesBySection(
  responses: GroupedResponse[],
): SectionGroup {
  return responses.reduce((acc, curr) => {
    const defaultSectionTitle = "Section";
    const title = curr.sectionTitle || defaultSectionTitle;
    acc[title] = [...(acc[title] ?? []), curr];
    return acc;
  }, {} as SectionGroup);
}
function combineFormAndResponse(
  providerForm: ProviderForm,
  response: FormResponse,
): GroupedFormData {
  const questionsWithResponses = mapQuestionsAndAnswers(providerForm, response);
  const groupedBySection = groupResponsesBySection(questionsWithResponses);

  const formattedCreatedAt = new Date(response.createdAt).toLocaleDateString(
    "en-US",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
  );

  return {
    formId: providerForm.formId,
    formPrompt: providerForm.formModel.formPrompt,
    formVersion: providerForm.formVersion,
    formResponseId: response.id,
    score: response.score,
    formType: providerForm.formType as FormType,
    formTitle: providerForm.formModel.formTitle || "",
    formAnswers: response.formResponseModel?.pages,
    highAcuity: response.highAcuity,
    formQuestionsWithAnswers: groupedBySection,
    /** Response submission date
     *
     * TODO: Change this field name to indicate that it's the form response's
     * submission date
     */
    createdAt: formattedCreatedAt,
  };
}

export function sortFormsByFormType(
  forms: GroupedFormData[],
  order: FormType[] = FORM_ORDER,
) {
  return [...forms].sort((a, b) => {
    return (
      order.indexOf(a.formType as FormType) -
      order.indexOf(b.formType as FormType)
    );
  });
}
const getLatestForm = (formA: ProviderForm, formB?: ProviderForm) => {
  if (!formB) return formA;
  return formA.formVersion > formB.formVersion ? formA : formB;
};

function indexByFormId(providerForms: ProviderForm[]): ProviderFormObject {
  return indexBy(providerForms, (form) => form.formId);
}

function mapResponsesToQuestions(
  formResponse: FormResponse,
  allProviderElements: ProviderFormElement[],
): GroupedResponse[] {
  const groupedReponse = formResponse.formResponseModel.pages.flatMap((page) =>
    page.elements.map((element) => {
      const question = allProviderElements.find((q) => q.name === element.name);
      let answer: AnswerValue = element.value;
      answer = processAnswer(
        element,
        formResponse.formType as FormType,
        question,
      );
      return {
        question: question?.title,
        type: question?.type as QuestionTypeEnum,
        answer,
        sectionTitle: question?.sectionTitle,
      };
    }),
  );
  return groupedReponse;
}

export function combineFormData(
  formResponses?: FormResponse[],
  providerForms?: ProviderForm[],
): GroupedFormData[] {
  if (!formResponses || !providerForms) return [];

  const providerFormsMap = indexByFormId(providerForms);

  return formResponses.map((formResponse) => {
    const matchedProviderForm =
      providerFormsMap[formResponse.formId.toString()];
    const allProviderElements = flattenProviderElements(matchedProviderForm);
    const questionsWithResponses = mapResponsesToQuestions(
      formResponse,
      allProviderElements,
    );
    const groupedBySection = groupResponsesBySection(questionsWithResponses);

    const formattedCreatedAt = new Date(
      formResponse.createdAt,
    ).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return {
      formVersion: NaN,
      formPrompt: "",
      formId: formResponse.formId,
      score: formResponse.score,
      formType: formResponse.formType as FormType,
      formResponseId: formResponse.id,
      formTitle: matchedProviderForm?.formModel.formTitle || "",
      formAnswers: formResponse.formResponseModel.pages,
      highAcuity: formResponse.highAcuity,
      formQuestionsWithAnswers: groupedBySection,
      createdAt: formattedCreatedAt,
    };
  });
}

export function combineFormDataV2(
  formResponses?: FormResponse[],
  providerForms?: ProviderForm[],
) {
  if (!formResponses || !providerForms) return {};

  const providerFormsById = indexByFormId(providerForms);

  return providerForms.reduce<CombinedFormDataEntriesByFormTitle>(
    (acc, providerForm) => {
      const matchedFormResponses = formResponses.filter(
        (response) => response.formType === providerForm.formType,
      );
      const prevFormId = acc[providerForm.formType as FormType]?.[0]?.formId;
      const prevForm = providerFormsById[prevFormId];
      const latestProviderForm = getLatestForm(providerForm, prevForm);

      if (
        checkIfUnwantedForm(latestProviderForm, !isEmpty(matchedFormResponses))
      ) {
        return acc;
      }

      if (isEmpty(matchedFormResponses)) {
        return {
          ...acc,
          [providerForm.formModel.formTitle]: [
            combineFormAndResponse(latestProviderForm, {} as FormResponse),
          ],
        };
      }

      return {
        ...acc,
        [providerForm.formModel.formTitle]: matchedFormResponses.map(
          (response) => combineFormAndResponse(latestProviderForm, response),
        ),
      };
    },
    {},
  );
}
