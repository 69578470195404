import { gql, useMutation } from "@apollo/client";
import { Exact, InputMaybe, Scalars } from "../../types";
import { Server } from "../../apollo/types";
import { GET_PATIENT_BY_PATIENT_SHORT_ID_GQL } from "../../provider/useGetPatientByShortId";

export type SubmitMeasureResponseMutationVariables = Exact<{
  clientUserShortId: Scalars["ID"]["input"];
  measureSchemaShortId: Scalars["ID"]["input"];
  values: SubmitMeasureResponseInput;
}>;

export type SubmitMeasureResponseMutation = {
  __typename?: "Mutations";
  submitMeasureResponse?: {
    __typename?: "SubmitMeasureResponse";
    measureResponse: {
      __typename?: "SubmitMeasureResponseOutput";
      measureResponseShortId: string;
    };
  } | null;
};

export type SubmitMeasureResponseInput = {
  completionCampaign?: InputMaybe<"CLIENT_INFO_IN_SESSION" | "WAITING_ROOM">;
  responseContent: Scalars["String"]["input"];
  startedAt: Scalars["DateTime"]["input"];
  providerShortId?: Scalars["ID"]["input"];
};

const SUBMIT_MEASURE_RESPONSE = gql`
  mutation SubmitMeasureResponse(
    $clientUserShortId: ID!
    $measureSchemaShortId: ID!
    $values: SubmitMeasureResponseInput!
  ) {
    submitMeasureResponse(
      clientUserShortId: $clientUserShortId
      measureSchemaShortId: $measureSchemaShortId
      values: $values
    ) {
      measureResponse {
        measureResponseShortId
      }
    }
  }
`;

export function useSubmitMeasureResponse() {
  return useMutation<
    SubmitMeasureResponseMutation,
    SubmitMeasureResponseMutationVariables
  >(SUBMIT_MEASURE_RESPONSE, {
    context: { server: Server.MONOLITH, skipAuth: true },
    refetchQueries: [GET_PATIENT_BY_PATIENT_SHORT_ID_GQL],
  });
}
