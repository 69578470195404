import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";

export function PageContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const { theme } = useTheme();
  return (
    <div
      className={twMerge(
        classnames(
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          "h-full flex flex-col page-background",
        ),
        className,
      )}
      data-testid="page-container"
    >
      {children}
    </div>
  );
}
