import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { DrawerState, drawerStateAtom } from "../state";
import { selectedFormTitleAtom } from "./state";

export function useOpenClientFormsCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSelectedFormTitle = useSetAtom(selectedFormTitleAtom);

  return useCallback(
    (formTitle: string) => {
      setSelectedFormTitle(formTitle);
      setDrawerState(DrawerState.CLIENT_FORM_RESPONSE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
