import { twMerge } from "tailwind-merge";
import { deviceIsMobile } from "../../utils";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Text } from "@grow-therapy-team/sprout-ui";
import { useEffect, useState } from "react";
import classNames from "classnames";

export function ScreenShareVideo({
  screenShareRef,
  audioRef,
}: {
  screenShareRef: React.MutableRefObject<HTMLVideoElement | null>;
  audioRef?: React.MutableRefObject<HTMLAudioElement | null>;
}) {
  const [instructionShouldFade, setInstructionShouldFade] = useState(false);
  useEffect(function handleFadeInstruction() {
    setInstructionShouldFade(true);
  }, []);

  const isMobile = deviceIsMobile();

  return (
    <TransformWrapper centerOnInit>
      <div
        className="relative overflow-hidden w-full flex h-full justify-center items-center order-2 sm:order-1 sm:mr-2 mt-2 sm:mt-0 cursor-grab rounded-2xl min-h-[250px] bg-neutral-900 rebrand:bg-neutral_rebrand-900 max-h-[calc(100vh-13.125rem)]"
        /* 
         For accessibility: The aria-label and aria-labelledby attributes are not well supported 
         on the HTML5 <audio> and <video> elements at this time. In the meantime, we can 
         add the label to the parent element.
        */
        role="region"
        aria-label="Live screen share video"
      >
        <TransformComponent
          wrapperClass="h-full w-full"
          contentClass="h-full w-full flex justify-center items-center"
        >
          <video // eslint-disable-line  jsx-a11y/media-has-caption
            className={twMerge(
              "relative max-w-full sm:w-unset fs-exclude display-block max-h-full",
              isMobile && "sm:w-full",
            )}
            data-testid={"screenshare-video"}
            ref={screenShareRef}
          />
          {audioRef && (
            <audio // eslint-disable-line  jsx-a11y/media-has-caption
              data-testid="screenshare-audio"
              className="remote-audio"
              ref={audioRef}
            />
          )}
        </TransformComponent>
        {isMobile && (
          <div
            className={twMerge(
              classNames(
                "absolute text-neutral-100 py-4 px-5 bg-neutral-900 rounded-lg pointer-events-none transition-opacity delay-[2000ms]",
                {
                  "opacity-0": instructionShouldFade,
                },
              ),
            )}
          >
            <Text>Pinch out to zoom in</Text>
          </div>
        )}
      </div>
    </TransformWrapper>
  );
}
