import { useCallback, useEffect, useRef } from "react";
import { useRecordUnaccompaniedParticipantMutation } from "./useRecordUnaccompaniedParticipantMutation";
import { twilioRoomAtom } from "../twilio";
import { useAtomCallback } from "jotai/utils";
import { getVisitorPresenceUpdateIntervalMs } from "../config";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";
import { UserType } from "../types";
import { isAbortedRequestError } from "../apollo";
import { logger } from "../datadog/logger";

export function useRecordUnaccompaniedProviderInterval() {
  const [recordUnaccompaniedParticipant] =
    useRecordUnaccompaniedParticipantMutation();

  const { data } = useGetCurrentProviderQuery();
  const providerShortId = data?.currentProvider.shortId;
  const providerName = data?.currentProvider.name;

  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(
    function continuouslyUpdatePatientPresence() {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      const recordUnaccompaniedParticipantCallback = async () => {
        if (!providerShortId || !providerName) {
          return;
        }
        // If a room exists, then a patient has already joined
        if (getTwilioRoom()) {
          return;
        }

        try {
          recordUnaccompaniedParticipant({
            variables: {
              entityShortId: providerShortId,
              entityType: UserType.PROVIDER,
              name: providerName,
            },
            context: {
              skipAuth: true,
            },
          });
        } catch (e) {
          const error = e as Error;
          if (isAbortedRequestError(error)) {
            logger.info("Aborted recording unaccompanied provider", { error });
            return;
          }
          logger.error("Unable to record unaccompanied provider", {}, error);
        }
      };
      recordUnaccompaniedParticipantCallback();
      intervalRef.current = setInterval(
        recordUnaccompaniedParticipantCallback,
        getVisitorPresenceUpdateIntervalMs(),
      );

      return function cleanup() {
        clearInterval(intervalRef.current);
      };
    },
    [
      providerShortId,
      providerName,
      getTwilioRoom,
      recordUnaccompaniedParticipant,
    ],
  );
}
