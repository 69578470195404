import { atom } from "jotai";
import { createDerivedWritableAtom, shortUuid } from "../utils";
import { chatParticipantsByIdAtom } from "../twilio/state";
import { parseIdentity } from "../twilio/utils";
import {
  FormDataGroup,
  FormValuesByFormTitle,
} from "../components/forms/types";
import { OutstandingMeasuresResponse } from "./measures/useGetClientUserPaperwork";
import { AppState, UserType } from "../types";
import { isFunction } from "remeda";
import { combineFormDataV2 } from "../components/forms/utils";

export enum VisitorState {
  INTRO = AppState.INTRO,
  WAITING = AppState.WAITING,
  LOADING = "loading",
  MEETING = AppState.MEETING,
  COMPLETED = "completed",
}

export enum VisitorDrawerState {
  CHAT = "CHAT",
  HELPFUL_TIPS_BEFORE_SESSION = "HELPFUL_TIPS_BEFORE_SESSION",
  HELPFUL_TIPS_AFTER_SESSION = "HELPFUL_TIPS_AFTER_SESSION",
  SETTINGS = "SETTINGS",
  MOOD_CHECK_IN_FORM = "MOOD_CHECK_IN_FORM",
  PENDING_FORMS = "PENDING_FORMS",
  PENDING_FORMS_COMPLETED = "PENDING_FORMS_COMPLETED",
}

export type HelpfulTipsDrawerStates =
  | VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION
  | VisitorDrawerState.HELPFUL_TIPS_AFTER_SESSION;

const MEETING_STATES = new Set([
  VisitorState.LOADING,
  VisitorState.MEETING,
  VisitorState.COMPLETED,
]);

export type OutstandingMeasures =
  OutstandingMeasuresResponse["outstandingMeasures"];

export type VisitorFormData = FormDataGroup;

export type VisitorAtom = {
  name: string;
  state: VisitorState;
  visitorUuid: string;
  clientShortId: string;
  visitorDrawerState: VisitorDrawerState | null;
  activeConversationSid?: string;
  sessionConversationSid?: string;
  customerId: string;
  // This is the provider's monolith PK ID, it's used for certain monolith mutations
  providerId: string;
  providerIsOnline: boolean | null;
  formData: VisitorFormData;
  isHelpModalOpen: boolean;
  visitorAccessDocSid?: string;
  deadline?: Date | null;
  measures: OutstandingMeasures;
  /**
   * Provided during admission for limited API access
   */
  apiToken?: string;
};

export function createVisitor(): VisitorAtom {
  return {
    name: "",
    state: VisitorState.INTRO,
    customerId: "",
    visitorUuid: shortUuid(),
    clientShortId: "",
    providerId: "",
    visitorDrawerState: null,
    providerIsOnline: null,
    isHelpModalOpen: false,
    formData: { providerForms: [], formResponses: [] },
    measures: [],
  };
}

export const visitorAtom = atom<VisitorAtom>(createVisitor());

export const apiTokenAtom = createDerivedWritableAtom(visitorAtom, "apiToken");

export const visitorNameAtom = createDerivedWritableAtom(visitorAtom, "name");

export const visitorClientShortIdAtom = createDerivedWritableAtom(
  visitorAtom,
  "clientShortId",
);

export const visitorStateAtom = createDerivedWritableAtom(visitorAtom, "state");

export const visitorDrawerStateAtom = createDerivedWritableAtom(
  visitorAtom,
  "visitorDrawerState",
);

export const visitorIsHelpModalOpenAtom = createDerivedWritableAtom(
  visitorAtom,
  "isHelpModalOpen",
);

export const visitorGroupedFormsAtom = atom((get) => {
  const { providerForms, formResponses } = get(visitorFormData) ?? {};

  if (!providerForms) return;
  return Object.values(combineFormDataV2(formResponses, providerForms)).flat();
});

export const visitorFormValues = atom(
  (get) => get(visitorFormData)?.formValuesByTitle,
  (
    _get,
    set,
    valueOrFunction:
      | FormValuesByFormTitle
      | ((prev?: FormValuesByFormTitle) => FormValuesByFormTitle),
  ) => {
    set(visitorFormData, (prev) => {
      const prevFormValuesByTitle = prev?.formValuesByTitle;
      return {
        ...prev,
        formValuesByTitle: isFunction(valueOrFunction)
          ? valueOrFunction(prevFormValuesByTitle)
          : valueOrFunction,
      };
    });
  },
);

export const visitorFormData = atom(
  (get) => get(visitorAtom).formData,
  (
    _get,
    set,
    valueOrFunction: FormDataGroup | ((prev: FormDataGroup) => FormDataGroup),
  ) => {
    set(visitorAtom, (prev) => ({
      ...prev,
      formData: isFunction(valueOrFunction)
        ? valueOrFunction(prev?.formData)
        : valueOrFunction,
    }));
  },
);

export const visitorMeasuresAtom = atom(
  (get) => get(visitorAtom).measures,
  (
    _get,
    set,
    valueOrFunction:
      | OutstandingMeasures
      | ((prev: OutstandingMeasures) => OutstandingMeasures),
  ) => {
    set(visitorAtom, (prev) => ({
      ...prev,
      measures: isFunction(valueOrFunction)
        ? valueOrFunction(prev?.measures)
        : valueOrFunction,
    }));
  },
);

/**
 * When using external APIs, do _not_ use this atom as a reference to the
 * visitor alone, use useVisitorId hook instead
 */
export const visitorUuidAtom = atom((get) => get(visitorAtom).visitorUuid);

export const activeConversationSidAtom = createDerivedWritableAtom(
  visitorAtom,
  "activeConversationSid",
);

export const shouldShowStaleVersionToastAtom = atom(
  (get) => !MEETING_STATES.has(get(visitorAtom).state),
);

export const providerParticipantAtom = atom((get) =>
  Object.values(get(chatParticipantsByIdAtom)).find(
    (participant) =>
      parseIdentity(participant.identity ?? "").userType === UserType.PROVIDER,
  ),
);

export const providerIsOnlineAtom = createDerivedWritableAtom(
  visitorAtom,
  "providerIsOnline",
);

export const visitorAccessDocSidAtom = createDerivedWritableAtom(
  visitorAtom,
  "visitorAccessDocSid",
);
