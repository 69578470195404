import { useEffect, useRef, useState } from "react";
import { getAppConfig } from "../../config";
import { logger } from "../../datadog/logger";
import { minutesToMilliseconds } from "date-fns";
import { StaleVersionToast } from "./StaleVersionToast";
import { toast } from "react-hot-toast";

export const VERSION_CHECK_INTERVAL_MS = minutesToMilliseconds(5);

export async function fetchVersion() {
  const config = getAppConfig();
  try {
    const version = await fetch("/version");
    const text = await version.text();
    return text.trim();
  } catch (error) {
    logger.error("Unable to fetch version", config, error as Error);
  }
}

/**
 * Detects stale version and shows toast if necessary. If the toast is not able
 * not be shown, then a toast will be pending until it can be shown.
 *
 * `shouldShowToast` is used to determine whether or not the toast should be
 * shown, and will hide a visible toast immediately if it returns false.
 */
export function useDetectStaleVersionInterval(shouldShowToast: boolean) {
  const shouldShowToastRef = useRef(shouldShowToast);
  const [isToastPending, setIsToastPending_] = useState(false);
  const isToastPendingRef = useRef(isToastPending);
  const setIsToastPending = (isPending: boolean) => {
    isToastPendingRef.current = isPending;
    setIsToastPending_(isPending);
  };
  const staleVersionToastRef = useRef<string>();
  const hideToast = (givenToastId?: string) => {
    const toastId = givenToastId ?? staleVersionToastRef.current;
    if (!toastId) return;
    toast.remove(toastId);
    setIsToastPending(false);
    staleVersionToastRef.current = undefined;
  };
  const showToast = () => {
    staleVersionToastRef.current = toast.custom(
      ({ id: toastId }) => {
        return (
          <StaleVersionToast
            onClose={() => {
              hideToast(toastId);
            }}
          />
        );
      },
      {
        duration: Infinity,
        position: "top-center",
        className: "top-center-toast",
      },
    );
    setIsToastPending(false);
  };
  const isToastVisible = () => !!staleVersionToastRef.current;

  useEffect(
    function handleStaleVersionDetection() {
      const fetchAndCheckVersion = async () => {
        if (isToastPendingRef.current) return; // No need to check if toast is already pending
        const version = await fetchVersion();
        if (!version || version === getAppConfig().version) {
          hideToast();
          return;
        }
        logger.info("Stale version detected", {
          currentVersion: getAppConfig().version,
          latestVersion: version,
        });
        if (shouldShowToastRef.current && !isToastVisible()) {
          showToast();
        } else if (!shouldShowToastRef.current) {
          setIsToastPending(true);
        }
      };

      // Immediately check for stale version
      fetchAndCheckVersion();

      const interval = setInterval(
        fetchAndCheckVersion,
        VERSION_CHECK_INTERVAL_MS,
      );

      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    function handlePendingStaleVersionToast() {
      if (!shouldShowToast || !isToastPending || isToastVisible()) return;
      showToast();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowToast, isToastPending],
  );

  useEffect(
    function handleHidingStaleVersionToast() {
      shouldShowToastRef.current = shouldShowToast; // Update ref
      if (!shouldShowToast) {
        hideToast();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowToast],
  );
}
