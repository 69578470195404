type ExperimentParameters = Record<string, string>;
export type ExperimentDefinition = readonly [string, ExperimentParameters];
export type ExperimentDefinitions = Record<string, ExperimentDefinition>;

export const ExperimentLayers = {
  ClientWaitingRoom: [
    "telehealth_client_waiting_room",
    { ShouldShowAvSetup: "shouldShowAVSetup" },
  ],
  Measures: [
    "telehealth_measures",
    {
      ShouldShowClientMeasures: "shouldShowClientMeasures",
      ShouldShowProviderMeasures: "shouldShowProviderMeasures",
    },
  ],
} as const satisfies ExperimentDefinitions;
