import { useEffect, useRef } from "react";
import {
  remoteScreenShareAudioTrackAtom,
  remoteScreenShareTrackAtom,
} from "./state";
import { useAtomValue } from "jotai";
import { RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";

function handleTrackAttachment(
  track: RemoteAudioTrack | RemoteVideoTrack,
  element: HTMLAudioElement | HTMLVideoElement,
) {
  track.attach(element);
  return function cleanup() {
    track.detach(element);
    element.srcObject = null;
  };
}

export function useRemoteScreenShare() {
  const remoteParticipantScreenShareRef = useRef<HTMLVideoElement>(null);
  const remoteParticipantAudioScreenShareRef = useRef<HTMLAudioElement>(null);
  const remoteScreenShareTrack = useAtomValue(remoteScreenShareTrackAtom);
  const remoteScreenShareAudioTrack = useAtomValue(
    remoteScreenShareAudioTrackAtom,
  );

  useEffect(
    function handleScreenShareTrackAttachment() {
      const element = remoteParticipantScreenShareRef.current;

      if (!remoteScreenShareTrack || !element) return;
      handleTrackAttachment(remoteScreenShareTrack, element);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteScreenShareTrack, remoteParticipantScreenShareRef],
  );

  useEffect(
    function handleScreenShareAudioTrackAttachment() {
      const audioElement = remoteParticipantAudioScreenShareRef.current;
      if (!remoteScreenShareAudioTrack || !audioElement) return;
      handleTrackAttachment(remoteScreenShareAudioTrack, audioElement);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteScreenShareAudioTrack, remoteParticipantAudioScreenShareRef],
  );

  return {
    remoteParticipantScreenShareRef,
    remoteParticipantAudioScreenShareRef,
  };
}
