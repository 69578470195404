import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import {
  drawerStateAtom,
  DrawerState,
  newSessionConfirmationDataAtom,
} from "./state";
import { ClientQueueToastWrapper as ClientQueueToast } from "./ClientQueueToast";
import toast from "react-hot-toast";
import { localScreenShareTrackAtom, twilioRoomAtom } from "../twilio";
import { useSetAtom } from "jotai";
import { useAdmitClientCallback } from "./clients-drawer/useAdmitClientCallback";
import { VisitorPresence } from "../types";

const IN_SESSION_TOAST_DURATION = 30_000;

export function useShowNewVisitorToastCallback() {
  const getScreenShareTrack = useAtomCallback(
    useCallback((get) => get(localScreenShareTrackAtom), []),
  );
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setNewSessionConfirmationData = useSetAtom(
    newSessionConfirmationDataAtom,
  );
  const [admitClient] = useAdmitClientCallback();

  function showVisitorToastCallback(
    shortId: VisitorPresence["patientShortId"],
    name: string,
    visitorUuid?: string,
  ) {
    const isInSession = !!getTwilioRoom();
    const toastDuration = isInSession ? IN_SESSION_TOAST_DURATION : Infinity;
    const isSharingScreen = !!getScreenShareTrack();
    const clientDrawerIsOpen = getDrawerState() === DrawerState.CLIENTS;
    const onAdmitHandler = async (toastId: string) => {
      if (!visitorUuid) return;
      await admitClient(visitorUuid, name, shortId);
      toast.remove(toastId);
    };
    if (clientDrawerIsOpen || isSharingScreen) return;

    const onShowStartNewSessionConfirmation = (toastId: string) => {
      if (!visitorUuid) return;

      setNewSessionConfirmationData({
        visitorName: name,
        visitorUuid,
        patientShortId: shortId,
      });

      toast.remove(toastId);
    };

    toast.custom(
      (t) => (
        <ClientQueueToast
          className="bottom-left-toast"
          participantName={name}
          patientShortId={shortId}
          onAdmit={visitorUuid ? () => onAdmitHandler(t.id) : undefined}
          onClose={() => toast.remove(t.id)}
          onOpenQueue={() => setDrawerState(DrawerState.CLIENTS)}
          onShowStartNewSessionConfirmation={() =>
            onShowStartNewSessionConfirmation(t.id)
          }
          visitorUuid={visitorUuid}
        />
      ),
      { duration: toastDuration, position: "bottom-left" },
    );
  }
  return showVisitorToastCallback;
}
