import { minutesToSeconds, secondsToMilliseconds } from "date-fns";
import { useCallback, useState } from "react";
import { useInterval } from "usehooks-ts";
import { MAX_ROOM_DURATION_SECONDS } from "../twilio/config";
import { useAtomCallback } from "jotai/utils";
import { connectedAtAtom } from "../twilio/state";
import { toast } from "@grow-therapy-team/sprout-ui";
import { logger } from "../datadog/logger";

export const MINUTES_FROM_MAX_DURATION_TO_SHOW_WARNING = 10;

function shouldShowMaxDurationWarningToast(connectedAt: number) {
  const currentDuration = Date.now() - connectedAt;
  const showWarningDuration = secondsToMilliseconds(
    MAX_ROOM_DURATION_SECONDS -
      minutesToSeconds(MINUTES_FROM_MAX_DURATION_TO_SHOW_WARNING),
  );

  return currentDuration >= showWarningDuration;
}

export function useMaxDurationWarning() {
  const getConnectedAtTimestamp = useAtomCallback(
    useCallback((get) => get(connectedAtAtom), []),
  );
  const [_, setToastShownForSession] = useState<number>();

  useInterval(
    useCallback(
      function warnAboutMaxDuration() {
        const connectedAt = getConnectedAtTimestamp();
        if (!connectedAt) return;
        if (!shouldShowMaxDurationWarningToast(connectedAt)) return;
        setToastShownForSession((prevConnectedAt) => {
          if (prevConnectedAt === connectedAt) return prevConnectedAt;
          logger.warn("Session will end in 10 minutes");
          toast.celebrate(
            "Your session will reach its time limit and end in 10 minutes.",
            { duration: Infinity },
          );
          return connectedAt;
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    1000,
  );
}
