import { useMutation, gql } from "@apollo/client";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import {
  visitorPresenceMapSidAtom,
  visitorPresenceMapTwilioTokenAtom,
} from "./state";
import { logger } from "../datadog/logger";

export type CreateTwilioVisitorPresenceMapAccessMutationData = {
  createTwilioVisitorPresenceMapAccess: {
    jwt: string;
    visitorPresenceMapSid: string;
  };
};

export function useCreateTwilioVisitorPresenceMapAccessMutation() {
  return useMutation<CreateTwilioVisitorPresenceMapAccessMutationData>(gql`
    mutation CreateTwilioVisitorPresenceMapAccess {
      createTwilioVisitorPresenceMapAccess {
        jwt
        visitorPresenceMapSid
      }
    }
  `);
}

/**
 * Returns a callback function that creates a new visitor presence map token and
 * sets the token and map SID in state.
 */
export function useCreateTwilioVisitorPresenceMapAccessCallback() {
  const [createTwilioVisitorPresenceMapAccess] =
    useCreateTwilioVisitorPresenceMapAccessMutation();
  const setVisitorPresenceMapTwilioToken = useSetAtom(
    visitorPresenceMapTwilioTokenAtom,
  );
  const setVisitorPresenceMapSid = useSetAtom(visitorPresenceMapSidAtom);

  return useCallback(
    async function getAndSetVisitorMapAccessData() {
      try {
        const { jwt: visitorPresenceMapJwt, visitorPresenceMapSid } =
          (await createTwilioVisitorPresenceMapAccess())?.data
            ?.createTwilioVisitorPresenceMapAccess ?? {};
        setVisitorPresenceMapTwilioToken(visitorPresenceMapJwt);
        setVisitorPresenceMapSid(visitorPresenceMapSid);
      } catch (error) {
        logger.error(
          "Unable to get a new Twilio room token for provider",
          {},
          error as Error,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
