import {
  Text,
  Button,
  Icon,
  AccessibleSelect,
  InputControl,
  ButtonSize,
  SelectOptionAtom,
  Checkbox,
  toast,
} from "@grow-therapy-team/sprout-ui";
import speakerTestChimeUri from "../../assets/audio/speaker-test-chime.mp3?url";
import { VolumeMonitor } from "..";
import { useGetInputVolume } from "../../twilio/useGetInputVolume";
import { usePlayAudioCallback } from "../../hooks/usePlayAudioCallback";
import {
  getDeviceOptionsFromDeviceList,
  useUpdateAvailableDevicesInterval,
} from "../../twilio/devices/useDevices";
import { useAtom, useAtomValue } from "jotai";
import {
  LocalTrackState,
  availableDevicesAtom,
  currentMicrophoneIdAtom,
  currentOutputDeviceIdAtom,
  getStoredAudioVideoSettings,
  isKrispNoiseCancellationSupported as isKrispNoiseCancellationSupported_,
  localAudioTrackStateAtom,
  permissionDeniedAtom,
  usePublishLocalTrackCallback,
} from "../../twilio";
import { BlockedPermissionMessageWrapper as BlockedPermissionMessage } from "./BlockedPermissionMessage";
import { NoAccessVariant } from "../../twilio/audio-video-controls/NoAccessToast";
import { useStoredAudioVideoSettings } from "../../twilio/useStoredAudioVideoSettings";
import { useDebounceCallback } from "usehooks-ts";
import { useNoiseCancellationCallbacks } from "../../twilio/audio-video-controls/useNoiseCancellationCallbacks";
import { useCallback } from "react";
import { faMicrophone } from "@fortawesome/pro-solid-svg-icons";

export function AudioSettingsTab({
  microphoneIsBlocked,
  microphoneIsLoading,
  inputVolume,
  outputVolume,
  playTestAudio,
  inputOptions,
  outputOptions,
  setMicrophone,
  setOutputDevice,
  selectedMicrophone,
  selectedOutputDevice,
  audioIsPlaying,
  toggleNoiseCancellation,
  noiseCancellationIsEnabled,
  isKrispNoiseCancellationSupported,
}: {
  microphoneIsBlocked: boolean;
  microphoneIsLoading?: boolean;
  inputVolume: number;
  outputVolume: number;
  playTestAudio: () => void;
  inputOptions: SelectOptionAtom[];
  outputOptions: SelectOptionAtom[];
  setMicrophone: (selectedId: string) => void;
  setOutputDevice: (selectedId: string) => void;
  selectedMicrophone?: string;
  selectedOutputDevice?: string;
  audioIsPlaying?: boolean;
  toggleNoiseCancellation?: () => Promise<void> | void;
  noiseCancellationIsEnabled?: boolean;
  isKrispNoiseCancellationSupported?: boolean;
}) {
  const shouldDisableFields = microphoneIsBlocked || microphoneIsLoading;
  return (
    <>
      <div className="pb-6 border-b border-neutral_rebrand-500">
        {outputOptions.length > 0 && (
          <InputControl label="Speakers">
            <AccessibleSelect
              onChange={(value: string) => setOutputDevice(value)}
              options={outputOptions}
              value={selectedOutputDevice ?? outputOptions[0]?.value}
            />
          </InputControl>
        )}
        <div className="flex justify-between">
          <Button
            use="secondary"
            size={ButtonSize.Small}
            onClick={() => playTestAudio()}
            disabled={audioIsPlaying}
          >
            Test speakers
          </Button>
          <VolumeMonitor volume={outputVolume} />
        </div>
      </div>
      <div className="border-t border-neutral-200 pt-6">
        <InputControl label="Microphone" disabled={shouldDisableFields}>
          <AccessibleSelect
            options={inputOptions}
            value={selectedMicrophone ?? inputOptions[0]?.value}
            onChange={(value: string) => setMicrophone(value)}
            placeholder={microphoneIsBlocked ? "No access" : "Select"}
          />
        </InputControl>
        <div className="flex justify-between pb-6">
          <Text
            variant="sm"
            className="text-neutral-600 font-semibold rebrand:font-medium"
          >
            <Icon aria-hidden icon={faMicrophone} className="mr-2" />
            Speak to test
          </Text>
          <VolumeMonitor volume={inputVolume} />
        </div>
        {microphoneIsBlocked && (
          <BlockedPermissionMessage
            noAccessVariant={NoAccessVariant.Microphone}
          />
        )}
        {isKrispNoiseCancellationSupported && (
          <InputControl aria-label="toggle noise Cancellation">
            <Checkbox
              onChange={() => toggleNoiseCancellation?.()}
              checked={noiseCancellationIsEnabled}
              label={"Reduce background noise"}
            />
          </InputControl>
        )}
      </div>
    </>
  );
}

export function AudioSettingsTabWrapper() {
  useUpdateAvailableDevicesInterval();

  const devices = useAtomValue(availableDevicesAtom);
  const { microphoneIsBlocked } = useAtomValue(permissionDeniedAtom);
  const localAudioState = useAtomValue(localAudioTrackStateAtom);
  const microphoneIsLoading =
    !localAudioState || localAudioState === LocalTrackState.LOADING;

  const inputVolume = useGetInputVolume();
  const [selectedOutputDevice, setOutputDevice] = useAtom(
    currentOutputDeviceIdAtom,
  );
  const { storedAudioVideoSettings } = useStoredAudioVideoSettings();
  const selectedMicrophone = useAtomValue(currentMicrophoneIdAtom);
  const { playAudio, volume, isPlaying } =
    usePlayAudioCallback(speakerTestChimeUri);
  const inputOptions = getDeviceOptionsFromDeviceList(
    devices?.audioInputDevices,
  );
  const outputOptions = getDeviceOptionsFromDeviceList(
    devices?.audioOutputDevices,
  );
  const setAudioTrack = usePublishLocalTrackCallback("audio");
  const { enableNoiseCancellation, disableNoiseCancellation } =
    useNoiseCancellationCallbacks();

  // This debounce shouldn't be strictly necessary, but it seems like the select
  // component can call its onChange twice on Android devices which causes
  // unexpected behavior
  const setMicrophone = useDebounceCallback(
    useCallback(
      async (selectedId: string) => {
        if (
          !(await setAudioTrack({ trackOptions: { deviceId: selectedId } }))
        ) {
          toast.error("Something went wrong selecting your microphone device.");
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    100,
  );

  const toggleNoiseCancellation = useDebounceCallback(
    useCallback(
      async () => {
        const { noiseCancellationIsEnabled: noiseCancellationIsEnabled } =
          getStoredAudioVideoSettings();
        let result;
        if (noiseCancellationIsEnabled) {
          result = await disableNoiseCancellation();
        } else {
          result = await enableNoiseCancellation();
        }
        if (result.status === "error") {
          toast.error("Failed to toggle noise cancellation");
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    100,
  );

  const correspondingMicrophoneId = inputOptions.some(
    (option) => option.value === selectedMicrophone,
  )
    ? selectedMicrophone
    : storedAudioVideoSettings?.inputAudioDeviceId;

  return (
    <AudioSettingsTab
      inputVolume={inputVolume ?? 0}
      outputVolume={volume}
      playTestAudio={() => playAudio(selectedOutputDevice)}
      audioIsPlaying={isPlaying}
      inputOptions={inputOptions}
      outputOptions={outputOptions}
      selectedMicrophone={correspondingMicrophoneId}
      setMicrophone={setMicrophone}
      selectedOutputDevice={selectedOutputDevice}
      setOutputDevice={setOutputDevice}
      microphoneIsBlocked={microphoneIsBlocked}
      microphoneIsLoading={microphoneIsLoading}
      toggleNoiseCancellation={toggleNoiseCancellation}
      noiseCancellationIsEnabled={
        storedAudioVideoSettings.noiseCancellationIsEnabled
      }
      isKrispNoiseCancellationSupported={isKrispNoiseCancellationSupported_}
    />
  );
}
