import { gql, useQuery } from "@apollo/client";
import { visitorAtom } from "./visitor/state";
import { useSetAtom } from "jotai";
import { Server } from "./apollo/types";
import { logger } from "./datadog";

export const GET_PUBLIC_PATIENT = gql`
  query GetPublicPatientByPatientShortId($patientShortId: ID!) {
    publicPatient(shortId: $patientShortId) {
      customerId
    }
  }
`;

export type GetPublicPatientVariables = {
  patientShortId?: string;
};
export type GetPublicPatientResponse = {
  publicPatient: {
    customerId: string;
  };
};
export function useGetPublicPatient(patientShortId?: string) {
  const setCustomerId = useSetAtom(visitorAtom);

  return useQuery<GetPublicPatientResponse, GetPublicPatientVariables>(
    GET_PUBLIC_PATIENT,
    {
      variables: { patientShortId: patientShortId },
      skip: !patientShortId,
      context: { server: Server.MONOLITH, skipAuth: true },
      onCompleted(data) {
        setCustomerId((prev) => ({
          ...prev,
          customerId: data.publicPatient.customerId,
        }));
      },
      onError: (error) => {
        logger.error("Unable to fetch patient", { patientShortId }, error);
      },
    },
  );
}
