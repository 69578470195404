import { gql, useMutation as useMutationHook } from "@apollo/client";

const RECORD_CLIENT_MOOD_GQL = gql`
  mutation RecordClientMood($visitorId: String!, $emotions: [String!]!) {
    recordClientMood(visitorId: $visitorId, emotions: $emotions) {
      recordedAt
    }
  }
`;

// placeholder type that I'd love to turn into a string template for more
// confident parsing of date strings
type ISO8601Timestamp = string;
type RecordClientMoodMutationData = {
  recordClientMood: {
    __typename: "RecordedMeasure";
    recordedAt?: ISO8601Timestamp;
  };
};
type RecordClientMoodMutationVariables = {
  visitorId: string;
  emotions: string[];
};

const useMutation = useMutationHook<
  RecordClientMoodMutationData,
  RecordClientMoodMutationVariables
>;

export function useRecordClientMoodMutation(
  defaultOptions?: Parameters<typeof useMutation>[1],
) {
  return useMutation(RECORD_CLIENT_MOOD_GQL, defaultOptions);
}
