import { atom } from "jotai";
import {
  LocalTrackState,
  RemoteAudioTrackStatsByTrackSid,
  RemoteTracksByParticipantId,
  localAudioTrackAtom,
  participantIdsAtom,
  remoteTracksByParticipantIdAtom,
  twilioAtom,
} from "../state";
import { RemoteAudioTrack } from "twilio-video";
import { ACTIVE_SPEAKER_THRESHOLD, parseTrackName } from "../config";
import { TrackSource } from "../types";
import { createDerivedWritableAtom } from "../../utils";

export type RemoteMicrophoneTracksByParticipantId = Record<
  string,
  RemoteAudioTrack
>;

export const remoteAudioTrackStatsBySidAtom = createDerivedWritableAtom(
  twilioAtom,
  "remoteAudioTrackStatsBySid",
);

export const localAudioTrackStatsAtom = createDerivedWritableAtom(
  twilioAtom,
  "localAudioTrackStats",
);

export function getMicrophoneTrack(
  remoteTracksByParticipantId: RemoteTracksByParticipantId,
  participantId: string,
) {
  const remoteAudioTracks = remoteTracksByParticipantId[participantId]?.audio;
  if (!remoteAudioTracks) return;
  const [remoteMicrophoneTrack] = remoteAudioTracks.filter(
    ({ name, kind }) =>
      parseTrackName(name, kind).source === TrackSource.Microphone,
  );
  return remoteMicrophoneTrack;
}

export const remoteMicrophoneTracksByParticipantIdAtom = atom((get) => {
  const participantIds = get(participantIdsAtom);
  const remoteTracksByParticipantId = get(remoteTracksByParticipantIdAtom);

  return participantIds.reduce((acc, participantId) => {
    const remoteMicrophoneTrack = getMicrophoneTrack(
      remoteTracksByParticipantId,
      participantId,
    );
    if (!remoteMicrophoneTrack) return acc;
    return {
      ...acc,
      [participantId]: remoteMicrophoneTrack,
    };
  }, {} as RemoteMicrophoneTracksByParticipantId);
});

export function filterActiveParticipantIds(
  remoteMicrophoneTracksByParticipantId: RemoteMicrophoneTracksByParticipantId,
  remoteTrackStatsBySid: RemoteAudioTrackStatsByTrackSid,
) {
  return Object.entries(remoteMicrophoneTracksByParticipantId).reduce(
    (acc, [participantId, remoteMicrophoneTrack]) => {
      const remoteAudioTrackStats =
        remoteTrackStatsBySid[remoteMicrophoneTrack.sid];
      if (!remoteAudioTrackStats) return acc;
      const { audioLevel } = remoteAudioTrackStats;
      if (
        (audioLevel ?? 0) < ACTIVE_SPEAKER_THRESHOLD ||
        !remoteMicrophoneTrack.isEnabled
      )
        return acc;
      return acc.add(participantId);
    },
    new Set<string>(),
  );
}

export const activelySpeakingParticipantIdsAtom = atom((get) => {
  const remoteMicrophoneTracksByParticipantId = get(
    remoteMicrophoneTracksByParticipantIdAtom,
  );
  const remoteAudioTrackStatsByTrackSid = get(remoteAudioTrackStatsBySidAtom);

  return filterActiveParticipantIds(
    remoteMicrophoneTracksByParticipantId,
    remoteAudioTrackStatsByTrackSid,
  );
});

export const isSpeakingAtom = atom((get) => {
  const localAudioTrackState = get(localAudioTrackAtom).state;
  const localAudioTrackStats = get(localAudioTrackStatsAtom);
  const audioLevel = localAudioTrackStats?.audioLevel ?? 0;

  return (
    localAudioTrackState === LocalTrackState.READY &&
    audioLevel >= ACTIVE_SPEAKER_THRESHOLD
  );
});
