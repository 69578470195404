import {
  BackgroundAnimationOverlay,
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { useAtomValue, useSetAtom } from "jotai";
import { visitorClientShortIdAtom, visitorDrawerStateAtom } from "../state";
import { sessionInfoAtom, Theme } from "../../state";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks";
import {
  PageContent,
  PageContentWrapperProps,
} from "./page-content/PageContent";
import { FC } from "react";

interface PageProps {
  pageContentComponent: FC<PageContentWrapperProps>;
}

const PUSH_DRAWER_ARIA_CONTROLS_ID = "introduction-room-push-drawer";

export function Page({
  pageContentComponent: PageContentComponent,
}: PageProps) {
  const visitorDrawerState = useAtomValue(visitorDrawerStateAtom);
  return (
    <>
      <PageProviders theme={Theme.LIGHT}>
        <PageContainer>
          <BackgroundAnimationOverlay autoplay={false} />
          <div className="relative flex flex-col h-full w-full">
            <Navbar
              drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
              drawerState={visitorDrawerState}
            />

            <PushDrawerContainer
              id={PUSH_DRAWER_ARIA_CONTROLS_ID}
              drawer={<PushDrawer className="sm:mt-2 sm:h-[calc(100%-16px)]" />}
            >
              <StageContainer className="bg-transparent overflow-auto sm:py-12 justify-normal">
                <PageContentComponent drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID} />
              </StageContainer>
            </PushDrawerContainer>
          </div>
        </PageContainer>
      </PageProviders>
    </>
  );
}

export function PageWrapper() {
  const { providerShortId, patientShortId } = useParams();

  useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointment = useAtomValue(sessionInfoAtom)?.appointment;
  const setClientShortId = useSetAtom(visitorClientShortIdAtom);

  setClientShortId(appointment?.clientUserShortId ?? "");

  return <Page pageContentComponent={PageContent} />;
}
