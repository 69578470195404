import { Alert } from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "../../components";
import {
  DrawerState,
  drawerStateAtom,
  providerShortIdAtom,
  selectedClientInfoAtom,
  selectedClientMeasuresData,
  selectedClientUserShortIdAtom,
} from "../state";
import { useAtomValue, useSetAtom } from "jotai";
import { nameToInitialsWithPeriods } from "../../utils";
import { MeasureSchemaAndAnswers } from "../../components/measures/types";
import { MeasuresActions } from "../../components/measures/MeasuresActions";
import { MeasuresContent } from "../../components/measures/MeasuresContent";
import { MeasuresDrawer } from "../../components/measures/MeasuresDrawer";
import { useSubmitMeasureResponseCallback } from "../../hooks/measures/useSubmitMeasureResponseCallback";

function MeasuresContentWrapper(
  props: PageComponentProps<MeasureSchemaAndAnswers>,
) {
  const clientInfo = useAtomValue(selectedClientInfoAtom);
  const clientInitials =
    nameToInitialsWithPeriods(
      clientInfo?.preferredName?.patientFirstName,
      clientInfo?.preferredName?.patientLastName,
    ) || "your patient";
  return (
    <MeasuresContent {...props}>
      <>
        <Alert
          use="info"
          heading={`You're submitting this on behalf of ${clientInitials}`}
        >
          Please confirm with your client that this information is accurate.
        </Alert>
      </>
    </MeasuresContent>
  );
}

function MeasuresActionsWrapper(
  props: PageComponentProps<MeasureSchemaAndAnswers>,
) {
  const submitMeasureResponse = useSubmitMeasureResponseCallback();
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  return (
    <MeasuresActions
      {...props}
      submitMeasure={async () => {
        const { status } = await submitMeasureResponse({
          ...props,
          clientUserShortId,
          providerShortId,
          completionCampaign: "CLIENT_INFO_IN_SESSION",
        });
        if (status === "success" && props.isLastPage) {
          setDrawerState(DrawerState.PENDING_FORMS_COMPLETED);
        }
      }}
    />
  );
}

export function PendingMeasuresDrawerWrapper() {
  const measureBundles = useAtomValue(selectedClientMeasuresData);

  if (!measureBundles.length) return null;
  const mostRecentMeasureBundle = measureBundles[0]?.measureBundleItems;
  const pages = mostRecentMeasureBundle?.map((item) => ({
    schema: item?.measureSchema,
    answers: {},
    errors: [] as string[],
    hasSubmitted: false,
  })) as MeasureSchemaAndAnswers[];

  return (
    <MeasuresDrawer
      pages={pages}
      contentComponent={MeasuresContentWrapper}
      actionsComponent={MeasuresActionsWrapper}
    />
  );
}
