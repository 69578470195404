import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  chatParticipantsByIdAtom,
  conversationsBySidAtom,
  currentConversationSidAtom,
  messagesByConversationSidAtom,
  participantIdsByConversationSidAtom,
  twilioChatClientAtom,
  unreadMessageCountByConversationSidAtom,
} from "../state";
import { MessageHandler } from "../types";
import {
  Client as ChatClient,
  Conversation,
  Message,
  Participant as ChatParticipant,
} from "@twilio/conversations";
import { logger } from "../../datadog/logger";
import { useSetAtom } from "jotai";
import { identity, last, omit } from "remeda";
import { CallbackError, CallbackSuccess } from "../../types";
import { removeImmutablyFromSet } from "../../utils";
import { parseIdentity } from "../utils";

export enum ConversationStatus {
  NOOP = "noop",
  JOINED = "joined",
  ERROR = "error",
  NOT_FOUND = "not_found",
  NO_CHAT_CLIENT = "no_chat_client",
  LEFT = "left",
}

export type CallbackResponse = Promise<
  | CallbackSuccess<Conversation, ConversationStatus>
  | CallbackError<ConversationStatus>
>;

type JoinConversationCallbackOpts = {
  onInitializeMessages?: (messages: Message[]) => void;
  onMessageAdded?: MessageHandler;
  onParticipantJoined?: (participant: ChatParticipant) => void;
  onParticipantLeft?: (participant: ChatParticipant) => void;
  chatClientOverride?: ChatClient;
};

function chatParticipantsToParticipantsByIdentity(
  participants: ChatParticipant[],
): Record<string, ChatParticipant> {
  return participants.reduce((acc, participant) => {
    if (!participant.identity) return acc;
    return {
      ...acc,
      [participant.identity]: participant,
    };
  }, {});
}

export function useTwilioConversationCallbacks() {
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getTwilioChatClient = useAtomCallback(
    useCallback((get) => get(twilioChatClientAtom), []),
  );
  const getConversations = useAtomCallback(
    useCallback((get) => get(conversationsBySidAtom), []),
  );
  const getCurrentConversationSid = useAtomCallback(
    useCallback((get) => get(currentConversationSidAtom), []),
  );
  const setConversations = useSetAtom(conversationsBySidAtom);
  const setMessages = useSetAtom(messagesByConversationSidAtom);
  const setParticipantsById = useSetAtom(chatParticipantsByIdAtom);
  const setParticipantIds = useSetAtom(participantIdsByConversationSidAtom);
  const setParticipants = (
    conversationSid: string,
    participants: ChatParticipant[],
  ) => {
    setParticipantsById((prev) => ({
      ...prev,
      ...chatParticipantsToParticipantsByIdentity(participants),
    }));
    setParticipantIds((prev) => {
      const existingParticipantIds = prev[conversationSid] ?? [];
      return {
        ...prev,
        [conversationSid]: new Set([
          ...existingParticipantIds,
          ...participants.map((p) => p.identity ?? "").filter(identity),
        ]),
      };
    });
  };
  const gracefullyGetAndSetMessages = async (
    conversation: Conversation,
    onInitializeMessages?: JoinConversationCallbackOpts["onInitializeMessages"],
  ) => {
    const conversationSid = conversation.sid;
    // Not dealing with pagination, because we don't expect to initialize
    // conversations with many messages if at all since Telehealth
    // conversations are so ephemeral
    try {
      const messages = (await conversation.getMessages(1000)).items;
      onInitializeMessages?.(messages);
      setMessages((prev) => ({
        ...prev,
        [conversationSid]: messages,
      }));
      const receivedMessages = messages.filter((message) => {
        return message.author !== getTwilioChatClient()?.user.identity;
      });
      const latestMessageIndex = last(receivedMessages)?.index;
      const lastReadMessageIndex = conversation.lastReadMessageIndex;
      const neverReadAnyMessages = lastReadMessageIndex === null;

      let unreadMessageCount = 0;
      if (neverReadAnyMessages) {
        unreadMessageCount = receivedMessages.length;
      } else {
        unreadMessageCount = (latestMessageIndex ?? 0) - lastReadMessageIndex;
      }

      if (conversationSid !== getCurrentConversationSid()) {
        setUnreadMessageCount((prev) => ({
          ...prev,
          [conversationSid]: unreadMessageCount,
        }));
      }
    } catch (e) {
      const error = e as Error;
      logger.error(
        "Unable to initialize messages for conversation",
        { conversationSid },
        error,
      );
    }
  };
  const gracefullyGetAndSetParticipants = async (
    conversation: Conversation,
  ) => {
    try {
      const participants = await conversation.getParticipants();
      setParticipants(conversation.sid, participants);
    } catch (e) {
      const error = e as Error;
      logger.error(
        "Unable to get participants for conversation",
        { conversationSid: conversation.sid },
        error,
      );
    }
  };

  const joinConversation = useCallback(
    async (
      conversationSid: string,
      {
        onInitializeMessages,
        onMessageAdded,
        onParticipantJoined,
        onParticipantLeft,
        chatClientOverride,
      }: JoinConversationCallbackOpts = {},
    ): CallbackResponse => {
      let conversation: Conversation;

      if ((conversation = getConversations()[conversationSid]))
        return {
          status: "success",
          code: ConversationStatus.NOOP,
          value: conversation,
        };

      const chatClient = chatClientOverride ?? getTwilioChatClient();
      if (!chatClient)
        return { status: "error", code: ConversationStatus.NO_CHAT_CLIENT };

      conversation = await chatClient.getConversationBySid(conversationSid);
      if (!conversation)
        return { status: "error", code: ConversationStatus.NOT_FOUND };

      // Can still have a degraded chat experience without being able to get
      // the participants or initial messages; no need to early return
      await Promise.all([
        gracefullyGetAndSetMessages(conversation, onInitializeMessages),
        gracefullyGetAndSetParticipants(conversation),
      ]);

      try {
        if (!conversation.getParticipantByIdentity(chatClient.user.identity)) {
          await conversation.join();
        }
      } catch (e) {
        const error = e as Error;
        logger.error("Unable to join conversation", { conversationSid }, error);
        return {
          status: "error",
          code: ConversationStatus.ERROR,
          value: error,
        };
      }

      conversation.on("messageAdded", (message: Message) => {
        onMessageAdded?.(message);
        setMessages((prev) => ({
          ...prev,
          [conversationSid]: [...prev[conversationSid], message],
        }));
        if (conversationSid !== getCurrentConversationSid()) {
          setUnreadMessageCount((prev) => ({
            ...prev,
            [conversationSid]: (prev[conversationSid] ?? 0) + 1,
          }));
        }
      });

      conversation.on("participantJoined", (participant: ChatParticipant) => {
        const { userType, id } = parseIdentity(participant.identity ?? "");
        logger.info("Participant joined conversation", {
          userType,
          id,
        });
        onParticipantJoined?.(participant);
        setParticipants(conversationSid, [participant]);
      });

      conversation.on("participantLeft", (participant: ChatParticipant) => {
        const { userType, id } = parseIdentity(participant.identity ?? "");
        logger.info("Participant left conversation", {
          userType,
          id,
        });
        onParticipantLeft?.(participant);
        setParticipantIds((prev) => {
          const existingParticipantIds = prev[conversationSid];
          if (
            !existingParticipantIds ||
            !existingParticipantIds.size ||
            !participant.identity
          )
            return prev;
          return {
            ...prev,
            [conversationSid]: removeImmutablyFromSet(
              existingParticipantIds,
              participant.identity,
            ),
          };
        });
      });

      setConversations((prev) => ({
        ...prev,
        [conversationSid]: conversation,
      }));
      return {
        status: "success",
        code: ConversationStatus.JOINED,
        value: conversation,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const leaveConversation = useCallback(
    async (conversationSid: string): CallbackResponse => {
      const conversation = getConversations()[conversationSid];
      const chatClient = getTwilioChatClient();
      if (!chatClient)
        return { status: "error", code: ConversationStatus.NO_CHAT_CLIENT };
      if (!conversation)
        return { status: "error", code: ConversationStatus.NOT_FOUND };
      try {
        await conversation.leave();
      } catch (e) {
        const error = e as Error;
        logger.error(
          "Unable to leave conversation",
          { conversationSid },
          error,
        );
        return {
          status: "error",
          code: ConversationStatus.ERROR,
          value: error,
        };
      }
      conversation.removeAllListeners("messageAdded");
      conversation.removeAllListeners("participantJoined");
      conversation.removeAllListeners("participantLeft");
      setConversations((prev) => omit(prev, [conversationSid]));
      setMessages((prev) => omit(prev, [conversationSid]));
      setParticipantIds((prev) => omit(prev, [conversationSid]));
      setUnreadMessageCount((prev) => omit(prev, [conversationSid]));
      return {
        status: "success",
        code: ConversationStatus.LEFT,
        value: conversation,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    joinConversation,
    leaveConversation,
  };
}
