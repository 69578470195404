import { GroupedFormData, FormType } from "./types";
import { IntakeFormSection } from "./IntakeFormSection"; // Ensure correct import path
import { SafetyPlanFormSection } from "./SafetyPlanFormSection"; // Ensure correct import path
import { Text } from "@grow-therapy-team/sprout-ui";

type FormResponseProps = {
  selectedForm: GroupedFormData;
};
export function FormResponse({ selectedForm }: FormResponseProps) {
  const renderFormContent = () => {
    const allQuestions = Object.values(
      selectedForm.formQuestionsWithAnswers,
    ).flat();

    switch (selectedForm.formType) {
      case FormType.Intake:
        return <IntakeFormSection selectedForm={selectedForm} />;
      case FormType.NoteToProvider:
        return (
          <div
            className="relative rounded py-4 text-start fs-exclude rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600"
            data-testid="client-information.forms.note-to-provider"
            data-dd-privacy="mask"
          >
            <Text className="mb-1 font-semibold rebrand:font-medium">
              Is there anything else you would like to share with your provider?
            </Text>
            <Text>{(allQuestions[0]?.answer as string) ?? ""}</Text>
          </div>
        );
      case FormType.Safetyplan:
        return <SafetyPlanFormSection selectedForm={selectedForm} />;
      case FormType.Gad7:
      case FormType.Phq9:
        return (
          <div
            data-testid="client-information.forms.questionnaire"
            className="relative rounded text-start fs-exclude rebrand:rounded-none"
            data-dd-privacy="mask"
          >
            {allQuestions.map((element, i) => (
              <div
                className="relative rounded py-4 text-start rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600"
                key={i}
              >
                <Text className="mb-1 font-semibold rebrand:font-medium">
                  {i + 1}. {element.question}
                </Text>
                <Text>{element.answer as string}</Text>
              </div>
            ))}
          </div>
        );
    }
  };

  return <>{renderFormContent()}</>;
}
