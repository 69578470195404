export const DOES_NOT_REPEAT_TEXT = "Does not repeat";
export const REPEATS_INDEFINITELY_TEXT = "Repeats indefinitely";
export const VIRTUAL_LABEL = "Virtual";
export const IN_OFFICE_LABEL = "In office";
export const TIME_RANGE_VALIDATION_MESSAGE = {
  INVALID_RANGE: "End time must be after start time",
  INVALID_START: "Start time cannot be in the past",
  INVALID_LENGTH: "Maximum appointment length exceeded",
};
export const APPOINTMENT_CONFLICT_MESSAGE = {
  EXISTING_APPOINTMENT: "This will conflict with an existing appointment.",
  EXISTING_BUSY_BLOCK: "This will conflict with an existing busy block.",
  EXISTING_EVENT:
    "This will conflict with an existing event on your synced calendar.",
};
export const NUM_INDEFINITE_APPOINTMENTS = 20;
export const MAX_APPOINTMENT_LENGTH_HOURS = 4;
export const MAX_RECURRENCES = 25;

export enum UpdateMode {
  RECURRING = "recurring",
  SINGLE = "single",
}

export const CANCEL_MODAL_TITLE = "Cancel appointment";
export const EDIT_MODAL_TITLE = "Edit recurring appointment";
export const RECURRING_UPDATE_OPTIONS = {
  [UpdateMode.SINGLE]: "Only this event",
  [UpdateMode.RECURRING]: "This and all following events",
};

export const DEFAULT_APPOINTMENT_RECURRING_KEY =
  "DEFAULT_APPOINTMENTS_RECURRING";

export const REPEAT_OPTIONS = [
  {
    label: "Repeats",
    value: "Repeats",
  },
  {
    label: "Does not repeat",
    value: "Does not repeat",
  },
];

export const FREQUENCY_VALIDATION_MESSAGE =
  "Please enter a value between 1 and 20.";

export const RECURRENCE_INPUT_ARIA_LABEL = "Appointment recurrence";

export const CALENDAR_LINK_TEXT = "See more availability in calendar view";

export const ADD_APPOINTMENT_BUTTON_TITLE = "Add appointment";

export const ADD_APPOINTMENT_FORM_ERROR = `Sorry, we’re unable to schedule an appointment for this client right now.
If this issue persists, please reach out to support via the Provider
Portal.`;
