import { Card, Text } from "@grow-therapy-team/sprout-ui";

import { NoteType } from "./types";

type TherapistInterventionProps = {
  sectionTitle: string;
  notes: NoteType[];
};

export function TherapistIntervention({
  sectionTitle,
  notes,
}: TherapistInterventionProps) {
  return (
    <Card key={sectionTitle} title={sectionTitle}>
      <div
        className="fs-exclude"
        data-testid="client-information.notes.therapist-interventions"
        data-dd-privacy="mask"
      >
        {notes.map((note) => (
          <div key={note.name}>
            <div>
              {note.question && (
                <Text className="font-semibold text-small mt-2">
                  {note.question}
                </Text>
              )}

              <Text className="mt-2 text-small">{note.answer}</Text>
            </div>
            <ul>
              {note.details.length > 0 &&
                note.details.map((detail) => (
                  <Text key={detail}>{detail}</Text>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </Card>
  );
}
