import { RecordingControl as BaseRecordingControl } from "../../twilio/audio-video-controls/RecordingControl";
import { useCombineAudioTrackMediaStreams } from "./useCombineAudioTracks";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { ConsentStatus } from "../../twilio/types";
import { useState } from "react";
import { ConsentModal } from "./ConsentModal";
import { useAtomValue, useSetAtom } from "jotai";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { useRecordProviderTranscriptConsentMutation } from "./useRecordProviderTranscriptionConsentMutation";
import { useRequestingClientConsentToast } from "./useRequestingClientConsentToast";
import classNames from "classnames";
import { useGate } from "statsig-react";
import { telehealthTranscriptionGate } from "../../statsig/gates";
import { patientConsentAtom } from "../../twilio/messages/state";
import { useRecordAudioCallbacks } from "./useRecordAudioCallbacks";
import { useSendProviderParticipantStateCallback } from "../messages/useSendProviderParticipantStateCallback";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { useAtomCallback } from "jotai/utils";
import { UserType } from "../../types";

/**
 * TODO: Extract contents to parent once feature is launched
 */
function CombineAudioTrackMediaStreams() {
  useCombineAudioTrackMediaStreams();

  return null;
}

export function RecordingControl() {
  const { value: telehealthTranscriptionIsEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  const { userClicked } = useTrackEvent();
  const getAppointmentShortId = useAtomCallback(
    (get) => get(inSessionPatientInformationAtom)?.inSessionAppointmentShortId,
  );
  useRequestingClientConsentToast();
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const setClientTranscriptionConsent = useSetAtom(patientConsentAtom);

  const sendParticipantState = useSendProviderParticipantStateCallback();
  const { isRecording, isVisible } = useRecordingControl(
    providerShortId,
    patientShortId,
    telehealthTranscriptionIsEnabled,
  );
  const { startRecording, stopRecording } = useRecordAudioCallbacks();

  const recordProviderConsent = useRecordProviderTranscriptConsentMutation(
    providerShortId,
    patientShortId,
  );
  const { providerConsent, patientConsent } = useGetProviderPatientConsent(
    providerShortId,
    patientShortId,
  );
  const requestingClientConsent = patientConsent === ConsentStatus.DECIDING;

  const [showConsentModal, setShowConsentModal] = useState(false);

  const toggleRecording = async (shouldRecord: boolean) => {
    if (shouldRecord) {
      await startRecording();
    } else {
      await stopRecording();
    }
    sendParticipantState();
  };

  const requestClientConsent = () => {
    setClientTranscriptionConsent({
      value: ConsentStatus.DECIDING,
      lastUpdated: Date.now(),
    });
    sendParticipantState();
  };

  const recordOptIn = async () => {
    const { data } = await recordProviderConsent(true);
    const providerIsOptedIn =
      data?.recordProviderTranscriptionConsent?.isOptedIn;
    if (providerIsOptedIn && patientConsent !== ConsentStatus.OPTED_IN) {
      requestClientConsent();
    } else if (providerIsOptedIn && patientConsent === ConsentStatus.OPTED_IN) {
      toggleRecording(true);
    }
    setShowConsentModal(false);
    userClicked(EventTypeEnum.GAVE_TRANSCRIPTION_CONSENT, {
      appointmentShortId: getAppointmentShortId()!,
      entityType: UserType.PROVIDER,
    });
  };

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (!isVisible || providerConsent === ConsentStatus.OPTED_OUT) return null;

  return (
    <>
      {showConsentModal && (
        <ConsentModal
          onClose={() => setShowConsentModal(false)}
          recordOptIn={recordOptIn}
          isOpen={showConsentModal}
        />
      )}
      <CombineAudioTrackMediaStreams />
      <BaseRecordingControl
        className={classNames({
          "rebrand:bg-lilac-700":
            requestingClientConsent || bothPartiesConsented,
        })}
        isRecording={isRecording}
        consentPending={!bothPartiesConsented}
        onRecordingToggle={(shouldRecord: boolean) => {
          toggleRecording(shouldRecord);
          userClicked(EventTypeEnum.CLICK_RECORDING_TOGGLE, {
            appointmentShortId: getAppointmentShortId()!,
            newRecordingStatus: shouldRecord ? "PLAY" : "PAUSE",
            entityType: UserType.PROVIDER,
          });
        }}
        onPendingConsentClick={() => {
          if (providerConsent !== ConsentStatus.OPTED_IN) {
            setShowConsentModal(true);
          } else {
            requestClientConsent();
          }
        }}
      />
    </>
  );
}
