import { gql, useMutation } from "@apollo/client";
import {
  GET_PROVIDER_PATIENT_CONSENT,
  optedInToConsentStatus,
} from "./useGetProviderPatientConsent";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { providerConsentAtom } from "../../twilio/messages/state";

type RecordProviderConsentVariables = {
  providerShortId: string;
  isOptedIn: boolean;
  updateSource: string;
  appointmentShortId?: string;
};

type RecordProviderConsentData = {
  recordProviderTranscriptionConsent: {
    providerShortId: string;
    isOptedIn: boolean;
    createdAt: Date;
  };
};

const PROVIDER_TRANCRIPTION_CONSENT_MUTATION = gql`
  mutation RecordProviderTranscriptionConsent(
    $providerShortId: String!
    $isOptedIn: Boolean!
    $updateSource: String!
    $appointmentShortId: String
  ) {
    recordProviderTranscriptionConsent(
      providerShortId: $providerShortId
      isOptedIn: $isOptedIn
      updateSource: $updateSource
      appointmentShortId: $appointmentShortId
    ) {
      providerShortId
      isOptedIn
      createdAt
    }
  }
`;

export function useRecordProviderTranscriptConsentMutation(
  providerShortId?: string,
  patientShortId?: string,
) {
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const setProviderTranscriptionConsent = useSetAtom(providerConsentAtom);
  const appointmentShortId = data?.telehealthSessionInfo?.appointment?.shortId;

  const [recordProviderConsent] = useMutation<
    RecordProviderConsentData,
    RecordProviderConsentVariables
  >(PROVIDER_TRANCRIPTION_CONSENT_MUTATION, {
    refetchQueries: [GET_PROVIDER_PATIENT_CONSENT],
  });

  return useCallback(
    async function recordProviderConsentCallback(isOptedIn: boolean) {
      return await recordProviderConsent({
        variables: {
          appointmentShortId,
          providerShortId: providerShortId!,
          updateSource: "telehealth",
          isOptedIn,
        },
        onCompleted: () => {
          setProviderTranscriptionConsent({
            value: optedInToConsentStatus(true),
            lastUpdated: Date.now(),
          });
        },
      });
    },
    [
      recordProviderConsent,
      appointmentShortId,
      providerShortId,
      setProviderTranscriptionConsent,
    ],
  );
}
