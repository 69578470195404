import {
  RemoteParticipantWrapper as BaseRemoteParticipant,
  RemoteParticipantWrapperProps,
} from "../twilio";
import { useHandleReceivedMessageCallback } from "./messages/useHandleReceivedMessageCallback";
import { useCallback } from "react";
import { useSendProviderParticipantStateCallback } from "./messages/useSendProviderParticipantStateCallback";

export function RemoteParticipant(
  props: Omit<RemoteParticipantWrapperProps, "onMessageReceived">,
) {
  const handleReceivedMessage = useHandleReceivedMessageCallback();

  const sendParticipantState = useSendProviderParticipantStateCallback();

  const onDataTrackSubscribed = useCallback(() => {
    sendParticipantState();
  }, [sendParticipantState]);

  return (
    <BaseRemoteParticipant
      {...props}
      onMessageReceived={handleReceivedMessage}
      onDataTrackSubscribed={onDataTrackSubscribed}
    />
  );
}
