import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { numWaitingVisitorsAtom, totalUnreadMessageCountAtom } from "./state";
import { updateFaviconAndTitle } from "../updateFaviconAndTitle";
import { pluralizeString } from "../utils";

function formatNotificationTitle(
  numberWaiting: number,
  unreadMessageCount: number,
): string {
  if (numberWaiting) {
    return `(${numberWaiting}) ${pluralizeString(
      numberWaiting,
      "client",
    )} waiting`;
  }
  if (unreadMessageCount) {
    return `(${unreadMessageCount}) ${pluralizeString(
      unreadMessageCount,
      "message",
    )}`;
  }
  return "";
}

export function useShowNotificationInFaviconAndTitle() {
  const unreadMessageCount = useAtomValue(totalUnreadMessageCountAtom);
  const numberWaiting = useAtomValue(numWaitingVisitorsAtom);

  useEffect(
    function showNotificationInFavIconAndTitle() {
      if (!numberWaiting && !unreadMessageCount) return updateFaviconAndTitle();
      updateFaviconAndTitle(
        "../../assets/favicon-alert.png",
        formatNotificationTitle(numberWaiting, unreadMessageCount),
      );
    },
    [numberWaiting, unreadMessageCount],
  );
}
