import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { logger } from "../../datadog/logger";
import { useSetAtom } from "jotai";
import { visitorAccessDocSidAtom } from "../state";

export type CreateVisitorAccessDocTokenData = {
  createTwilioVisitorAccess: {
    jwt: string;
    visitorAccessDocSid: string;
  };
};

export type CreateVisitorAccessDocTokenVariables = {
  providerShortId: string;
  visitorName: string;
  visitorUuid: string;
};

const createTwilioVisitorAccessDocTokenGql = gql`
  mutation CreateTwilioVisitorAccessDocToken(
    $providerShortId: String!
    $visitorName: String!
    $visitorUuid: String!
  ) {
    createTwilioVisitorAccess(
      providerShortId: $providerShortId
      visitorName: $visitorName
      visitorUuid: $visitorUuid
    ) {
      jwt
      visitorAccessDocSid
    }
  }
`;

export function useCreateVisitorAccessDocTokenMutation() {
  return useMutation<
    CreateVisitorAccessDocTokenData,
    CreateVisitorAccessDocTokenVariables
  >(createTwilioVisitorAccessDocTokenGql);
}

export function useCreateVisitorAccessDocTokenCallback(): [
  (
    variables: CreateVisitorAccessDocTokenVariables,
  ) => Promise<FetchResult<CreateVisitorAccessDocTokenData> | undefined>,
  MutationResult<CreateVisitorAccessDocTokenData>,
] {
  const [createTwilioVisitorAccessDocToken, result] =
    useCreateVisitorAccessDocTokenMutation();
  const setVisitorAccessDocSid = useSetAtom(visitorAccessDocSidAtom);

  return [
    useCallback(
      async (variables) => {
        try {
          const result = await createTwilioVisitorAccessDocToken({
            variables,
            context: {
              skipAuth: true,
            },
          });
          setVisitorAccessDocSid(
            result.data?.createTwilioVisitorAccess?.visitorAccessDocSid,
          );
          return result;
        } catch (error) {
          logger.error(
            "Failed to create visitor access doc token",
            {},
            error as Error,
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    result,
  ];
}
