import { useAtomValue, useSetAtom } from "jotai";
import {
  syncClientConnectionStateAtom,
  twilioIdentityAtom,
  twilioSyncClientAtom,
  usedTwilioWebsocketsSuccessfullyAtom,
  usingWebsocketsReverseProxyAtom,
} from "../state";
import { useCallback, useEffect } from "react";
import { parseJwt } from "../../auth/utils";
import { Client as SyncClient } from "twilio-sync";
import { logger } from "../../datadog/logger";
import { useAtomCallback } from "jotai/utils";
import {
  ConnectionError,
  ConnectionState,
  ConnectionStateStatus,
} from "../types";
import { reverseProxyWebsockets } from "../utils";

export function useTeardownTwilioSyncClientCallback() {
  const setTwilioSyncClient = useSetAtom(twilioSyncClientAtom);
  const getTwilioSyncClient = useAtomCallback(
    useCallback((get) => get(twilioSyncClientAtom), []),
  );

  return useCallback(
    function teardownTwilioSyncClient() {
      const client = getTwilioSyncClient();
      if (!client) return;
      client.removeAllListeners();
      client.shutdown();
      setTwilioSyncClient(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

type InitTwilioSyncClientOpts = {
  jwt?: string;
  onTokenExpired?: () => void;
  onTokenAboutToExpire?: () => void;
  onConnectionError?: (error: ConnectionError) => void;
  onConnectionStateChanged?: (newState: ConnectionStateStatus) => void;
};

export function useInitTwilioSyncClientCallback() {
  const setTwilioSyncClient = useSetAtom(twilioSyncClientAtom);
  const setSyncClientState = useSetAtom(syncClientConnectionStateAtom);
  const setTwilioIdentity = useSetAtom(twilioIdentityAtom);
  const teardownTwilioSyncClient = useTeardownTwilioSyncClientCallback();
  const setUsingWebsocketsReverseProxy = useSetAtom(
    usingWebsocketsReverseProxyAtom,
  );
  const setUsedTwilioWsSuccessfully = useSetAtom(
    usedTwilioWebsocketsSuccessfullyAtom,
  );
  const getUsingWebsocketsReverseProxy = useAtomCallback(
    useCallback((get) => get(usingWebsocketsReverseProxyAtom), []),
  );
  const getUsedTwilioWsSuccessfully = useAtomCallback(
    useCallback((get) => get(usedTwilioWebsocketsSuccessfullyAtom), []),
  );

  return useCallback(
    function initTwilioSyncClient({
      jwt,
      onTokenExpired,
      onTokenAboutToExpire,
      onConnectionError,
      onConnectionStateChanged,
    }: InitTwilioSyncClientOpts = {}) {
      if (!jwt) return;

      teardownTwilioSyncClient();

      if (getUsingWebsocketsReverseProxy()) {
        reverseProxyWebsockets();
      }

      const client = new SyncClient(jwt);
      logger.info("Initializing twilio sync client");

      client.on("tokenExpired", () => {
        logger.warn("Twilio sync token expired");
        onTokenExpired?.();
      });

      client.on("tokenAboutToExpire", () => {
        logger.warn("Twilio sync token about to expire");
        onTokenAboutToExpire?.();
      });

      client.on("connectionError", (connectionError) => {
        if (
          !getUsedTwilioWsSuccessfully() &&
          !getUsingWebsocketsReverseProxy()
        ) {
          setUsingWebsocketsReverseProxy(true);
        } else {
          logger.error(
            `Twilio sync connection error: ${connectionError.message}`,
          );
          onConnectionError?.(connectionError);
          setSyncClientState("error");
        }
      });

      client.on("connectionStateChanged", (newState) => {
        logger.debug("Twilio sync connection state change", {
          twilioSyncState: newState,
        });
        if (newState === ConnectionState.CONNECTED) {
          setUsedTwilioWsSuccessfully(true);
        }
        onConnectionStateChanged?.(newState);
        setSyncClientState(newState);
      });

      const identity = parseJwt<{ grants: { identity: string } }>(jwt)?.grants
        ?.identity;
      setTwilioSyncClient(client);
      setTwilioIdentity(identity);
      return client;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/**
 * This hook is responsible for the initialization and tear down of the Twilio
 * Sync client in Jotai state. Recommend no more than one usage per experience
 * (provider/visitor).
 */
export function useInitTwilioSyncClient(opts: InitTwilioSyncClientOpts) {
  const initTwilioSyncClient = useInitTwilioSyncClientCallback();
  const teardownTwilioSyncClient = useTeardownTwilioSyncClientCallback();
  const { jwt } = opts;
  const usingWebsocketsReverseProxy = useAtomValue(
    usingWebsocketsReverseProxyAtom,
  );

  useEffect(
    function handleTwilioSyncClient() {
      if (!jwt) return;
      initTwilioSyncClient(opts);
      return teardownTwilioSyncClient;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jwt, usingWebsocketsReverseProxy],
  );
}
