import {
  AvatarAccent,
  DrawerTitle,
  InitialsAvatar,
  Tag,
  TagUse,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { Message } from "@twilio/conversations";
import { nameToInitials } from "../../utils";
import { useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  sessionConversationReadyAtom,
  sessionConversationSidAtom,
  waitingVisitorsAtom,
} from "../state";
import {
  chatParticipantToName,
  chatParticipantsByIdAtom,
  getChatParticipants,
  getMostRecentMessageInConversation,
  messagesByConversationSidAtom,
  participantIdsByConversationSidAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
} from "../../twilio";
import { useOpenConversationCallback } from "./useOpenConversationCallback";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { useEffect } from "react";
import { DrawerHeader } from "../../components/DrawerHeader";
import { ParticipantStatus } from "../../types";

export type ParticipantConversation = {
  conversationSid: string;
  status: ParticipantStatus;
  chatName: string;
  hasUnreadMessages: boolean;
  onOpenConversation: () => void;
  lastMessage?: Message;
};

export function AllClientsChatDrawer({
  activeChats,
}: {
  activeChats: ParticipantConversation[];
}) {
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>Chat</DrawerTitle>
      </DrawerHeader>
      <ul>
        {activeChats.map((chat) => {
          const {
            conversationSid,
            status,
            chatName,
            hasUnreadMessages,
            onOpenConversation,
            lastMessage,
          } = chat;
          const ariaLabel = `participant(s) ${status.toLowerCase()}`;
          return (
            <li key={conversationSid}>
              <button
                className="flex items-center px-3 py-5 border-neutral-400 border-b w-full hover:bg-neutral-200"
                onClick={onOpenConversation}
                aria-label={hasUnreadMessages ? "Has unread messages" : ""}
              >
                <div
                  className={twMerge(
                    classNames("h-2 w-2 min-w-[0.5rem] rounded-full mr-2", {
                      "bg-lilac-800": hasUnreadMessages,
                    }),
                  )}
                />
                <InitialsAvatar
                  aria-hidden
                  accent={AvatarAccent.Lilac}
                  size="sm"
                  initials={nameToInitials(chatName.split(",")[0])}
                  className="min-w-[3rem] mr-2 flex justify-center"
                />
                <div className="w-full">
                  <div className="flex w-full">
                    <Text
                      className="font-medium text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-10rem)] fs-exclude"
                      data-dd-privacy="mask"
                      data-dd-action-name="Click on chat name in all clients drawer"
                    >
                      {chatName}
                    </Text>
                    <Tag
                      className="ml-1"
                      aria-label={ariaLabel}
                      use={
                        status === ParticipantStatus.IN_SESSION
                          ? TagUse.Green
                          : TagUse.Neutral
                      }
                    >
                      {status}
                    </Tag>
                  </div>
                  <div className="text-left text-neutral-600">
                    {lastMessage ? (
                      <Text
                        variant="sm"
                        className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-8rem)] fs-exclude"
                        data-dd-privacy="mask"
                        data-dd-action-name="Click on message preview in all clients drawer"
                      >
                        {lastMessage.body}
                      </Text>
                    ) : (
                      <Text variant="sm">Write a new message</Text>
                    )}
                    {lastMessage && (
                      <Text variant="xs">
                        {lastMessage.dateCreated?.toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </Text>
                    )}
                  </div>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export function AllClientsChatDrawerWrapper() {
  const sessionConversationSid = useAtomValue(sessionConversationSidAtom);
  const sessionConversationReady = useAtomValue(sessionConversationReadyAtom);
  const waitingClients = useAtomValue(waitingVisitorsAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const messagesByConversationSid = useAtomValue(messagesByConversationSidAtom);
  const openConversation = useOpenConversationCallback();
  const participantIds =
    useAtomValue(participantIdsByConversationSidAtom)[
      sessionConversationSid ?? ""
    ] ?? new Set();
  const chatParticipants = useAtomValue(chatParticipantsByIdAtom);
  const twilioIdentity = useAtomValue(twilioIdentityAtom);
  const chatParticipantList = getChatParticipants(
    participantIds,
    chatParticipants,
    twilioIdentity,
  );
  const unreadMessageCountByConversationSid = useAtomValue(
    unreadMessageCountByConversationSidAtom,
  );
  const sessionChatTitle = Array.from(chatParticipantList)
    .map(chatParticipantToName)
    .join(", ");

  const waitingRoomConversations = Object.values(waitingClients)
    .map((client) => {
      const conversationSid = client.waitingRoomConversationSid;
      if (conversationSid) {
        return {
          conversationSid,
          status: ParticipantStatus.WAITING,
          chatName: client.name,
          hasUnreadMessages:
            unreadMessageCountByConversationSid[conversationSid] > 0,
          onOpenConversation: () => {
            openConversation(DrawerState.WAITING_ROOM_CHAT, conversationSid);
          },
          lastMessage: getMostRecentMessageInConversation(
            messagesByConversationSid,
            conversationSid,
          ),
        };
      }
    })
    .filter(Boolean) as ParticipantConversation[];
  const allConversations = [
    ...(sessionConversationReady && sessionConversationSid
      ? [
          {
            conversationSid: sessionConversationSid,
            status: ParticipantStatus.IN_SESSION,
            chatName: sessionChatTitle,
            hasUnreadMessages:
              unreadMessageCountByConversationSid[sessionConversationSid] > 0,
            onOpenConversation: () => {
              openConversation(
                DrawerState.SESSION_CHAT,
                sessionConversationSid,
              );
            },
            lastMessage: getMostRecentMessageInConversation(
              messagesByConversationSid,
              sessionConversationSid,
            ),
          },
        ]
      : []),
    ...waitingRoomConversations,
  ];

  useEffect(() => {
    // if drawer is open and all conversation participants leave, set state to empty waiting room chat
    if (!allConversations.length) {
      setDrawerState(DrawerState.WAITING_ROOM_CHAT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConversations]);

  return <AllClientsChatDrawer activeChats={allConversations} />;
}
