import { Button } from "@grow-therapy-team/sprout-ui";
import { toast } from "react-hot-toast";
import { PageComponentProps, Toast, ToastVariant } from "../../components";
import { GroupedFormData } from "../../provider/client-information/types";
import { useSubmitClientFormCallback } from "../useSubmitClientForm";
import { visitorFormValues, visitorDrawerStateAtom } from "../state";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { validateForm } from "../../components/forms/question/utils";
import { combinedFormToQuestions } from "../../components/forms/utils";
import { FormSubmissionStatusCode } from "../../components/forms/types";
import { useRecordClientMeasure } from "../useRecordClientMeasure";
import { usePreSessionPromptState } from "../waiting-room/usePreSessionPromptState";

export function ClientPendingFormsDrawerActions({
  page,
  pageIndex,
  nextPage,
  isLastPage,
}: PageComponentProps<GroupedFormData>) {
  const submitForm = useSubmitClientFormCallback();
  const [recordMeasure] = useRecordClientMeasure();

  const formValuesByTitle = useAtomValue(visitorFormValues);
  const [isLoading, setIsLoading] = useState(false);
  const { clearPreSessionPrompt } = usePreSessionPromptState();
  const startedAt = useMemo(
    () => new Date().toISOString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex],
  );
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  if (!page) return null;
  const currentFormTitle = page.formTitle;
  const formValues = formValuesByTitle?.[currentFormTitle];

  const isDisabled =
    validateForm(combinedFormToQuestions(page), formValues).size > 0;

  const closeDrawer = () => setDrawerState(null);
  const onClick = async () => {
    try {
      setIsLoading(true);
      const { code: formSubmissionResultCode } = await submitForm({
        isLastForm: isLastPage,
        pendingForm: page,
        startedAt,
        completedBy: "CLIENT",
      });

      switch (formSubmissionResultCode) {
        case FormSubmissionStatusCode.SUCCESS:
          if (isLastPage) {
            closeDrawer();
            clearPreSessionPrompt();
            toast.custom(
              ({ id: toastId }) => (
                <Toast
                  variant={ToastVariant.Success}
                  onClose={() => {
                    toast.remove(toastId);
                  }}
                >
                  Assessments Submitted!
                </Toast>
              ),
              {
                position: "bottom-right",
              },
            );
          } else {
            nextPage();
          }
          break;
        case FormSubmissionStatusCode.MISSING_REQUIRED_FIELDS:
        case FormSubmissionStatusCode.ERROR:
          toast.error("Unable to submit form due to an unexpected error.");
          break;
        default:
          break;
      }
      recordMeasure();
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col items-start">
      <Button disabled={isDisabled} loading={isLoading} onClick={onClick}>
        {isLastPage ? "Submit" : "Continue"}
      </Button>
    </div>
  );
}
