import { useCallback } from "react";
import { logger } from "../../datadog/logger";
import { useAtomCallback } from "jotai/utils";
import { sessionConversationAtom, visitorPresenceAtom } from "../state";
import { twilioRoomAtom } from "../../twilio/state";
import { CallbackSuccess } from "../../types";
import { Conversation } from "@twilio/conversations";
import { Mutex } from "async-mutex";
import { useCreateSessionConversationCallback } from "./useCreateSessionConversationCallback";
import { useUpdateSessionConversationCallback } from "./useUpdateSessionConversationCallback";
import { useSessionConversationCallbacks } from "./useSessionConversationCallbacks";

export enum CreateConversationStatusCode {
  CREATED = "CREATED",
  ALREADY_EXISTS = "ALREADY_EXISTS",
}

export const createConversationMutex = new Mutex();

/**
 * Returns a function that will create a session conversation for the provider
 * and given client, or add the client to an existing conversation
 *
 * Will join the conversation if it was created.
 */
export function useUpsertSessionConversationCallback() {
  const getSessionConversation = useAtomCallback(
    useCallback((get) => get(sessionConversationAtom), []),
  );
  const getRoomSid = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom)?.sid, []),
  );
  const getVisitorById = useAtomCallback(
    useCallback((get) => get(visitorPresenceAtom), []),
  );
  const { joinSessionConversation } = useSessionConversationCallbacks();
  const [createSessionConversation] = useCreateSessionConversationCallback();
  const [updateSessionConversation] = useUpdateSessionConversationCallback();

  return useCallback(
    async (
      visitorId: string,
    ): Promise<undefined | CallbackSuccess<Conversation>> => {
      const releaseMutex = await createConversationMutex.acquire();
      try {
        const { name: visitorName = "" } = getVisitorById()[visitorId] ?? {};
        const existingConversation = getSessionConversation();
        if (existingConversation) {
          const {
            status: updatedConversationStatus,
            value: updatedConversationValue,
          } = await updateSessionConversation({
            conversationSid: existingConversation.sid,
            visitorId,
            visitorName,
          });

          if (updatedConversationStatus === "error") {
            logger.error(
              "Unable to update session conversation",
              { visitorId, conversationSid: existingConversation.sid },
              updatedConversationValue,
            );
            return;
          }

          return {
            status: "success",
            code: CreateConversationStatusCode.ALREADY_EXISTS,
            value: existingConversation,
          };
        }

        const roomSid = getRoomSid();
        if (!roomSid) {
          logger.error("Unable to create session conversation: no room SID", {
            visitorId,
          });
          return;
        }

        const {
          status: createdConversationStatus,
          value: createdConversationValue,
        } = await createSessionConversation({
          roomSid,
          visitorId,
          visitorName,
        });

        if (createdConversationStatus === "error") {
          logger.error(
            "Unable to create session conversation",
            { visitorId, roomSid },
            createdConversationValue,
          );
          return;
        }

        const createdConversationSid =
          createdConversationValue.data?.createTwilioSessionConversation
            .conversationSid;
        if (!createdConversationSid) {
          logger.error(
            "Unable to create session conversation: no conversation SID",
            { visitorId, roomSid },
          );
          return;
        }

        const {
          status: joinedConversationStatus,
          value: joinedConversationValue,
        } = await joinSessionConversation(createdConversationSid);
        if (joinedConversationStatus === "error") {
          logger.error(
            "Unable to join session conversation",
            { visitorId, createdConversationSid, roomSid },
            joinedConversationValue,
          );
          return;
        }

        return {
          status: "success",
          code: CreateConversationStatusCode.CREATED,
          value: joinedConversationValue,
        };
      } catch (error) {
        logger.error(
          "Unexpected error occurred while upserting session conversation",
          { visitorId },
          error as Error,
        );
      } finally {
        releaseMutex();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
