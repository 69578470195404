import { useAtomValue } from "jotai";
import { ChatDrawerButton } from "../../components";
import { useChatDrawerButtonUtils } from "./useChatDrawerButtonUtils";
import { DrawerState, drawerStateAtom } from "../state";

export function ChatDrawerButtonWrapper({ drawerId }: { drawerId: string }) {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();
  const drawerState = useAtomValue(drawerStateAtom);

  return (
    <ChatDrawerButton
      drawerId={drawerId}
      forDrawerStates={[
        DrawerState.ALL_CLIENTS_CHAT_LIST,
        DrawerState.PARTICIPANT_DISCONNECTED_CHAT,
        DrawerState.SESSION_CHAT,
        DrawerState.WAITING_ROOM_CHAT,
      ]}
      drawerState={drawerState}
      messageCount={unreadMessageCount}
      onClick={toggleChatDrawer}
    />
  );
}
