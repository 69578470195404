import { Text } from "@grow-therapy-team/sprout-ui";
import { ComponentProps } from "react";
import { Appointment } from "../types";
import { asTimeWithTimeZone } from "../utils";

export interface AppointmentTimeProps extends ComponentProps<typeof Text> {
  appointment?: Pick<Appointment, "timeStart">;
  isLoading?: boolean;
}
export function AppointmentTime({
  appointment,
  isLoading,
  ...textProps
}: AppointmentTimeProps) {
  const appointmentText = isLoading ? (
    "..."
  ) : appointment?.timeStart ? (
    `Appointment at ${asTimeWithTimeZone(appointment.timeStart)}`
  ) : (
    <i>Appointment details not found</i>
  );

  return (
    <Text
      variant="sm"
      className="text-left fs-exclude text-neutral-700"
      data-dd-privacy="mask"
      data-dd-action-name="appointment time"
      data-testid="appointment-time"
      {...textProps}
    >
      {appointmentText}
    </Text>
  );
}
