import { ChatDrawer } from "../../components";
import { useAtomValue } from "jotai";
import {
  connectedAtAtom,
  currentConversationAtom,
  currentConversationMessagesAtom,
  currentConversationParticipantNamesAtom,
} from "../../twilio/state";
import { isChatErrorAtom, twilioIdentityAtom } from "../../twilio";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { VisitorState, visitorStateAtom } from "../state";

export function ChatDrawerWrapper() {
  const chatName = useAtomValue(currentConversationParticipantNamesAtom).join(
    ", ",
  );
  const conversation = useAtomValue(currentConversationAtom);
  const messages = useAtomValue(currentConversationMessagesAtom);
  const identity = useAtomValue(twilioIdentityAtom);
  const connectedAt = useAtomValue(connectedAtAtom);
  const visitorState = useAtomValue(visitorStateAtom);
  const isChatError = useAtomValue(isChatErrorAtom);
  const sendMessage = (message: string) => {
    sendLoggingEvents(TrackingEvents.ANY_MESSAGE_SEND);
    sendLoggingEvents(TrackingEvents.CLIENT_MESSAGE_SEND);
    if (visitorState === VisitorState.MEETING) {
      sendLoggingEvents(TrackingEvents.ANY_SESSION_MESSAGE_SEND);
    }
    if (visitorState === VisitorState.WAITING) {
      sendLoggingEvents(TrackingEvents.ANY_WAITING_ROOM_MESSAGE_SEND);
    }
    conversation?.sendMessage(message);
  };
  const footerText = !connectedAt
    ? "Your provider may be in a session with another client and will respond as soon as possible."
    : undefined;

  return (
    <ChatDrawer
      chatName={chatName}
      identity={identity}
      messages={messages}
      isError={isChatError}
      onSend={sendMessage}
      connectedAt={connectedAt}
      footerText={footerText}
    />
  );
}
