import { useEffect } from "react";
import { PaginatedDrawer } from "../../../components";
import { useTrackEvent } from "../../../segment/segment";
import { EventTypeEnum } from "../../../segment/types";
import { Actions } from "./mood-check-in/Actions";
import { Content } from "./mood-check-in/Content";
import { Header } from "./mood-check-in/Header";
import { MOOD_CHECKIN_CHOICES, getMaxDepth } from "./mood-check-in/utils";

export function MoodCheckInDrawer() {
  // given the dynamic nature of pages beyond the first one, the Content
  // component will handle the logic of choosing what to display, and pages need
  // only be an empty array of the correct length
  const pages = Array.from(Array(getMaxDepth(MOOD_CHECKIN_CHOICES)));

  return (
    <PaginatedDrawer
      actionsComponent={Actions}
      contentComponent={Content}
      contentOnly
      drawerBodyProps={{
        className: "bg-neutral-100",
      }}
      headerComponent={Header}
      pages={pages}
    />
  );
}

export function MoodCheckInDrawerWrapper({
  patientShortId,
}: {
  patientShortId?: string;
}) {
  const { userViewed } = useTrackEvent();

  useEffect(() => {
    if (!patientShortId) return;

    userViewed(EventTypeEnum.CLIENT_MOOD_CHECKIN, { patientShortId });
  }, [patientShortId, userViewed]);

  return <MoodCheckInDrawer />;
}
