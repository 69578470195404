import { HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function FormListItemLayout({
  buttonProps: { className: buttonClassName, ...buttonProps } = {},
  children,
  className: containerClassName,
  ...containerProps
}: {
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) {
  const baseStyles =
    "relative flex w-full items-center space-x-4 rounded py-4 text-start rebrand:rounded-none rebrand:border-b rebrand:border-neutral_rebrand-600";
  const buttonStyles =
    "hover:bg-neutral-100 rebrand:hover:bg-neutral_rebrand-400";
  return (
    <div
      className={twMerge("list-none m-0 p-0", containerClassName)}
      {...containerProps}
    >
      <button
        className={twMerge(baseStyles, buttonStyles, buttonClassName)}
        {...buttonProps}
      >
        {children}
      </button>
    </div>
  );
}
