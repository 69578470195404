import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { CallbackError, CallbackSuccess } from "../../types";
import { useCallback } from "react";

export const CREATE_TWILIO_SESSION_CONVERSATION_MUTATION_GQL = gql`
  mutation CreateTwilioSessionConversation(
    $roomSid: String!
    $visitorId: String!
    $visitorName: String!
  ) {
    createTwilioSessionConversation(
      roomSid: $roomSid
      visitorId: $visitorId
      visitorName: $visitorName
    ) {
      conversationSid
    }
  }
`;

export type CreateTwilioSessionConversationMutationData = {
  createTwilioSessionConversation: {
    conversationSid: string;
  };
};

export type CreateTwilioSessionConversationMutationVariables = {
  roomSid: string;
  visitorId: string;
  visitorName: string;
};

export function useCreateSessionConversationCallback(): [
  (
    variables: CreateTwilioSessionConversationMutationVariables,
  ) => Promise<
    | CallbackSuccess<FetchResult<CreateTwilioSessionConversationMutationData>>
    | CallbackError
  >,
  MutationResult<CreateTwilioSessionConversationMutationData>,
] {
  const [createSessionConversation, result] = useMutation<
    CreateTwilioSessionConversationMutationData,
    CreateTwilioSessionConversationMutationVariables
  >(CREATE_TWILIO_SESSION_CONVERSATION_MUTATION_GQL);

  return [
    useCallback(
      async (variables) => {
        try {
          return {
            status: "success",
            value: await createSessionConversation({ variables }),
          };
        } catch (error) {
          return {
            status: "error",
            value: error as Error,
          };
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    result,
  ];
}
