import { createContext } from "react";

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export const THEME_CONTEXT_DEFAULT_VALUE = {
  theme: Theme.LIGHT,
};

export const ThemeContext = createContext(THEME_CONTEXT_DEFAULT_VALUE);
