import { useEffect, useRef } from "react";
import { useUpdateVisitorPresenceCallback } from "./useUpdateVisitorPresenceCallback";
import { getVisitorPresenceUpdateIntervalMs } from "../../config";

export function useUpdateVisitorPresenceInterval() {
  const updateVisitorPresence = useUpdateVisitorPresenceCallback();
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(
    function continuouslyUpdateVisitorPresence() {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      updateVisitorPresence();

      intervalRef.current = setInterval(
        updateVisitorPresence,
        getVisitorPresenceUpdateIntervalMs(),
      );

      return function cleanup() {
        clearInterval(intervalRef.current);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
