import {
  faPause,
  faPlay,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { LottieRef } from "lottie-react";
import { useCallback, useReducer, useRef } from "react";
import { useLocalStorage } from "usehooks-ts";

export type ActionType = "Play" | "Pause";

export function getNextAction(currentAction: ActionType): ActionType {
  return currentAction === "Play" ? "Pause" : "Play";
}

export const ACTION_ICONS: Record<ActionType, IconDefinition> = {
  Play: faPlay,
  Pause: faPause,
};

type ReducerState = {
  nextAction: ActionType;
  lottieRef: LottieRef;
};

function animationStateReducer(
  state: {
    nextAction: ActionType;
    lottieRef: LottieRef;
  },
  action: ActionType,
) {
  const { lottieRef } = state;
  if (!lottieRef.current || !lottieRef.current?.animationItem) return state;
  const animationItem = lottieRef.current.animationItem;
  switch (action) {
    case "Play":
      animationItem.play();
      return { ...state, nextAction: "Pause" } satisfies ReducerState;
    case "Pause":
      animationItem.pause();
      return { ...state, nextAction: "Play" } satisfies ReducerState;
  }
}

const STORED_ANIMATION_STATE_KEY = "background-animation-state";

function isAnimationState(action: string): action is ActionType {
  return action === "Play" || action === "Pause";
}

export const useAnimationStateReducer = () => {
  const lottieRef = useRef(null);
  // We record to local storage when a user toggles the animation state
  // themselves, to respect their preference across sessions and during
  // interactions where we programmatically pause and play the animation.
  const [storedAnimationState, setStoredAnimationState] = useLocalStorage(
    STORED_ANIMATION_STATE_KEY,
    "Unset",
  );

  // Defer to the next state of the stored state if it is valid, otherwise
  // default to playing.
  const initialNextAction: ActionType = isAnimationState(storedAnimationState)
    ? getNextAction(storedAnimationState)
    : "Pause";
  const [{ nextAction }, dispatch] = useReducer(animationStateReducer, {
    lottieRef,
    nextAction: initialNextAction,
  });

  // As there are only two states, current state is the opposite of the next.
  const currentState = getNextAction(nextAction);

  // Toggle is always initiated by the user, so we update the stored state
  // when it is called.
  const toggle = useCallback(() => {
    dispatch(nextAction);
    setStoredAnimationState(nextAction);
  }, [nextAction, setStoredAnimationState]);

  // Pause is only initiated programmatically during other interactions, such as
  // the drawer opening.
  const pause = useCallback(() => dispatch("Pause"), []);

  // Play is only initiated programmatically during other interactions, such as
  // the drawer closing.
  const play = useCallback(() => {
    // We will respect the user's preference to pause the animation and not
    // programmatically resume it if they intentionally paused it.
    if (storedAnimationState === "Pause") return;
    dispatch("Play");
  }, [storedAnimationState]);

  return { lottieRef, toggle, pause, play, currentState };
};
