import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { Statsig } from "statsig-react";
import { twilioRoomAtom } from "../twilio/state";
import { useStoredAudioVideoSettings } from "../twilio/useStoredAudioVideoSettings";
import { participantSidsAtom, roomToDiagnosticInfo } from "../twilio";
import {
  setUserId as setFullstoryUserId,
  setUserVars as setFullstoryUserVars,
} from "../fullstory/init";
import {
  setUserId as setDatadogUserId,
  setUserVars as setDatadogUserVars,
} from "../datadog/init";
import { logger } from "../datadog/logger";
import { ExperimentLayers } from "../statsig/experiments";

function getExperimentParameters() {
  const parameters: Record<string, unknown> = {};

  Object.values(ExperimentLayers).forEach(([name, parameterMap]) => {
    Object.values(parameterMap).forEach((p) => {
      parameters[p] =
        Statsig.getLayerWithExposureLoggingDisabled(name).getValue(p);
    });
  });

  return parameters;
}

/**
 * Sets application-specific FullStory / Datadog vars i.e. all sessions will
 * have this information. This should help debug with application/user-specific
 * context.
 *
 * You can add info to the global context as desired, but take care to not add
 * PHI/PII.
 */
export function useInitUserTracking(
  userId?: string,
  additionalVars: Record<string, unknown> = {},
) {
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const remoteParticipantSids = useAtomValue(participantSidsAtom);
  const storedAudioVideoSettings =
    useStoredAudioVideoSettings()?.storedAudioVideoSettings;

  useEffect(
    function setIdentity() {
      if (!userId) return;
      try {
        setFullstoryUserId(userId);
        setDatadogUserId(userId);
      } catch (error) {
        logger.error("Unable to set identity", {}, error as Error);
      }
    },
    [userId],
  );

  useEffect(
    function setCustomVars() {
      try {
        const opts = {
          storedAudioVideoSettings: storedAudioVideoSettings,
          ...(twilioRoom
            ? {
                twilio: roomToDiagnosticInfo(twilioRoom),
              }
            : {}),
          remoteParticipantSids,
          ...additionalVars,
          experimentParameters: getExperimentParameters(),
        };

        setFullstoryUserVars(opts);
        setDatadogUserVars(opts);
      } catch (error) {
        logger.error("Unable to set user vars", {}, error as Error);
      }
    },
    [
      additionalVars,
      remoteParticipantSids,
      storedAudioVideoSettings,
      twilioRoom,
    ],
  );
}
