import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  FormSubmissionStatusCode,
  FormSubmissionMutationData,
  FormSubmissionMutationVariables,
  FormSubmissionCallbackResponse,
  FormSubmissionRequiredFields,
} from "../../../components/forms/types";
import { logger } from "../../../datadog/logger";
import { GroupedFormData } from "../types";
import {
  formValuesToFormResponse,
  trimUnusedFormValues,
  validateForm,
} from "../../../components/forms/question/utils";
import { useAtomCallback } from "jotai/utils";
import { formValuesForSelectedClientAtom } from "../state";
import { combinedFormToQuestions } from "../../../components/forms/utils";
import {
  GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
  useGetCachedPatientByShortIdCallback,
} from "../../useGetPatientByShortId";
import { selectedClientInfoAtom } from "../../state";
import { Server } from "../../../apollo/types";
import { pipe } from "remeda";

export const SUBMIT_FORM_GQL = gql`
  mutation SubmitFormResponse(
    $providerId: ID!
    $customerId: String!
    $formResponse: String!
    $formId: ID!
    $isLastForm: Boolean!
    $startedAt: DateTime!
    $completedBy: CompletedBy
  ) {
    submitFormResponse(
      values: {
        providerId: $providerId
        customerId: $customerId
        formResponse: $formResponse
        formId: $formId
        isLastForm: $isLastForm
        startedAt: $startedAt
        completedBy: $completedBy
      }
    ) {
      formSubmission {
        formId
        formResponseId: id
      }
    }
  }
`;

export function useSubmitFormMutation() {
  return useMutation<
    FormSubmissionMutationData,
    FormSubmissionMutationVariables
  >(SUBMIT_FORM_GQL, {
    context: {
      server: Server.MONOLITH,
    },
  });
}

export function useSubmitFormCallback() {
  const [submitFormMutation] = useSubmitFormMutation();
  const getFormValuesByTitle = useAtomCallback(
    useCallback((get) => get(formValuesForSelectedClientAtom), []),
  );
  const getCachedPatientByShortIdCallback =
    useGetCachedPatientByShortIdCallback();
  const getSelectedPatientShortId = useAtomCallback(
    useCallback((get) => get(selectedClientInfoAtom)?.patientShortId, []),
  );

  return useCallback(
    async ({
      pendingForm,
      isLastForm,
      startedAt,
      completedBy,
    }: {
      pendingForm: GroupedFormData;
      startedAt: string;
      isLastForm?: boolean;
      completedBy?: "PROVIDER" | "CLIENT";
    }): Promise<FormSubmissionCallbackResponse> => {
      try {
        const formValuesByTitle = getFormValuesByTitle();
        const questions = combinedFormToQuestions(pendingForm);
        const pendingFormTitle = pendingForm.formTitle;
        const formValues =
          formValuesByTitle && formValuesByTitle[pendingFormTitle];
        const validationErrors = validateForm(questions, formValues);

        if (!formValues) {
          return {
            status: "failure",
            code: FormSubmissionStatusCode.MISSING_FORM_VALUES,
            value: null,
          };
        }

        if (validationErrors.size) {
          return {
            status: "failure",
            code: FormSubmissionStatusCode.VALIDATION_ERROR,
            value: validationErrors,
          };
        }

        const patientShortId = getSelectedPatientShortId();
        if (!patientShortId) {
          logger.error("Missing patient short ID when submitting form");
          return {
            status: "failure",
            code: FormSubmissionStatusCode.MISSING_REQUIRED_FIELDS,
            value: new Set(["patientShortId"]),
          };
        }

        const cachedPatientData = getCachedPatientByShortIdCallback({
          patientShortId,
        });

        const missingFields = new Set<FormSubmissionRequiredFields>();
        const customerId = cachedPatientData?.customerId;
        if (!customerId) {
          missingFields.add("customerId");
        }
        const providerId = cachedPatientData?.provider?.id;
        if (!providerId) {
          missingFields.add("providerId");
        }
        if (missingFields.size) {
          logger.error("Missing required fields when submitting form", {
            missingFields,
          });
          return {
            status: "failure",
            code: FormSubmissionStatusCode.MISSING_REQUIRED_FIELDS,
            value: missingFields,
          };
        }

        try {
          const response = await submitFormMutation({
            variables: {
              customerId: customerId!,
              formId: pendingForm.formId,
              formResponse: pipe(
                formValues,
                (formValues) =>
                  trimUnusedFormValues(
                    formValues,
                    questions.map((q) => q.questionElement!),
                  ),
                formValuesToFormResponse,
                JSON.stringify,
              ),
              isLastForm: !!isLastForm,
              providerId: providerId!,
              startedAt,
              completedBy,
            },
            refetchQueries: [GET_PATIENT_BY_PATIENT_SHORT_ID_GQL],
          });
          return {
            status: "success",
            code: FormSubmissionStatusCode.SUCCESS,
            value: response.data?.submitFormResponse?.formSubmission,
          };
        } catch (e) {
          const error = e as Error;
          logger.error("Error while submitting form", {}, error);
          return {
            status: "failure",
            code: FormSubmissionStatusCode.ERROR,
            value: error,
          };
        }
      } catch (e) {
        const error = e as Error;
        logger.error(
          "Unexpected error while executing submit-form callback",
          {},
          error,
        );
        return {
          status: "failure",
          code: FormSubmissionStatusCode.ERROR,
          value: error,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
