import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { useRecordClientMoodMutation } from "../../hooks/measures/useRecordClientMoodMutation";
import { VisitorPresence } from "../../types";
import { arrayEquals } from "../../utils";
import { visitorPresenceAtom } from "../state";
import { useClientEmotionsCallback } from "../../hooks/measures/useClientMood";

/**
 * This hook is used to sync the mood checkin data each time visitorPresenceAtom
 * updates. It is used to ensure that the mood checkin data is up-to-date with
 * the client's mood checkin data. There is no return value, but will set up
 * apollo cache to return client-specific data when calling useMoodCheckin below
 */
export function useSyncMoodCheckin() {
  const visitorPresence = useAtomValue(visitorPresenceAtom);
  const getClientEmotions = useClientEmotionsCallback();
  const previousEmotionsRef = useRef<
    Record<string, VisitorPresence["emotions"]>
  >({});
  const [onSaveClientMood] = useRecordClientMoodMutation();

  useEffect(() => {
    const visitorIds = Object.keys(visitorPresence);
    visitorIds.forEach((id) => {
      const emotions = getClientEmotions(id);

      if (!emotions?.length) return;

      const previousEmotions = previousEmotionsRef.current[id];

      if (arrayEquals(emotions, previousEmotions)) return;

      previousEmotionsRef.current[id] = emotions;

      // NOTE: this is a fire-and-forget mutation, so we don't need to wait for
      // it, and we made a conscious decision not to handle errors.
      onSaveClientMood({
        variables: { visitorId: id, emotions },
      });
    });
  }, [getClientEmotions, onSaveClientMood, visitorPresence]);
}
