import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CallbackError, CallbackSuccess } from "../../types";

const UPDATE_TWILIO_SESSION_CONVERSATION_MUTATION_GQL = gql`
  mutation UpdateTwilioSessionConversation(
    $conversationSid: String!
    $visitorId: String!
    $visitorName: String!
  ) {
    updateTwilioSessionConversation(
      conversationSid: $conversationSid
      visitorId: $visitorId
      visitorName: $visitorName
    ) {
      participantSid
    }
  }
`;

type UpdateTwilioSessionConversationMutationVariables = {
  conversationSid: string;
  visitorId: string;
  visitorName: string;
};

type UpdateTwilioSessionConversationMutationData = {
  updateTwilioSessionConversation: {
    participantSid: string;
  };
};

export function useUpdateSessionConversationCallback(): [
  (
    variables: UpdateTwilioSessionConversationMutationVariables,
  ) => Promise<
    | CallbackSuccess<FetchResult<UpdateTwilioSessionConversationMutationData>>
    | CallbackError
  >,
  MutationResult<UpdateTwilioSessionConversationMutationData>,
] {
  const [updateSessionConversation, result] = useMutation<
    UpdateTwilioSessionConversationMutationData,
    UpdateTwilioSessionConversationMutationVariables
  >(UPDATE_TWILIO_SESSION_CONVERSATION_MUTATION_GQL);

  return [
    useCallback(
      async (variables: UpdateTwilioSessionConversationMutationVariables) => {
        try {
          return {
            status: "success",
            value: await updateSessionConversation({
              variables,
            }),
          };
        } catch (error) {
          return {
            status: "error",
            value: error as Error,
          };
        }
      },
      [updateSessionConversation],
    ),
    result,
  ];
}
