// import classNames from "classnames";
import { HTMLProps } from "react";

export function ActiveSpeakerOutline(
  _props: { isOutlined: boolean } & HTMLProps<HTMLDivElement>,
) {
  return null;
  // return (
  //   <div
  //     {...divProps}
  //     className={classNames(
  //       "absolute w-full h-full bg-none pointer-events-none rounded-2xl transition-shadow",
  //       {
  //         "shadow-border-rebrand-lilac-500": isOutlined,
  //       },
  //     )}
  //   />
  // );
}
