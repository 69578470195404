import {
  ButtonSize,
  DRAWER_TITLE_ID,
  IconButton,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "../../components";
import { GroupedFormData } from "./types";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

export function PendingFormsDrawerHeader({
  page,
  backButtonDisabled,
  isFirstPage,
  prevPage,
}: PageComponentProps<GroupedFormData>) {
  return (
    <div className="flex flex-nowrap">
      {!isFirstPage && (
        <IconButton
          disabled={backButtonDisabled}
          size={ButtonSize.Small}
          iconDefinition={faArrowLeft}
          onClick={prevPage}
          aria-label="Go back"
          data-testid="pending-measures-drawer-header.back-button"
          className="mr-4 text-medium w-8 h-8 flex justify-center items-center"
        />
      )}
      <Text className="font-medium" variant="lg" as="h4" id={DRAWER_TITLE_ID}>
        {page.formTitle}
      </Text>
    </div>
  );
}
