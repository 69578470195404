import { Heading, tailwindMerge } from "@grow-therapy-team/sprout-ui";

export interface ProgressBarProps {
  className?: string;
  heading?: string;
  currentStep: number;
  currentStepPercentage?: number;
  totalSteps: number;
}

const ProgressPill = ({
  filled,
  percentage = 100,
}: {
  filled?: boolean;
  percentage?: number;
}): JSX.Element => {
  return (
    <span
      data-testid="progress-bar.progress-pill"
      className={`absolute h-full rounded ${
        filled ? "bg-lilac-700" : "bg-neutral_rebrand-500"
      }`}
      style={{ width: `${percentage}%` }}
    />
  );
};

export const ProgressBar = ({
  className,
  heading,
  currentStep,
  currentStepPercentage = 100,
  totalSteps,
}: ProgressBarProps): JSX.Element | undefined => {
  if (!heading && totalSteps <= 1) {
    return;
  }

  return (
    <div
      data-testid="progress-bar.wrapper"
      className={tailwindMerge("flex grow flex-col gap-y-4", className)}
    >
      {heading && <Heading variant="md">{heading}</Heading>}
      {totalSteps > 1 && (
        <div className="flex w-full gap-x-2">
          {[...Array(totalSteps)].map((_, index) => (
            <span
              key={`progress-bar-step-${index}`}
              className="relative h-1.5 grow"
            >
              <ProgressPill />
              {index <= currentStep && (
                <ProgressPill
                  filled
                  percentage={
                    index === currentStep ? currentStepPercentage : 100
                  }
                />
              )}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
