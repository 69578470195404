import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { DrawerState, drawerStateAtom, selectedClientIdAtom } from "../state";
export function useOpenClientInformationCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSelectedClientId = useSetAtom(selectedClientIdAtom);

  return useCallback(
    (visitorId: string) => {
      setSelectedClientId(visitorId);
      setDrawerState(DrawerState.CLIENT_INFORMATION);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
