import {
  Alert,
  Button,
  ButtonSize,
  CalloutBanner,
  Card,
  Icon,
  Text,
} from "@grow-therapy-team/sprout-ui";
import PreSessionImage from "../../assets/images/pre-session-image.svg?react";
import {
  faChevronRight,
  faPauseCircle,
  faPenLine,
} from "@fortawesome/pro-solid-svg-icons";
import { useAtom } from "jotai";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { useEffect } from "react";
import { DrawerButton } from "../../components/DrawerButton";

interface PreSessionPromptProps {
  callToActionContent: React.ReactNode;
  openHelpModal: () => void;
  showTabSwitchWarning: boolean;
}

export function PreSessionPrompt({
  callToActionContent,
  openHelpModal,
  showTabSwitchWarning,
}: PreSessionPromptProps) {
  return (
    <Card className="flex flex-col mobile:border-x rounded-xl items-center max-w-[38rem] gap-y-10 sm:p-10">
      <div className="hidden sm:block">
        <PreSessionImage aria-label="A light purple sketch of a pair of hands clasped together." />
      </div>

      <div className="flex flex-col gap-y-4 items-center">
        <Text as="h1" variant="lg" className="font-medium">
          You&apos;re in the right place.
        </Text>

        <Text className="text-neutral-700 text-center">
          You&apos;ll be notified when your provider is ready to start your
          session. They have a 10-min grace period.
        </Text>

        {showTabSwitchWarning && (
          <div data-testid="tab-switch-warning-callout">
            <Alert
              use="warning"
              heading="Please stay here while you wait."
              icon={faPauseCircle}
            >
              <Text variant="sm" className="text-neutral-900 pt-1">
                Switching tabs or apps will remove you from the waiting room and
                will require you to join the session again.
              </Text>
            </Alert>
          </div>
        )}

        {callToActionContent}

        <div className="flex justify-center">
          <Button
            onClick={openHelpModal}
            use="link"
            size={ButtonSize.Default}
            className="pt-2"
          >
            Need help?
          </Button>
        </div>
      </div>
    </Card>
  );
}

type CTAProps = { openDrawer: (drawer: VisitorDrawerState) => void };

function ClientMeasuresCta({ openDrawer }: CTAProps) {
  return (
    <CalloutBanner
      use="lilac"
      className="border-none w-fit"
      heading="Take this time for a mental health check-in"
      headingVariant="md"
      icon={faPenLine}
      data-testid="pre-session-prompt-client-measures"
    >
      <div className="flex flex-col gap-y-4">
        <Text>
          These short surveys help your provider better understand how
          you&apos;ve been doing.
        </Text>
        <Button
          className="flex gap-4 justify-center items-center w-full"
          onClick={() => openDrawer(VisitorDrawerState.PENDING_FORMS)}
        >
          <span>Start check-in</span>
          <Icon aria-hidden icon={faChevronRight} />
        </Button>
      </div>
    </CalloutBanner>
  );
}

function MoodSurveyCta({ openDrawer }: CTAProps) {
  const [drawerState, setDrawerState] = useAtom(visitorDrawerStateAtom);

  useEffect(() => {
    return function closeDrawerOnUmount() {
      if (drawerState !== VisitorDrawerState.MOOD_CHECK_IN_FORM) return;

      setDrawerState(null);
    };
  }, [drawerState, setDrawerState]);

  return (
    <>
      <Text
        data-testid="pre-session-prompt-mood-survey"
        className="font-medium"
      >
        In the meantime, take a moment to...
      </Text>

      <Button
        className="flex gap-4 justify-center items-center w-full"
        onClick={() => openDrawer(VisitorDrawerState.MOOD_CHECK_IN_FORM)}
      >
        <span>Check in with your mood</span>
        <Icon aria-hidden icon={faChevronRight} />
      </Button>
    </>
  );
}

function TipsCta({ openDrawer }: CTAProps) {
  return (
    <>
      <Text data-testid="pre-session-prompt-tips" className="font-medium">
        In the meantime, take a moment to...
      </Text>

      <DrawerButton
        as={Button}
        forDrawerStates={VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION}
        className="flex gap-4 justify-center items-center w-full"
        onClick={() =>
          openDrawer(VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION)
        }
      >
        <span>See tips for your session</span>
        <Icon aria-hidden icon={faChevronRight} />
      </DrawerButton>
    </>
  );
}

export enum PreSessionPromptVariant {
  CLIENT_MEASURES = "CLIENT_MEASURES",
  MOOD_SURVEY = "MOOD_SURVEY",
  TIPS = "TIPS",
}

export const VariantComponents: Record<
  PreSessionPromptVariant,
  React.FC<{
    openDrawer: (drawer: VisitorDrawerState) => void;
  }>
> = {
  [PreSessionPromptVariant.CLIENT_MEASURES]: ClientMeasuresCta,
  [PreSessionPromptVariant.MOOD_SURVEY]: MoodSurveyCta,
  [PreSessionPromptVariant.TIPS]: TipsCta,
};

export function PreSessionPromptWrapper({
  variant,
  showTabSwitchWarning = false,
  openHelpModal,
  openDrawer,
}: {
  variant: PreSessionPromptVariant;
  showTabSwitchWarning?: boolean;
  openHelpModal: () => void;
  openDrawer: (drawer: VisitorDrawerState) => void;
}) {
  const CallToAction = VariantComponents[variant];
  return (
    <PreSessionPrompt
      callToActionContent={<CallToAction openDrawer={openDrawer} />}
      openHelpModal={openHelpModal}
      showTabSwitchWarning={showTabSwitchWarning}
    />
  );
}
