import { atom, useAtom } from "jotai";
import { getMostSpecificEmotion } from "../../../../hooks/measures/useClientMood";
import { GetClientMoodQueryData } from "../../../../hooks/measures/useClientMoodQuery";
import { createDerivedWritableAtom } from "../../../../utils";

export const moodCheckinAtom = atom<GetClientMoodQueryData["clientMood"]>({
  __typename: "ClientMood",
  emotions: [],
  moodCheckinAt: undefined,
});

export const moodCheckinSelectionsAtom = createDerivedWritableAtom(
  moodCheckinAtom,
  "emotions",
);

export function useMoodCheckinSelectionsState() {
  return useAtom(moodCheckinSelectionsAtom);
}

export const recordedMoodCheckin = atom((get) => {
  const moodCheckin = get(moodCheckinAtom);

  if (!moodCheckin.moodCheckinAt) return undefined;

  return getMostSpecificEmotion(moodCheckin.emotions);
});
